import {FC, PropsWithChildren, useState} from "react";
import {IUser} from "../models/IUser";
import {stateContext} from "../hooks/useStateContext";
export const ContextProvider: FC<PropsWithChildren> = ({ children }) => {

    const _ACCESS_TOKEN = "ACCESS_TOKEN";

    const [user, setUser] = useState<IUser>({} as IUser);
    const [lockscreen, _setLockscreen] = useState<string | null>(localStorage.getItem('LOCKSCREEN'));
    const [token, _setToken] = useState<string | null>(localStorage.getItem('ACCESS_TOKEN') ? localStorage.getItem('ACCESS_TOKEN') : sessionStorage.getItem('ACCESS_TOKEN'));

    const setToken = (token: string | null) => {
        _setToken(token);
        if (token) {
            localStorage.setItem(_ACCESS_TOKEN, window.btoa(token))
        }
        else {
            localStorage.removeItem(_ACCESS_TOKEN)
        }
    }

    const setTokenSession = (token: string | null) => {
        _setToken(token);
        if (token) {
            sessionStorage.setItem(_ACCESS_TOKEN, window.btoa(token))
        }
        else {
            sessionStorage.removeItem(_ACCESS_TOKEN)
        }
    }

    const setLockscreen = (screen: string | null) => {
        _setLockscreen(screen);
        if (screen) {
            localStorage.setItem('LOCKSCREEN', screen)
        }
        else {
            localStorage.removeItem('LOCKSCREEN')
        }
    }

    return (
        <stateContext.Provider
            value={{
                user,
                token,
                lockscreen,
                setLockscreen,
                setToken,
                setTokenSession,
                setUser
            }}
        >
            {children}
        </stateContext.Provider>
    )
}