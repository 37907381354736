import React from 'react';
import {
    Container
} from "reactstrap";
import DevicesChat from "../components/Chat/devices/DevicesChat";

const Chat = () => {

    return (
        <div className="page-content">
            <Container fluid>
                <DevicesChat/>
            </Container>
        </div>
    );
};

export default Chat;
