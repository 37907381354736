import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {useAppSelector} from "../../../hooks/redux";
import TableDefect from "./tables/TableDefect";
import ModalAddDefect from "./ModalAddDefect";
import Select from "react-select";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {convertDate, convertDateFull, convertDateTimeFull1C} from "../../../functions/ConvertDate";
import ModalAddCompl from "./ModalAddCompl";
import TableCompl from "./tables/TableCompl";
import TableZamers from "./tables/TableZamers";
import ModalAddZamer from "./ModalAddZamer";
import AxiosClient from "../../../api/AxiosClient";
import InfoLoaderModal from "../../layouts/SceletonLoaders/InfoLoaderModal";

interface IModalAddActEquipmentInspectionCertificate {
    id: string,
    setModalUpdate: (value: boolean) => void,
    modalUpdate: boolean
}

const ModalAddActEquipmentInspectionCertificate: FC<IModalAddActEquipmentInspectionCertificate> = ({id, setModalUpdate, modalUpdate}) => {
    const {date} = useAppSelector(state => state.WidgetReducer)
    const [dateTime, setDateTime] = useState(new Date());
    const [planRemont, setPlanRemont] = useState(new Date());

    const [defects, setDefects] = useState<any[]>([]);
    const [compl, setCompl] = useState<any[]>([]);
    const [zamers, setZamers] = useState<any[]>([]);
    const [addZamers, setAddZamers] = useState(false);
    const [addDefect, setAddDefect] = useState(false);
    const [addCompl, setAddCompl] = useState(false);
    const [warehouses, setWarehouses] = useState<any[]>([]);
    const [objectRepair, setObjectRepair] = useState<any[]>([]);
    const [selectWarehouse, setSelectWarehouse] = useState<any>('');
    const [selectObjectRepair, setSelectObjectRepair] = useState<any>('');
    const [info, setInfo] = useState<any[]>([]);
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [main, setMain] = useState<string>('');
    const [selectMainEquipment, setSelectMainEquipment] = useState<any>(null);
    const [selectOrganization, setSelectOrganization] = useState<any>('');
    const [typeView, setTypeView] = useState<any[]>([]);
    const [selectTypeView, setSelectTypeView] = useState<any | null>(null);
    const [selectMain, setSelectMain] = useState<any>('');
    const [loading, setLoading] = useState(false);

    const [dataDoc, setDataDoc] = useState<any>({});
    useEffect(() => {

        if (id){
            setLoading(true)
            WidgetService.getInfoActInspection(id).then(r => {
                setDataDoc(r.data[0])
                setDateTime(new Date(convertDateFull(r.data[0].date.split(' ')[0])))
                setPlanRemont(new Date(convertDateFull(r.data[0].repair_plan_data)))
                setSelectObjectRepair({value: r.data[0].repair_obj_id, label: r.data[0].repair_obj})
                setSelectMainEquipment({value: r.data[0].repair_id, label: r.data[0].repair})
                setSelectWarehouse({value: r.data[0].sklad_id, label: r.data[0].sklad})
                setSelectTypeView({value: r.data[0].type_repair_id, label: r.data[0].type_repair})
                setSelectOrganization({value: r.data[0].org_id, label: r.data[0].org_name})
                setSelectMain({value: r.data[0].osnovanue_id})
                setCompl(r.data[0].Material_repair ? r.data[0].Material_repair?.map((mat: any) => ({name: mat.material, count: mat.material_count, uid: mat.material_id})) : [])
                setDefects(r.data[0].Defect_repair ? r.data[0].Defect_repair?.map((mat: any) => (
                    {
                        name_type_defect: mat.vid_defect,
                        name_critical_defect: mat.defect_criticality,
                        name_cause_defect: mat.defect_causes,
                        cause_defect_uid: mat.defect_causes_id,
                        critical_defect_uid: mat.defect_criticality_id,
                        type_defect_uid: mat.vid_defect_id,
                        description: mat.defect_opisanie
                    }
                )) : [])
                setZamers(r.data[0].Control_repair ? r.data[0]?.Control_repair?.map((mat: any) => (
                    {
                        name: mat.control,
                        uid: mat.control_uid,
                        count: mat.zamer_count
                    }
                )) : [])
                setLoading(false)
            })
        }
    }, [id]);

    useEffect(() => {
        WidgetService.getListObjectWarehouse(date).then(r => {
            setWarehouses(r.data)
        })
        WidgetService.getDispatchServiceRequest(date).then(r => {
            setData(r.data)
        })
    }, [date]);

    useEffect(() => {
        main &&
        WidgetService.getInfoDispatchServiceRequest(main).then(
            (r) => {
                WidgetService.getInfoObject(dateTime, r.data[0].sklad_id, r.data[0].repair_id)
                    .then((r) => setInfo(r.data))
            }
        )
    }, [main, dateTime]);

    useEffect(() => {
        WidgetService.getInfoOrganization().then(r => setOrganizations(r.data))
        WidgetService.getListTypeView().then(r => setTypeView(r.data))
    }, []);

    useEffect(() => {
        selectWarehouse &&
        WidgetService.getListObjectRepair(dateTime, selectWarehouse.value)
            .then((r) => setObjectRepair(r.data))
    }, [dateTime, selectWarehouse]);

    useEffect(() => {
        selectWarehouse && selectObjectRepair &&
        WidgetService.getInfoObject(dateTime, selectWarehouse.value, selectObjectRepair.value)
            .then((r) => setInfo(r.data))
    }, [dateTime, selectWarehouse, selectObjectRepair]);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }
    function selectMainLabel(selectMain: any, data: any[]){
        const label = data.find(data => data.doc_id === selectMain.value)
        if (!label){
            return {value: '', label: ''}
        }
        return {...selectMain, label: label?.date + " "  + label?.equipment + " " + label?.sklad}
    }

    return (
        <Modal id="createProjectModal" isOpen={modalUpdate}
               toggle={() => setModalUpdate(!modalUpdate)}
               modalClassName="zoomIn" tabIndex={-1} centered size={"xl"}>
            <ModalHeader toggle={() => setModalUpdate(!modalUpdate)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновить акт осмотра оборудования
            </ModalHeader>
            <ModalBody>
                {
                    loading ? <Row><Col className="d-flex justify-content-center"><InfoLoaderModal msg="Загружаю информацию по акту"/></Col></Row>
                        :
                        <Formik
                            enableReinitialize
                            initialValues={
                                {
                                    zakl: dataDoc.zakl,
                                }
                            }
                            onSubmit={(values) => {
                                const data = {
                                    nomer: dataDoc?.nomer,
                                    disp_zayavka_uid: dataDoc.osnovanue_id,
                                    date: convertDateTimeFull1C(dateTime),
                                    date_plan: convertDate(planRemont),
                                    sklad_id: selectWarehouse.value,
                                    org_uid: selectOrganization.value,
                                    repair_obj_id: selectObjectRepair.value,
                                    repair_id: selectMainEquipment.value,
                                    zavod: info[0].zavod,
                                    inv_nomer: info[0]?.inv_nomer,
                                    zavod_nomer: info[0]?.zavod_nomer,
                                    main_price: info[0]?.main_price,
                                    date_of_operation: info[0]?.date_of_operation,
                                    useful_life: info[0]?.useful_life,
                                    priority: info[0]?.priority,
                                    phasing: info[0]?.phasing,
                                    marka: info[0]?.marka,
                                    defect_repair: defects,
                                    zakl: values.zakl,
                                    compl: compl,
                                    zamer: zamers,
                                    type_view: selectTypeView.value,
                                }
                                AxiosClient.post('/save_inspection_act', {doc_id: id, data: data})
                                    .then(() => {
                                        setModalUpdate(false)
                                    })
                            }}>
                            <Form>
                                <Row className="mb-1">
                                    <Col sm={12} lg={12} xl={6} xxl={6}>
                                        <fieldset className="border-dark">
                                            <legend>Информация по акту осмотра</legend>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Диспетчерские заявки:</label>
                                                    <Select options={data.map(data => ({value: data.doc_id, label: data.date + " "  + data.equipment + " " + data.sklad}))}
                                                            name="stock"
                                                            value={
                                                                selectMainLabel(selectMain, data)
                                                            }
                                                            onChange={(change: any) => {
                                                                setMain(change.value)
                                                            }}
                                                            styles={customStyles}
                                                            placeholder="Поиск"/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col className="mb-1">
                                                    <label>Дата:</label>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <Flatpickr
                                                                className="form-control border-0 dash-filter-picker shadow"
                                                                value={dateTime}
                                                                options={{
                                                                    locale: Russian,
                                                                    mode: "single",
                                                                    dateFormat: "d.m.Y",
                                                                }}
                                                                onChange={(date) => setDateTime(date[0])}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <label>Организация:</label>
                                                    <Select options={organizations?.map(org => ({value: org.uid, label: org.name}))}
                                                            name="organization"
                                                            value={selectOrganization}
                                                            onChange={(change: any) => {
                                                                setSelectOrganization({value: change.value, label: change.label})
                                                            }}
                                                            styles={customStyles}
                                                            placeholder="Поиск"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Склад:</label>
                                                    <Select options={warehouses.map(warehouses => ({value: warehouses.uid, label: warehouses.name}))}
                                                            name="stock"
                                                            value={selectWarehouse}
                                                            onChange={(change: any) => {
                                                                setSelectWarehouse({value: change.value, label: change.label})
                                                            }}
                                                            styles={customStyles}
                                                            placeholder="Поиск"/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Основное средство:</label>
                                                    <Select options={objectRepair.map(object => ({value: object.os_uid, label: object.os_name}))}
                                                            name="fixed_asset"
                                                            value={selectMainEquipment}
                                                            onChange={(change: any) => {
                                                                setSelectMainEquipment({value: change.value, label: change.label})
                                                            }}
                                                            styles={customStyles}
                                                            placeholder="Поиск"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Объект ремонта:</label>
                                                    <Select options={objectRepair.map(object => ({value: object.uid, label: object.repair_name}))}
                                                            name="element"
                                                            value={selectObjectRepair}
                                                            onChange={(change: any) => {
                                                                setSelectObjectRepair({value: change.value, label: change.label})
                                                            }}
                                                            styles={customStyles}
                                                            placeholder="Поиск"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Вид ремонтных работ:</label>
                                                    <Select options={typeView.map(object => ({value: object.uid, label: object.name}))}
                                                            name="element"
                                                            value={selectTypeView}
                                                            onChange={(change: any) => {
                                                                setSelectTypeView({value: change.value, label: change.label})
                                                            }}
                                                            styles={customStyles}
                                                            placeholder="Поиск"
                                                    />
                                                </Col>
                                            </Row>

                                        </fieldset>
                                    </Col>
                                    <Col>
                                        <fieldset className="border-dark">
                                            <legend>Информация по основному средству</legend>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Завод изготовитель:</label>
                                                    <input className="form-control" value={dataDoc?.zavod} disabled/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Инвентарный номер:</label>
                                                    <div className="input-group">
                                                        <input className="form-control" name="invent_number" value={dataDoc?.inv_nomer} disabled/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <label>Заводской номер:</label>
                                                    <div className="input-group">
                                                        <input className="form-control" name="invent_number" value={dataDoc?.zavod_nomer} disabled/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Cтоимость:</label>
                                                    <div className="input-group">
                                                        <input className="form-control" name="invent_number" value={dataDoc?.main_price} disabled/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <label>Ввод в эксплуатацию:</label>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <input className="form-control" name="invent_number" value={dataDoc?.date_of_operation} disabled/>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Срок использования:</label>
                                                    <div className="input-group">
                                                        <input className="form-control" name="invent_number" value={dataDoc?.useful_life} disabled/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <label>Приоритет:</label>
                                                    <input className="form-control" name="invent_number" value={dataDoc?.priority} disabled/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Фазность:</label>
                                                    <input className="form-control" name="invent_number" value={dataDoc?.phasing} disabled/>
                                                </Col>
                                                <Col>
                                                    <label>Марка:</label>
                                                    <input className="form-control" name="invent_number" value={dataDoc?.marka} disabled/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-1">
                                                <Col>
                                                    <label>Плановая дата ремонта:</label>
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <Flatpickr
                                                                className="form-control border-0 dash-filter-picker shadow"
                                                                value={planRemont}
                                                                options={{
                                                                    locale: Russian,
                                                                    mode: "single",
                                                                    dateFormat: "d.m.Y",
                                                                }}
                                                                onChange={(date) => setPlanRemont(date[0])}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="h-100">
                                        <label>Заключение:</label>
                                        <Field name="zakl" as="textarea" className="form-control h-100" rows={9} />
                                    </Col>
                                </Row>
                                <fieldset>
                                    <legend>Список замеров</legend>
                                    <TableZamers compl={zamers} setCompl={(zam) => setZamers(zam)}/>
                                    <button type="button" className="btn mt-3 btn-primary" id="addNewProject" onClick={() => setAddZamers(true)}>Добавить замеры</button>
                                </fieldset>
                                <fieldset>
                                    <legend>Список комплектующих</legend>
                                    <TableCompl compl={compl} setCompl={(comp) => setCompl(comp)}/>
                                    <button type="button" className="btn mt-3 btn-primary" id="addNewProject" onClick={() => setAddCompl(true)}>Добавить комплектующие</button>
                                </fieldset>
                                <fieldset className="mt-3">
                                    <legend>Список дефектов</legend>
                                    <TableDefect defect={defects} setCompl={(defect) => setDefects(defect)}/>
                                    <button type="button" className="btn mt-3 btn-primary" id="addNewProject" onClick={() => setAddDefect(true)}>Добавить дефект</button>
                                </fieldset>
                                <div className="hstack gap-2 justify-content-end mt-3">
                                    <button type="submit" className="btn btn-primary" id="addNewProject">Сохранить</button>
                                </div>
                            </Form>
                        </Formik>
                }
            </ModalBody>
            <ModalAddZamer addDefect={addZamers} setAddDefect={(data) => setAddZamers(data)} setAddNewDefect={(zam) => setZamers([...zamers, zam])}/>
            <ModalAddCompl addDefect={addCompl} setAddDefect={(data) => setAddCompl(data)} setAddNewDefect={(comp) => setCompl([...compl, comp])}/>
            <ModalAddDefect addDefect={addDefect} setAddDefect={(data) => setAddDefect(data)} setAddNewDefect={(defect) => setDefects([...defects, defect])}/>
        </Modal>
    );
};



export default ModalAddActEquipmentInspectionCertificate;
