import React from 'react';

const SuccessPayInfo = () => {
    return (
        <div className='d-flex justify-content-center'>
            <div className='d-grid justify-content-center'>
                <h2>Оплачено</h2>
            </div>
        </div>
    );
};

export default SuccessPayInfo;
