import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {Col, Label, Row} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {updatePasswordUser} from "../../../store/reducers/admin/ActionCreator";

const UpdatePasswordForm = () => {
    const dispatch = useAppDispatch();
    const {selectUser} = useAppSelector(state => state.AdminReducer);
    return (
        <Formik
            initialValues={
                {
                    newpassword: '',
                    newpassword_confirmation: ''
                }
            }
            validationSchema={
                Yup.object(
                    {
                        newpassword: Yup.string().required('Введите новый пароль').min(8, 'Минимальная длина пароля 8 символов'),
                        newpassword_confirmation: Yup.string().required('Повторите новый пароль').oneOf([Yup.ref('newpassword'), "null"], 'Пароли не совпадают')
                    }
                )
            }
            onSubmit={
                (value, {resetForm}) => {
                    dispatch(updatePasswordUser(value, selectUser!.id))
                    resetForm()
                }
            }
        >
            <Form>
                <Row className="g-2">
                    <Col lg={6}>
                        <div>
                            <Label htmlFor="newpasswordInput" className="form-label">Новый
                                пароль*</Label>
                            <Field type="password" className="form-control"
                                   name="newpassword"
                                   id="newpasswordInput"
                                   placeholder="Введите новый пароль" autoComplete="off"/>
                            <ErrorMessage name="newpassword">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <Label htmlFor="confirmpasswordInput"
                                   className="form-label">Подтвердите пароль*</Label>
                            <Field type="password" className="form-control"
                                   name="newpassword_confirmation"
                                   id="confirmpasswordInput"
                                   placeholder="Подтвердите пароль" autoComplete="off"/>
                            <ErrorMessage name="newpassword_confirmation">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="text-end">
                            <button type="submit" className="btn btn-primary">Обновить
                                пароль
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdatePasswordForm;
