import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import AxiosClient from "../../api/AxiosClient";
import moment from "moment";

interface IPropsStatisticsTable {
    type: 'default' | 'kshp',
    classId?: string,
    buildId?: string,
    schoolId?: string,
}

const StatisticTable: FC<IPropsStatisticsTable> = ({type, buildId = '', classId = '', schoolId = ''}) => {
    const [statisticsData, setStatisticsData] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [search, setSearch] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    useEffect(() => {
        fetchStatistics(1)
    }, []);

    useEffect(() => {
        fetchStatistics(1, perPage, schoolId, buildId, classId)
    }, [buildId, schoolId, classId]);
    
    const fetchStatistics = async (page = 10, perPage = 10, schoolId = '', buildId = '', classId = '') => {
        setSearch(true)
        type === "default" &&
        AxiosClient.get('/users/statistics_login', {params: {page, perPage}})
            .then(r => {
                setStatisticsData(r.data.users)
                setTotalRows(r.data.count)
                setSearch(false)
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            })
        type === "kshp" &&
        AxiosClient.get('/users/statistics_login_kshp', {params: {page, perPage, schoolId, buildId, classId}})
            .then(r => {
                setStatisticsData(r.data.users)
                setTotalRows(r.data.count)
                setSearch(false)
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            })
    }

    const handlePageChange = async (page: number) => {
        await fetchStatistics(page, perPage);
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        await fetchStatistics(page, newPerPage);
        setPerPage(newPerPage);
    };

    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>ФИО</span>,
            selector: (row: any) => `${row.middle_name} ${row.name} ${row.last_name}`,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Первый вход</span>,
            selector: (row: any) => row.login_history[0] ? moment(new Date(row.login_history[0].min_login_date)).format('DD.MM.YYYY') : 'Не входили',
        },
        {
            name: <span className='font-weight-bold fs-13'>Последний вход</span>,
            selector: (row: any) => row.login_history[0] ? moment(new Date(row.login_history[0].max_login_date)).format('DD.MM.YYYY') : 'Не входили',
        },
        {
            name: <span className='font-weight-bold fs-13'>Всего посещений</span>,
            selector: (row: any) => row.login_history[0] ? row.login_history[0].login_count : 'Не входили',
        }
    ]


    return (
        <>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={statisticsData}
                                noDataComponent={'Нет данных'}
                                progressPending={search}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={page}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно родителей:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default StatisticTable;
