import React, {useEffect, useState} from 'react';
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Alert, Col, Label, Row} from "reactstrap";
import {useAppSelector} from "../../../hooks/redux";
import OrganizationService from "../../../api/services/organizationService/OrganizationService";

const UpdatePasswordOrganizationForm = () => {
    const {selectOrganization} = useAppSelector(state => state.AdminReducer)
    const [isUpdate, setIsUpdate] = useState<any>('');

    useEffect(() => {
        if (isUpdate){
            const idInterval = setInterval(() => setIsUpdate(''), 5000)
            return () => clearInterval(idInterval)
        }
    }, [isUpdate]);


    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    password: '',
                    password_confirmation: '',
                }
            }
            validationSchema={
                Yup.object(
                    {
                        password: Yup.string().required('Введите новый пароль').min(8, 'Минимальная длина пароля 8 символов'),
                        password_confirmation: Yup.string().required('Повторите новый пароль').oneOf([Yup.ref('password'), "null"], 'Пароли не совпадают'),
                    }
                )
            }
            onSubmit={
                (value) => {
                    (value as any).organizationId = selectOrganization?.id;
                    OrganizationService.updatePasswordOrganization(value, selectOrganization?.id as number)
                        .then(r => setIsUpdate(r.data))
                        .catch((r) => setIsUpdate(r.data))
                }
            }
        >
            <Form>
                {
                    isUpdate && <Alert>{isUpdate}</Alert>
                }
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="password"
                                   className="form-label">Пароль</Label>
                            <Field type="password" className="form-control" id="password"
                                   name="password"
                                   placeholder="Пароль" autoComplete="on" />
                            <ErrorMessage name="password">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="password_confirmation" className="form-label">Повторный пароль</Label>
                            <Field type="password" className="form-control" id="password_confirmation"
                                   name="password_confirmation"
                                   placeholder="Повторный пароль" autoComplete="off"/>
                            <ErrorMessage name="password_confirmation">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить пароль
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdatePasswordOrganizationForm;
