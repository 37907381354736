import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICabinet} from "../../../models/ICabinet";
import {ITypeCabinet} from "../../../models/ITypeCabinet";
import {ICategoryOvz} from "../../../models/ICategoryOvz";
import {IOrganization} from "../../../models/IOrganization";
import {ITypeGroup} from "../../../models/ITypeGroup";
import {ITypeDeviation} from "../../../models/ITypeDeviation";
import {ISubdivision} from "../../../models/ISubdivision";
import {IRegion} from "../../../models/IRegion";
import {IRecommendedActive} from "../../../models/IRecommendedActive";
import {IPosition} from "../../../models/IPosition";
import {IGroup} from "../../../models/IGroup";
import {IGroupHealth} from "../../../models/IGroupHealth";
import {ILimitHealth} from "../../../models/ILimitHealth";
import {IEmployee} from "../../../models/IEmployee";
import {IDoGroup} from "../../../models/IDoGroup";
import {IChildren} from "../../../models/IChildren";
import {IParent} from "../../../models/IParent";
import {IDeviation} from "../../../models/IDeviation";
import {IChildrenMovementRegister} from "../../../models/IChildrenMovementRegister";

interface IDepartmentState {
    cabinets: ICabinet[],
    typeCabinets: ITypeCabinet[],
    categoriesOvz: ICategoryOvz[],
    organizations: IOrganization[],
    typesGroup: ITypeGroup[],
    typesDeviation: ITypeDeviation[],
    subdivisions: ISubdivision[],
    regions: IRegion[],
    recommendedActive: IRecommendedActive[],
    positions: IPosition[],
    groups: IGroup[],
    groupsHealth: IGroupHealth[],
    limitsHealth: ILimitHealth[],
    employees: IEmployee[],
    doGroups: IDoGroup[],
    children: IChildren[],
    parents: IParent[],
    deviations: IDeviation[],
    childrenMovementRegister: IChildrenMovementRegister[],
    selectCabinet: ICabinet | null,
    selectTypeCabinet: ITypeCabinet | null,
    selectCategoryOvz: ICategoryOvz | null,
    selectTypeGroup: ITypeGroup | null
    selectTypeDeviation: ITypeDeviation | null,
    selectSubdivision: ISubdivision | null
    selectRegion: IRegion | null,
    selectRecommendedActive: IRecommendedActive | null
    selectGroup: IGroup | null,
    selectGroupHealth: IGroupHealth | null,
    selectLimitHealth: ILimitHealth | null,
    selectEmployee: IEmployee | null,
    selectDoGroup: IDoGroup | null,
    selectChildren: IChildren | null,
    selectParent: IParent | null,
    selectDeviation: IDeviation | null,
    selectChildrenMovementRegister: IChildrenMovementRegister | null
    isLoading: boolean,
    isError: string,
    selectOrganizations: {value: string, label: string, checked: boolean}[]
    dataSelectOrganizations: any[],
    countSelectOrganizations: {value: string, label: string}
}

const initialState: IDepartmentState = {
    cabinets: [],
    typeCabinets: [],
    categoriesOvz: [],
    organizations: [],
    typesGroup: [],
    typesDeviation: [],
    subdivisions: [],
    regions: [],
    recommendedActive: [],
    positions: [],
    groups: [],
    groupsHealth: [],
    limitsHealth: [],
    employees: [],
    doGroups: [],
    children: [],
    parents: [],
    deviations: [],
    childrenMovementRegister: [],
    selectCabinet: null,
    selectTypeCabinet: null,
    selectCategoryOvz: null,
    selectTypeGroup: null,
    selectTypeDeviation: null,
    selectSubdivision: null,
    selectRegion: null,
    selectRecommendedActive: null,
    selectGroup: null,
    selectGroupHealth: null,
    selectLimitHealth: null,
    selectEmployee: null,
    selectDoGroup: null,
    selectChildren: null,
    selectParent: null,
    selectDeviation: null,
    selectChildrenMovementRegister: null,
    isLoading: false,
    isError: "",
    selectOrganizations: [],
    dataSelectOrganizations: [],
    countSelectOrganizations: {value: '-1', label: 'Выбрано 0'}
}

export const DepartmentSlice = createSlice({
    name: "departmentSettings",
    initialState,
    reducers: {
        dataLoading (state){
            state.isLoading = true;
        },
        dataLoadingError(state, action: PayloadAction<string> ){
            state.isLoading = false;
            state.isError = action.payload
        },
        cabinetsLoadingSuccess(state, action: PayloadAction<ICabinet[]>){
            state.isLoading = false;
            state.isError = "";
            state.cabinets = action.payload;
        },
        setSelectCabinet(state, action: PayloadAction<ICabinet>){
            state.isLoading = false;
            state.isError = "";
            state.selectCabinet = action.payload;
        },
        typeCabinetsLoadingSuccess(state, action: PayloadAction<ITypeCabinet[]>){
            state.isLoading = false;
            state.isError = "";
            state.typeCabinets = action.payload;
        },
        setSelectTypeCabinet(state, action: PayloadAction<ITypeCabinet>){
            state.isLoading = false;
            state.isError = "";
            state.selectTypeCabinet = action.payload;
        },
        categoriesOvzLoadingSuccess(state, action: PayloadAction<ICategoryOvz[]>){
            state.isLoading = false;
            state.isError = "";
            state.categoriesOvz = action.payload;
        },
        setSelectCategoryOvz(state, action: PayloadAction<ICategoryOvz>){
            state.isLoading = false;
            state.isError = "";
            state.selectCategoryOvz = action.payload;
        },
        organizationsLoadingSuccess(state, action: PayloadAction<IOrganization[]>){
            state.isLoading = false;
            state.isError = "";
            state.organizations = action.payload;
        },
        typesGroupLoadingSuccess(state, action: PayloadAction<ITypeGroup[]>){
            state.isLoading = false;
            state.isError = "";
            state.typesGroup = action.payload;
        },
        setSelectTypeGroup(state, action: PayloadAction<ITypeGroup>){
            state.isLoading = false;
            state.isError = "";
            state.selectTypeGroup = action.payload;
        },
        typesDeviationLoadingSuccess(state, action: PayloadAction<ITypeDeviation[]>){
            state.isLoading = false;
            state.isError = "";
            state.typesDeviation = action.payload;
        },
        setSelectTypeDeviation(state, action: PayloadAction<ITypeDeviation>){
            state.isLoading = false;
            state.isError = "";
            state.selectTypeDeviation = action.payload;
        },
        subdivisionLoadingSuccess(state, action: PayloadAction<ISubdivision[]>){
            state.isLoading = false;
            state.isError = "";
            state.subdivisions = action.payload;
        },
        setSelectSubdivision(state, action: PayloadAction<ISubdivision>){
            state.isLoading = false;
            state.isError = "";
            state.selectSubdivision = action.payload;
        },
        regionsLoadingSuccess(state, action: PayloadAction<IRegion[]>){
            state.isLoading = false;
            state.isError = "";
            state.regions = action.payload;
        },
        setSelectRegion(state, action: PayloadAction<IRegion>){
            state.isLoading = false;
            state.isError = "";
            state.selectRegion = action.payload;
        },
        recommendedActiveLoadingSuccess(state, action: PayloadAction<IRecommendedActive[]>){
            state.isLoading = false;
            state.isError = "";
            state.recommendedActive = action.payload;
        },
        setSelectRecommendedActive(state, action: PayloadAction<IRecommendedActive>){
            state.isLoading = false;
            state.isError = "";
            state.selectRecommendedActive = action.payload;
        },
        positionsActiveLoadingSuccess(state, action: PayloadAction<IPosition[]>){
            state.isLoading = false;
            state.isError = "";
            state.positions = action.payload;
        },
        groupsLoadingSuccess(state, action: PayloadAction<IGroup[]>){
            state.isLoading = false;
            state.isError = "";
            state.groups = action.payload;
        },
        setSelectGroup(state, action: PayloadAction<IGroup>){
            state.isLoading = false;
            state.isError = "";
            state.selectGroup = action.payload;
        },
        groupsHealthLoadingSuccess(state, action: PayloadAction<IGroupHealth[]>){
            state.isLoading = false;
            state.isError = "";
            state.groupsHealth = action.payload;
        },
        setSelectGroupHealth(state, action: PayloadAction<IGroupHealth>){
            state.isLoading = false;
            state.isError = "";
            state.selectGroupHealth = action.payload;
        },
        limitsHealthLoadingSuccess(state, action: PayloadAction<ILimitHealth[]>){
            state.isLoading = false;
            state.isError = "";
            state.limitsHealth = action.payload;
        },
        setSelectLimitHealth(state, action: PayloadAction<ILimitHealth>){
            state.isLoading = false;
            state.isError = "";
            state.selectLimitHealth = action.payload;
        },
        employeesLoadingSuccess(state, action: PayloadAction<IEmployee[]>){
            state.isLoading = false;
            state.isError = "";
            state.employees = action.payload;
        },
        setSelectEmployee(state, action: PayloadAction<IEmployee>){
            state.isLoading = false;
            state.isError = "";
            state.selectEmployee = action.payload;
        },
        doGroupsLoadingSuccess(state, action: PayloadAction<IDoGroup[]>){
            state.isLoading = false;
            state.isError = "";
            state.doGroups = action.payload;
        },
        setSelectDoGroup(state, action: PayloadAction<IDoGroup>){
            state.isLoading = false;
            state.isError = "";
            state.selectDoGroup = action.payload;
        },
        childrenLoadingSuccess(state, action: PayloadAction<IChildren[]>){
            state.isLoading = false;
            state.isError = "";
            state.children = action.payload;
        },
        setSelectChildren(state, action: PayloadAction<IChildren>){
            state.isLoading = false;
            state.isError = "";
            state.selectChildren = action.payload;
        },
        parentsLoadingSuccess(state, action: PayloadAction<IParent[]>){
            state.isLoading = false;
            state.isError = "";
            state.parents = action.payload;
        },
        setSelectParent(state, action: PayloadAction<IParent>){
            state.isLoading = false;
            state.isError = "";
            state.selectParent = action.payload;
        },
        deviationLoadingSuccess(state, action: PayloadAction<IDeviation[]>){
            state.isLoading = false;
            state.isError = "";
            state.deviations = action.payload;
        },
        setSelectDeviation(state, action: PayloadAction<IDeviation>){
            state.isLoading = false;
            state.isError = "";
            state.selectDeviation = action.payload;
        },
        childrenMovementRegisterLoadingSuccess(state, action: PayloadAction<IChildrenMovementRegister[]>){
            state.isLoading = false;
            state.isError = "";
            state.childrenMovementRegister = action.payload;
        },
        setSelectChildrenMovementRegister(state, action: PayloadAction<IChildrenMovementRegister>){
            state.isLoading = false;
            state.isError = "";
            state.selectChildrenMovementRegister = action.payload;
        },
        setSelectOrganizations(state, action:PayloadAction<{value: string, label: string, checked: boolean}[]>){
            state.selectOrganizations = action.payload
        },
        setDataSelectOrganizations(state, action:PayloadAction<any>){
            state.dataSelectOrganizations = action.payload
        },
        setCountSelectOrganizations(state, action:PayloadAction<{value: string, label: string}>){
            state.countSelectOrganizations = action.payload
        }
    }
})


export default DepartmentSlice.reducer