import React, {useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import TableContactWithBank from "./TableContactWithBank";
import {Card, CardBody, Col, Row} from "reactstrap";
import ModalAddContactWithBank from "./ModalAddContactWithBank";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";

const FormAddCateringOrganizers = () => {
    const [addContactWithBank, setAddContactWithBank] = useState(false);
    const [banksNew, setBanksNew] = useState<any[]>([]);
    const [date, setDate] = useState(new Date());

    return (
        <Card>
            <CardBody>
                <Formik
                    initialValues={
                        {
                            name: '',
                            inn: '',
                            kpp: '',
                            legal_address: '',
                            actual_address: '',
                            kbk: '',
                            oktmo: '',
                            okato: '',
                            email: '',
                            countDayNoUpdateMenu: '',
                            timeBlockMenu: '',
                            countDayUploadMenu: '',
                            banks: [{}]
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                            kpp: Yup.number().typeError("Только числа").integer("Только целые числа").required('Обязательное поле'),
                            legal_address: Yup.string().required('Обязательное поле'),
                            inn: Yup.number().typeError("Только числа").integer("Только целые числа").required('Обязательное поле'),
                            actual_address: Yup.string().required('Обязательное поле'),
                            kbk: Yup.number().typeError("Только числа").integer("Только целые числа"),
                            oktmo: Yup.number().typeError("Только числа").integer("Только целые числа"),
                            okato: Yup.number().typeError("Только числа").integer("Только целые числа"),
                            countDayNoUpdateMenu: Yup.number().typeError("Только числа").integer("Только целые числа").required('Обязательное поле'),
                            countDayUploadMenu: Yup.number().typeError("Только числа").integer("Только целые числа").required('Обязательное поле')
                        })
                    }
                    onSubmit={(values) => {
                            values.timeBlockMenu = moment(date).format('H:mm:00').toString();
                            values.banks = banksNew

                            AxiosClient.post('/catering_organizaers/create', values)
                                .then(() => window.location.href = '/catering_organizers')
                                .catch(() => console.log('error'))
                    }}>
                    {
                        ({errors, touched}) => (
                            <Form className="my-4">
                                <div>
                                    <fieldset className="border border-2 p-2">
                                        <legend className="fs-5 mt-n4 mx-3 p-1 w-auto bg-white">Общие реквезиты</legend>
                                        <Row className="m-0">
                                            <Col>
                                                <Row>
                                                    <Col xs={12} sm={12} lg={12} xl={4} xxl={4}>
                                                        <div>
                                                            <label className="w-100">Код:
                                                                <Field name="code" className="form-control" disabled/>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label title="Обязательное поле" className="w-100">КПП *:
                                                                <Field name="kpp" className={`form-control ${errors.kpp && touched.kpp && "border border-2 border-danger"}`}/>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="w-100">КБК:
                                                                <Field name="kbk" className={`form-control ${errors.kbk && touched.kbk && "border border-2 border-danger"}`}/>
                                                            </label>
                                                        </div>
                                                        <div className="flex-1">
                                                            <label className="w-100">Email:
                                                                <Field name="email" className="form-control"/>
                                                            </label>
                                                            <ErrorMessage name="email"/>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={12} lg={12} xl={4} xxl={4}>
                                                        <div className="flex-1">
                                                            <label title="Обязательное поле" className="w-100">Наименование *:
                                                                <Field name="name" className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}/>
                                                            </label>
                                                        </div>
                                                        <div className="flex-1">
                                                            <label title="Обязательное поле" className="w-100">Юридичееский адрес *:
                                                                <Field name="legal_address" className={`form-control ${errors.legal_address && touched.legal_address && "border border-2 border-danger"}`}/>
                                                            </label>
                                                        </div>
                                                        <div className="flex-1">
                                                            <label className="w-100">ОКТМО:
                                                                <Field name="oktmo" className={`form-control ${errors.oktmo && touched.oktmo && "border border-2 border-danger"}`}/>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={12} lg={12} xl={4} xxl={4}>
                                                        <div className="flex-1">
                                                            <label title="Обязательное поле" className="w-100">ИНН *:
                                                                <Field name="inn" className={`form-control ${errors.inn && touched.inn && "border border-2 border-danger"}`}/>
                                                            </label>
                                                        </div>
                                                        <div className="flex-1">
                                                            <label title="Обязательное поле" className="w-100">Фактический адрес *:
                                                                <Field name="actual_address" className={`form-control ${errors.actual_address && touched.actual_address && "border border-2 border-danger"}`}/>
                                                            </label>
                                                        </div>
                                                        <div className="flex-1">
                                                            <label className="w-100">ОКАТО:
                                                                <Field name="okato" className="form-control"/>
                                                            </label>
                                                            <ErrorMessage name="okato"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </fieldset>

                                    <fieldset className="border border-2 p-2 mt-3">
                                        <legend className="fs-5 mt-n4 mx-3 p-1 w-auto bg-white">Доп. настройки</legend>
                                        <Row className="m-0">
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <div className="flex-1">
                                                            <label className="w-100">Количество рабочих дней, для запрета редактирования меню:
                                                                <Field name="countDayNoUpdateMenu" className={`form-control ${errors.countDayNoUpdateMenu && touched.countDayNoUpdateMenu && "border border-2 border-danger"}`}/>
                                                            </label>
                                                            <ErrorMessage name="countDayNoUpdateMenu">
                                                                {err => <p className="text-danger">{err}</p>}
                                                            </ErrorMessage>
                                                        </div>
                                                        <div className="flex-1">
                                                            <label  className="w-100">Время блокировки меню:
                                                            <Flatpickr
                                                                name="timeBlock"
                                                                className="form-control border-0 dash-filter-picker shadow"
                                                                value={date}
                                                                options={{
                                                                    locale: Russian,
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                }}
                                                                onChange={(date) => setDate(date[0])}
                                                            />
                                                            </label>
                                                        </div>
                                                        <div className="flex-1">
                                                            <label  className="w-100">Количество открытых дней, для оповещения о загруженных комплексах *:
                                                                <Field name="countDayUploadMenu" className={`form-control ${errors.countDayUploadMenu && touched.countDayUploadMenu && "border border-2 border-danger"}`}/>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </fieldset>

                                    <fieldset className="border border-2 p-2 mt-3">
                                        <legend className="fs-5 mt-n4 mx-3 p-1 w-auto bg-white">Связь с банком</legend>
                                        <TableContactWithBank setBanksNew={value => setBanksNew(value)} banksNew={banksNew}/>
                                    </fieldset>
                                </div>
                                <Row className="m-0 mt-2">
                                    <Col sm={12} lg={4} xxl={3}>
                                        <button type="submit" className="btn btn-primary w-100 mt-1" id="addNewProject">Сохранить</button>
                                    </Col>
                                    <Col sm={12} lg={4} xxl={3}>
                                        <button type="button" className="btn btn-primary w-100 mt-1" id="addNewProject" onClick={() => setAddContactWithBank(true)}>Добавить банк</button>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }
                </Formik>
            </CardBody>
            {
                addContactWithBank &&
                <ModalAddContactWithBank setBanksNew={(value) => setBanksNew([...banksNew, value])} addContactWithBank={addContactWithBank} setAddContactWithBank={(value) => setAddContactWithBank(value)}/>
            }
        </Card>
    );
};

export default FormAddCateringOrganizers;
