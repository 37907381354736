import React, {useState} from 'react';
import TableSelectPage from "./TableSelectPage";
import {Col, Label, Row} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import * as Yup from 'yup'
import {updateTerm} from "../../../store/reducers/admin/ActionCreator";

const UpdateDataTermForm = () => {

    const dispatch= useAppDispatch();
    const {pages, selectTerm} = useAppSelector(state => state.AdminReducer)
    const [checkAll, setCheckAll] = useState<boolean>(false);

    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    role: `${selectTerm?.name}`,
                    page: checkAll ? pages.map((page) => {
                        return `${page.id}`
                    }) :  selectTerm?.pages.map((page) => {
                        return `${page.id}`
                    }),
                    slug: `${selectTerm?.slug}`,
                    mainPage: `${selectTerm?.base_url}`
                }
            }
            validationSchema={
                Yup.object(
                    {
                        role: Yup.string().required('Введите название роли'),
                        slug: Yup.string().required('Введите slug роли'),
                    }
                )
            }
            onSubmit={
                (value) => {
                    dispatch(updateTerm(value, selectTerm!.id)).then()
                }
            }
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="roleInput"
                                   className="form-label">Роль</Label>
                            <Field type="text" className="form-control" id="roleInput"
                                   name="role"
                                   placeholder="Введите роль"/>
                            <ErrorMessage name="role">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>

                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="slugInput" className="form-label">Имя роли
                                (на английском)</Label>
                            <Field type="text" className="form-control" id="slugInput"
                                   name="slug"
                                   placeholder="Введите роль"/>
                            <ErrorMessage name="slug">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="mainPageInput" className="form-label">Главная
                                страница</Label>
                            <Field
                                as="select"
                                className="form-select"
                                id="mainPageInput"
                                name="mainPage"
                            >
                                {
                                    pages.map((page) => {
                                        return <option key={page.id}
                                                       value={page.path}>{page.name}</option>
                                    })
                                }
                            </Field>
                        </div>
                    </Col>

                    <Col lg={12}>
                        <div className="mb-3">
                            <TableSelectPage setCheckAll={(check) => setCheckAll(check)}/>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="text-end">
                            <button type="submit" className="btn btn-primary">Обновить
                                данные
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataTermForm;
