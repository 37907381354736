import React, {FC, memo, useEffect, useState} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import AxiosClient from "../../../api/AxiosClient";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";

interface IAddDish {
    addDish: boolean,
    setAddDish: (value: boolean) => void,
    setAssortments: (value: any[]) => void
    fetchAssortment: () => void
}

const AddDish: FC<IAddDish> = ({setAddDish, addDish, setAssortments, fetchAssortment}) => {
    const idButtonSave: string = 'buttonSaveAddDish';
    const [startAction, setStartAction] = useState(new Date());
    const [endAction, setEndAction] = useState<string | Date>('');

    const [ageCategories, setAgeCategories] = useState<any[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<any[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    const [currentObject, setCurrentObject] = useState<any>({ name: '', age_category_id: '', catering_organizer_id: '', kcal_kJ: '',
        proteins: '', fats: '', carbohydrates: '', quantity_units_id: '', sign_divisibility: false, startAction: '', endAction: '', out_dish: '', price: ''});
    const [newObject, setNewObject] = useState<any>({ name: '', age_category_id: '', catering_organizer_id: '', kcal_kJ: '',
        proteins: '', fats: '', carbohydrates: '', quantity_units_id: '', sign_divisibility: false, startAction: '', endAction: '', out_dish: '', price: ''});
    const [isChanges, setIsChanges] = useState(false);
    const dispatch = useAppDispatch()
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);

    const fetchCateringOrganizer = () => {
        AxiosClient.get(`/users/catering_organizers`)
            .then((r) => setListCateringOrganizer(r.data))
    }

    useEffect(() => {
        AxiosClient.get('/quantity_units')
            .then((r) => setQuantityUnits(r.data))
            .catch(() => console.log('error'))
        fetchCateringOrganizer()
        AxiosClient.get('/age_categories')
            .then((r) => setAgeCategories(r.data))
            .catch(() => console.log('error'))
    }, []);

    const confirmOrExit = () => {
        if(!_.isEqual(currentObject, newObject)){
            setConfirmExit(true);
        }else{
            setAddDish(false);
        }
    }

    return (
        <Modal id="createProjectModal" isOpen={addDish}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление блюда
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            name: '',
                            age_category_id: ageCategories[0] && ageCategories[0].id,
                            catering_organizer_id: '',
                            kcal_kJ: '',
                            proteins: '',
                            fats: '',
                            carbohydrates: '',
                            quantity_units_id: quantityUnits[0] && quantityUnits[0].id,
                            sign_divisibility: false,
                            startAction: new Date(),
                            endAction: '',
                            out_dish: '',
                            price: ''
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                            kcal_kJ: Yup.string().required('Обязательное поле'),
                            proteins: Yup.number().typeError('Только числа').required('Обязательное поле'),
                            fats: Yup.number().typeError('Только числа').required('Обязательное поле'),
                            carbohydrates: Yup.number().typeError('Только числа').required('Обязательное поле'),
                            out_dish: Yup.number().typeError('Только числа').required('Обязательное поле'),
                            price: Yup.number().typeError('Только числа').required('Обязательное поле'),
                            catering_organizer_id: Yup.string().required('Обязательное поле')
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        values.startAction = startAction;
                        (values.endAction as (string | Date)) = endAction

                        AxiosClient.post('/assortments/create', values)
                            .then(() => {
                                dispatch(setIsSend(false))
                                fetchAssortment()
                                setAddDish(false)
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })
                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <Col>
                                            <label>Наименование *:</label>
                                            <Field name="name"
                                                   className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}
                                                   placeholder="Наименование"
                                                   onChange={(e: any) => {
                                                       setNewObject({...newObject, 'name': e.target.value});
                                                       setFieldValue('name', e.target.value);
                                                   }}
                                            />
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Возрастная категория:</label>
                                        <Field name="age_category_id" as="select" className="form-select"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'age_category_id': e.target.value});
                                                   setFieldValue('age_category_id', e.target.value);
                                               }}
                                        >
                                            {
                                                ageCategories.map(ageCategory => (<option key={ageCategory.id} value={ageCategory.id}>{ageCategory.name}</option>))
                                            }
                                        </Field>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Организатор питания:</label>
                                        <Field name="catering_organizer_id" as="select" className={`form-select ${errors.catering_organizer_id && touched.catering_organizer_id && "border border-2 border-danger"}`}
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'catering_organizer_id': e.target.value});
                                                   setFieldValue('catering_organizer_id', e.target.value);
                                               }}
                                        >
                                            <option value="">Не выбрано</option>
                                            {
                                                listCateringOrganizer.map((ca) => (<option key={ca.id} value={ca.id}>{ca.name}</option>))
                                            }
                                        </Field>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Дата с:</label>
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={startAction}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onClose={(date) => {
                                                setStartAction(date[0])
                                                setNewObject({...newObject, 'startAction': date[0]});
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <label>Дата по:</label>
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={endAction}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onClose={(date) => {
                                                setEndAction(date[0])
                                                setNewObject({...newObject, 'endAction': date[0]});
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Ккал/кДж*:</label>
                                        <Field name="kcal_kJ" className={`form-control ${errors.kcal_kJ && touched.kcal_kJ && "border border-2 border-danger"}`} placeholder="Ккал/кДж"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'kcal_kJ': e.target.value});
                                                   setFieldValue('kcal_kJ', e.target.value);
                                               }}
                                        />
                                    </Col>
                                    <Col>
                                        <label>Белки*:</label>
                                        <Field name="proteins" className={`form-control ${errors.proteins && touched.proteins && "border border-2 border-danger"}`} placeholder="Белки"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'proteins': e.target.value});
                                                   setFieldValue('proteins', e.target.value);
                                               }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Жиры*:</label>
                                        <Field name="fats" className={`form-control ${errors.fats && touched.fats && "border border-2 border-danger"}`} placeholder="Жиры"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'fats': e.target.value});
                                                   setFieldValue('fats', e.target.value);
                                               }}
                                        />
                                    </Col>
                                    <Col>
                                        <label>Углеводы*:</label>
                                        <Field name="carbohydrates" className={`form-control ${errors.carbohydrates && touched.carbohydrates && "border border-2 border-danger"}`} placeholder="Углеводы"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'carbohydrates': e.target.value});
                                                   setFieldValue('carbohydrates', e.target.value);
                                               }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Выход блюда*:</label>
                                        <Field name="out_dish" className={`form-control ${errors.out_dish && touched.out_dish && "border border-2 border-danger"}`} placeholder="Жиры"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'out_dish': e.target.value});
                                                   setFieldValue('out_dish', e.target.value);
                                               }}
                                        />
                                    </Col>
                                    <Col>
                                        <label>Цена*:</label>
                                        <Field name="price" className={`form-control ${errors.price && touched.price && "border border-2 border-danger"}`} placeholder="Цена"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'price': e.target.value});
                                                   setFieldValue('price', e.target.value);
                                               }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Единица измерения количества:</label>
                                        <Field name="quantity_units_id" as="select" className="form-select"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'quantity_units_id': e.target.value});
                                                   setFieldValue('quantity_units_id', e.target.value);
                                               }}
                                        >
                                            {
                                                quantityUnits.map(quantityUnit => (<option key={quantityUnit.id} value={quantityUnit.id}>{quantityUnit.name}</option>))
                                            }
                                        </Field>
                                    </Col>
                                    <Col className="align-items-center">
                                        <label className="m-0">Признак делимости:</label>
                                        <Field name="sign_divisibility" type="checkbox">
                                            {({ field }: any) => (
                                                <div>
                                                    <Input
                                                        name="sign_divisibility"
                                                        type="checkbox"
                                                        {...field}
                                                        className="mx-2 my-0 fs-18 mt-3"
                                                        onChange={(e: any) => {
                                                            setNewObject({...newObject, 'sign_divisibility': e.target.checked});
                                                            setFieldValue('sign_divisibility', e.target.checked);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </Col>
                                </Row>
                                <button type="submit" className="btn btn-primary mt-1" id={idButtonSave}>Добавить</button>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setAddDish(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal>
    );
};

export default memo(AddDish);
