import React, {useEffect, useMemo, useState} from 'react';
import {useStateContext} from "../../../hooks/useStateContext";
import {useLocation} from "react-router-dom";

const MenuData = () => {

    const {user} = useStateContext()
    const {pathname} = useLocation()
    const [isCurrentState, setIsCurrentState] = useState<string>('');
    const [isGroup, setIsGroup] = useState('');

    const headers: string[] = useMemo(() => [
            ...new Set((user.term?.pages)
                .filter(page => page?.prefix_table && page?.prefix_table?.name === "Страница")
                .map((header: any) => header?.header_page && header?.header_page?.name))
        ],
        [user.term?.pages]);

    const groupPage: string[] = useMemo(() => [
            ...new Set((user.term?.pages)
                .filter(page => page?.prefix_table && page?.prefix_table?.name === "Страница")
                .map((header) => header?.group_page && header?.group_page?.name))
        ],
        [user.term?.pages]);

    useEffect(() => {
        setIsCurrentState(user.term?.pages.find(p => p.path === pathname)?.header_page?.name as string)
    }, [headers, pathname, user.term.pages])


    const menuItems = [
        ...headers.map((header, key) => (
            {
                id: key,
                label: header,
                icon: "bx bxs-dashboard",
                link: "/#",
                stateVariables: header === isCurrentState,
                click: function (e: Event) {
                    e.preventDefault();
                    setIsCurrentState(prev => prev === header ? '' : header);
                },
                subItems: user.term.pages[0].prefix_table?.name ? groupPage.map(groupPage => {
                    return ({
                        groupPage: groupPage,
                        stateIsGroup: groupPage === isGroup,
                        click: function (e: Event) {
                            e.preventDefault();
                            setIsGroup(prev => prev === groupPage ? '' : groupPage);
                        },
                        pages: user.term?.pages?.filter(page => page.prefix_table.name === 'Страница'
                            && page.header_page.name === header && page.group_page.name === groupPage).map(page => ({
                            id: page.id,
                            label: page.name,
                            link: page.path,
                            parentId: header,
                        }))
                    })
                }) : null
            }
        ))
    ]
    return (<>{menuItems}</>)
};

export default MenuData;
