import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Col, Label, Row} from "reactstrap";
import * as Yup from 'yup'
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {updatePosition} from "../../../store/reducers/admin/ActionCreator";
const UpdateDataPositionForm = () => {

    const dispatch = useAppDispatch();
    const {selectPosition} = useAppSelector(state => state.AdminReducer)

    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    position: `${selectPosition?.name}`
                }
            }
            validationSchema={
                Yup.object(
                    {
                        position: Yup.string().required('Обязательное поле')
                    }
                )
            }
            onSubmit={
                (value) => {
                    dispatch(updatePosition(value, selectPosition!.id))
                }
            }
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="roleInput"
                                   className="form-label">Должность</Label>
                            <Field type="text" className="form-control" id="roleInput"
                                   name="position"
                                   placeholder="Введите должность"/>
                            <ErrorMessage name="position">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataPositionForm;
