import React, {FC, useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import ListUpdateClass from "./ListUpdateClass";
import ModalUpdateBuilding from "./ModalUpdateBuilding";
import AxiosClient from "../../../api/AxiosClient";

interface IListBuilds {
    builds: any[],
    setBuilds: (value: any[]) => void,
    newBuilds: any[],
    setNewBuilds: (value: any[]) => void
}
const ListUpdateBuilds: FC<IListBuilds> = ({builds, setNewBuilds, newBuilds, setBuilds}) => {

    const [openListClass, setOpenListClass] = useState(false);
    const [listClass, setListClass] = useState<any[]>([]);
    const [selectBuild, setSelectBuild] = useState<any | null>(null);
    const [selectBuildClass, setSelectBuildClass] = useState<any | null>(null);
    const [updateBuild, setUpdateBuild] = useState(false);

    useEffect(() => {
        setListClass(builds.find(b => b.name === selectBuildClass?.name)?.class)
    }, [builds, selectBuildClass]);

    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Корпус</span>,
            selector: (row: any) => row.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Классов</span>,
            cell: (row: any) => row.class.length,
        },
        {
            cell: (row: any) => <i className="bx bx-pencil fs-3 cursor-pointer" title="Обновить" onClick={() => {
                setSelectBuild(row)
                setUpdateBuild(true)
            }}></i>,
            center: true,
            width: '70px'
        },
        {
            cell: (row: any) => row.id && <i className="bx bx-archive-in fs-3 cursor-pointer" title="Добавить в архив" onClick={() => {
                setBuilds(builds.filter((b: any) => b.name !== row.name))
                AxiosClient.post(`/schools/archive_build/${row.id}`)
                    .catch(() => console.log('error'))
            }}></i>,
            center: true,
            width: '70px'
        }
    ]

    return (
        <>
            <DataTable
                columns={columns}
                data={builds}
                noDataComponent={'Нет данных'}
                pagination
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Видно корпусов:',
                        rangeSeparatorText: 'из'
                    }
                }
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                onRowClicked={(row) => {
                    setListClass(row.class)
                    setSelectBuildClass(row)
                    setOpenListClass(true)
                }}
            />
            <ModalUpdateBuilding updateBuild={updateBuild} setUpdateBuild={value => setUpdateBuild(value)} setBuilds={value => setBuilds(value)} build={selectBuild} builds={builds} setNewBuilds={value => setNewBuilds(value)} newBuilds={newBuilds}/>
            <ListUpdateClass newBuilds={newBuilds} setBuilds={value => setBuilds(value)} setNewBuilds={value => setNewBuilds(value)} builds={builds} selectBuild={selectBuildClass?.name} listClass={listClass} openListClass={openListClass} setOpenListClass={value => setOpenListClass(value)}/>
        </>
    );
};

export default ListUpdateBuilds;
