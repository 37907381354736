import React, {FC, useState} from 'react';
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import {Form, Formik} from "formik";
import AxiosClient from "../../../api/AxiosClient";
import Notification from "../../widgets/Notification";

interface ITableConfirmationImportTeacher {
    listTeacherImport: any[],
    setListTeacherImport(value: any[]) : any
}

const TableConfirmationImportTeacher: FC<ITableConfirmationImportTeacher> = ({listTeacherImport, setListTeacherImport}) => {
    const [isSync, setIsSync] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    // Определение обработчиков для изменения страницы и количества строк на странице
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
    };

    const toggleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        const updatedList = listTeacherImport.map(item => {
            return { ...item, isAdd: newSelectAll };
        });
        setListTeacherImport(updatedList);
    };

    const columns = [
        {
            id: "name",
            name: <span className='font-weight-bold fs-13'>ФИО</span>,
            selector: (row: any) => row.nameTeacher,
            wrap: true,
        },
        {
            id: "listFindClass",
            name: <span className='font-weight-bold fs-13'>Классы на добавление</span>,
            selector: (row: any) => row.listFindClass.map((value: any)=>value.name).join(", "),
            wrap: true
        },
        {
            id: "listNotClass",
            name: <span className='font-weight-bold fs-13'>Не найденные классы</span>,
            selector: (row: any) => row.listNotClass.join(", "),
            wrap: true
        },
        {
            id: "annotation",
            name: <span className='font-weight-bold fs-13'>Примечание</span>,
            selector: (row: any) => row.annotation,
            wrap: true,
        },
        {
            id: "isAdd",
            name: <Input type="checkbox" className='fs-18' checked={selectAll} onChange={toggleSelectAll}/>,
            selector: (row: any) => row.isAdd,
            cell: (row: any, index: number) =>
                <Input type="checkbox" checked={row.isAdd} className='fs-18'
                       onChange={()=> {
                           const updatedList = [...listTeacherImport];
                           updatedList[index + (((currentPage - 1) * rowsPerPage))].isAdd =  !updatedList[index + (((currentPage - 1) * rowsPerPage))].isAdd;
                           setListTeacherImport(updatedList);
                       }}
                />,
            'width' : '70px'
        },
    ]
    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    school: '',
                    build: '',
                }
            }
            onSubmit={() => {
                AxiosClient.post('/schools/import_teacher_class', {listTeacherImport, isSync})
                    .then((r) => {
                        setNotificationType('success');
                        setNotificationMessage('Готово, данные загружены!');
                        setShowNotification(true);
                    })
                    .catch((error) => {
                        console.log("error");
                        setNotificationType('error');
                        setNotificationMessage('Ошибка, данные не загружены!');
                        setShowNotification(true);
                    })
            }}>
            {
                () => (
                    <Form>
                        <Row>
                            <Col>
                                <Card className="mt-3">
                                    <CardBody>
                                        <DataTable
                                            columns={columns}
                                            data={listTeacherImport}
                                            noDataComponent={'Нет данных'}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                            pagination
                                            paginationRowsPerPageOptions={[100, 500, 1000]}
                                            paginationPerPage={100}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Видно учеников:',
                                                    rangeSeparatorText: 'из'
                                                }
                                            }
                                            highlightOnHover
                                            customStyles={{
                                                rows: {
                                                    highlightOnHoverStyle: {
                                                        backgroundColor: "#ffebb5"
                                                    },
                                                },
                                                expanderRow: {
                                                    style: {
                                                        backgroundColor: "#ffebb5",
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    },
                                                },
                                            }}
                                        />
                                        <label>
                                        <Input
                                            type="checkbox"
                                            name="toggle"
                                            className="fs-14"
                                            checked={isSync}
                                            onChange={(e) => setIsSync(e.target.checked)}
                                        />
                                        &nbsp;&nbsp;Удалить старые классы&nbsp;
                                        </label>
                                        <div className="hstack gap-2" >
                                            <button type="submit" className="btn btn-primary" id="addNewProject">Добавить</button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
                        </Row>
                    </Form>
                )
            }
        </Formik>
    );
};

export default TableConfirmationImportTeacher;
