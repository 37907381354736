import { useEffect, useState } from 'react'
import DataTable from "react-data-table-component"
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap"
import AxiosClient from "../../../api/AxiosClient"
import ListTypesNutrition from "./ListTypesNutrition"
import Select from "react-select"
import { customStyles } from "../../settings"
import {ITypePlannedMenu} from "../../../models/KSHP/ITypePlannedMenu";
import {ICateringOrganizer} from "../../../models/KSHP/ICateringOrganizer";

const ListTableCateringOrganizers = () => {

    const [cateringOrganizers, setCateringOrganizers] = useState<ICateringOrganizer[]>([])
    const [typePlannedMenu, setTypePlannedMenu] = useState<{value: string, label: string}[]>([])
    const [selectTypePlannedMenu, setSelectTypePlannedMenu] = useState<{value: string, label: string}>({value: '', label: 'Не выбрано'})

    useEffect(() => {
        AxiosClient.get<ITypePlannedMenu[]>('/type_planned_menu')
            .then((r) => {
                setTypePlannedMenu([{value: '', label: 'Не выбрано'} ,...r.data.map((el: any) => ({ value: el.id, label: el.name }))])
            })
            .catch(() => console.log('error'))
    }, [])

    useEffect(() => {
        AxiosClient.get<ICateringOrganizer[]>('/catering_organizaers', {params: {selectTypePlannedMenuFilter: selectTypePlannedMenu.value}})
            .then(r => setCateringOrganizers(r.data))
    }, [selectTypePlannedMenu.value]);


    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Идентификатор</span>,
            selector: (row: ICateringOrganizer) => row.identifier,
            hide: 959
        },
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Наименование</span>,
            selector: (row: ICateringOrganizer) => row.name,
            sortable: true,
        },
    ]

    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label className='w-100'>Тип планового меню:
                    <Select
                        options={typePlannedMenu}
                        value={selectTypePlannedMenu}
                        onChange={(change: any) => {
                            setSelectTypePlannedMenu({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardHeader>
                            Организаторы питания
                        </CardHeader>
                        <CardBody className='align-items-center'>
                            <DataTable
                                columns={columns}
                                data={cateringOrganizers}
                                noDataComponent={'Нет организаторов питания'}
                                defaultSortFieldId={"date"}
                                defaultSortAsc={true}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно организаторов:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        }
                                    }
                                }}
                                expandableRows
                                expandOnRowClicked
                                expandableIcon={{ collapsed: <i className="bx bx-buildings fs-3"></i>, expanded: <i className="bx bx-buildings fs-3"></i> }}
                                expandableRowsComponent={({data}) => {
                                    return <ListTypesNutrition id={data.id} selectTypePlannedMenuFilter={selectTypePlannedMenu.value} setCateringOrganizers={value => setCateringOrganizers(value)}/>
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ListTableCateringOrganizers
