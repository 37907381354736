import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import ListSchools from "../../components/KShP/Schools/ListSchools";

const Schools = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Школы</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={12}>
                            <ListSchools/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Schools;
