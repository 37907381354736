import React from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import RequireChat from "../RequireChat";
import ChatMessageRequire from "../ChatMessageRequire";
import {useAppSelector} from "../../../hooks/redux";

const Phone = () => {
    const {selectChat} = useAppSelector(state => state.WidgetReducer)

    return (
        !selectChat ?
            <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1 h-100">
                <div className="chat-leftsidebar ">
                    <div className="px-4 pt-4 mb-4">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h5 className="mb-4">Чаты</h5>
                            </div>
                            <div className="flex-shrink-0">
                            </div>
                        </div>
                        <div className="search-box">
                            <input
                                id="search-user"
                                type="text"
                                className="form-control bg-light border-light"
                                placeholder="Найти чат"
                            />
                            <i className="ri-search-2-line search-icon"></i>
                        </div>
                    </div>

                    <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
                        <NavItem>
                            <NavLink
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                }}
                            >
                                Чаты
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent
                        activeTab={'1'}
                        className="text-muted "
                    >
                        <TabPane tabId="1" id="chats">
                            <RequireChat/>
                            {/*<UsersChat/>*/}
                        </TabPane>
                    </TabContent>

                </div>
                <ChatMessageRequire/>
            </div>
            :
            <div className="mx-n4 mt-n4">
                <ChatMessageRequire/>
            </div>

    );
};

export default Phone;
