import React, {useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {useAppDispatch} from "../../../hooks/redux";
import classNames from "classnames";
import {addCity} from "../../../store/reducers/admin/ActionCreator";

const CityAdd = () => {

    const dispatch = useAppDispatch();

    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <>
            <Formik
                initialValues={
                    {
                        city: ""
                    }
                }
                validationSchema={
                    Yup.object(
                        {
                            city: Yup.string().required('Введите город')
                        }
                    )
                }
                onSubmit={
                    (value, {resetForm}) => {
                        dispatch(addCity(value))
                        resetForm()
                    }
                }
            >
                <Form>
                    <Card className="mx-n3">
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                 role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "1"})}
                                        onClick={() => {
                                            tabChange("1");
                                        }}
                                        type="button">
                                        <i className="fas fa-home"></i>
                                        Данные о городе
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className="p-4">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="roleInput" className="form-label">Город</Label>
                                                <Field type="text" className="form-control" id="roleInput" name="city"
                                                       placeholder="Введите город"/>
                                                <ErrorMessage name="city">{(msg) => <div
                                                    className="text-danger">{msg}</div>}</ErrorMessage>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className="mt-3">
                                            <div className="hstack gap-2 justify-content-end">
                                                <button type="submit"
                                                        className="btn btn-primary">Добавить
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Form>
            </Formik>
        </>
    );
};

export default CityAdd;
