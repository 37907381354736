import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody,
    CardHeader
} from "reactstrap";
import {useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {ProjectOverviewChartsActEquipmentInspectionCertificate} from "../ProjectStatusCharts";
import GraphLoaders from "../../layouts/SceletonLoaders/GraphLoaders";

const WidgetGraphActEquipmentInspectionCertificate = () => {

    const {date} = useAppSelector(state => state.WidgetReducer);
    const [dataGroup, setDataGroup] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        WidgetService.getItogInfoObjectsDefects(date).then(r => {
            setDataGroup(r.data)
            setLoading(false)
        })
    }, [date]);


    return (
        <>
            {
                loading ?
                    <GraphLoaders/>
                    :
                    <Card>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Осмотренное оборудование</h4>
                        </CardHeader>
                        <ProjectOverviewChartsActEquipmentInspectionCertificate data={dataGroup.map(data => ({name: data.name, type: "bar", data: [data.count_defects]}))} />
                        <CardBody>
                            {
                                dataGroup.length > 0 ?
                                    <div className="mt-3">
                                        <div className="d-flex justify-content-center align-items-center mb-4">
                                            <div>
                                                <p className="text-muted mb-0">Всего осмотренно</p>
                                            </div>
                                            <h2 className="mx-3 ff-secondary mb-0">{dataGroup.map((dev: any) => +dev.count_defects).reduce((a: number, b: number) => a + b)}</h2>

                                        </div>

                                        {
                                            (dataGroup).map((type: any, id: number) => {
                                                return (
                                                    <div key={id}
                                                         className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                                                        <p className="fw-medium mb-0">{type.name}</p>
                                                        <div>
                                                        <span
                                                            className="text-muted pe-5">{type?.count_defects} ШТ</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                    :
                                    <div className="d-flex justify-content-center align-items-center mb-4">
                                        <div>
                                            <p className="text-muted mb-0">Данных нет</p>
                                        </div>
                                    </div>
                            }
                        </CardBody>
                    </Card>
            }
        </>
    );
};

export default WidgetGraphActEquipmentInspectionCertificate;
