import {combineReducers, configureStore} from "@reduxjs/toolkit";
import AdminReducer from "./reducers/admin/AdminSlice"
import DepartmentReducer from "./reducers/department/DepartmentSlice";
import WidgetReducer from "./reducers/widgets/WidgetsSlice";
import ParentReducer from "./reducers/parent/ParentSlice"

const rootReducers = combineReducers({
    AdminReducer,
    DepartmentReducer,
    WidgetReducer,
    ParentReducer
})

export const setupStore = () =>{
    return configureStore({
        reducer: rootReducers,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false,
            })
    })
}

export type RootState = ReturnType<typeof rootReducers>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

