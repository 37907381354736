import React, {FC} from 'react';
import {ISchool} from "../../../models/KSHP/ISchool";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalUpdateClass from "./ModalUpdateClass";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";

interface IModalConfirmExit {
    setUpdate: (value: boolean) => void,
    confirmExit: boolean,
    setConfirmExit: (value: boolean) => void,
    idButtonSave: string
}

const ModalConfirmExit: FC<IModalConfirmExit> = ({setUpdate, confirmExit, setConfirmExit, idButtonSave}) => {
    const clouseNotSave = () => {
        setConfirmExit(false);
        setUpdate(false);
    };
    const clouseSave = () => {
        document.getElementById(idButtonSave)?.click();
        setConfirmExit(false);
    };

    return (
        <Modal id="createProjectModal" isOpen={confirmExit}
               toggle={() => setConfirmExit(!confirmExit)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setConfirmExit(!confirmExit)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Закрыть окно
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <p>Вы не сохранили данные! Сохранить?</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="hstack gap-2">
                            <button className="btn btn-primary " type="button" id="addNewProject"
                                    onClick={() => clouseSave()
                            }>Да
                            </button>
                            <button className="btn btn-primary" type="button" id="addNewProject"
                                    onClick={() => clouseNotSave()
                            }>Нет
                            </button>
                        </div>
                    </Col>
                </Row>

            </ModalBody>
        </Modal>
    );
};

export default ModalConfirmExit;
