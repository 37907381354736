import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {customStyles} from "../../settings";
import Select from "react-select";
import AxiosClient from "../../../api/AxiosClient";
import * as Yup from "yup";

interface IAddDishInMenu {
    addDish: boolean,
    setAddDish: (value: boolean) => void
    listDishMenu: any[],
    setListDishMenu: (value: any[]) => void
}

const AddDishInMenu: FC<IAddDishInMenu> = ({setAddDish, addDish, setListDishMenu, listDishMenu}) => {
    const [assortments, setAssortments] = useState<any[]>([]);
    const [selectDish, setSelectDish] = useState({value: '', label: 'Не выбрано'});
    const [dataSelectDish, setDataSelectDish] = useState<null | any>(null);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    useEffect(() => {
        fetchMoreAssortments(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setAssortments(r)
            })
        // AxiosClient.get('/assortments')
        //     .then(r => {
        //         setAssortments(r.data.assortments)
        //     })
        //     .catch(() => console.log('error'))
    }, []);

    const fetchMoreAssortments = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/assortments`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.assortments
    };

    const loadMoreData = async () => {
        fetchMoreAssortments(pageOption).then(r => {
            setAssortments(prevOptions => [...prevOptions, ...r]);
            setPageOption(state => state + 1)
        });
    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== assortments.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const onChangeDish = (selectDish: {value: string, label: string}) => {
        setSelectDish(selectDish)
        setDataSelectDish(assortments.find(i => i.id.toString() === selectDish.value.toString()))
    }
    console.log(assortments)
    return (
        <Modal id="createProjectModal" isOpen={addDish}
               toggle={() => setAddDish(!addDish)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setAddDish(!addDish)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление блюда в меню
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            count: '1',
                            out_dish: dataSelectDish?.out_dish,
                            price: dataSelectDish?.price
                        }
                    }
                    validationSchema={
                        Yup.object({
                            count: Yup.number().required('Обязательное поле'),
                            out_dish: Yup.number().required('Обязательное поле'),
                            price: Yup.number().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        setListDishMenu([...listDishMenu, {id: selectDish.value, name: selectDish.label, count: values.count, out_dish: values.out_dish, price: values.price}])
                        setAddDish(false)
                    }}>
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Блюдо:</label>
                                        <Select
                                            options={[{value: '', label: 'Не выбрано'}, ...assortments.map(as => ({value: as.id, label: as.name}))]}
                                            value={selectDish}
                                            onChange={(change: any) => {
                                                onChangeDish({value: change.value, label: change.label})
                                            }}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                            name="name"
                                            onMenuScrollToBottom={handleMenuScroll}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Количество*:</label>
                                        <Field name="count" className={`form-control ${errors.count && touched.count && "border border-2 border-danger"}`} type="number" />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Выход блюда*:</label>
                                        <Field name="out_dish" className={`form-control ${errors.out_dish && touched.out_dish && "border border-2 border-danger"}`} type="number" />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Цена*:</label>
                                        <Field name="price" className={`form-control ${errors.price && touched.price && "border border-2 border-danger"}`} type="number" />
                                    </Col>
                                </Row>
                                <button type="submit" className="btn btn-primary mt-1" id="addNewProject">Добавить</button>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default AddDishInMenu;
