import React, {memo} from 'react';
import {useAppSelector} from "../../../hooks/redux";

const LoginHistory = () => {

    const {selectUser} = useAppSelector(state => state.AdminReducer)

    return (
        <>
            <div className="mt-4 mb-3 border-bottom pb-2">
                <h5 className="card-title">История входа</h5>
            </div>
            {
                selectUser?.login_history?.length === 0 ?
                    <h5 className="text-muted mb-0">Пользователь не входил</h5> :
                    selectUser?.login_history?.map((item) => {
                        return <div key={item.id} className="d-flex align-items-center mb-3">
                            <div className="flex-grow-1 ms-3">
                                <h5 className="text-muted mb-0">{item.entry}</h5>
                            </div>
                        </div>
                    })
            }
        </>
    );
};

export default memo(LoginHistory);
