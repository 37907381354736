import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import ListBenefit from "../../components/KShP/Benefit/ListBenefit";

const Benefit = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Льготы</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={12}>
                            <ListBenefit/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
};

export default Benefit;
