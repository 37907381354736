import React, {useEffect, useState} from 'react';
import {Alert, Col, Container, Row} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getGroupPages, getHeaderPages, getPages, getPrefixTable} from "../../store/reducers/admin/ActionCreator";
import PageAdd from "../../components/admin/page/PageAdd";
import SelectToUpdatePage from "../../components/admin/page/SelectToUpdatePage";
import PageUpdate from "../../components/admin/page/PageUpdate";

const SettingsPage = () => {
    const dispatch = useAppDispatch();
    const {isError, pages} = useAppSelector(state => state.AdminReducer);
    const [addPage, setAddPage] = useState(false);

    useEffect(() => {
        dispatch(getPages())
        dispatch(getGroupPages())
        dispatch(getHeaderPages())
        dispatch(getPrefixTable())
    }, [dispatch])

    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdatePage
                        setAddPage={(val) => setAddPage(val)}
                        pages={pages}/>
                    <div className="w-100 overflow-hidden">
                        <div className="chat-content d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {
                                                    addPage ? <PageAdd /> : <PageUpdate/>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsPage;
