const search = () => {
    let input : any,
        filter: string,
        li: any,
        a: HTMLElement,
        i: number,
        txtValue: string;

    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    let userList = document.getElementsByClassName("users-list");
    Array.prototype.forEach.call(userList, function (el) {
        li = el.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    });
};
export default search;