import React from 'react';
import {Card, CardBody, Table} from "reactstrap";

const GuestInfo = () => {
    return (
        <Card>
            <CardBody>
                <h5 className="card-title mb-3">Информация</h5>
                <div className="table-responsive">
                    <Table className="table-borderless mb-0">
                        <tbody>
                        <tr>
                            <th className="ps-0" scope="row">ФИО:</th>
                            <td className="text-muted">Петров Александр Александрович</td>
                        </tr>
                        <tr>
                            <th className="ps-0" scope="row">Телефон :</th>
                            <td className="text-muted">+(1) 987 6543</td>
                        </tr>
                        <tr>
                            <th className="ps-0" scope="row">E-mail :</th>
                            <td className="text-muted">daveadame@velzon.com</td>
                        </tr>
                        <tr>
                            <th className="ps-0" scope="row">Место работы :</th>
                            <td className="text-muted">California, United States
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>
    );
};

export default GuestInfo;
