import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getUsers, setSelectUser} from "../../../store/reducers/admin/ActionCreator";
import {IUser} from "../../../models/IUser";
import searchUsersAndOrg from "../../../functions/SearchUsersAndOrg";
import {Button, Nav, NavItem, TabContent, TabPane, UncontrolledTooltip} from "reactstrap";
import {Link, NavLink} from "react-router-dom";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import ModalUploadEmployeeAccountData from "./ModalUploadEmployeeAccountData";
import ModalUploadParentAccountData from "./ModalUploadParentAccountData";
import {useStateContext} from "../../../hooks/useStateContext";
import useDebounce from "../../../hooks/useDebounce";
import Select from "react-select";
import schools from "../../../pages/KShP/Schools";
import AxiosClient from "../../../api/AxiosClient";
import {customStyles} from "../../settings";

interface IListUsers {
    setAddUser: (val: boolean) => void
}
const ListUsers: FC<IListUsers> = ({setAddUser}) => {
    const {user} = useStateContext()
    const dispatch = useAppDispatch();
    const { users, terms} = useAppSelector(state => state.AdminReducer)
    const [currentRoomId, setCurrentRoomId] = useState<number>(0);
    const [customActiveTab, setCustomActiveTab] = useState<string>("1");
    const [name, setName] = useState('');
    const [page, setPage] = useState(1);
    const [openModalUploadEmployeeAccountData, setOpenModalUploadEmployeeAccountData] = useState(false);
    const [openModalUploadParentAccountData, setOpenModalUploadParentAccountData] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [schools, setSchools] = useState<any[]>([]);
    const [builds, setBuilds] = useState<any[]>([]);
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectTerm, setSelectTerm] = useState<any>({value: '', label: 'Не выбрано'});
    const debounceName = useDebounce(name, 1000)
    useEffect(() => {
        dispatch(getUsers(page, name, selectSchool.value, selectBuild.value, selectTerm.value));
    }, [dispatch, page]);

    useEffect(() => {
            setPage(1)
            dispatch(getUsers(1, name,selectSchool.value, selectBuild.value, selectTerm.value, "name"));
    }, [debounceName]);

    useEffect(() => {
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
    }, []);


    const toggleCustom = (tab: string) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const userUpdate = (user: IUser) => {
        setCurrentRoomId(user.id)
        dispatch(setSelectUser(user))
    }

    useEffect(() => {
        setCurrentRoomId(users[0]?.id)
        dispatch(setSelectUser(users[0]))
    }, [])

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };

    const onChangeSchool = (data: { value: string, label: string }) => {
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        setSelectSchool(data)
        setPage(1)
        dispatch(getUsers(1, name, data.value,'', selectTerm.value, "name"));
    }

    const onChangeBuild = (data: { value: string, label: string }) => {
        setSelectBuild(data)
        setPage(1)
        dispatch(getUsers(1, name, selectSchool.value, data.value, selectTerm.value, "name"));
    }

    const onChangeTerm = (data: { value: string, label: string }) => {
        setSelectTerm(data)
        setPage(1)
        dispatch(getUsers(1, name, selectSchool.value, selectBuild.value, data.value, "name"));
    }

    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchools(prevOptions => [...prevOptions, ...newData]);
    };
    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };


    return (
        <div className="chat-leftsidebar " style={{height: "calc(100vh - 25px)"}}>
            <div className="px-4 pt-4 mb-4">
                <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                        <h5 className="mb-4">Настройки</h5>
                    </div>
                    {
                        user.term.slug === "ARM OP" &&
                        <div className="flex-shrink-0">
                            <UncontrolledTooltip placement="bottom" target="addcontact">
                                Добавить пользователя
                            </UncontrolledTooltip>

                            <Button
                                color=""
                                id="addcontact"
                                className="btn btn-soft-primary btn-sm"
                                onClick={() => setAddUser(true)}
                            >
                                <i className="ri-add-line align-bottom"></i>
                            </Button>
                        </div>
                    }
                </div>
                {
                    user.term.slug === "ARM OP" && <div className="search-box">
                        <button className="btn btn-primary w-100" onClick={() => setOpenModalUploadEmployeeAccountData(true)}>Создать пароль сотрудникам</button>
                        <button className="btn btn-primary w-100 mt-2" onClick={() => setOpenModalUploadParentAccountData(true)}>Создать пароль родителям</button>
                    </div>
                }
                <div className="search-box mt-2">
                    <input
                        onChange={(event) => setName(event.target.value)}
                        id="search-user"
                        type="text"
                        className="form-control bg-light border-light"
                        placeholder="Поиск пользователя"
                    />
                    <i className="ri-search-2-line search-icon"></i>
                </div>
                <div className="search-box mt-2">
                    <label htmlFor="">Школы</label>
                        <Select
                            options={schools?.length > 0 ? [{
                                value: '',
                                label: 'Не выбрано'
                            }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                            value={selectSchool}
                            onChange={(change: any) => {
                                onChangeSchool({value: change.value, label: change.label})
                            }}
                            isLoading={loadingOptions}
                            onMenuScrollToBottom={handleMenuScroll}
                            styles={customStyles}
                            placeholder="Поиск"
                            name="name"
                        />
                </div>
                <div className="search-box mt-2">
                    <label htmlFor="">Корпус</label>
                    <Select
                        options={builds?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                        value={selectBuild}
                        onChange={(change: any) => {
                            onChangeBuild({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                        isLoading={loadingOptionsBuildings}
                    />
                </div>
                <div className="search-box mt-1">
                    <label htmlFor="">Роль</label>
                    <Select
                        options={terms?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...terms?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                        value={selectTerm}
                        onChange={(change: any) => {
                            onChangeTerm({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                        isLoading={loadingOptionsBuildings}
                    />
                </div>
            </div>

            <Nav tabs className="nav nav-tabs nav-tabs-custom nav-primary nav-justified mb-3">
                <NavItem>
                    <NavLink
                        to="#"
                        style={{cursor: "pointer"}}
                        className={classNames({
                            active: customActiveTab === "1",
                        })}
                        onClick={() => {
                            toggleCustom("1");
                        }}
                    >
                        Пользователи
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent
                activeTab={customActiveTab}
                className="text-muted"
            >
                <TabPane tabId="1" id="chats">
                    <SimpleBar
                        className="chat-room-list pt-3"

                        style={{margin: "-16px 0px 0px"}}
                    >
                        <div className="d-flex align-items-center px-4 mb-2">
                            <div className="flex-grow-1">
                                <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                    Выберите для изменения данных
                                </h4>
                            </div>
                        </div>

                        <div className="chat-message-list">
                            <ul
                                className="list-unstyled chat-list chat-user-list users-list"
                                id="userList"
                            >
                                {(users || []).map((user) => (
                                    <li
                                        key={user.id}
                                        className={
                                            currentRoomId === user.id ? " bg-primary bg-opacity-25" : ""
                                        }
                                    >
                                        <Link to="#"
                                              onClick={() => {
                                                  userUpdate(user)
                                                  setAddUser(false);
                                              }}
                                        >
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                    <div className="avatar-xxs">
                                                        {user.photo !== null ? (
                                                            <img
                                                                src={`http://127.0.0.1:8000${user.photo}`}
                                                                className="rounded-circle img-fluid userprofile"
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <div
                                                                className="avatar-title rounded-circle bg-primary  userprofile">
                                                                {user.name && user.name.charAt(0)}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <p className="text-truncate mb-0">{user.middle_name} {user.name && user.name} {user?.organization?.name}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                                <li>
                                    <button type="button" className="btn w-100 btn-primary mt-3" onClick={() => setPage(page + 1)}>Еще</button>
                                </li>
                            </ul>
                        </div>
                    </SimpleBar>
                </TabPane>
            </TabContent>
            {
                openModalUploadEmployeeAccountData &&
                <ModalUploadEmployeeAccountData openModalUploadEmployeeAccountData={openModalUploadEmployeeAccountData} setOpenModalUploadEmployeeAccountData={value => setOpenModalUploadEmployeeAccountData(value)}/>
            }
            {
                openModalUploadParentAccountData &&
                <ModalUploadParentAccountData openModalUploadParentAccountData={openModalUploadParentAccountData} setOpenModalUploadParentAccountData={value => setOpenModalUploadParentAccountData(value)}/>
            }
        </div>
    );
};

export default ListUsers;
