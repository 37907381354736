import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles} from "../../settings";
import * as Yup from "yup";
import {areIntervalsOverlapping} from "../../../functions/ConvertDate";
import moment from "moment";

interface IModalUpdateViewMenu {
    updateViewMenu: boolean,
    setUpdateViewMenu: (value: boolean) => void,
    selectViewMenu: any,
    listViewMenu: any[],
    setListViewMenu: (value: any[]) => void,
    listMenu: any[]
}

const ModalUpdateViewMenu: FC<IModalUpdateViewMenu> = ({
                                                           setUpdateViewMenu,
                                                           updateViewMenu,
                                                           selectViewMenu,
                                                           setListViewMenu,
                                                           listViewMenu,
                                                           listMenu
                                                       }) => {

    const [dataBegin, setDataBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState<Date | string>('');

    const [selectListMenu, setSelectListMenu] = useState({value: '', label: ''});
    useEffect(() => {
        selectViewMenu &&
        setSelectListMenu({value: selectViewMenu?.idPlan, label: selectViewMenu?.name})
    }, [selectViewMenu]);

    useEffect(() => {
        if (selectViewMenu) {
            setDataBegin(new Date(selectViewMenu?.date_begin))
            setDateEnd(selectViewMenu?.date_end ? new Date(selectViewMenu?.date_end) : '')
        }
    }, [selectViewMenu]);

    return (
        <Modal id="createProjectModal" isOpen={updateViewMenu}
               toggle={() => setUpdateViewMenu(!updateViewMenu)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setUpdateViewMenu(!updateViewMenu)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление планового меню
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        planMenu: selectListMenu.value,
                        date_begin: '',
                        date_end: ''

                    }}
                    validationSchema={
                        Yup.object({
                            planMenu: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values, {setErrors}) => {
                        const isDuplicate = listMenu.some(m => (m.idPlan.toString() === values.planMenu.toString() && m.idPlan.toString() !== selectViewMenu.idPlan.toString()) && areIntervalsOverlapping(m, {
                            date_begin: dataBegin,
                            date_end: dateEnd
                        }));
                        if (isDuplicate) {
                            setErrors({planMenu: 'Такой тип меню уже существует'});
                        } else {
                            setListViewMenu(listViewMenu.map((v: any, index) => {
                                if (index === selectViewMenu.index) {
                                    return {
                                        ...v,
                                        id: selectListMenu.value,
                                        date_begin: moment(dataBegin).format('YYYY-MM-DD'),
                                        date_end: dateEnd ? moment(dateEnd).format('YYYY-MM-DD') : ""
                                    }
                                }
                                return v
                            }))
                            setUpdateViewMenu(false)
                        }
                    }}
                >
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Плановое меню:
                                            <Field
                                                name="planMenu"
                                                component={() => (
                                                    <Select
                                                        name="planMenu"
                                                        options={listMenu?.map(v => ({value: v.idPlan, label: v.name}))}
                                                        value={selectListMenu}
                                                        onChange={(change: any) => {
                                                            setSelectListMenu({
                                                                value: change.value,
                                                                label: change.label
                                                            })
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                    />
                                                )}
                                            />
                                            </label>
                                        </div>
                                        {errors.planMenu && touched.planMenu ? (
                                            <div className="text-danger">{errors.planMenu.toString()}</div>
                                        ) : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Дата с:
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dataBegin}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y"
                                                }}
                                                onClose={(date) => {
                                                    setDataBegin(date[0])
                                                }}
                                            />
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Дата по:
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dateEnd}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y"
                                                }}
                                                onChange={(date) => {
                                                    setDateEnd(date[0])
                                                }}
                                            />
                                            </label>
                                            {errors.date_end && touched.date_end ? (
                                                <div className="text-danger">{errors.date_end.toString()}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    <div className="hstack gap-2">
                                        <button type="submit" className="btn btn-primary mt-1">Сохранить</button>
                                    </div>
                                </div>
                            </Form>
                        )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateViewMenu;
