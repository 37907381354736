import React, {FC} from 'react';
import {Spinner} from "reactstrap";
interface InfoLoaderModal {
    msg?: string
}
const InfoLoaderModal: FC<InfoLoaderModal> = ({msg}) => {
    return (
        <div className="graphLoader">
            <Spinner
                color="primary"
                style={{
                    height: '10rem',
                    width: '10rem',
                    margin: '5rem'
                }}
            >
                Loading...
            </Spinner>
            <h2 className="text-muted">{msg}</h2>
        </div>
    );
};

export default InfoLoaderModal;
