import React, {FC, PropsWithChildren} from 'react';
import {Link} from "react-router-dom";
import {Container} from "reactstrap";
import SimpleBar from "simplebar-react";
import logoSm from "../../../assets/images/logo-sm.png";


const HelperSideBar: FC<PropsWithChildren> = ({children}) => {
    const addEventListenerOnSmHoverMenu = () => {
        // add listener Sidebar Hover icon on change layout from setting
        if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
        } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
        } else {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
        }
    };

    const showMenu = () => {
        document.body.classList.remove('vertical-sidebar-enable');
    }

    return(
        <>
            <div className="app-menu navbar-menu">
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-light" >
                        <span className="logo-sm">
                            <img src={logoSm} alt="" height="22" />
                        </span>
                        <span className="logo-lg fs-24 text-white">
                            СКДОУ
                        </span>
                    </Link>
                    <button
                        onClick={addEventListenerOnSmHoverMenu}
                        type="button"
                        className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover"
                    >
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>
                <>
                    <SimpleBar id="scrollbar" className="h-100">
                        <div id="two-column-menu"></div>
                        <Container fluid>
                            <div id="two-column-menu"></div>
                            <ul className="navbar-nav" id="navbar-nav">
                                {children}
                            </ul>
                        </Container>
                    </SimpleBar>
                </>
            </div>
            <div className="vertical-overlay" onClick={() => showMenu()}></div>
        </>
    )
};

export default HelperSideBar;
