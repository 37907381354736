import React, {FC, useState} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import AxiosClient from "../../../api/AxiosClient";
import Select from "react-select";
import {customStyles} from "../../settings";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {Russian} from "flatpickr/dist/l10n/ru";
import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

interface IModalAddAmountPayment {
    selectChildren: any,
    addAmountPayment: boolean,
    setAddAmountPayment(value: boolean) : any,
}

const ModalAddAmountPayment: FC<IModalAddAmountPayment> = ({selectChildren, addAmountPayment, setAddAmountPayment}) => {
    const dispatch = useAppDispatch();
   // const [date, setDate] = useState([new Date(new Date().setDate(1)), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
    const [date, setDate] = useState([new Date()]);


    return (
        <Modal id="createProjectModal" isOpen={addAmountPayment}
               toggle={() => setAddAmountPayment(!addAmountPayment)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddAmountPayment(!addAmountPayment)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Скачать квитанцию об оплате
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            sum: '',
                        }
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        const params = {
                            sum: values.sum,
                            date: date,
                            children: selectChildren,
                        }
                        AxiosClient.get('cash_movements/payment_receipt', {responseType: 'blob', params})
                            .then((r: any) => {
                                dispatch(setIsSend(false))
                                const url = window.URL.createObjectURL(new Blob([r.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'Квитанция об оплате.xlsx');
                                document.body.appendChild(link);
                                link.click();
                                link.remove();
                                setAddAmountPayment(!addAmountPayment);
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })

                    }}>
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Выберите дату:
                                        <Flatpickr
                                            name="date"
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={date}
                                            options={{
                                                plugins: [
                                                    monthSelectPlugin({
                                                        shorthand: true,
                                                        dateFormat: "F Y",
                                                        altFormat: "F Y",
                                                        theme: "light"
                                                    })
                                                ],
                                                locale: Russian, disableMobile: true
                                            }}
                                            onChange={(date) => setDate(date)}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Введите сумму для оплаты:
                                            <Field name="sum" className="mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"/>
                                        </label>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <SubmitWithLoading className="btn btn-primary w-100 mt-1"
                                                       onClick={() => {}}
                                                       text={"Скачать квитанцию"}/>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddAmountPayment;
