import React, {FC} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";

interface IModelAddSchool {
    addClass: boolean,
    setAddClass: (value: boolean) => void,
    setBuilds: (value: any) => void
}

const ModalAddBuilding: FC<IModelAddSchool> = ({setAddClass, addClass, setBuilds}) => {
    return (
        <Modal id="createProjectModal" isOpen={addClass}
               toggle={() => setAddClass(!addClass)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddClass(!addClass)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление корпуса
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            name: '',
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        setBuilds({
                            name: values.name,
                            class: []
                        })
                    }}>
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Название:
                                            <Field name="name" className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}/>
                                        </label>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <button type="submit" className="btn btn-primary" id="addNewProject" onClick={() => setAddClass(false)}>Добавить</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddBuilding;
