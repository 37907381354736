import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment/moment";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import ModalConfirmExit from "./ModalConfirmExit";
import _ from "lodash";

interface IModalUpdateCateringOrganizer {
    selectCateringOrganizer: any,
    updateCateringOrganizer: boolean,
    setUpdateCateringOrganizer: (value: boolean) => void,
    setSchools: (value: any) => void
}

const ModalUpdateCateringOrganizer: FC<IModalUpdateCateringOrganizer> = ({setSchools, selectCateringOrganizer, updateCateringOrganizer, setUpdateCateringOrganizer}) => {
    const idButtonSave: string = 'buttonSaveModalUpdateCateringOrganizer';
    const [dateContract, setDateContract] = useState(new Date());
    const [dateBegin, setDateBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [confirmExit, setConfirmExit] = useState(false);
    const [newObject, setNewObject] = useState<any>({})
    const [isChanges, setIsChanges] = useState(false);


    const [cateringOrganizers, setCateringOrganizers] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/catering_organizaers')
            .then(r => {
                setCateringOrganizers(r.data)
            })
    }, []);

    useEffect(() => {
        if (selectCateringOrganizer){
            setDateContract(moment(selectCateringOrganizer.date_contract).toDate())
            setDateBegin(moment(selectCateringOrganizer.date_begin).toDate())
            setDateEnd(moment(selectCateringOrganizer.date_end).toDate())
        }
        setNewObject(selectCateringOrganizer)
    }, [selectCateringOrganizer]);

    const confirmOrExit = () => {
        if(!_.isEqual(selectCateringOrganizer, newObject)){
            setConfirmExit(true);
        }else{
            setUpdateCateringOrganizer(false);
        }
    }


    return (
        <Modal id="createProjectModal" isOpen={updateCateringOrganizer}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление ОП
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            catering_organizer_id: selectCateringOrganizer?.catering_organizer_id,
                            contract: selectCateringOrganizer?.contract,
                            date_contract: new Date(),
                            date_begin: new Date(),
                            date_end: new Date(),
                            discount_price: selectCateringOrganizer?.discount_price
                        }
                    }
                    validationSchema={
                        Yup.object({
                            contract: Yup.number().required('Обязательное поле'),
                            discount_price: Yup.number()
                        })
                    }
                    onSubmit={(values) => {
                        values.date_contract = dateContract
                        values.date_begin = dateBegin
                        values.date_end = dateEnd

                        AxiosClient.post(`/schools/update_catering_organizer/${selectCateringOrganizer.id}`, values)
                            .then(() => {
                                setUpdateCateringOrganizer(false)
                                AxiosClient.get('/schools', {params: {page: 1, per_page: 10}})
                                    .then((r) => setSchools(r.data))
                                    .catch(() => console.log('error'))
                            })
                            .catch(() => console.log('error'))

                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Организатор питания:
                                        <Field name="catering_organizer_id" as="select" className="form-select"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'catering_organizer_id': e.target.value});
                                                   setFieldValue('catering_organizer_id', e.target.value);
                                               }}
                                        >
                                            {
                                                cateringOrganizers.map(co => <option key={co.id} value={co.id}>{co.name}</option>)
                                            }
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Номер договора с ОП:
                                        <Field name="contract" className={`form-control ${errors.contract && touched.contract && "border border-2 border-danger"}`}
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'contract': e.target.value});
                                                   setFieldValue('contract', e.target.value);
                                               }}
                                        />
                                        </label>
                                    </Col>
                                    <Col>
                                        <label className="w-100">Дата договора с ОП:
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateContract}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onClose={(date) => {
                                                setDateContract(date[0])
                                                setNewObject({...newObject, 'date_contract': date[0]});
                                            }}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Дата с:
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateBegin}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onClose={(date) => {
                                                setDateBegin(date[0])
                                                setNewObject({...newObject, 'date_begin': date[0]});
                                            }}
                                        />
                                        </label>
                                    </Col>
                                    <Col>
                                        <label className="w-100">Дата по:
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateEnd}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onClose={(date) => {
                                                setDateEnd(date[0])
                                                setNewObject({...newObject, 'date_end': date[0]});
                                            }}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100">Скидка:
                                        <Field name="discount_price" className={`form-control ${errors.discount_price && touched.discount_price && "border border-2 border-danger"}`}
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'discount_price': e.target.value});
                                                   setFieldValue('discount_price', e.target.value);
                                               }}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2 mt-2">
                                    <button type="submit" className="btn btn-primary" id={idButtonSave}>Сохранить</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setUpdateCateringOrganizer(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal>
    );
};

export default ModalUpdateCateringOrganizer;
