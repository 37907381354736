import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Button, Col, Row, Spinner} from "reactstrap";
import {Link} from "react-router-dom";
import userDummayImage from "../../assets/images/users/user-dummy-img.jpg";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useStateContext} from "../../hooks/useStateContext";
import AxiosClient from "../../api/AxiosClient";
import Message from "./Message";
import "react-perfect-scrollbar/dist/css/styles.css";
import {convertDateTimeMessage} from "../../functions/ConvertDate";
import {setSelectChat} from "../../store/reducers/widgets/WidgetsSlice";

const ChatGpt = () => {
    const dispatch = useAppDispatch();
    const {user} = useStateContext();
    const [messageBox, setMessageBox] = useState<any>(null);
    const {selectChat} = useAppSelector(state => state.WidgetReducer)
    const [message, setMessage] = useState<string>('');
    const [newMess, setNewMess] = useState<any[]>([]);
    const [oldMess, setOldMess] = useState<any[]>([]);
    const [timeReply, setTimeReply] = useState(false);

    const scrollToBottom = useCallback(() => {
        if (messageBox) {
            messageBox.scrollTop = messageBox.scrollHeight + 1000;
        }
    }, [messageBox]);

    useEffect(() => {
        scrollToBottom();
    }, [newMess, scrollToBottom, oldMess]);

    useEffect(() => {
        AxiosClient.get('/history/gpt')
            .then(r => setOldMess(r.data.map((mes: any) => (
                <Message isUser={mes.send_user_id === user.id} message={mes.message}
                         time={convertDateTimeMessage(new Date(mes.created_at))}/>))))
    }, [user.id]);


    function sendMessage() {
        setTimeReply(true)
        setMessage('')
        setNewMess([...newMess, <Message isUser={true} message={message} time={convertDateTimeMessage(new Date())}/>])
        AxiosClient.post('/ai/gpt', {message})
            .then(r => {
                setTimeReply(false)
                setNewMess([...newMess,
                    <Message isUser={true} message={message} time={convertDateTimeMessage(new Date())}/>,
                    <Message isUser={false} message={`${r.data}`} time={convertDateTimeMessage(new Date())}/>])
            })
            .catch(() => {
                setTimeReply(false)
                setNewMess([...newMess,
                    <Message isUser={true} message={message} time={convertDateTimeMessage(new Date())}/>,
                    <Message isUser={false} type="info" message="Мне не удалось обработать запрос, потому что сервер перегружен, попробуйте еще раз."
                             time={convertDateTimeMessage(new Date())}/>])
            })
        // AxiosClient.get('/message');
    }

    function keyEnter(e: any) {
        if (message) {
            if (e.keyCode === 13) {
                e.preventDefault()
                sendMessage()
            }
        } else {
            if (e.keyCode === 13) {
                e.preventDefault()
            }
        }

    }

    return (
        <div className="w-100 overflow-hidden">
            <div className="chat-content d-lg-flex">
                <div className="w-100 overflow-hidden position-relative">
                    <div className="position-relative">
                        <div className="p-3 pb-2 bg-white">
                            <Row className="align-items-center">
                                <Col sm={12} xs={12}>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 d-block me-3">
                                            <Link
                                                to="#"
                                                className="user-chat-remove p-1"
                                                style={{fontSize: 30}}
                                                onClick={() => {
                                                    dispatch(setSelectChat(null))
                                                }}
                                            >
                                                <i className="ri-arrow-left-s-line align-bottom"></i>
                                            </Link>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                                    <img
                                                        src={userDummayImage}
                                                        className="rounded-circle avatar-xs"
                                                        alt=""
                                                    />
                                                    <span className="user-status"></span>
                                                </div>
                                                <div className=" overflow-hidden">
                                                    <h5 className=" mb-0 fs-16">
                                                        <a
                                                            className="text-reset username"
                                                            data-bs-toggle="offcanvas"
                                                            aria-controls="userProfileCanvasExample"
                                                        >
                                                            {selectChat?.name}
                                                        </a>
                                                    </h5>
                                                    <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                                        <small>Online</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="position-relative" id="users-chat">
                            <PerfectScrollbar
                                className="chat-conversation p-3 p-lg-4"
                                id="chat-conversation"
                                containerRef={ref => setMessageBox(ref)}>
                                <div id="elmLoader"></div>
                                <ul
                                    className="list-unstyled chat-conversation-list"
                                    id="users-conversation"
                                >
                                    {
                                        oldMess.map((mes, i) => <Fragment key={i}>{mes}</Fragment>)
                                    }
                                    <Message isUser={false} type="warning"
                                             message="Я искусственный интелект! Все мои советы не должны восприниматься как должное (призыв к действию). Все советы должны быть Вами проанализированны и взвешаны. За преминение советов (результатов) выданных исскуственным интелектом Вы самостоятельно несете ответственность. Администрация учреждения и разработчики программного обеспечения за результаты формирования советов искуственного интелекта ответственности не несут."
                                             time={convertDateTimeMessage(new Date())}/>
                                </ul>
                                <ul
                                    className="list-unstyled chat-conversation-list"
                                    id="users-conversation"
                                >
                                    {
                                        newMess.map((mes, i) => <Fragment key={i}>{mes}</Fragment>)
                                    }
                                </ul>
                            </PerfectScrollbar>
                        </div>
                        <div className="chat-input-section p-3 p-lg-4">
                            {
                                timeReply ?
                                    <Row className="g-0 align-items-center">
                                        <span
                                            className="d-flex form-control border-white align-items-center justify-content-center">
                                            <span className="me-2">Макс. время ответа 3 мин. подождите...</span>
                                            <Spinner size="sm" className="flex-shrink-0" role="status">Загрузка</Spinner>
                                        </span>
                                    </Row>
                                    :
                                    <form id="chatinput-form">
                                        <Row className="g-0 align-items-center">

                                            <div className="col">
                                                <div className="chat-input-feedback">
                                                    Введите сообщение
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control chat-input bg-light border-light"
                                                    id="chat-input"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    onKeyDown={keyEnter}
                                                    placeholder="Введите сообщение"
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <div className="chat-input-links ms-2">
                                                    <div className="links-list-item">
                                                        <Button
                                                            type="button"
                                                            color="success"
                                                            className="chat-send waves-effect waves-light"
                                                            onClick={sendMessage}

                                                        >
                                                            <i className="ri-send-plane-2-fill align-bottom"></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </form>
                            }
                        </div>

                        {/*<div>*/}
                        {/*    <Card className="mb-0">*/}
                        {/*        <CardBody className="py-3">*/}
                        {/*            <div className="replymessage-block mb-0 d-flex align-items-start">*/}
                        {/*                <div className="flex-grow-1">*/}
                        {/*                    <h5 className="conversation-name">zxc</h5>*/}
                        {/*                    <p className="mb-0">zxc</p>*/}
                        {/*                </div>*/}
                        {/*                <div className="flex-shrink-0">*/}
                        {/*                    <button*/}
                        {/*                        type="button"*/}
                        {/*                        id="close_toggle"*/}
                        {/*                        className="btn btn-sm btn-link mt-n2 me-n3 fs-18"*/}

                        {/*                    >*/}
                        {/*                        <i className="bx bx-x align-middle"></i>*/}
                        {/*                    </button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </CardBody>*/}
                        {/*    </Card>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatGpt;
