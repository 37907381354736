import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

interface IModalUpdateBenefit {
    updateBenefit: boolean,
    setUpdateBenefit: (value: boolean) => void,
    addMyBenefits: any[],
    setAddMyBenefits: (value: any[]) => void
    selectBenefit: any
}
const ModalUpdateBenefit: FC<IModalUpdateBenefit> = ({selectBenefit, addMyBenefits, setAddMyBenefits, updateBenefit, setUpdateBenefit}) => {
    const [benefits, setBenefits] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/benefits')
            .then(r => setBenefits(r.data))
    }, []);


    return (
        <Modal id="createProjectModal" isOpen={updateBenefit}
               toggle={() => setUpdateBenefit(!updateBenefit)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setUpdateBenefit(!updateBenefit)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление льготы
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {benefit_id: selectBenefit?.id}
                    }
                    validationSchema={
                        Yup.object({
                            benefit_id: Yup.string().required('Require')
                        })
                    }
                    onSubmit={(values) => {
                        setAddMyBenefits(addMyBenefits.map(el => {
                            if (el.id === selectBenefit.id){
                                return benefits.find(el => el.id.toString() === values.benefit_id.toString())
                            }
                            return el
                        }))
                        setUpdateBenefit(false)
                    }}
                >
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Field as="select" name="benefit_id" className={`form-select ${errors.benefit_id && touched.benefit_id && "border border-2 border-danger"}`}>
                                    <option value="">Не выбрано</option>
                                    {
                                        benefits.map(el => (<option key={el.id} value={el.id}>{el.name}</option>))
                                    }
                                </Field>
                                <button type="submit" className="btn btn-primary mt-1" id="addNewProject">Сохранить</button>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateBenefit;
