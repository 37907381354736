import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import ListStatusBenefit from "../../components/KShP/StatusBenefit/ListStatusBenefit";

const Status = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Статусы льгот</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={12}>
                            <ListStatusBenefit/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Status;
