import React, {useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalAddAgeCategory from "./ModalAddAgeCategory";
import ModalUpdateAgeCategory from "./ModalUpdateAgeCategory";
import Notification from "../../widgets/Notification";

const ListAgeCategory = () => {

    const [ageCategories, setAgeCategories] = useState<any[]>([]);
    const [addAgeCategory, setAddAgeCategory] = useState(false);
    const [selectAgeCategory, setSelectAgeCategory] = useState<any | null>(null);
    const [updateAgeCategory, setUpdateAgeCategory] = useState(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');

    useEffect(() => {
        AxiosClient.get('/age_categories')
            .then(r => {
                setAgeCategories(r.data)
            })
    }, []);

    const archiveAgeCategory = (id: any) => {
        AxiosClient.post(`/age_categories/archive/${id}`)
            .then(() => {
                AxiosClient.get('/age_categories')
                    .then(r => setAgeCategories(r.data))
                setNotificationType('success');
                setNotificationMessage('Готово, возрастная категория в архиве');
                setShowNotification(true);
            })
            .catch((error) => {
                setNotificationType('error');
                setNotificationMessage('Ошибка, не удалось добавить в архив');
                setShowNotification(true);
            })
    }

    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Наименование</span>,
            selector: (row: any) => row.name,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold fs-13'></span>,
            selector: (row: any) => <i className="bx bx-archive-in fs-3 cursor-pointer" title="Добавить в архив" onClick={() => archiveAgeCategory(row.id)}></i>,
            width: '60px',
        }
    ]


    return (
        <>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={ageCategories}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно категорий:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setSelectAgeCategory(row)
                                            setUpdateAgeCategory(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setSelectAgeCategory(row)
                                            setUpdateAgeCategory(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter>
                            <button className="btn btn-primary" onClick={() => setAddAgeCategory(true)}>Добавить</button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <ModalAddAgeCategory addAgeCategory={addAgeCategory} setAddAgeCategory={value => setAddAgeCategory(value)} setAgeCategories={value => setAgeCategories(value)}/>
            <ModalUpdateAgeCategory updateAgeCategory={updateAgeCategory} setUpdateAgeCategory={value => setUpdateAgeCategory(value)} setAgeCategories={value => setAgeCategories(value)} selectAgeCategory={selectAgeCategory}/>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default ListAgeCategory;
