import React, {FC, useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import ModalAllInfoChildren from "./ModalAllInfoChildren";
import AxiosClient from "../../../api/AxiosClient";

interface ITableStudent {
    students: any[],
    setStudents: (value: any[]) => void
}

const TableStudent:FC<ITableStudent> = ({students, setStudents}) => {

    const [openAllInfoChildren, setOpenAllInfoChildren] = useState(false);
    const [selectChildren, setSelectChildren] = useState<any | null>(null);
    const [deviations, setDeviations] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/deviation_school')
            .then(r => setDeviations(r.data))
    }, []);

    const deviationChange = (id: string | number, deviation: string | number) => {
        setStudents(students.map((student: any) => {
            if (student.id.toString() === id.toString()){
                return {
                    ...student,
                    attendance: +deviation === 1,
                    deviation: deviation,
                    catering_organizer_type_planned_menu: student?.catering_organizer_type_planned_menu?.map((co: any) => {
                        return {
                            ...co,
                            mealtime: co.mealtime.map((meal: any) => {
                                return {
                                    ...meal,
                                    checked: +deviation === 1
                                }
                            })
                        }
                    })
                }
            }
            return student
        }))
    }

    const columns: any = [
        {
            name: <span className='font-weight-bold fs-13'>ФИО ребенка (Баланс)</span>,
            selector: (row: any) => `${row.student.last_name} ${row.student.name} (${row.balance})`,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Отсутствие</span>,
            selector: (row: any, i: number) => (
                <select className="form-select table-add-ceil selectStudentsDev" value={row.deviation} onChange={(e) => deviationChange(row.id, e.target.value)}>
                    {
                        deviations.map((d: any) => <option key={d.id} value={d.id}>{d.name}</option>)
                    }
                </select>
            ),
            center: true,
            style: {padding: '0'}
        }
    ];

    return (
        <>
            <DataTable
                columns={columns}
                data={students}
                className="custom-scroll-news"
                fixedHeader={true}
                fixedHeaderScrollHeight={'400px'}
                noDataComponent={'В данном классе нет детей'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                onRowClicked={(row) => {
                    setSelectChildren(row)
                    setOpenAllInfoChildren(true)
                }}
            />
            <ModalAllInfoChildren setChildren={value => setStudents(value)} setSelectChildren={value => setSelectChildren(value)} children={students} selectChildren={selectChildren} openAllInfoChildren={openAllInfoChildren} setOpenAllInfoChildren={values => setOpenAllInfoChildren(values)}/>
        </>
    );
};

export default TableStudent;
