import {AxiosResponse} from "axios";
import {IPage} from "../../../models/IPage";
import $api from "../../AxiosClient";
import {IGroupPage} from "../../../models/IGroupPage";
import {IHeaderPage} from "../../../models/IHeaderPage";
import {IPrefixTable} from "../../../models/IPrefixTable";

class PageService {
    static async getPage(): Promise<AxiosResponse<IPage[]>>{
        return $api.get('/pages')
    }

    static async updatePage(value: object, pageId: number): Promise<AxiosResponse<any>>{
        return $api.post(`/pages/update/${pageId}`, value)
    }

    static async addPage(value: object): Promise<AxiosResponse<any>>{
        return $api.post(`/pages/create`, value)
    }

    static async getPrefixTables(): Promise<AxiosResponse<IPrefixTable[]>>{
        return $api.get('/prefix_table')
    }

    static async getHeaderPage(): Promise<AxiosResponse<IHeaderPage[]>>{
        return $api.get('/header_pages')
    }

    static async getGroupPage(): Promise<AxiosResponse<IGroupPage[]>>{
        return $api.get('/group_pages')
    }
}

export default PageService