import {AxiosResponse} from "axios";
import {ICity} from "../../../models/ICity";
import $api from "../../AxiosClient";

class CityService{
    static async getCities(): Promise<AxiosResponse<ICity[]>>{
        return $api.post("/sitys")
    }

    static async addCity(value: object): Promise<AxiosResponse<any>>{
        return $api.post("/sitys/add", value)
    }

    static async updateCity(value: object, cityId: number): Promise<AxiosResponse<any>>{
        return $api.post(`/sitys/update/${cityId}`, value)
    }
}

export default CityService;