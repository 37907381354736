import {AxiosResponse} from "axios";
import {IRegion} from "../../../models/IRegion";
import $api from "../../AxiosClient";

class RegionService {
    static async getRegions(): Promise<AxiosResponse<IRegion[]>>{
        return $api.post<IRegion[]>("/regions");
    }

    static async addRegions(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/region/add", value);
    }

    static async updateRegions(value: object, regionId: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/region/update/${regionId}`, value);
    }
}

export default RegionService