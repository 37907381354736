import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import ListCashMovement from "../../components/KShP/CashMovement/ListCashMovement";
import ListCashMovementResult from "../../components/KShP/CashMovement/ListCashMovementResult";
import moment from 'moment';

const CashMovement = () => {
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'});
    const [dateEnd, setDateEnd] = useState(moment().endOf('month').toDate());
    const [dateStart, setDateStart] = useState(moment().startOf('month').toDate());


    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Движение ДС</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={12}>
                            <ListCashMovement selectBuild={selectBuild} selectClass={selectClass}
                                              selectSchool={selectSchool}
                                              setSelectBuild={value => setSelectBuild(value)}
                                              setSelectClass={value => setSelectClass(value)}
                                              setSelectSchool={value => setSelectSchool(value)}
                                              dateEnd={dateEnd}
                                              dateStart={dateStart}
                                              setDateEnd={value => setDateEnd(value)}
                                              setDateStart={value => setDateStart(value)}
                                />
                            <ListCashMovementResult selectBuild={selectBuild} selectClass={selectClass}
                                                    selectSchool={selectSchool} dateEnd={dateEnd} dateStart={dateStart}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default CashMovement;
