export const showPhone = (phone: string): string => {

    let p = ''

    for (let i = 0; i < phone.length; i++) {
        if (i > 3 && i < 9) {
            p += '*'
        } else {
            p += phone.charAt(i)
        }
    }

    return p;
}