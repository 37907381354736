import React from 'react';
import {Spinner} from "reactstrap";

const CheckPayInfo = () => {
    return (
        <div className='d-flex justify-content-center'>
            <div className='d-grid justify-content-center'>
                <h2 className="align-content-center">Проверка оплаты <br/>
                    Не закрывайте страницу!</h2>
                <div className='d-flex justify-content-center'>
                    <Spinner color="dark" style={{ width: '5rem', height: '5rem' }} />
                </div>
            </div>
        </div>
    );
};

export default CheckPayInfo;
