import React, {FC, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";
import {customStyles} from "../../settings";
import Select from "react-select";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";

interface IModalAddTeacher {
    teacher: any,
    addTeacher: boolean,
    setAddTeacher: (value: boolean) => void,
    schoolId: number | string
    setSchool: (value: any[]) => void,
}

const ModalAddTeacher: FC<IModalAddTeacher> = ({teacher, setAddTeacher, addTeacher, schoolId, setSchool}) => {

    const [users, setUsers] = useState<any[]>([]);
    const [selectUser, setSelectUser] = useState({value: '', label: ''});
    const [errorDouble, setErrorDouble] = useState(false);
    const dispatch = useAppDispatch()
    useEffect(() => {
        AxiosClient.get('/users/users_for_schools')
            .then((r) => {
                setUsers(r.data)
                setSelectUser({value: r.data[0].id, label: `${r.data[0].middle_name} ${r.data[0].name} ${r.data[0].last_name}`})
            })
    }, []);

    const saveTeacher = () => {
        dispatch(setIsSend(true))
        if(teacher.users.find((teacherValue: any) => teacherValue.id === selectUser.value)){
            setErrorDouble(true)
            dispatch(setIsSend(false))
        }else {
            setErrorDouble(false)
            AxiosClient.post('/schools/add_user_school', {id: schoolId, idUser: selectUser.value})
                .then((r) => {
                    dispatch(setIsSend(false))
                    setAddTeacher(false)
                    AxiosClient.get('/schools/get_users')
                        .then((r) => setSchool(r.data))
                })
                .catch(() => dispatch(setIsSend(false)))
        }
    }

    return (
        <Modal id="createProjectModal" isOpen={addTeacher}
               toggle={() => setAddTeacher(!addTeacher)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddTeacher(!addTeacher)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавить учителя
            </ModalHeader>
            <ModalBody>
                <Select
                    options={users?.map(v => ({value: v.id, label: `${v.middle_name ? v.middle_name : ''} ${v.name} ${v?.last_name ? v?.last_name : ''}`}))}
                    value={selectUser}
                    onChange={(change: any) => {
                        setSelectUser({value: change.value, label: change.label})
                    }}
                    styles={customStyles}
                    placeholder="Поиск"
                    name="name"
                    className="mt-3"
                />
                {errorDouble ? (
                    <div className="text-danger">Такой учитель уже есть в этой школе</div>
                ) : null}
            </ModalBody>
            <ModalFooter className="justify-content-start">
                <div className="hstack gap-2">
                    <SubmitWithLoading type="submit" text={"Добавить"} onClick={() => saveTeacher()}/>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddTeacher;
