import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    getCities,
    getCountries,
    getOrganizations,
    getPositions,
    getTerms
} from "../../store/reducers/admin/ActionCreator";
import {Alert, Container, Row} from "reactstrap";
import UserAdd from "../../components/admin/user/UserAdd";
import UserUpdate from "../../components/admin/user/UserUpdate";
import ListUsers from "../../components/KShP/SettingsUser/ListUsers";

const SettingsUserKSHP = () => {
    const dispatch = useAppDispatch();
    const [addUser, setAddUser] = useState(false);
    const {isError} = useAppSelector(state => state.AdminReducer)
    useEffect(() => {
        dispatch(getCities())
        dispatch(getOrganizations())
        dispatch(getTerms())
        dispatch(getPositions())
        dispatch(getCountries())
    }, [dispatch]);

    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <ListUsers
                        setAddUser={(val) => setAddUser(val)}
                    />
                    <div className="w-100 overflow-hidden">
                        <div>
                            <div className="position-relative">
                                <div className="position-relative mt-5" id="users-chat">
                                    <Row>
                                        {
                                            addUser ? <UserAdd/> : <UserUpdate/>
                                        }
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsUserKSHP;
