import React from 'react';
import {Container, Row} from "reactstrap";
import MyInfoForm from "../components/profile/MyInfoForm";
import MyHeaderInfo from "../components/profile/MyHeaderInfo";
import {Helmet} from "react-helmet";


const MyProfile = () => {

    return (
        <div className="page-content">
            <Container fluid>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Профиль</title>
                </Helmet>
                <MyHeaderInfo/>
                <Row>
                    <MyInfoForm/>
                </Row>
            </Container>
        </div>
    );
};

export default MyProfile;
