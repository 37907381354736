import React, {FC, useState} from 'react';
import DataTable, {ConditionalStyles} from "react-data-table-component";
import ModalUpdatePersonalAccount from "./ModalUpdatePersonalAccount";
interface IListPersonalAccount {
    listPersonalAccount: any[],
    setStudents: (value: any[]) => void
}
const ListPersonalAccount: FC<IListPersonalAccount> = ({listPersonalAccount, setStudents}) => {

    const [updatePersonalAccount, setUpdatePersonalAccount] = useState(false);
    const [selectPersonalAccount, setSelectPersonalAccount] = useState<any | null>(null);
    const columns = [
        {
            id: "null",
            name: <span className='font-weight-bold fs-13'></span>,
            cell: () => <i className="fs-3"></i>,
            width: '48px',
            style: {padding: '0.4rem'}
        },
        {
            id: "date",
            cell: (row: any) => <span className='px-1 fs-13'>{row.personal_account}</span>,
            wrap: true
        },
        {
            id: "FIO",
            cell: (row: any) => <span className='px-1 fs-13'>{`${row.student.last_name} ${row.student.name} ${row.student.middle_name}`}</span>,
            wrap: true

        },
        {
            id: "benefits",
            cell: (row: any) => <span className='px-1 fs-13'>{row.benefits.map((b: any, index: number) => (<div key={index}>{b.name}<br/></div>))}</span>,
            wrap: true
        },
        {
            id: "sum",
            cell: (row: any) => <span className='px-1 fs-13'>{`${row.benefits.map((b: any) => b.pivot.sum ? +b.pivot.sum : 0).reduce((a: any, b: any) => a + b, 0)} р`}</span>,
        },
        {
            id: "statuses",
            cell: (row: any) => <span className='px-1 fs-13'>{`${row.statuses.map((b: any) => " "+b.name)}`}</span>,
            wrap: true
        },
        {
            id: "cateringOrganizer",
            cell: (row: any) => <span className='px-1 fs-13'>{row.catering_organizer_type_planned_menu.map((el: any, index: number) => (<div key={index}>{el.type_planned_menu.name}<br/></div>))}</span>,
            wrap: true
        },
        {
            id: "price",
            cell: (row: any) => <span className='px-1 fs-13'>{`${row.catering_organizer_type_planned_menu.map((b: any) => b.price ? +b.price : 0).reduce((a: any, b: any) => a + b, 0)} р`}</span>,
        },
        {
            id: "nullEnd",
            cell: () => <span className='px-1 fs-13'></span>,
            width: '50px'
        },
    ]

    const conditionalRowStyles: ConditionalStyles<any>[] = [
        {
            when: (row: any) => row.date_end !== null,
            style: {
                color: 'gray'
            },
        },
    ];

    return (
        <>
            <DataTable
                noTableHead
                columns={columns}
                data={listPersonalAccount}
                noDataComponent={'Нет данных'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                conditionalRowStyles={conditionalRowStyles}
                onRowClicked={(row) => {
                    setUpdatePersonalAccount(true)
                    setSelectPersonalAccount(row)
                }}
            />
            <ModalUpdatePersonalAccount setStudents={value => setStudents(value)} selectPersonalAccount={selectPersonalAccount} updatePersonalAccount={updatePersonalAccount} setUpdatePersonalAccount={(value) => setUpdatePersonalAccount(value)}/>
        </>
    );
};

export default ListPersonalAccount;
