export const convertDate = (date: Date): string => {
       let month = "" + (date.getMonth() + 1),
        day = "" + date.getDate(),
        year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join(".");
}

export const convertDateFull = (date: string): string => {
    const newDate = date.split('.')
    let month = "" + newDate[1],
        day = "" + newDate[0],
        year = newDate[2];
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [month, day, year].join(".");
}

export const convertDateFullCalendar = (date: string): string => {
    const newDate = date.split('.')
    let month = "" + newDate[1],
        day = "" + newDate[0],
        year = newDate[2];
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
}

export const convertDateLineInDot = (date: string): string => {
    const newDate = date.split('-')
    let month = "" + newDate[1],
        day = "" + newDate[2],
        year = newDate[0];
    return [day, month, year].join(".");
}

export const convertDateFullTime = (date: string): string => {
    const arrDateTime = date.split(' ');
    const newDate = arrDateTime[0].split('.')
    let month = "" + newDate[1],
        day = "" + newDate[0],
        year = newDate[2];
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [month, day, year].join(".") + " " + arrDateTime[1];
}

export const convertDateFullMonthName = (date: string): string => {
    const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
    ];
    const newDate = date.split('.')
    let month = "" + monthNames[newDate[1] as any],
        day = "" + newDate[0],
        year = newDate[2];
    if (day.length < 2) day = "0" + day;
    return [month, day, year].join(" ");
}

export const convertDateGetMonthName = (date: Date): string => {
    const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
    ];
    return monthNames[date.getMonth()];
}

export const convertDateTimeFull = (date: Date): string => {
    const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
    ];
    date = new Date(date)
    let month = "" + monthNames[date?.getUTCMonth()],
        day = "" + date?.getDate(),
        year = date?.getFullYear(),
        hour = date?.getHours(),
        minutes = date?.getMinutes();
    if (day.length < 2) day = "0" + day;
    return [month, day, year, [hour, minutes].join(':')].join(" ");
}

export const convertDateTimeMessage = (date: Date): string => {
    date = new Date(date)
    let hour = date?.getHours(),
        minutes = date?.getMinutes();
    return [hour, minutes].join(':');
}

export const convertDateTimeFull1C = (date: Date): string => {
    date = new Date(date)
    let month = "" + (date.getMonth() + 1),
        day = "" + date?.getDate(),
        year = date?.getFullYear(),
        hour = date?.getHours(),
        minutes = date?.getMinutes();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join(".") + " " + [hour, minutes].join(':');
}

export const areIntervalsOverlapping = (obj1: any, obj2: any): boolean => {
    const start1 = new Date(obj1.date_begin);
    const end1 = obj1.date_end ? new Date(obj1.date_end) : null;
    const start2 = new Date(obj2.date_begin);
    const end2 = obj2.date_end ? new Date(obj2.date_end) : null;

    // Если любой из интервалов бесконечен (end1 или end2 == null)
    if (end1 === null && end2 === null) {
        return true;
    } else if (end1 === null && end2 !== null) {
        return start1 <= end2;
    } else if (end2 === null && end1 !== null) {
        return start2 <= end1;
    }

    // Проверка пересечения интервалов
    return end1 !== null && end2 !== null && (start1 <= end2) && (start2 <= end1);
}
