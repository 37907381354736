import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import ModalUpdateDefect from "../ModalUpdateDefect";
interface ITableDefect {
    defect: any,
    setCompl: (data: any[]) => void
}
const TableDefect: FC<ITableDefect> = ({defect, setCompl}) => {

    const [update, setUpdate] = useState(false);
    const [selectDefect, setSelectDefect] = useState<any>({});

    function deleteCompl(i: number){
        const newCompl = defect.filter((data: any, indexElem: number) => indexElem !== i)
        setCompl(newCompl)
    }

    function updateData(data: any) {
        const newData = defect.map((zam: any, i: number) => {
            if (i === data.index){
                return {
                    name_type_defect: data.name_type_defect,
                    type_defect_uid: data.type_defect_uid,
                    name_critical_defect: data.name_critical_defect,
                    critical_defect_uid: data.critical_defect_uid,
                    name_cause_defect: data.name_cause_defect,
                    cause_defect_uid: data.cause_defect_uid,
                    description: data.description
                }
            }
            return zam
        })
        setCompl(newData)
    }

    const columns: any = [
        {
            name: <span className='font-weight-bold fs-13'>Вид дефекта</span>,
            selector: (row: any) => row.name_type_defect,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Критичность дефекта</span>,
            selector: (row: any) => row.name_critical_defect,
        },
        {
            name: <span className='font-weight-bold fs-13'>Причина дефекта</span>,
            selector: (row: any) => row.name_cause_defect,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Описание</span>,
            selector: (row: any) => row.description,
        },
        {
            selector: (row: any, i: number) => (
                <div className="col-auto">
                    <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                        <div className="text-center">
                            <Link to="#" className="text-black"><i className="bx bx-trash fs-24" onClick={() => deleteCompl(i)}></i></Link>
                        </div>
                    </div>
                </div>
            ),
        }
    ];

    return (
        <>
            <DataTable
                columns={columns}
                data={defect}
                noDataComponent={'Нет дефектов'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                onRowClicked={(row: any) => {
                    setSelectDefect({...row, index: defect.findIndex((zam: any) => zam.type_defect_uid === row.type_defect_uid && zam.critical_defect_uid === row.critical_defect_uid && zam.cause_defect_uid === row.cause_defect_uid && zam.description === row.description)})
                    setUpdate(true)
                }}
            />
            {
                update &&
                <ModalUpdateDefect addDefect={update} setAddDefect={data => setUpdate(data)} setAddNewDefect={updateData} defect={selectDefect}/>
            }
        </>
    );
};

export default TableDefect;
