import React from 'react';
import Flatpickr from "react-flatpickr";
import {Russian} from 'flatpickr/dist/l10n/ru'
import {setDate} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import moment from "moment";

const WidgetRequiredFlatpikerPeriod = () => {

    const dispatch = useAppDispatch();

    const {date} = useAppSelector(state => state.WidgetReducer)
    return (
        <div className="mb-2">
            <div className="input-group">
                <Flatpickr
                    className="form-control border-0 dash-filter-picker shadow"
                    value={date}
                    options={{
                        locale: Russian, mode: "range", dateFormat: "d.m.Y", disableMobile: true
                    }}
                    onClose={(date) => {
                        dispatch(setDate([moment(date[0]).toDate(), moment(date[1]).toDate()]))
                    }}
                />
            </div>
        </div>
    );
};

export default WidgetRequiredFlatpikerPeriod;
