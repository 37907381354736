import React, {FC, useEffect, useState} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import AxiosClient from "../../../api/AxiosClient";
import {IBank} from "../../../models/KSHP/IBank";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";

interface IModalUpdateContactWithBank {
    updateContactWithBank: boolean,
    setUpdateContactWithBank: (value: boolean) => void,
    selectBank: null | any,
    setBanksNew: (value: any[]) => void
    banksNew: any[]
}

const ModalUpdateContactWithBank: FC<IModalUpdateContactWithBank> = ({banksNew, setBanksNew, updateContactWithBank, setUpdateContactWithBank, selectBank}) => {
    const idButtonSave: string = 'buttonSaveModalUpdateContactWithBank';
    const [banks, setBanks] = useState<IBank[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    const [newObject, setNewObject] = useState<any>({bank_id: '', name_bank: '', city: '', paymentAccount: '', IDMerchant: '',
        autoCvit: false, onlinePay: false, autoPay: false, nameAcquiring: '', passwordAcquiring: '', BIK: '', correspondent_account: '', active: false});

    useEffect(() => {
        AxiosClient.get<IBank[]>('/banks')
            .then(r => setBanks(r.data))
    }, []);

    useEffect(()=> {
        setNewObject(selectBank)
    }, [selectBank])

    const confirmOrExit = () => {
        if(!_.isEqual(selectBank, newObject)){
            setConfirmExit(true);
        }else{
            setUpdateContactWithBank(false);
        }
    }

    return (
        <Modal id="createProjectModal" isOpen={updateContactWithBank}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление банка
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            bank_id: selectBank?.bank_id,
                            name_bank: selectBank.name_bank,
                            city: selectBank?.city,
                            paymentAccount: selectBank?.paymentAccount,
                            IDMerchant: selectBank?.IDMerchant,
                            autoCvit: !!selectBank?.autoCvit,
                            onlinePay: !!selectBank?.onlinePay,
                            autoPay: !!selectBank?.autoPay,
                            nameAcquiring: selectBank?.nameAcquiring,
                            passwordAcquiring: selectBank?.passwordAcquiring,
                            BIK: selectBank?.BIK,
                            correspondent_account: selectBank?.correspondent_account,
                            active: !!selectBank?.active
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name_bank: Yup.string().required('Обязательное поле'),
                            city: Yup.string().required('Обязательное поле'),
                            correspondent_account: Yup.number().required('Обязательное поле').typeError("Только числа").integer("Только целые числа"),
                            BIK: Yup.number().required('Обязательное поле').typeError("Только числа").integer("Только целые числа"),
                            paymentAccount: Yup.string().required('Обязательное поле')
                        })
                    }
                    onSubmit={(values) => {
                        setBanksNew(banksNew.map(bank => {
                            if (bank.id == selectBank.id){
                                return ({id: bank.id, ...values, bank: banks.find(bank => bank.id.toString() === values.bank_id.toString())})
                            }
                            return bank
                        }))
                        setUpdateContactWithBank(false)
                    }}>
                    {
                        ({errors, touched, setFieldValue}) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <label className="w-100">Банк:
                                        <Field name="bank_id" as="select" className="form-select"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'bank_id': e.target.value});
                                                   setFieldValue('bank_id', e.target.value);
                                               }}
                                        >
                                            {
                                                banks.map((bank) => <option key={bank.id} value={bank.id}>{bank.name}</option>)
                                            }
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100">Название банка*:
                                            <Field name="name_bank"
                                                   className={`form-control ${errors.name_bank && touched.name_bank && "border border-2 border-danger"}`}
                                                   onChange={(e: any) => {
                                                       setNewObject({...newObject, 'name_bank': e.target.value});
                                                       setFieldValue('name_bank', e.target.value);
                                                   }}
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100">Город*:
                                        <Field name="city" className={`form-control ${errors.city && touched.city && "border border-2 border-danger"}`}
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'city': e.target.value});
                                                   setFieldValue('city', e.target.value);
                                               }}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100">Расчетный счет*:
                                        <Field name="paymentAccount" className={`form-control ${errors.paymentAccount && touched.paymentAccount && "border border-2 border-danger"}`}
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'paymentAccount': e.target.value});
                                                   setFieldValue('paymentAccount', e.target.value);
                                               }}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100">БИК*:
                                            <Field name="BIK"
                                                   className={`form-control ${errors.BIK && touched.BIK && "border border-2 border-danger"}`}
                                                   onChange={(e: any) => {
                                                       setNewObject({...newObject, 'BIK': e.target.value});
                                                       setFieldValue('BIK', e.target.value);
                                                   }}
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100">Кориспондентский счет*:
                                            <Field name="correspondent_account"
                                                   className={`form-control ${errors.correspondent_account && touched.correspondent_account && "border border-2 border-danger"}`}
                                                   onChange={(e: any) => {
                                                       setNewObject({...newObject, 'correspondent_account': e.target.value});
                                                       setFieldValue('correspondent_account', e.target.value);
                                                   }}
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100">ID мерчанта:
                                        <Field name="IDMerchant" className="form-control"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'IDMerchant': e.target.value});
                                                   setFieldValue('IDMerchant', e.target.value);
                                               }}
                                        />
                                        </label>
                                        <ErrorMessage name="IDMerchant"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} xxl={6} className="d-flex align-items-center mt-1">
                                        <label className="m-0">Авто - квитовка:
                                        <Field name="autoCvit" type="checkbox"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'autoCvit': e.target.checked});
                                                   setFieldValue('autoCvit', e.target.checked);
                                               }}
                                        >
                                            {({
                                                  field,
                                              }: any) => (
                                                    <Input name="autoCvit" type="checkbox" {...field} className="mx-2 my-0" style={{fontSize: 18}}/>
                                            )}
                                        </Field>
                                        </label>
                                    </Col>
                                    <Col lg={6} xxl={6} className="d-flex align-items-center mt-1">
                                        <label className="m-0">Онлайн - платежи:
                                        <Field name="onlinePay" type="checkbox"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'onlinePay': e.target.checked});
                                                   setFieldValue('onlinePay', e.target.checked);
                                               }}
                                        >
                                            {({
                                                  field,
                                              }: any) => (
                                                    <Input name="onlinePay" type="checkbox" {...field} className="mx-2 my-0 " style={{fontSize: 18}}/>
                                            )}
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} xxl={6} className="d-flex align-items-center mt-1">
                                        <label className="m-0">Авто - платежи:
                                        <Field name="autoPay" type="checkbox"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'autoPay': e.target.checked});
                                                   setFieldValue('autoPay', e.target.checked);
                                               }}
                                        >
                                            {({
                                                  field,
                                              }: any) => (
                                                    <Input name="autoPay" type="checkbox" {...field} className="mx-2 my-0" style={{fontSize: 18}}/>
                                            )}
                                        </Field>
                                        </label>
                                    </Col>
                                    <Col lg={6} xxl={6} className="d-flex align-items-center mt-1">
                                        <label className="m-0">Активен:
                                        <Field name="active" type="checkbox"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'active': e.target.checked});
                                                   setFieldValue('active', e.target.checked);
                                               }}
                                        >
                                            {({
                                                  field,
                                              }: any) => (
                                                    <Input name="active" type="checkbox" {...field} className="mx-2 my-0" style={{fontSize: 18}}/>
                                            )}
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col>
                                        <label className="w-100">Имя пользователя (эквайринг):
                                        <Field name="nameAcquiring" className="form-control"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'nameAcquiring': e.target.value});
                                                   setFieldValue('nameAcquiring', e.target.value);
                                               }}
                                        />
                                        </label>
                                        <ErrorMessage name="nameAcquiring"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100">Пароль (эквайринг):
                                        <Field name="passwordAcquiring" className="form-control"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'passwordAcquiring': e.target.value});
                                                   setFieldValue('passwordAcquiring', e.target.value);
                                               }}
                                        />
                                        </label>
                                        <ErrorMessage name="passwordAcquiring"/>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <button type="submit" className="btn btn-primary" id={idButtonSave}>Сохранить</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setUpdateContactWithBank(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal>
    );
};

export default ModalUpdateContactWithBank;
