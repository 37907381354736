import React from 'react';
import {useStateContext} from "../../hooks/useStateContext";
import {Spinner} from "reactstrap";

const MyInfo = () => {
    const {user} = useStateContext();

    return (
        <>
            {
                user.name && user.middle_name && user.last_name && user.organization && user.position ?
                    <>
                        <div className="p-2">
                            <h3 className="text-white mb-1">{`${user.name && user.name} ${user.middle_name && user.middle_name} ${user.last_name && user.last_name}`}</h3>
                            <p className="text-white-75">Должность: {user.position?.name && user.position.name}</p>
                            <div className="hstack text-white-75 gap-1">
                                <div className="me-2"><i
                                    className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>
                                    {`${user.country.name}`}, {`${user.city.name}`}
                                </div>
                                <div><i
                                    className="ri-building-line me-1 text-white-75 fs-16 align-middle"></i>Организация: {user.organization?.name && user.organization.name}
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div>
                        <Spinner/>
                    </div>
            }
        </>
    );
};

export default MyInfo;
