import React, { useEffect, useState } from 'react'
import {Card, CardBody, Col, Input, Row, Spinner} from "reactstrap"
import DataTable from "react-data-table-component"
import ModelAddSchool from "./ModelAddSchool"
import AxiosClient from "../../../api/AxiosClient"
import ModalUpdateSchool from "./ModalUpdateSchool"
import ListCateringOrganizer from "./ListCateringOrganizer"
import Select from "react-select"
import { customStyles } from "../../settings"
import {ISchool} from "../../../models/KSHP/ISchool";
import {ICateringOrganizer} from "../../../models/KSHP/ICateringOrganizer";
import moment from "moment/moment";

const ListSchools = () => {

    const [addSchool, setAddSchool] = useState(false)
    const [updateSchool, setUpdateSchool] = useState(false)
    const [schools, setSchools] = useState<ISchool[]>([])
    const [schoolsOption, setSchoolsOption] = useState<ISchool[]>([])
    const [cateringOrganizers, setCateringOrganizers] = useState<ICateringOrganizer[]>([])
    const [school, setSchool] = useState<ISchool | null>(null)
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState<{ value: string, label: string }>({ value: '', label: 'Не выбрано' })
    const [selectSchool, setSelectSchool] = useState<{ value: string, label: string }>({ value: '', label: 'Не выбрано' })
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);

    const columns: any = [
        {
            name: <span className='font-weight-bold fs-13'>Школа/ОП</span>,
            selector: (row: ISchool) => row.name,
            wrap: true,
            width: '14rem'
        },
        {
            name: <span className='font-weight-bold fs-13'>Номер договора</span>,
            //selector: '',
            wrap: true,
            hide: 959,
            width: '11rem'
        },
        {
            name: <span className='font-weight-bold fs-13'>Дата с</span>,
            //selector: '',
            wrap: true,
            hide: 959,
            width: '11rem'
        },
        {
            name: <span className='font-weight-bold fs-13'>Дата по</span>,
            //selector: '',
            wrap: true,
            hide: 959,
            width: '11rem'
        },
        {
            name: <span className='font-weight-bold fs-13'>Скидка</span>,
            //selector: '',
            wrap: true,
            hide: 959,
        },
        {
            name: <span className='font-weight-bold fs-13'>Сб</span>,
            cell: (row: ISchool) => row.isSaturday ? <i className="bx bx-check fs-3"></i> : '',
                //<Input type="checkbox" defaultChecked={!!row.isSaturday} />,
            width: '50px',
            hide: 1299
        }
    ]


    useEffect(() => {
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchoolsOption(r)
            })
        AxiosClient.get<ICateringOrganizer[]>('/catering_organizaers')
            .then((r) => setCateringOrganizers(r.data))
            .catch(() => console.log('error'))
    }, [])

    useEffect(() => {
        fetchSchools(1, perPage, selectSchool.value, selectCateringOrganizer.value)
    }, [selectSchool.value, selectCateringOrganizer.value]);


    const fetchSchools = async (page: number, size = perPage, schoolFilter = '', cateringOrganizerFilter = '') => {
        setSearch(true)
        await AxiosClient.get(`/schools`, {params: {page: page, per_page: size, schoolFilter, cateringOrganizerFilter}})
            .then((response) => {
                setSearch(false)
                setSchools(response.data.schools);
                setTotalRows(response.data.total);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });
    };

    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const handlePageChange = (page: number) => {
        fetchSchools(page, perPage, selectSchool.value, selectCateringOrganizer.value);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        await fetchSchools(page, newPerPage, selectSchool.value, selectCateringOrganizer.value);
        setPerPage(newPerPage);
    };

    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchoolsOption(prevOptions => [...prevOptions, ...newData]);
    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schoolsOption.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label className="w-100">Школа:
                    <Select
                        options={schoolsOption?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...schoolsOption.map(v => ({ value: v.id.toString(), label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectSchool}
                        onChange={(change: any) => {
                            setSelectSchool({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        isLoading={loadingOptions}
                        placeholder="Поиск"
                        name="name"
                        onMenuScrollToBottom={handleMenuScroll}
                    />
                    </label>
                </Col>
                <Col xxl={3} xl={3} lg={6}>
                    <label className="w-100">Орг. питания:
                    <Select
                        options={cateringOrganizers?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...cateringOrganizers.map(v => ({ value: v.id.toString(), label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectCateringOrganizer}
                        onChange={(change: any) => {
                            setSelectCateringOrganizer({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={schools}
                                noDataComponent={'Нет данных'}
                                progressComponent={<Spinner></Spinner>}
                                progressPending={search}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно школ:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        }
                                    }
                                }}
                                onRowClicked={(row) => {
                                    setSchool(row)
                                    setUpdateSchool(true)
                                }}
                                expandableRows
                                expandableRowsComponent={({data}) => {
                                    return <ListCateringOrganizer cateringOrganizerFilter={selectCateringOrganizer.value} setSchools={value => {
                                        setSchools(value.schools);
                                        setTotalRows(value.total);
                                    }} schoolId={data.id} />
                                }}
                            />
                            <Row className="m-0 mt-2">
                                <Col sm={12} lg={4} xxl={3}>
                                    <button className="btn btn-primary w-100 mt-1" id="addNewProject" onClick={() => setAddSchool(true)}>Добавить школу</button>
                                </Col>
                            </Row>
                        </CardBody>
                        {
                            updateSchool &&
                            <ModalUpdateSchool updateSchool={updateSchool} setSchools={value => {
                                setSchools(value.schools);
                                setTotalRows(value.total);
                            }} setUpdateSchool={value => setUpdateSchool(value)} school={school} />

                        }
                        {
                            addSchool &&
                            <ModelAddSchool setSchools={value => {
                                setSchools(value.schools);
                                setTotalRows(value.total);
                            }} addSchool={addSchool} setAddSchool={(value) => setAddSchool(value)} />
                        }
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ListSchools
