import React from 'react';
import {Formik, Field, ErrorMessage, Form} from "formik";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import * as Yup from 'yup';
import {Col, Label, Row} from "reactstrap";
import {updateUser} from "../../../store/reducers/admin/ActionCreator";

const UpdateDataUserForm = () => {
    const dispatch = useAppDispatch();
    const {selectUser, countries, terms, positions, cities, organizations} = useAppSelector(state => state.AdminReducer)

    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    firstname: `${selectUser?.name}`,
                    middlename: `${selectUser?.middle_name}`,
                    login: `${selectUser?.login}`,
                    lastname: selectUser?.last_name ? `${selectUser?.last_name}` : '',
                    phone: `${selectUser?.phone}`,
                    email: `${selectUser?.email}`,
                    position: selectUser?.position?.id,
                    company: selectUser?.organization?.id,
                    city: selectUser?.city?.id,
                    country: selectUser?.country?.id,
                    role: selectUser?.term?.id,
                }
            }
            validationSchema={
                Yup.object(
                    {
                        firstname: Yup.string().required('Обязательное поле*'),
                        middlename: Yup.string().required('Обязательное поле*'),
                        login: Yup.string().required('Обязательное поле*'),
                        phone: Yup.string().required('Обязательное поле*'),
                        position: Yup.number(),
                        company: Yup.number(),
                        city: Yup.number(),
                        country: Yup.number(),
                        role: Yup.number(),
                    }
                )
            }
            onSubmit={
                (value) => {
                    (value as any).userId = selectUser?.id;
                    dispatch(updateUser(value, selectUser!.id))

                }
            }
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="firstnameInput"
                                   className="form-label">Имя</Label>
                            <Field type="text" className="form-control"
                                   id="firstnameInput" name="firstname"
                                   placeholder="Введите имя"/>
                            <ErrorMessage name="firstname">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="countryInput"
                                   className="form-label">Страна</Label>
                            <Field
                                as="select"
                                className="form-select"
                                id="countryInput"
                                placeholder="Страна"
                                name="country">
                                {
                                    countries.map(country => {
                                        return <option key={country.id}
                                                       value={country.id}>{country.name}</option>
                                    })
                                }
                            </Field>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="middlenameInput"
                                   className="form-label">Фамилия</Label>
                            <Field type="text" className="form-control"
                                   id="middlenameInput" name="middlename"
                                   placeholder="Введите фамилию"/>
                            <ErrorMessage name="middlename">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="cityInput"
                                   className="form-label">Город</Label>
                            <Field
                                as="select"
                                className="form-select"
                                id="cityInput"
                                name="city">

                                {
                                    cities.map(city => {
                                        return <option key={city.id}
                                                       value={city.id}>{city.name}</option>
                                    })
                                }
                            </Field>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="lastnameInput"
                                   className="form-label">Отчество</Label>
                            <Field type="text" className="form-control"
                                   id="lastnameInput" name="lastname"
                                   placeholder="Введите отчество"/>
                            <ErrorMessage name="lastname">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="loginInput"
                                   className="form-label">Логин</Label>
                            <Field type="text" className="form-control"
                                   id="loginInput" name="login"
                                   placeholder="Введите логин"/>
                            <ErrorMessage name="login">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="designationInput"
                                   className="form-label">Организация</Label>
                            <Field
                                as="select"
                                className="form-select"
                                name="company">
                                {
                                    organizations.map(element => {
                                        return <option key={element.id}
                                                       value={element.id}>{element.name}</option>
                                    })
                                }
                            </Field>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="designationInput"
                                   className="form-label">Должность</Label>
                            <Field
                                as="select"
                                className="form-select"
                                name="position"
                            >
                                {
                                    positions.map((position) => {
                                        return <option key={position.id}
                                                       value={position.id}>{position.name}</option>
                                    })
                                }
                            </Field>
                            <ErrorMessage name="position">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="emailInput"
                                   className="form-label">Почта</Label>
                            <Field type="text" className="form-control" id="emailInput"
                                   name="email"
                                   placeholder="Введите почту"/>
                            <ErrorMessage name="email">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="countryInput"
                                   className="form-label">Роль</Label>
                            <Field
                                as="select"
                                className="form-select"
                                name="role">
                                {
                                    terms.map((term) => {
                                        return <option key={term.id}
                                                       value={term.id}>{term.name}</option>
                                    })
                                }
                            </Field>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="phonenumberInput"
                                   className="form-label">Телефон</Label>
                            <Field type="text" className="form-control" name="phone"
                                   id="phonenumberInput"
                                   placeholder="Введите свой телефон"/>
                            <ErrorMessage name="phone">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>

                    <Col lg={12}>
                        <div className="text-end">
                            <button type="submit" className="btn btn-primary">Обновить
                                данные
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataUserForm;
