import React from 'react';
import classNames from "classnames";
import {Spinner} from "reactstrap";
import {useAppSelector} from "../../../hooks/redux";

const Loading = () => {

    const admin = useAppSelector(state => state.AdminReducer)
    const department = useAppSelector(state => state.DepartmentReducer)
    const widget = useAppSelector(state => state.WidgetReducer)
    const parent = useAppSelector(state => state.ParentReducer);

    const loading = admin.isLoading || department.isLoading || widget.isLoading || parent.isLoading
    const classes = classNames({
        send_request_loading: loading,
        send_request_loading_no: !loading
    })

    return (
        <div className={`${classes} page-content`}>
            <Spinner color="dark" style={{width: '5rem', height: '5rem'}}/>
            <p>Загрузка...</p>
        </div>
    );
};

export default Loading;
