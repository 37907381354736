import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment/moment";

interface IGraphDonutCashAccounting {
    selectChildren: string | number,
    date: Date[]
}

const GraphDonutCashAccounting: FC<IGraphDonutCashAccounting> = ({selectChildren, date}) => {

    const [food, setFood] = useState<any>(0);

    useEffect(() => {
        selectChildren &&
        AxiosClient.get('/students/expenditure_total_type', {params: {id: selectChildren, date: [moment(date[0]).format('YYYY-MM-DD').toString(), moment(date[1]).format('YYYY-MM-DD').toString()]}})
            .then(r => setFood(r.data.count))
    }, [selectChildren, date]);


    const options: any = {
        labels: ['Услуги за питание'],
        chart: {
            type: "donut",
            height: 230,
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
            width: 0
        },
    }

    return (
        <>
            <Card>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Денежные средства</h4>
                </CardHeader>

                <CardBody>
                    <ReactApexChart
                        options={options}
                        series={[food]}
                        type="donut"
                        height="150"
                        className="apex-charts"
                    />
                    {
                            <div className="mt-3">
                                <div className="d-flex justify-content-center align-items-center mb-4">
                                    <div>
                                        <p className="text-muted mb-0">Всего затрат</p>
                                    </div>
                                    <h2 className="mx-3 ff-secondary mb-0">{food}</h2>
                                </div>
                                <div
                                    className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                                    <p className="fw-medium mb-0">Услуги за питание</p>
                                    <div>
                                        <span className="text-muted pe-5">{food} руб.</span>
                                    </div>
                                </div>
                            </div>
                    }
                </CardBody>
            </Card>
        </>
    )
};

export default GraphDonutCashAccounting;
