import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Select from "react-select";
import WidgetService from "../../../api/services/widgetService/WidgetService";

interface IModalAddDefect {
    addDefect: boolean,
    setAddDefect: (addDefect: boolean) => void,
    setAddNewDefect: (defect: object) => void,
    defect: any
}
const ModalAddDefect:FC<IModalAddDefect> = ({addDefect, setAddDefect, setAddNewDefect, defect}) => {

    const [typeDefect, setTypeDefect] = useState<any[]>([]);
    const [criticalDefect, setCriticalDefect] = useState<any[]>([]);
    const [defectCauses, setDefectCauses] = useState<any[]>([]);

    const [selectDefect, setSelectDefect] = useState<any | null>(null);
    const [selectCriticalDefect, setSelectCriticalDefect] = useState<any | null>(null);
    const [selectCause, setSelectCause] = useState<any | null>(null);

    useEffect(() => {
        WidgetService.getListTypeDefect().then(r => setTypeDefect(r.data))
        WidgetService.getListCriticalDefect().then(r => setCriticalDefect(r.data))
        WidgetService.getListDefectCauses().then(r => setDefectCauses(r.data))
        setSelectDefect({value: defect.type_defect_uid, label: defect.name_type_defect})
        setSelectCriticalDefect({value: defect.critical_defect_uid, label: defect.name_critical_defect})
        setSelectCause({value: defect.cause_defect_uid, label: defect.name_cause_defect})
    }, []);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }

    return (
        <Modal id="createProjectModal" isOpen={addDefect}
               toggle={() => setAddDefect(!addDefect)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddDefect(!addDefect)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновить дефект
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            defect: typeDefect[0]?.uid,
                            status: criticalDefect[0]?.uid,
                            cause: defectCauses[0]?.uid,
                            description: defect.description
                        }
                    }
                    onSubmit={(values) => {
                        setAddNewDefect({
                            index: defect.index,
                            name_type_defect: selectDefect.label,
                            type_defect_uid: selectDefect.value,
                            name_critical_defect: selectCriticalDefect.label,
                            critical_defect_uid: selectCriticalDefect.value,
                            name_cause_defect: selectCause.label,
                            cause_defect_uid: selectCause.value,
                            description: values.description
                        })
                        setAddDefect(false)
                    }}>
                    {
                        ({handleChange}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Вид дефекта:</label>
                                        <Select options={typeDefect.map(defect => ({value: defect.uid, label: defect.name}))}
                                                name="defect"
                                                value={selectDefect}
                                                onChange={(change: any) => setSelectDefect({value: change.value, label: change.label})}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Критичность дефекта:</label>
                                        <Select options={criticalDefect.map(defect => ({value: defect.uid, label: defect.name}))}
                                                name="status"
                                                value={selectCriticalDefect}
                                                onChange={(change: any) => setSelectCriticalDefect({value: change.value, label: change.label})}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Причина дефекта:</label>
                                        <Select options={defectCauses.map(defect => ({value: defect.uid, label: defect.name}))}
                                                name="cause"
                                                value={selectCause}
                                                onChange={(change: any) => setSelectCause({value: change.value, label: change.label})}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Причина дефекта:</label>
                                        <Field as="textarea" name="description" className="form-control" placeholder="Описание" />
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <button type="submit" className="btn btn-primary" id="addNewProject">Обновить</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddDefect;
