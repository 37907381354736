import React, {FC, useState} from 'react';
import moment from "moment/moment";
import DataTable from "react-data-table-component";
import {Col, Row} from "reactstrap";
import ModalAddStatus from "./ModalAddStatus";
import ModalUpdateStatus from "./ModalUpdateStatus";

interface IListStatuses {
    listStatuses: any[]
    setListStatuses: (value: any[]) => void
}

const ListStatuses: FC<IListStatuses> = ({listStatuses, setListStatuses}) => {
    const [addStatus, setAddStatus] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [selectStatus, setSelectStatus] = useState<any | null>(null);
    const columns: any = [
        {
            id: 'index',
            selector: (row: any) => row.index,
            omit: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Статус</span>,
            selector: (row: any) => row.name,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Дата с</span>,
            selector: (row: any) => moment(row.date_begin).format('DD.MM.Y'),
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Дата по</span>,
            selector: (row: any) => row.date_end ? moment(row.date_end).format('DD.MM.YYYY') : '',
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'></span>,
            cell: (row: any) => <i className="bx bx-trash fs-3 cursor-pointer" onClick={() => setListStatuses(listStatuses.filter((value, index) => index !== row.index))}></i>,
            width: '50px'
        }
    ]
    return (
        <div>
            <DataTable
                columns={columns}
                data={listStatuses.map((el: any, index) => ({...el, index}))}
                keyField={'index'}
                noDataComponent={'Нет данных'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                onRowClicked={(row) => {
                    setSelectStatus(row)
                    setUpdateStatus(true)
                }}
            />
            <Row>
                <Col xxl={6} xl={6} lg={6} sm={6}>
                    <div className="hstack gap-2">
                        <button type="button" className="btn btn-primary mt-3" id="addNewProject"
                                onClick={() => setAddStatus(true)}>Добавить
                        </button>
                    </div>
                </Col>
            </Row>
            <ModalAddStatus addStatus={addStatus} setAddStatus={value => setAddStatus(value)}
                            listStatuses={listStatuses} setListStatuses={value => setListStatuses(value)}/>
            <ModalUpdateStatus updateStatus={updateStatus} setUpdateStatus={value => setUpdateStatus(value)}
                               listStatuses={listStatuses} setListStatuses={value => setListStatuses(value)}
                               selectStatus={selectStatus}/>
        </div>
    )
};

export default ListStatuses;
