import React, {FC, useEffect, useState} from 'react';
import DataTable, {TableColumn} from "react-data-table-component";
import {Card, CardBody, Col, Row} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";

interface IListCashMovementResult {
    selectSchool: {value: string, label: string},
    selectBuild: {value: string, label: string},
    selectClass: {value: string, label: string},
    dateEnd: Date,
    dateStart: Date
}
const ListCashMovementResult: FC<IListCashMovementResult> = ({selectClass, selectBuild, selectSchool, dateEnd, dateStart}) => {
    const [listCashMovement, setListCashMovement] = useState<any[]>([]);
    useEffect(() => {
        AxiosClient.get('/cash_movements')
            .then((r) => setListCashMovement(r.data))
    }, []);

    const balanceStart = () => {
        let balance = 0;
        if (selectBuild.value && selectSchool.value && selectClass.value) {
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString() && lcm.building.id.toString() === selectBuild.value.toString() && lcm.class.id.toString() === selectClass.value.toString()).forEach((transaction) => {
                balance += +transaction.sum;
            });
            return balance;
        }

        if (selectBuild.value && selectSchool.value){
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString() && lcm.building.id.toString() === selectBuild.value.toString()).forEach((transaction) => {
                balance += +transaction.sum;
            });
            return balance;
        }
        if (selectSchool.value){
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString()).forEach((transaction) => {
                balance += +transaction.sum;
            });
            return balance;
        }

        return balance;
    }

    const balanceEnd = () => {
        let balance = 0;
        if (selectBuild.value && selectSchool.value && selectClass.value) {
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString() && lcm.building.id.toString() === selectBuild.value.toString() && lcm.class.id.toString() === selectClass.value.toString()).forEach((transaction) => {
                balance += +transaction.sum;
            });
            return balance;
        }

        if (selectBuild.value && selectSchool.value){
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString() && lcm.building.id.toString() === selectBuild.value.toString()).forEach((transaction) => {
                balance += +transaction.sum;
            });
            return balance;
        }
        if (selectSchool.value){
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString()).forEach((transaction) => {
                balance += +transaction.sum;
            });
            return balance;
        }

        return balance;
    }

    const refill = () => {
        let balance = 0;
        if (selectBuild.value && selectSchool.value && selectClass.value) {
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString() && lcm.building.id.toString() === selectBuild.value.toString() && lcm.class.id.toString() === selectClass.value.toString()).forEach((transaction) => {
                if (+transaction.sum > 0)
                    balance += +transaction.sum;
            });
            return balance;
        }

        if (selectBuild.value && selectSchool.value){
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString() && lcm.building.id.toString() === selectBuild.value.toString()).forEach((transaction) => {
                if (+transaction.sum > 0)
                    balance += +transaction.sum;
            });
            return balance;
        }
        if (selectSchool.value){
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString()).forEach((transaction) => {
                if (+transaction.sum > 0)
                    balance += +transaction.sum;
            });
            return balance;
        }

        return balance;
    }

    const debit = () => {
        let balance = 0;
        if (selectBuild.value && selectSchool.value && selectClass.value) {
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString() && lcm.building.id.toString() === selectBuild.value.toString() && lcm.class.id.toString() === selectClass.value.toString()).forEach((transaction) => {
                if (+transaction.sum < 0)
                    balance += +transaction.sum;
            });
            return balance;
        }

        if (selectBuild.value && selectSchool.value){
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString() && lcm.building.id.toString() === selectBuild.value.toString()).forEach((transaction) => {
                if (+transaction.sum < 0)
                    balance += +transaction.sum;
            });
            return balance;
        }
        if (selectSchool.value){
            listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString()).forEach((transaction) => {
                if (+transaction.sum < 0)
                    balance += +transaction.sum;
            });
            return balance;
        }

        return balance;
    }

    const selectFilter = (listCashMovement: any[]) => {
        if (selectBuild.value && selectSchool.value && selectClass.value){
            const data = listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString() && lcm.building.id.toString() === selectBuild.value.toString() && lcm.class.id.toString() === selectClass.value.toString())
            return data.length ? [data[0] ]: []
        }

        if (selectBuild.value && selectSchool.value){
            const data = listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString() && lcm.building.id.toString() === selectBuild.value.toString())
            return data.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.school.id === item.school.id && t.building.id === item.building.id
                    ))
            )
        }

        if (selectSchool.value){
            const data = listCashMovement.filter((lcm) => lcm.school.id.toString() === selectSchool.value.toString())
            return data.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.school.id === item.school.id
                    ))
            )
        }


        return [];
    }


    const columns: TableColumn<any>[] = [
        {
            name: <span className='font-weight-bold fs-13'>Школа</span>,
            selector: (row: any) => {
                if (selectBuild.value && selectSchool.value && selectClass.value){
                    return row.school.name
                }
                if(selectBuild.value && selectSchool.value){
                    return row.school.name
                }
                if (selectSchool.value){
                    return row.school.name
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Корпус</span>,
            selector: (row: any) => {
                if (selectBuild.value && selectSchool.value && selectClass.value){
                    return row.building.name
                }
                if(selectBuild.value && selectSchool.value){
                    return row.building.name
                }
                if (selectSchool.value){
                    return ''
                }
            },
            hide: 599
        },
        {
            name: <span className='font-weight-bold fs-13'>Класс</span>,
            selector: (row: any) => {
                if (selectBuild.value && selectSchool.value && selectClass.value){
                    return row.class.name
                }
                if(selectBuild.value && selectSchool.value){
                    return ''
                }
                if (selectSchool.value){
                    return ''
                }
            },
            hide: 599
        },
        {
            name: <span className='font-weight-bold fs-13'>Остаток на начало</span>,
            selector: () => balanceStart(),
        },
        {
            name: <span className='font-weight-bold fs-13'>Итого приход</span>,
            selector: () => refill(),
            hide: 959
        },
        {
            name: <span className='font-weight-bold fs-13'>Итого расход</span>,
            selector: () => debit(),
            hide: 959
        },
        {
            name: <span className='font-weight-bold fs-13'>Остаток на конец</span>,
            selector: () => balanceEnd(),
        }
    ]

    return (
        <>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                title="Итоги"
                                columns={columns}
                                data={selectFilter(listCashMovement)}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно итогов:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ListCashMovementResult;
