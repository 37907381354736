import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup'
import {Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row} from "reactstrap";
import {addOrganization} from "../../../store/reducers/admin/ActionCreator";

const OrganizationAdd = () => {

    const dispatch = useAppDispatch();
    const {organizations, regions} = useAppSelector(state => state.AdminReducer)

    return (
        <Col>
            <>
                <Formik
                    initialValues={
                        {
                            code: '',
                            org_number: '',
                            name: '',
                            host: '',
                            port: '',
                            login_prefix: '',
                            login: '',
                            password: '',
                            password_confirmation: '',
                            publication_name: '',
                            help_center: 'true',
                            consultative_center: 'true',
                            parent_organization_id: 'null',
                            region_id: regions[0].id,
                            use_database: 'false'
                        }
                    }
                    validationSchema={
                        Yup.object(
                            {
                                code: Yup.string().required('Введите код организации'),
                                org_number: Yup.string().required('Введите номер огранизации'),
                                name: Yup.string().required('Введите название организации'),
                                host: Yup.string().required('Введите хост организации'),
                                port: Yup.string().required('Введите порт организации'),
                                login_prefix: Yup.string().required('Введиет префикс логина организации'),
                                login: Yup.string().required('Введите логин организации'),
                                password: Yup.string().required('Введите пароль'),
                                password_confirmation: Yup.string().required('Повторите пароль').oneOf([Yup.ref('password'), "null"], 'Пароли не совпадают'),
                                publication_name: Yup.string().required('Укажите имя публикации')
                            }
                        )
                    }
                    onSubmit={
                        (value, {resetForm}) => {
                            dispatch(addOrganization(value));
                            resetForm()
                        }
                    }
                >
                    <Form>
                        <Card className="mx-n3">
                            <CardHeader>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className="active">
                                            <i className="fas fa-home"></i>
                                            Данные о организации
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody className="p-4">
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="code" className="form-label">Код</Label>
                                            <Field type="text" className="form-control" id="code"
                                                   name="code"
                                                   placeholder="Введите код организации"/>
                                            <ErrorMessage name="code">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="org_number" className="form-label">Номер
                                                организации</Label>
                                            <Field type="text" className="form-control" id="org_number"
                                                   name="org_number"
                                                   placeholder="Введите номер организации"/>
                                            <ErrorMessage name="org_number">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name"
                                                   className="form-label">Название</Label>
                                            <Field type="text" className="form-control" id="name"
                                                   name="name"
                                                   placeholder="Введите название организации"/>
                                            <ErrorMessage name="name">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="host" className="form-label">Хост</Label>
                                            <Field type="text" className="form-control" id="host"
                                                   name="host"
                                                   placeholder="Введите хост организации"/>
                                            <ErrorMessage name="host">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="port" className="form-label">Порт</Label>
                                            <Field type="text" className="form-control" id="port"
                                                   name="port"
                                                   placeholder="Введите порт организации"/>
                                            <ErrorMessage name="port">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="login_prefix" className="form-label">Префикс
                                                логина</Label>
                                            <Field type="text" className="form-control" id="login_prefix"
                                                   name="login_prefix"
                                                   placeholder="Введите префикс логина организации"/>
                                            <ErrorMessage name="login_prefix">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="publication_name" className="form-label">Имя
                                                публикации</Label>
                                            <Field type="text" className="form-control" id="publication_name"
                                                   name="publication_name"
                                                   placeholder="Введите имя публикации организации"/>
                                            <ErrorMessage name="publication_name">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="login" className="form-label">Логин</Label>
                                            <Field type="text" className="form-control" id="login"
                                                   name="login"
                                                   placeholder="Введите логин организации"/>
                                            <ErrorMessage name="login">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="password" className="form-label">Пароль</Label>
                                            <Field type="text" className="form-control" id="password"
                                                   name="password"
                                                   placeholder="Введите пароль организации" autoComplete="on"/>
                                            <ErrorMessage name="password">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="password_confirmation" className="form-label">Повторный
                                                пароль</Label>
                                            <Field type="text" className="form-control" id="password_confirmation"
                                                   name="password_confirmation"
                                                   placeholder="Введите повторный пароль организации"
                                                   autoComplete="on"/>
                                            <ErrorMessage name="password_confirmation">{(msg) => <div
                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="help_center"
                                                   className="form-label">Центр ранней помощи</Label>
                                            <Field as="select" className="form-select" id="help_center"
                                                   name="help_center">
                                                <option value='true'>Центр ранней помощи</option>
                                                <option value='false'>Не центр ранней помощи</option>
                                            </Field>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="consultative_center"
                                                   className="form-label">Консультативный центр</Label>
                                            <Field as="select" className="form-select" id="consultative_center"
                                                   name="consultative_center">
                                                <option value='true'>Консультативный центр</option>
                                                <option value='false'>Не консультативный центр</option>
                                            </Field>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="parent_organization_id"
                                                   className="form-label">Вышестоящая
                                                организация</Label>
                                            <Field as="select" className="form-select" id="parent_organization_id"
                                                   name="parent_organization_id">
                                                <option value="null">Нет</option>
                                                {
                                                    organizations.map(org =>
                                                        (<option key={org.id}
                                                                 value={org.id}>{org.name}</option>)
                                                    )
                                                }
                                            </Field>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="region_id"
                                                   className="form-label">Регион</Label>
                                            <Field as="select" className="form-select" id="region_id"
                                                   name="region_id">
                                                {
                                                    regions.map(org =>
                                                        (<option key={org.id}
                                                                 value={org.id}>{org.name}</option>)
                                                    )
                                                }
                                            </Field>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="use_database"
                                                   className="form-label">
                                                Использовать Базу Данных
                                            </Label>
                                            <Field as="select" className="use_database"
                                                   id="use_databaseInput"
                                                   name="use_database">
                                                <option value="true">Да</option>
                                                <option value="false">Нет</option>
                                            </Field>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="mt-3">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button type="submit"
                                                    className="btn btn-primary">Добавить
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Form>
                </Formik>
            </>
        </Col>
    );
};

export default OrganizationAdd;
