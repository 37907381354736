import React, {FC} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";

interface IModalAllInfoChildren {
    selectChildren: any,
    openAllInfoChildren: boolean,
    setOpenAllInfoChildren: (values: boolean) => void,
    children: any[],
    setSelectChildren: (value: any) => void,
    setChildren: (value: any[]) => void
}

const ModalAllInfoChildren: FC<IModalAllInfoChildren> = ({
                                                             selectChildren,
                                                             setOpenAllInfoChildren,
                                                             openAllInfoChildren,
                                                             setSelectChildren,
                                                             children,
                                                             setChildren
                                                         }) => {
    const check = (name: string, check: boolean) => {
        const mealtime: any[] = selectChildren?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime.map((d: any) => ({...d, idx: el.id}))).flat() || [];
        const idx = mealtime.find((m: any) => m.slug === name)?.idx
        switch (name) {
            case 'breakfast': {
                if (mealtime.find((m: any) => m.slug === name)) {
                    if (!check) {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: false
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: false
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    } else {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: true
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: true
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    }
                }
                break;
            }
            case 'hot_breakfast': {
                if (mealtime.find((m: any) => m.slug === name)) {
                    if (!check) {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: false
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: false
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    } else {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: true
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: true
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    }
                }
                break;
            }
            case 'dinner': {
                if (mealtime.find((m: any) => m.slug === name)) {
                    if (!check) {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: false
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: false
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    } else {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: true
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: true
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    }
                }
                break;
            }
            case 'snack': {
                if (mealtime.find((m: any) => m.slug === name)) {
                    if (!check) {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: false
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: false
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    } else {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: true
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: true
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    }
                }
                break;
            }
            case 'lunch': {
                if (mealtime.find((m: any) => m.slug === name)) {
                    if (!check) {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: false
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: false
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    } else {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: true
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: true
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    }
                }
                break;
            }
            case 'sleeps': {
                if (mealtime.find((m: any) => m.slug === name)) {
                    if (!check) {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: false
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: false
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    } else {
                        setSelectChildren({
                            ...selectChildren,
                            catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                                if (co.type_planned_menu.benefits.length > 0 && co.id === idx) {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            return {
                                                ...meal,
                                                checked: true
                                            }
                                        })
                                    }
                                } else {
                                    return {
                                        ...co,
                                        mealtime: co.mealtime.map((meal: any) => {
                                            if (meal.slug === name) {
                                                return {
                                                    ...meal,
                                                    checked: true
                                                }
                                            }
                                            return meal
                                        })
                                    }
                                }
                            })
                        })
                    }
                }
                break;
            }
        }
    }

    const orderDay = () => {
        const isDiscountPrice = selectChildren?.catering_organizer_type_planned_menu.find((el: any) => el?.type_planned_menu?.benefits?.length > 0)
        const mealtime: any[] = selectChildren?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime).flat() || [];
        let sum = 0;

        selectChildren?.attendance && mealtime.length > 0 &&
        mealtime.forEach((m) => {
            if (m.slug === 'breakfast' && m.checked) {
                sum += +m.price
            }
            if (m.slug === 'hot_breakfast' && m.checked) {
                sum += +m.price
            }
            if (m.slug === 'dinner' && m.checked) {
                sum += +m.price
            }
            if (m.slug === 'snack' && m.checked) {
                sum += +m.price
            }
            if (m.slug === 'lunch' && m.checked) {
                sum += +m.price
            }
            if (m.slug === 'sleeps' && m.checked) {
                sum += +m.price
            }
        })
        if(isDiscountPrice){
            sum -= +selectChildren.discount_price
        }
        if (sum < 0) return 0
        return sum
    }

    const attendance = (check: boolean) => {
        if (!check) {
            setSelectChildren({
                ...selectChildren,
                attendance: false,
                deviation: 2,
                catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                    return {
                        ...co,
                        mealtime: co.mealtime.map((meal: any) => {
                            return {
                                ...meal,
                                checked: false
                            }
                        })
                    }
                })
            })
        } else {
            setSelectChildren({
                ...selectChildren,
                deviation: 1,
                attendance: true,
                catering_organizer_type_planned_menu: selectChildren.catering_organizer_type_planned_menu.map((co: any) => {
                    return {
                        ...co,
                        mealtime: co.mealtime.map((meal: any) => {
                            return {
                                ...meal,
                                checked: true
                            }
                        })
                    }
                })
            })
        }
    }


    return (
        <Modal id="createProjectModal" isOpen={openAllInfoChildren}
               toggle={() => setOpenAllInfoChildren(!openAllInfoChildren)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpenAllInfoChildren(!openAllInfoChildren)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Информация
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        balance: selectChildren?.balance,
                        order_day: orderDay(),
                        attendance: selectChildren?.attendance,
                        credit: false,
                        breakfast: selectChildren?.attendance && selectChildren?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime).flat().find((m: any) => m.slug === 'breakfast')?.checked,
                        hot_breakfast: selectChildren?.attendance && selectChildren?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime).flat().find((m: any) => m.slug === 'hot_breakfast')?.checked,
                        dinner: selectChildren?.attendance && selectChildren?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime).flat().find((m: any) => m.slug === 'dinner')?.checked,
                        snack: selectChildren?.attendance && selectChildren?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime).flat().find((m: any) => m.slug === 'snack')?.checked,
                        lunch: selectChildren?.attendance && selectChildren?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime).flat().find((m: any) => m.slug === 'lunch')?.checked,
                        sleeps: selectChildren?.attendance && selectChildren?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime).flat().find((m: any) => m.slug === 'sleeps')?.checked
                    }}
                    onSubmit={(values) => {
                        setChildren(
                            children.map(c => c.id.toString() === selectChildren.id.toString() ? {
                                ...selectChildren,
                                credit: values.credit
                            } : c)
                        )
                        setOpenAllInfoChildren(false)
                    }}
                >
                    <Form>
                        <Row>
                            <Col>
                                <div className="">
                                    <label htmlFor="" className="m-0">Баланс</label>
                                    <Field name="balance" className="form-control" disabled/>
                                </div>
                            </Col>
                            <Col>
                                <div className="">
                                    <label htmlFor="" className="m-0">Заказ на день (Со скидкой ОП)</label>
                                    <Field name="order_day" className="form-control" disabled/>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label htmlFor="" className="m-0">Явка</label>
                                    <Field name="attendance" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="attendance" className="fs-18" {...field}
                                                       onChange={event => attendance(event.target.checked)}/>
                                            )
                                        }
                                    </Field>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label htmlFor="" className="m-0">В кредит</label>
                                    <Field name="credit" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="credit" className="fs-18" {...field}/>
                                            )
                                        }
                                    </Field>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label htmlFor="" className="m-0">Завтрак</label>
                                    <Field name="breakfast" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="breakfast" className="fs-18" {...field}
                                                       onChange={event => check('breakfast', event.target.checked)}/>
                                            )
                                        }
                                    </Field>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label htmlFor="" className="m-0">Горячий завтрак</label>
                                    <Field name="hot_breakfast" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="hot_breakfast" className="fs-18" {...field}
                                                       onChange={event => check('hot_breakfast', event.target.checked)}/>
                                            )
                                        }
                                    </Field>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label htmlFor="" className="m-0">Обед</label>
                                    <Field name="dinner" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="dinner" className="fs-18" {...field}
                                                       onChange={event => check('dinner', event.target.checked)}/>
                                            )
                                        }
                                    </Field>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label htmlFor="" className="m-0">Полдник</label>
                                    <Field name="snack" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="snack" className="fs-18" {...field}
                                                       onChange={event => check('snack', event.target.checked)}/>
                                            )
                                        }
                                    </Field>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label htmlFor="" className="m-0">Ужин</label>
                                    <Field name="lunch" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="lunch" className="fs-18" {...field}
                                                       onChange={event => check('lunch', event.target.checked)}/>
                                            )
                                        }
                                    </Field>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label htmlFor="" className="m-0">Сонник</label>
                                    <Field name="sleeps" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="sleeps" className="fs-18" {...field}
                                                       onChange={event => check('sleeps', event.target.checked)}/>
                                            )
                                        }
                                    </Field>
                                </div>
                            </Col>
                        </Row>
                        <button className="btn btn-primary mt-3">Сохранить</button>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAllInfoChildren;
