import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Card, CardBody, CardHeader, Col, Input, Label, Nav, NavItem, NavLink, Row, Spinner} from "reactstrap";
import classNames from "classnames";
import {useStateContext} from "../../hooks/useStateContext";
import * as Yup from 'yup';
import UserService from "../../api/services/userService/UserService";

const MyInfoForm = () => {

    const {user, setUser} = useStateContext();
    return (
        <Col>
            <Card className="mt-xxl-n5">
                <CardHeader>
                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                         role="tablist">
                        <NavItem>
                            <NavLink
                                className={classNames({active: true})}>
                                <i className="fas fa-home"></i>
                                Персональные данные
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody className="p-4">
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                firstname: `${user?.name}`,
                                middlename: `${user?.middle_name}`,
                                lastname: `${user?.last_name}`,
                                phone: `${user?.phone}`,
                                email: `${user?.email}`,
                                about: `${user.about === null ? '' : user.about}`
                            }
                        }
                        validationSchema={
                            Yup.object(
                                {
                                    firstname: Yup.string().required('Обязательное поле*'),
                                    middlename: Yup.string().required('Обязательное поле*'),
                                    lastname: Yup.string().required('Обязательное поле*'),
                                    phone: Yup.string().required('Обязательное поле*'),
                                    email: Yup.string().required('Обязательное поле*').email('Укажите почту правильно')
                                }
                            )
                        }
                        onSubmit={
                            (value: any) => {
                                value.userId = user.id;

                                UserService.updateUserProfile(user.id, value)
                                    .then(() => {
                                            UserService.getUser()
                                                .then(r => setUser(r.data))
                                        }
                                    )
                            }
                        }
                    >
                        {
                            user.name ?
                                <Form>
                                    <Row>
                                        <Row>
                                            <Col xs={12} sm={6} lg={6} xl={6} xxl={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstnameInput"
                                                           className="form-label">Имя</Label>
                                                    <Field type="text" className="form-control"
                                                           id="firstnameInput" name="firstname"
                                                           placeholder="Введите имя"/>
                                                    <ErrorMessage name="firstname">{(msg) => <div
                                                        className="text-danger">{msg}</div>}</ErrorMessage>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} lg={6} xl={6} xxl={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="emailInput"
                                                           className="form-label">Почта</Label>
                                                    <Field type="email" className="form-control"
                                                           id="emailInput" name="email"
                                                           placeholder="Введите почту"/>
                                                    <ErrorMessage name="email">{(msg) => <div
                                                        className="text-danger">{msg}</div>}</ErrorMessage>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={6} lg={6} xl={6} xxl={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="lastnameInput"
                                                           className="form-label">Отчество</Label>
                                                    <Field type="text" className="form-control"
                                                           id="lastnameInput" placeholder="Введите отчество"
                                                           name="lastname"/>
                                                    <ErrorMessage name="lastname">{(msg) => <div
                                                        className="text-danger">{msg}</div>}</ErrorMessage>
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="lastnameInput"
                                                           className="form-label">Страна</Label>
                                                    <Input type="text" className="form-control"
                                                           id="lastnameInput" disabled
                                                           defaultValue={user.country?.name}
                                                    />
                                                    <ErrorMessage name="lastname">{(msg) => <div
                                                        className="text-danger">{msg}</div>}</ErrorMessage>

                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="lastnameInput"
                                                           className="form-label">Город</Label>
                                                    <Input type="text" className="form-control"
                                                           id="lastnameInput" disabled
                                                           defaultValue={user.city?.name}
                                                    />

                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} lg={6} xl={6} xxl={6}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="lastnameInput"
                                                               className="form-label">Обо мне</Label>
                                                        <Field as="textarea" className="form-control" rows={6}
                                                               id="lastnameInput" placeholder="Информация обо мне"
                                                               name="about"/>
                                                    </div>
                                                    <div className="mt-3 d-flex justify-content-end">
                                                        <button type="submit"
                                                                className="btn btn-primary mx-1">Обновить
                                                        </button>
                                                        <button type="button"
                                                                className="btn btn-soft-primary">Отмена
                                                        </button>
                                                    </div>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Form>
                                :
                                <div className="d-flex justify-content-center">
                                    <Spinner/>
                                </div>
                        }
                    </Formik>
                </CardBody>
            </Card>
        </Col>
    );
};

export default MyInfoForm;
