import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {ITerm} from "../../../models/ITerm";

class TermService {
    static async getTerms(): Promise<AxiosResponse<ITerm[]>>{
        return $api.post("/terms/pages")
    }

    static async addTerms(value: object): Promise<AxiosResponse<any>>{
        return $api.post("/term/add", value)
    }

    static async updateTerms(value: object, termId: number): Promise<AxiosResponse<any>>{
        return $api.post(`/term/update/${termId}`, value)
    }
}

export default TermService;