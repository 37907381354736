import React, {useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {addCountry, addPage} from "../../../store/reducers/admin/ActionCreator";
import {Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";

const PageAdd = () => {

    const dispatch = useAppDispatch();
    const {prefixTable, groupPages, headerPages} = useAppSelector(state => state.AdminReducer)
    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <>
            <Formik
                initialValues={{
                    name: '',
                    path: '',
                    prefix_table_id: prefixTable[0] && prefixTable[0].id,
                    header_page_id: headerPages[0] && headerPages[0].id,
                    group_page_id: groupPages[0] && groupPages[0].id
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required('Обязательное поле'),
                    path: Yup.string().required('Обязательное поле'),
                    prefix_table_id: Yup.string().required('Обязательное поле'),
                    header_page_id: Yup.string().required('Обязательное поле'),
                    group_page_id: Yup.string().required('Обязательное поле')
                })}
                onSubmit={
                    (value, {resetForm}) => {
                        dispatch(addPage(value));
                        resetForm();
                    }
                }
            >
                <Form>
                    <Card className=" mx-n3">
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                 role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "1"})}
                                        onClick={() => {
                                            tabChange("1");
                                        }}
                                        type="button">
                                        <i className="fas fa-home"></i>
                                        Данные о странице
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className="p-4">

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="roleInput"
                                                       className="form-label">Название</Label>
                                                <Field type="text" className="form-control" id="roleInput"
                                                       name="name"
                                                       placeholder="Введите название"/>
                                                <ErrorMessage name="name">{(msg) => <div
                                                    className="text-danger">{msg}</div>}</ErrorMessage>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="roleInput"
                                                       className="form-label">Название</Label>
                                                <Field type="text" className="form-control" id="roleInput"
                                                       name="path"
                                                       placeholder="Введите название"/>
                                                <ErrorMessage name="path">{(msg) => <div
                                                    className="text-danger">{msg}</div>}</ErrorMessage>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="roleInput"
                                                       className="form-label">Префикс страницы</Label>
                                                <Field as="select" className="form-select" id="roleInput"
                                                       name="prefix_table_id"
                                                       placeholder="Введите название">
                                                    {
                                                        prefixTable.map((el) => {
                                                            return <option key={el.id} value={el.id}>{el.name}</option>
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="prefix_table_id">{(msg) => <div
                                                    className="text-danger">{msg}</div>}</ErrorMessage>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="roleInput"
                                                       className="form-label">Группа страницы</Label>
                                                <Field as="select" className="form-select" id="roleInput"
                                                       name="group_page_id"
                                                       placeholder="Введите название">
                                                    {
                                                        groupPages.map((el) => {
                                                            return <option key={el.id} value={el.id}>{el.name}</option>
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="group_page_id">{(msg) => <div
                                                    className="text-danger">{msg}</div>}</ErrorMessage>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="roleInput"
                                                       className="form-label">Заколовок страницы</Label>
                                                <Field as="select" className="form-select" id="roleInput"
                                                       name="header_page_id"
                                                       placeholder="Введите название">
                                                    {
                                                        headerPages.map((el) => {
                                                            return <option key={el.id} value={el.id}>{el.name}</option>
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="header_page_id">{(msg) => <div
                                                    className="text-danger">{msg}</div>}</ErrorMessage>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col lg={12} className="mt-3">
                                            <div className="hstack gap-2 justify-content-end">
                                                <button type="submit"
                                                        className="btn btn-primary">Добавить
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Form>
            </Formik>
        </>
    );
};

export default PageAdd;
