import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {updatePage} from "../../../store/reducers/admin/ActionCreator";
import {Col, Label, Row} from "reactstrap";

const UpdateDataPageForm = () => {
    const dispatch = useAppDispatch();
    const {selectPage, headerPages, groupPages, prefixTable} = useAppSelector(state => state.AdminReducer)

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: `${selectPage?.name}`,
                path: `${selectPage?.path}`,
                prefix_table_id: selectPage?.prefix_table_id,
                header_page_id: selectPage?.header_page_id,
                group_page_id: selectPage?.group_page_id
            }}
            validationSchema={Yup.object({
                name: Yup.string().required('Обязательное поле'),
                path: Yup.string().required('Обязательное поле'),
                prefix_table_id: Yup.string().required('Обязательное поле'),
                header_page_id: Yup.string().required('Обязательное поле'),
                group_page_id: Yup.string().required('Обязательное поле')
            })}
            onSubmit={(value) => {
                dispatch(updatePage(value, selectPage!.id))
            }}
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="roleInput"
                                   className="form-label">Название</Label>
                            <Field type="text" className="form-control" id="roleInput"
                                   name="name"
                                   placeholder="Введите название"/>
                            <ErrorMessage name="name">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="roleInput"
                                   className="form-label">Название</Label>
                            <Field type="text" className="form-control" id="roleInput"
                                   name="path"
                                   placeholder="Введите название"/>
                            <ErrorMessage name="path">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="roleInput"
                                   className="form-label">Префикс страницы</Label>
                            <Field as="select" className="form-select" id="roleInput"
                                   name="prefix_table_id"
                                   placeholder="Введите название">
                                {
                                    prefixTable.map((el) => {
                                        return <option key={el.id} value={el.id}>{el.name}</option>
                                    })
                                }
                            </Field>
                            <ErrorMessage name="prefix_table_id">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="roleInput"
                                   className="form-label">Группа страницы</Label>
                            <Field as="select" className="form-select" id="roleInput"
                                   name="group_page_id"
                                   placeholder="Введите название">
                                {
                                    groupPages.map((el) => {
                                        return <option key={el.id} value={el.id}>{el.name}</option>
                                    })
                                }
                            </Field>
                            <ErrorMessage name="group_page_id">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="roleInput"
                                   className="form-label">Заколовок страницы</Label>
                            <Field as="select" className="form-select" id="roleInput"
                                   name="header_page_id"
                                   placeholder="Введите название">
                                {
                                    headerPages.map((el) => {
                                        return <option key={el.id} value={el.id}>{el.name}</option>
                                    })
                                }
                            </Field>
                            <ErrorMessage name="header_page_id">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataPageForm;
