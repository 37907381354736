import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row, Spinner} from "reactstrap";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import Select from "react-select";
import {customStyles} from "../../settings";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import useDebounce from "../../../hooks/useDebounce";
import ModalNotifyDebt from "./ModalNotifyDebt";

const ListBalance = () => {
    const [balance, setBalance] = useState([]);
    const [schools, setSchools] = useState<any[]>([]);
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'});
    const [personalAccount, setPersonalAccount] = useState('');
    const [fio, setFio] = useState('');
    const [date, setDate] = useState(new Date());
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [openModalNotifyDebt, setOpenModalNotifyDebt] = useState(false);
    const [search, setSearch] = useState(false);
    const debouncePersonalAccount = useDebounce(personalAccount, 500)
    const debounceFio = useDebounce(fio, 500)

    useEffect(() => {
        fetchBalance(1, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, date, fio);
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
    }, []);

    useEffect(() => {
        search &&
        fetchBalance(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, date, fio);
    }, [debouncePersonalAccount]);

    useEffect(() => {
        search &&
        fetchBalance(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, date, fio);
    }, [debounceFio]);

    const fetchBalance = async (page: number, size = perPage, schoolId = '', buildingId = '', classId = '', personalAccount: string, date: Date, fio = '') => {
        setSearch(true)
        await AxiosClient.get(`/personal_accounts/balance`, {params: {page: page, per_page: size, fio, schoolId, buildingId, classId, personalAccount, date: moment(date).format('YYYY-MM-DD').toString()}})
            .then((response) => {
                setSearch(false)
                setBalance(response.data.balance);
                setTotalRows(response.data.total);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });

    };

    const onChangePersonalAccount = (name: string) => {
        setPersonalAccount(name)
        setSearch(true)
    }

    const onChangeFio = (name: string) => {
        setFio(name)
        setSearch(true)
    }

    const onChangeBuild = (data: { value: string, label: string }) => {
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        fetchBalance(currentPage, perPage, selectSchool.value, data.value, selectClass.value, personalAccount, date, fio);
        setSelectBuild(data)
        setClasses(sortClass)
        setSelectClass({ value: '', label: 'Не выбрано' })
    }

    const onChangeClass = (data: { value: string, label: string }) => {
        fetchBalance(currentPage, perPage, selectSchool.value, selectBuild.value, data.value, personalAccount ,date, fio);
        setSelectClass(data)
    }

    const onChangeSchool = (data: { value: string, label: string }) => {
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        fetchBalance(currentPage, perPage, data.value, selectBuild.value, selectClass.value, personalAccount, date, fio);
        setSelectSchool(data)
    }

    const onChangeDate = (data: Date) => {
        fetchBalance(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount , data, fio);
        setDate(data)
    }

    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };

    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchools(prevOptions => [...prevOptions, ...newData]);
    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const handlePageChange = (page: number) => {
        fetchBalance(page, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, date, fio);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        await fetchBalance(page, newPerPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, date, fio);
        setPerPage(newPerPage);
    };


    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Лицевой счет</span>,
            selector: (row: any) => row.personal_account,
        },
        {
            name: <span className='font-weight-bold fs-13'>ФИО</span>,
            selector: (row: any) => `${row?.student.last_name} ${row?.student.name} ${row?.student.middle_name}`,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Школа</span>,
            selector: (row: any) => row.school.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Корпус</span>,
            selector: (row: any) => row.building.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Класс</span>,
            selector: (row: any) => row.class.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Баланс</span>,
            selector: (row: any) => row.balance,
        }
    ]

    return (
        <>
            <Row>
                <Col sm={12} lg={6} xl={3} xxl={2}>
                    <label htmlFor="">Дата</label>
                    <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        value={date}
                        options={{
                            locale: Russian, dateFormat: "d M Y"
                        }}
                        onClose={(dates) => onChangeDate(dates[0])}
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3}>
                    <label htmlFor="">Школа</label>
                    <Select
                        options={schools?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                        value={selectSchool}
                        onChange={(change: any) => {
                            onChangeSchool({value: change.value, label: change.label})
                        }}
                        isLoading={loadingOptions}
                        onMenuScrollToBottom={handleMenuScroll}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={2}>
                    <label htmlFor="">Корпус</label>
                    <Select
                        options={builds?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                        value={selectBuild}
                        onChange={(change: any) => {
                            onChangeBuild({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                        isLoading={loadingOptionsBuildings}
                    />
                </Col>
                <Col sm={12} lg={6} xl={2} xxl={2}>
                    <label htmlFor="class">Класс</label>
                    <Select
                        options={classes?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...classes?.map(v => ({value: v.id, label: v.name}))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectClass}
                        onChange={(change: any) => {
                            onChangeClass({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                        isLoading={loadingOptionsBuildings}
                    />
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3}>
                    <label htmlFor="personalAccount">Лицевой счет</label>
                    <input type="text" id="personalAccount" className="form-control" value={personalAccount}
                           onChange={(event) => onChangePersonalAccount(event.target.value)}/>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3}>
                    <label htmlFor="personalAccount">ФИО</label>
                    <input type="text" id="personalAccount" className="form-control" value={fio}
                           onChange={(event) => onChangeFio(event.target.value)}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-3">
                        <CardHeader>
                            <button onClick={() => setOpenModalNotifyDebt(true)} className="btn btn-primary mt-1 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0">Уведомить о задолжности
                            </button>
                        </CardHeader>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={balance}
                                noDataComponent={'Нет данных'}
                                progressComponent={<Spinner></Spinner>}
                                progressPending={search}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно балансов:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ModalNotifyDebt selectClass={selectClass.value} selectSchool={selectSchool.value} selectBuild={selectBuild.value} openModalNotifyDebt={openModalNotifyDebt} setOpenModalNotifyDebt={value => setOpenModalNotifyDebt(value)}/>
        </>
    );
};

export default ListBalance;
