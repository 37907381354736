import React, {useState} from 'react';
import {Input, Label} from "reactstrap";
import userDummayImage from "../../assets/images/users/user-dummy-img.jpg";
import {Form, Formik} from "formik";
import UserService from "../../api/services/userService/UserService";
import {useStateContext} from "../../hooks/useStateContext";

const MyPhotoForm = () => {
    const {user, setUser} = useStateContext();

    const [file, setFile] = useState('')
    return (
        <div className="text-center">
            <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                <img
                    src={!user.photo ? userDummayImage : `http://127.0.0.1:8000${user.photo}`}
                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                    alt="user-profile"/>
                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">

                    <Formik
                        initialValues={{
                            file1: ''
                        }}
                        onSubmit={
                            () => {
                                const formData = new FormData();
                                formData.append('img', file!);

                                UserService.updateUserImage(formData, user!.id)
                                    .then(() => {
                                        UserService.getUser().then(r => setUser(r.data))
                                    })
                            }
                        }
                    >
                        <Form encType="multipart/form-data">
                            <Input id="profile-img-file-input" type="file"
                                   className="profile-img-file-input" name="file1"
                                   onChange={(e: any) => {
                                       setFile(e.target?.files[0]);
                                       (document.getElementById('btnfile') as HTMLElement).click()
                                   }}/>
                            <Label htmlFor="profile-img-file-input"
                                   className="profile-photo-edit avatar-xs">
                                                            <span
                                                                className="avatar-title rounded-circle bg-light text-body">
                                                                <i className="ri-camera-fill"></i>
                                                            </span>
                            </Label>
                            <button type="submit" id="btnfile"
                                    className="btn-primary"
                                    style={{display: 'none'}}></button>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default MyPhotoForm;
