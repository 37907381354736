import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import { Field, Form, Formik} from "formik";
import * as Yup from "yup";
import moment from "moment";
import classNames from "classnames";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles} from "../../settings";
import ListBenefit from "./ListBenefit";
import ListViewMenu from "./ListViewMenu";
import ListStatuses from "./ListStatuses";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
interface IModalAddPersonalAccount {
    addPersonalAccount: boolean,
    setAddPersonalAccount: (value: boolean) => void,
    setStudents: (value: any) => void
    selectStudent: any | null

}
const ModalAddPersonalAccount: FC<IModalAddPersonalAccount> = ({addPersonalAccount, setAddPersonalAccount, setStudents, selectStudent}) => {
    const idButtonSave: string = 'buttonSaveModalAddPersonalAccount';
    const [dataBegin, setDataBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState<Date | string>('');
    const [activeTab, setActiveTab] = useState("1");
    const [schools, setSchools] = useState<any[]>([]);
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState({value: '', label: 'Не выбрано'});
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'});
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([])
    const [listBenefit, setListBenefit] = useState<any[]>([]);
    const [typeMenu, setTypeMenu] = useState<any[]>([]);
    const [listViewMenu, setListViewMenu] = useState<any[]>([]);
    const [listMenu, setListMenu] = useState<any[]>([]);
    const [ageCategories, setAgeCategories] = useState<any[]>([]);
    const [listStatuses, setListStatuses] = useState<any[]>([]);
    const [withoutChanges, setWithoutChanges] = useState<any[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    const [currentObject, setCurrentObject] = useState<any>({magnetic_card: '', name: '', last_name: '', middle_name: '', date_begin: '', date_end: '',
        school_id: '', catering_organizer_id: '', building_id: '', class_id: '', type_menu_id: '',  age_category_id: ''});
    const [newObject, setNewObject] = useState<any>({magnetic_card: '', name: '', last_name: '', middle_name: '', date_begin: '', date_end: '',
        school_id: '', catering_organizer_id: '', building_id: '', class_id: '', type_menu_id: '',  age_category_id: ''});
    const [isChanges, setIsChanges] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        AxiosClient.get('/age_categories')
            .then((r) => setAgeCategories(r.data))
            .catch(() => console.log('error'))
        AxiosClient.get('/schools')
            .then((r) => {
                setSchools(r.data.schools)
            })
            .catch(() => console.log('error'))
        AxiosClient.get('/type_menu')
            .then((r) => {
                setTypeMenu(r.data)
            })
            .catch(() => console.log('error'))
    }, []);

    useEffect(() => {
        fetchBuildings(selectSchool.value)
            .then(r => setBuilds(r))
        if (selectSchool.value){
            AxiosClient.get(`/schools/${selectSchool.value}/catering_organizers/type_planned_menu`)
                .then((r) => setListMenu(r.data.catering_organizer?.catering_organizer_type_planned_menu?.map((v: any) => ({
                    ...v.type_planned_menu,
                    idPlan: v.id,
                    pricePlan: v.price
                }))))
            AxiosClient.get(`/schools/${selectSchool.value}/catering_organizers/all`)
                .then((r) => setListCateringOrganizer(r.data))
        } else {
            setListCateringOrganizer([])
            setSelectCateringOrganizer({value: '', label: 'Не выбрано'})
        }
        setSelectBuild({value: '', label: 'Не выбрано'})
        setSelectClass({value: '', label: 'Не выбрано'})
    }, [selectSchool.value, schools])

    useEffect(() => {
        const sortClass = builds?.find(s => s.id.toString() === selectBuild.value.toString())?.class
        setClasses(sortClass)
        setSelectClass({value: '', label: 'Не выбрано'})
    }, [builds, selectBuild.value])

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            return response?.data
        }
        return []
    };

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const confirmOrExit = () => {
        if(!_.isEqual(currentObject, newObject) || !_.isEqual(listBenefit, withoutChanges) || !_.isEqual(listStatuses, withoutChanges) || !_.isEqual(listMenu, withoutChanges)){
            setConfirmExit(true);
        }else{
            setAddPersonalAccount(false);
        }
    }

    return (
        <Modal id="createProjectModal" isOpen={addPersonalAccount}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered size={'lg'}>
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление лиц. счета
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            personalAccount: '',
                            magnetic_card: '',
                            name: selectStudent?.name,
                            last_name: selectStudent?.last_name,
                            middle_name: selectStudent?.middle_name,
                            type_menu_id: '',
                            school_id: '',
                            building_id: '',
                            class_id: '',
                            date_begin: '',
                            date_end: '',
                            age_category_id: ageCategories[0] && ageCategories[0].id,
                            catering_organizer_id: selectCateringOrganizer.value,
                            listBenefit: [{}],
                            listMenu: [{}],
                            listStatuses: [{}]
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                            last_name: Yup.string().required('Обязательное поле'),
                            middle_name: Yup.string().required('Обязательное поле'),
                            catering_organizer_id: Yup.string().required('Обязательное поле')
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        values.date_begin = moment(dataBegin).format("YYYY-MM-DD").toString()
                        values.date_end = dateEnd ? moment(dateEnd).format("YYYY-MM-DD").toString() : ''
                        values.school_id = selectSchool.value
                        values.building_id = selectBuild.value
                        values.class_id = selectClass.value
                        values.listBenefit = listBenefit
                        values.listMenu = listViewMenu
                        values.listStatuses = listStatuses
                        AxiosClient.post(`/students/create_personal_account/${selectStudent.id}`, values)
                            .then(() => {
                                dispatch(setIsSend(false))
                                setAddPersonalAccount(false)
                                AxiosClient.get('/students', {params: {page: 1, per_page: 10}})
                                    .then(r => setStudents(r.data))
                                    .catch(() => console.log('error'))
                            })
                            .catch(() => dispatch(setIsSend(false)))
                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Основное
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Льгота
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "3", "p-2": true})}
                                            onClick={() => {
                                                tabChange("3");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Плановое меню
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "5", "p-2": true})}
                                            onClick={() => {
                                                tabChange("5");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Категория льгот
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Фамилия:
                                                        <Field name="last_name" className={`form-control ${errors.last_name && touched.last_name && "border border-2 border-danger"}`} disabled/>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Имя:
                                                        <Field name="name" className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`} disabled/>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label>Отчество:</label>
                                                    <Field name="middle_name" className={`form-control ${errors.middle_name && touched.middle_name && "border border-2 border-danger"}`} disabled/>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Магнитная карта:
                                                    <Field name="magnetic_card" className={`form-control ${errors.magnetic_card && touched.magnetic_card && "border border-2 border-danger"}`} onChange={(e: any) => {
                                                        setNewObject({...newObject, 'magnetic_card': e.target.value});
                                                        setFieldValue('magnetic_card', e.target.value);
                                                    }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Школа:
                                                    <Select
                                                        options={schools?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                        value={selectSchool}
                                                        onChange={(change: any) => {
                                                            setSelectSchool({value: change.value, label: change.label})
                                                            setNewObject({...newObject, 'school_id': change.value});
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                        name="name"
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Организатор питания:
                                                    <Select
                                                        options={listCateringOrganizer?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...listCateringOrganizer.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                        value={selectCateringOrganizer}
                                                        onChange={(change: any) => {
                                                            setSelectCateringOrganizer({value: change.value, label: change.label})
                                                            setFieldValue('catering_organizer_id', change.value)
                                                            setNewObject({...newObject, 'catering_organizer_id': change.value});
                                                        }}
                                                        styles={customStyles}
                                                        className={`${errors.catering_organizer_id && 'border border-2 border-danger rounded-3'}`}
                                                        placeholder="Поиск"
                                                        name="name"
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Корпус:
                                                    <Select
                                                        options={builds?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                        value={selectBuild}
                                                        onChange={(change: any) => {
                                                            setSelectBuild({value: change.value, label: change.label})
                                                            setNewObject({...newObject, 'building_id': change.value});
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                        name="name"
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Класс:
                                                    <Select
                                                        options={classes?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...classes?.map(v => ({value: v.id, label: v.name}))] : [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }]}
                                                        value={selectClass}
                                                        onChange={(change: any) => {
                                                            setSelectClass({value: change.value, label: change.label})
                                                            setNewObject({...newObject, 'class_id': change.value});
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                        name="name"
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Комплекс:
                                                    <Field name="type_menu_id" as="select" className="form-select" onChange={(e: any) => {
                                                        setFieldValue('type_menu_id', e.target.value);
                                                        setNewObject({...newObject, 'type_menu_id': e.target.value});
                                                    }}>
                                                        <option value="">Не выбрано</option>
                                                        {
                                                            typeMenu.map(tm => <option value={tm.id}
                                                                                       key={tm.id}>{tm.name}</option>)
                                                        }
                                                    </Field>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Возрастная категория:
                                                    <Field name="age_category_id" as="select" className="form-select" onChange={(e: any) => {
                                                        setNewObject({...newObject, 'age_category_id': e.target.value});
                                                        setFieldValue('age_category_id', e.target.value);
                                                    }}>
                                                        {
                                                            ageCategories.map(ageCategory => (<option key={ageCategory.id}
                                                                                                      value={ageCategory.id}>{ageCategory.name}</option>))
                                                        }
                                                    </Field>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Дата с:
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dataBegin}
                                                        options={{
                                                            locale: Russian, dateFormat: "d M Y"
                                                        }}
                                                        onClose={(date) => {
                                                            setNewObject({...newObject, 'date_begin': date[0]});
                                                            setDataBegin(date[0])
                                                        }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Дата по:
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dateEnd}
                                                        options={{
                                                            locale: Russian, dateFormat: "d M Y"
                                                        }}
                                                        onClose={(date) => {
                                                            setDateEnd(date[0])
                                                            setNewObject({...newObject, 'date_end': date[0]});
                                                        }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="hstack gap-2">
                                            <SubmitWithLoading id={idButtonSave} text={"Добавить"}/>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <ListBenefit setListBenefit={value => setListBenefit(value)} listBenefit={listBenefit}/>
                                    </TabPane>
                                    <TabPane tabId="3" className="align-items-center">
                                        <ListViewMenu listViewMenu={listViewMenu} listMenu={listMenu}
                                                      setListViewMenu={value => setListViewMenu(value)}/>
                                    </TabPane>
                                    <TabPane tabId="5" className="align-items-center">
                                        <ListStatuses listStatuses={listStatuses} setListStatuses={value => setListStatuses(value)}/>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setAddPersonalAccount(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal>
    );
};

export default ModalAddPersonalAccount;
