import {AppDispatch} from "../../store";
import UserService from "../../../api/services/userService/UserService";
import {AdminSlice} from "./AdminSlice";
import CityService from "../../../api/services/cityService/CityService";
import OrganizationService from "../../../api/services/organizationService/OrganizationService";
import TermService from "../../../api/services/TermService/TermService";
import PositionService from "../../../api/services/positionService/PositionService";
import CountryService from "../../../api/services/countryService/CountryService";
import {IUser} from "../../../models/IUser";
import {ICity} from "../../../models/ICity";
import {ICountry} from "../../../models/ICountry";
import {IOrganization} from "../../../models/IOrganization";
import RegionService from "../../../api/services/regionService/RegionService";
import {IPosition} from "../../../models/IPosition";
import {ITerm} from "../../../models/ITerm";
import PageService from "../../../api/services/pageService/PageService";
import {IPage} from "../../../models/IPage";

export const getUsers = (page?: number, name?: string, schoolId?: string | number, buildId?: string | number, termId?: string | number,  search?: 'name' | 'page') => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await UserService.getUsers(page, name, schoolId, buildId, termId).then(res => res.data)
        dispatch(AdminSlice.actions.usersLoadingSuccess({response, search}))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const getCities = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await CityService.getCities().then(res => res.data)
        dispatch(AdminSlice.actions.citiesLoadingSuccess(response))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const getOrganizations = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await OrganizationService.getOrganizations().then(res => res.data)
        dispatch(AdminSlice.actions.organizationsLoadingSuccess(response))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const getTerms = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await TermService.getTerms().then(res => res.data)
        dispatch(AdminSlice.actions.termsLoadingSuccess(response))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const getPositions = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await PositionService.getPositions().then(res =>  res.data)
        dispatch(AdminSlice.actions.positionsLoadingSuccess(response))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const getCountries = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await CountryService.getCounties().then(res => res.data)
        dispatch(AdminSlice.actions.countriesLoadingSuccess(response))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const getRegions = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await RegionService.getRegions().then(res => res.data)
        dispatch(AdminSlice.actions.regionsLoadingSuccess(response))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const getPages = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await PageService.getPage().then(res => res.data)
        dispatch(AdminSlice.actions.pagesLoadingSuccess(response))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const getGroupPages = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await PageService.getGroupPage().then(res => res.data)
        dispatch(AdminSlice.actions.groupPagesLoadingSuccess(response))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const getHeaderPages = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await PageService.getHeaderPage().then(res => res.data)
        dispatch(AdminSlice.actions.headerPagesLoadingSuccess(response))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const getPrefixTable = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        const response = await PageService.getPrefixTables().then(res => res.data)
        dispatch(AdminSlice.actions.prefixTableLoadingSuccess(response))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const addUser = (data: FormData) => async (dispatch: AppDispatch) => {
    try {
        await UserService.addUser(data)
        const users = await UserService.getUsers().then(res => res.data)
        dispatch(AdminSlice.actions.usersLoadingSuccess({response: users}))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const updateImageUser = (data: FormData, userId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        await UserService.updateUserImage(data, userId);
        const users = await UserService.getUsers().then(res => res.data)
        const user = users.find(user => user.id === userId) as IUser;
        dispatch(AdminSlice.actions.setSelectUser(user))
        dispatch(AdminSlice.actions.usersLoadingSuccess({response: users}))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const updateUser = (data: object, userId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        await UserService.updateUser(data, userId);
        const users = await UserService.getUsers().then(res => res.data)
        const user = users.find(user => user.id === userId) as IUser;
        dispatch(AdminSlice.actions.setSelectUser(user))
        dispatch(AdminSlice.actions.usersLoadingSuccess({response: users}))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const updatePasswordUser = (data: object, userId: number) => async (dispatch: AppDispatch) => {
    try {
        await UserService.updatePasswordUser(data, userId);
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const addCity = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await CityService.addCity(value)
        const cities = await CityService.getCities().then(res => res.data)
        dispatch(AdminSlice.actions.citiesLoadingSuccess(cities))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const updateCity = (value: object, cityId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        await CityService.updateCity(value, cityId);
        const cities = await CityService.getCities().then(res => res.data)
        const city = cities.find(city => city.id === cityId) as ICity;
        dispatch(AdminSlice.actions.setSelectCity(city))
        dispatch(AdminSlice.actions.citiesLoadingSuccess(cities))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}
export const addPage = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await PageService.addPage(value)
        const pages = await PageService.getPage().then(res => res.data)
        dispatch(AdminSlice.actions.pagesLoadingSuccess(pages))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}
export const updatePage = (value: object, pageId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        await PageService.updatePage(value, pageId);
        const pages = await PageService.getPage().then(res => res.data)
        const page = pages.find(page => page.id === pageId) as IPage;
        dispatch(AdminSlice.actions.setSelectPage(page))
        dispatch(AdminSlice.actions.pagesLoadingSuccess(pages))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const addCountry = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await CountryService.addCountry(value)
        const countries = await CountryService.getCounties().then(res => res.data)
        dispatch(AdminSlice.actions.countriesLoadingSuccess(countries))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const updateCountry = (value: object, countryId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        await CountryService.updateCountry(value, countryId);
        const countries = await CountryService.getCounties().then(res => res.data)
        const country = countries.find(country => country.id === countryId) as ICountry;
        dispatch(AdminSlice.actions.setSelectCountry(country))
        dispatch(AdminSlice.actions.countriesLoadingSuccess(countries))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const addOrganization = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await OrganizationService.addOrganization(value)
        const organizations = await OrganizationService.getOrganizations().then(res => res.data)
        dispatch(AdminSlice.actions.organizationsLoadingSuccess(organizations))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const updateOrganizations = (value: object, organizationId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        await OrganizationService.updateOrganization(value, organizationId);
        const organizations = await OrganizationService.getOrganizations().then(res => res.data)
        const organization = organizations.find(organization => organization.id === organizationId) as IOrganization;
        dispatch(AdminSlice.actions.setSelectOrganization(organization))
        dispatch(AdminSlice.actions.organizationsLoadingSuccess(organizations))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const addPosition = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await PositionService.addPosition(value)
        const positions = await PositionService.getPositions().then(res => res.data)
        dispatch(AdminSlice.actions.positionsLoadingSuccess(positions))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const updatePosition = (value: object, positionId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        await PositionService.updatePosition(value, positionId);
        const positions = await PositionService.getPositions().then(res => res.data)
        const position = positions.find(position => position.id === positionId) as IPosition;
        dispatch(AdminSlice.actions.setSelectPosition(position))
        dispatch(AdminSlice.actions.positionsLoadingSuccess(positions))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const addTerm = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await TermService.addTerms(value)
        const terms = await TermService.getTerms().then(res => res.data)
        dispatch(AdminSlice.actions.termsLoadingSuccess(terms))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const updateTerm = (value: object, termId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(AdminSlice.actions.dataLoading())
        await TermService.updateTerms(value, termId);
        const terms = await TermService.getTerms().then(res => res.data)
        const term = terms.find(term => term.id === termId) as ITerm;
        dispatch(AdminSlice.actions.setSelectTerm(term))
        dispatch(AdminSlice.actions.termsLoadingSuccess(terms))
    } catch (e: any){
        dispatch(AdminSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectUser = (user: IUser) => async (dispatch: AppDispatch) =>{
    dispatch(AdminSlice.actions.dataLoading())
    dispatch(AdminSlice.actions.setSelectUser(user))
}

export const setSelectCity = (city: ICity) => async (dispatch: AppDispatch) => {
    dispatch(AdminSlice.actions.dataLoading());
    dispatch(AdminSlice.actions.setSelectCity(city));
}

export const setSelectPage = (page: IPage) => async (dispatch: AppDispatch) => {
    dispatch(AdminSlice.actions.dataLoading());
    dispatch(AdminSlice.actions.setSelectPage(page));
}

export const setSelectCountry = (country: ICountry) => async (dispatch: AppDispatch) => {
    dispatch(AdminSlice.actions.dataLoading());
    dispatch(AdminSlice.actions.setSelectCountry(country));
}

export const setSelectOrganization = (organization: IOrganization) => async (dispatch: AppDispatch) => {
    dispatch(AdminSlice.actions.dataLoading());
    dispatch(AdminSlice.actions.setSelectOrganization(organization));
}

export const setSelectPosition = (position: IPosition) => async (dispatch: AppDispatch) => {
    dispatch(AdminSlice.actions.dataLoading());
    dispatch(AdminSlice.actions.setSelectPosition(position));
}

export const setSelectTerm = (term: ITerm) => async (dispatch: AppDispatch) => {
    dispatch(AdminSlice.actions.dataLoading());
    dispatch(AdminSlice.actions.setSelectTerm(term));
}
