import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getPages, getTerms} from "../../store/reducers/admin/ActionCreator";
import {Alert, Col, Container, Row} from "reactstrap";
import SelectToUpdateTerm from "../../components/admin/term/SelectToUpdateTerm";
import TermAdd from "../../components/admin/term/TermAdd";
import TermUpdate from "../../components/admin/term/TermUpdate";

const SettingsTerm = () => {

    const dispatch = useAppDispatch();
    const {isError, terms} = useAppSelector(state => state.AdminReducer);
    const [addTerm, setAddTerm] = useState(false);

    useEffect(() => {
        dispatch(getTerms()).then()
        dispatch(getPages()).then()
    }, [dispatch]);

    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdateTerm
                        setAddTerm={(val) => setAddTerm(val)}
                        terms={terms}/>
                    <div className="w-100 overflow-hidden">
                        <div className="d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {
                                                    addTerm ? <TermAdd /> : <TermUpdate/>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsTerm;
