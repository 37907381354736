import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import MenuData from "./MenuData";
import {Link, useLocation} from "react-router-dom";
import Header from "../header/Header";
import HelperSideBar from "./HelperSideBar";
import {Collapse} from "reactstrap";
import Footer from "../footer/Footer";
import Loading from "../loading/Loading";

const SideBarMain: FC<PropsWithChildren> = ({children}) => {

    const navData = MenuData().props.children;

    const location = useLocation();
    const [lastActive, setLastActive] = useState<HTMLAnchorElement>();

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
        const initMenu = () => {
            const pathName = process.env.PUBLIC_URL + location.pathname;
            const ul = document.getElementById("navbar-nav") as HTMLElement;
            const items = ul.getElementsByTagName("a");
            let itemsArray = [...items]; // converts NodeList to Array
            removeActivation(itemsArray);
            let matchingMenuItem = itemsArray.find((x) => {
                return x.pathname === pathName;
            });
            if (matchingMenuItem) {
                setLastActive(matchingMenuItem);
                activateParentDropdown(matchingMenuItem);
            } else {
                lastActive &&
                activateParentDropdown(lastActive);
            }
        };
        initMenu()
    }, [location.pathname]);

    function activateParentDropdown(item: HTMLElement) {
        item.classList.add("active");
        let parentCollapseDiv = (item.closest(".collapse.menu-dropdown") as HTMLElement);

        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add("show");
            let parentCollapseDivParentElement = parentCollapseDiv.parentElement as HTMLElement
            parentCollapseDivParentElement.children[0].classList.add("active");
            parentCollapseDivParentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
            );
            if (parentCollapseDivParentElement.closest(".collapse.menu-dropdown")) {
                let parentCollapseDivParentElementClosest = parentCollapseDivParentElement.closest(".collapse") as HTMLElement
                parentCollapseDivParentElementClosest.classList.add("show");
                if (
                    parentCollapseDivParentElementClosest.previousElementSibling
                )
                    (parentCollapseDivParentElementClosest.previousElementSibling as HTMLElement).classList.add("active");
                if (
                    (parentCollapseDivParentElementClosest.previousElementSibling as HTMLElement).closest(".collapse")
                ) {
                    ((parentCollapseDivParentElementClosest.previousElementSibling as HTMLElement).closest(".collapse") as HTMLElement).classList.add("show");
                    //parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.closest(".collapse").classList.add("show");
                    (((parentCollapseDivParentElementClosest.previousElementSibling as HTMLElement).closest(".collapse") as HTMLElement).previousElementSibling as HTMLElement).classList.add("active");
                }
            }
            return false;
        }
        return false;
    }
    const showMenu = () => {
        if(document.body.classList.contains('vertical-sidebar-enable'))
            document.body.classList.remove('vertical-sidebar-enable');
    }
    const removeActivation = (items: HTMLElement[]) => {
        let actiItems = items.filter((x: HTMLElement) => x.classList.contains("active"));

        actiItems.forEach((item) => {
            if (item.classList.contains("menu-link")) {
                if (!item.classList.contains("active")) {
                    item.setAttribute("aria-expanded", String(false));
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
            }
            if (item.classList.contains("nav-link")) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
                item.setAttribute("aria-expanded", String(false));
            }
            item.classList.remove("active");
        });
    };

    return (
        <>
            <Header/>
            <HelperSideBar>
                <>
                    {
                        (navData || []).map((item: any, key: number) => {
                            return (
                                <React.Fragment key={key}>
                                    {
                                        item['isHeader'] ?
                                            (
                                                <li className="menu-title">
                                                    <span data-key="t-menu">{item.label}</span>
                                                </li>
                                            ) :
                                            (
                                                <li className="nav-item">
                                                    <Link
                                                        onClick={item.click}
                                                        className="nav-link menu-link"
                                                        to={item.link ? item.link : "/#"}
                                                        data-bs-toggle="collapse"
                                                    >
                                                        <i className={item.icon}></i>{" "}
                                                        <span data-key="t-apps">{item.label}</span>
                                                    </Link>
                                                    <Collapse
                                                        className="menu-dropdown"
                                                        isOpen={item.stateVariables}
                                                        id="sidebarApps"
                                                    >
                                                        {
                                                            (item.subItems || []).map((subItem: any, key: number) => {
                                                                if (subItem.pages.length > 0){
                                                                    if (subItem.groupPage === 'Нет') {
                                                                        return (
                                                                            <ul className="nav nav-sm flex-column test" key={key}>
                                                                                {
                                                                                    subItem.pages.map((page: any, key: number) => (
                                                                                        <React.Fragment key={key}>
                                                                                            <li className="nav-item"
                                                                                                style={{marginLeft: '15px'}}>
                                                                                                <Link
                                                                                                    to={page.link ? page.link : "/#"}
                                                                                                    className="nav-link"
                                                                                                    onClick={() => showMenu()}
                                                                                                >
                                                                                                    {page.label}
                                                                                                </Link>
                                                                                            </li>
                                                                                        </React.Fragment>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                        )
                                                                    } else {
                                                                        return <ul className="nav nav-sm flex-column test" key={key}>
                                                                            <React.Fragment>
                                                                                <li className="nav-item"
                                                                                    style={{marginLeft: '15px'}}>
                                                                                    <style>
                                                                                        {
                                                                                            `
                                                                                                #navLinkGroupPage::before{
                                                                                                    display: none !important
                                                                                                }
                                                                                                #navLinkGroupPage{
                                                                                                    padding-left: 0 !important
                                                                                                }
                                                                                            `
                                                                                        }
                                                                                    </style>
                                                                                    <Link
                                                                                        to={"/#"}
                                                                                        className="nav-link fw-bold"
                                                                                        id="navLinkGroupPage"
                                                                                        onClick={subItem.click}
                                                                                    >
                                                                                        <i className="ri ri-bar-chart-horizontal-line"></i> {subItem.groupPage}
                                                                                    </Link>
                                                                                    <Collapse
                                                                                        className="menu-dropdown"
                                                                                        isOpen={subItem.stateIsGroup}
                                                                                        id="sidebarEcommerce"
                                                                                    >
                                                                                        <ul className="nav nav-sm flex-column">
                                                                                            {
                                                                                                subItem.pages.map((page: any, key: number) => (
                                                                                                    <li className="nav-item"
                                                                                                        key={key}>
                                                                                                        <Link
                                                                                                            to={page.link ? page.link : "/#"}
                                                                                                            className="nav-link"
                                                                                                            onClick={() => showMenu()}
                                                                                                        >
                                                                                                            {page.label}
                                                                                                        </Link>
                                                                                                    </li>
                                                                                                ))
                                                                                            }
                                                                                        </ul>
                                                                                    </Collapse>
                                                                                </li>
                                                                            </React.Fragment>
                                                                        </ul>
                                                                    }
                                                                }
                                                                else {
                                                                    return null
                                                                }
                                                            })
                                                        }
                                                    </Collapse>
                                                </li>
                                            )
                                    }
                                </React.Fragment>
                            )
                        })
                    }

                </>
            </HelperSideBar>
            <div className="main-content">
                {
                    children
                }
                <Loading/>
            </div>
            <Footer/>
        </>
    );
};
export default SideBarMain;
