import {AxiosResponse} from "axios";
import {ICountry} from "../../../models/ICountry";
import $api from "../../AxiosClient";

class CountryService {
    static async getCounties(): Promise<AxiosResponse<ICountry[]>>{
        return $api.post("/countrys")
    }

    static async addCountry(value: object): Promise<AxiosResponse<void>>{
        return $api.post("/country/add", value);
    }

    static async updateCountry(value: object, countryId: number): Promise<AxiosResponse<void>>{
        return $api.post(`/country/update/${countryId}`, value);
    }
}

export default CountryService