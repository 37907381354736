import React, {FC, useEffect, useState} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles, customStylesImport} from "../../settings";
import AxiosClient from "../../../api/AxiosClient";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import moment from "moment";

interface IModalBalance {
    setOpenModalFileBalance: (value: boolean) => void
    openModalFileBalance: boolean
}

const ModalBalance: FC<IModalBalance> = ({setOpenModalFileBalance, openModalFileBalance}) => {
    const [schools, setSchools] = useState<any[]>([]);
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'});
    const [date, setDate] = useState(new Date());
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [totalOptions, setTotalOptions] = useState(0);
    const [pageOption, setPageOption] = useState(1);
    const [error, setError] = useState(false);
    const [outAllChildrenList, setOutAllChildrenList] = useState(false);

    const dispatch = useAppDispatch()

    useEffect(() => {
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
    }, []);


    const onChangeBuild = (data: { value: string, label: string }) => {
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        setSelectBuild(data)
        setClasses(sortClass)
        setSelectClass({ value: '', label: 'Не выбрано' })
    }

    const onChangeClass = (data: { value: string, label: string }) => {
        setSelectClass(data)
    }

    const onChangeSchool = (data: { value: string, label: string }) => {
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        setSelectSchool(data)
    }

    const onChangeDate = (data: Date) => {
        setDate(data)
    }

    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 3}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };

    const loadMoreData = async () => {
        fetchMoreSchools(pageOption)
            .then(r => {
                setPageOption(state => state + 1)
                setSchools(prevOptions => [...prevOptions, ...r]);
            })

    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const getFile = () => {
        if (!selectSchool.value){
            setError(true)
            return;
        } else {
            dispatch(setIsSend(true))
            setError(false)
            const params = {
                schoolId: selectSchool.value,
                buildingId: selectBuild.value,
                classId: selectClass.value,
                date: moment(date).format('YYYY-MM-DD').toString(),
                outAllChildrenList: outAllChildrenList,
            }
            AxiosClient.get('/personal_accounts/balance/export', {responseType: 'blob', params})
                .then(response => {
                    dispatch(setIsSend(false))
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Баланс.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(() => {
                    console.log('error')
                    dispatch(setIsSend(false))
                })
        }
    }

    return (
            <Modal id="createProjectModal" isOpen={openModalFileBalance}
                   toggle={() => setOpenModalFileBalance(!openModalFileBalance)}
                   modalClassName="zoomIn" tabIndex={-1} centered >
                <ModalHeader toggle={() => setOpenModalFileBalance(!openModalFileBalance)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Отчет по балансу
                </ModalHeader>
                <ModalBody>
                    <Row className="mb-2">
                        <Col sm={12}>
                            <label  className="w-100">Дата:
                            <Flatpickr
                                className="form-control border-0 dash-filter-picker shadow"
                                value={date}
                                options={{
                                    locale: Russian, dateFormat: "d M Y"
                                }}
                                onClose={(dates) => onChangeDate(dates[0])}
                            />
                            </label>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm={12}>
                            <label  className="w-100">Школа:
                            <Select
                                options={schools?.length > 0 ? [{
                                    value: '',
                                    label: 'Не выбрано'
                                }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                value={selectSchool}
                                onChange={(change: any) => {
                                    onChangeSchool({value: change.value, label: change.label})
                                }}
                                isLoading={loadingOptions}
                                onMenuScrollToBottom={handleMenuScroll}
                                styles={!error ? customStyles : customStylesImport()}
                                placeholder="Поиск"
                                name="school"
                            />
                            </label>
                            {
                                error &&
                                <div>
                                    <p className="text-danger">Выберите школу</p>
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm={12}>
                            <label  className="w-100">Корпус:
                            <Select
                                options={builds?.length > 0 ? [{
                                    value: '',
                                    label: 'Не выбрано'
                                }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                value={selectBuild}
                                onChange={(change: any) => {
                                    onChangeBuild({value: change.value, label: change.label})
                                }}
                                styles={customStyles}
                                placeholder="Поиск"
                                name="build"
                                isLoading={loadingOptionsBuildings}
                            />
                            </label>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm={12}>
                            <label  className="w-100">Класс:
                            <Select
                                options={classes?.length > 0 ? [{
                                    value: '',
                                    label: 'Не выбрано'
                                }, ...classes?.map(v => ({value: v.id, label: v.name}))] : [{
                                    value: '',
                                    label: 'Не выбрано'
                                }]}
                                value={selectClass}
                                onChange={(change: any) => {
                                    onChangeClass({value: change.value, label: change.label})
                                }}
                                styles={customStyles}
                                placeholder="Поиск"
                                name="class"
                                isLoading={loadingOptionsBuildings}
                            />
                            </label>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                                <label>
                                    <Input
                                        type="checkbox"
                                        name="toggle"
                                        className="fs-14"
                                        checked={outAllChildrenList}
                                        onChange={(e) => setOutAllChildrenList(e.target.checked)}
                                    />
                                    &nbsp;&nbsp;Вывод детей согласно списку&nbsp;
                                </label>
                        </Col>
                    </Row>
                    <Row>
                        <div className="hstack gap-2 mt-2">
                            <SubmitWithLoading onClick={() => getFile()} text={'Получить файл'}/>
                        </div>
                    </Row>
                </ModalBody>
            </Modal>
    );
};

export default ModalBalance;
