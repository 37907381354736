import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import StatisticTable from "../../components/StatisticsLogin/StatisticTable";
import Select from "react-select";
import {customStyles} from "../../components/settings";
import AxiosClient from "../../api/AxiosClient";
import {ISchool} from "../../models/KSHP/ISchool";

const StatisticLoginKshp = () => {

    const [schools, setSchools] = useState<ISchool[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'})
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'})
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'})
    const [builds, setBuilds] = useState<any[]>([])
    const [classes, setClasses] = useState<any[]>([])
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);

    useEffect(() => {
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
    }, [])

    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setPageOption(prev => prev + 1)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };
    const fetchBuildings = async (selectSchool: string) => {
        if (selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };
    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchools(prevOptions => [...prevOptions, ...newData]);
    };
    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };
    const onChangeSchool = (data: { value: string, label: string }) => {
        setSelectBuild({value: '', label: 'Не выбрано'})
        setSelectClass({value: '', label: 'Не выбрано'})
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        setSelectSchool(data)
    }
    const onChangeBuilding = (data: { value: string, label: string }) => {
        setSelectClass({value: '', label: 'Не выбрано'})
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        setSelectBuild(data)
        setClasses(sortClass)
    }

    const onChangeClass = (data: { value: string, label: string }) => {
        setSelectClass(data)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Статистика входа</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12} lg={6} xl={3} xxl={3}>
                            <label htmlFor="">Школа</label>
                            <Select
                                options={schools?.length > 0 ? [{
                                    value: '',
                                    label: 'Не выбрано'
                                }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                value={selectSchool}
                                onChange={(change: any) => {
                                    onChangeSchool({value: change.value, label: change.label})
                                }}
                                styles={customStyles}
                                placeholder="Поиск"
                                name="name"
                                isLoading={loadingOptions}
                                onMenuScrollToBottom={handleMenuScroll}
                            />
                        </Col>
                        <Col sm={12} lg={6} xl={3} xxl={2}>
                            <label htmlFor="">Корпус</label>
                            <Select
                                options={builds?.length > 0 ? [{
                                    value: '',
                                    label: 'Не выбрано'
                                }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                value={selectBuild}
                                onChange={(change: any) => {
                                    onChangeBuilding({value: change.value, label: change.label})
                                }}
                                isLoading={loadingOptionsBuildings}
                                styles={customStyles}
                                placeholder="Поиск"
                                name="name"
                            />
                        </Col>
                        <Col sm={12} lg={6} xl={3} xxl={2}>
                            <label htmlFor="">Класс</label>
                            <Select
                                options={classes?.length > 0 ? [{
                                    value: '',
                                    label: 'Не выбрано'
                                }, ...classes?.map(v => ({value: v.id, label: v.name}))] : [{
                                    value: '',
                                    label: 'Не выбрано'
                                }]}
                                value={selectClass}
                                onChange={(change: any) => {
                                    onChangeClass({value: change.value, label: change.label})
                                }}
                                isLoading={loadingOptionsBuildings}
                                styles={customStyles}
                                placeholder="Поиск"
                                name="name"
                            />
                        </Col>
                        <Col xxl={12}>
                            <StatisticTable type="kshp" buildId={selectBuild.value} classId={selectClass.value} schoolId={selectSchool.value}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default StatisticLoginKshp;
