import React, {FC, useEffect, useState} from 'react';
import moment from "moment";
import {Card, CardBody, CardHeader, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import AddPayments from "./AddPayments";
import AxiosClient from "../../../api/AxiosClient";
import ElemPay from "./ElemPay";

interface IListMyPayments {
    selectChildren: string | number
}

const ListMyPayments: FC<IListMyPayments> = ({selectChildren}) => {

    const [addPayment, setAddPayment] = useState(false);
    const [payments, setPayments] = useState<any[]>([]);
    const [paymentsWait, setPaymentsWait] = useState<any[]>([]);
    useEffect(() => {
        selectChildren &&
        AxiosClient.get('/students/payments', {params: {id: selectChildren}})
            .then(r => {
                setPayments(r.data)
            })
            .catch(() => console.log('error'))
        AxiosClient.get('/sber/actuality_pays')
            .then(r => {
                setPaymentsWait(r.data);
            })
            .catch(() => console.log('error'));
    }, [selectChildren]);

    const updateData = () => {
        if (selectChildren) {
            AxiosClient.get('/students/payments', { params: { id: selectChildren } })
                .then(r => {
                    setPayments(r.data);
                })
                .catch(() => console.log('error'));
        }
    }

    const updateElemPay = () => {
            AxiosClient.get('/sber/actuality_pays')
                .then(r => {
                    setPaymentsWait(r.data);
                })
                .catch(() => console.log('error'));

    }

    const columns: any = [
        {
            id: 'date',
            name: <span className='font-weight-bold fs-14'>Дата</span>,
            selector: (row: any) => moment(row.date).format('DD.MM.YYYY').toString(),
            sortable: true,
            style: {
                minWidth: '110px'
            }
        },
        {
            name: <span className='font-weight-bold fs-14'>Лицевой счет</span>,
            selector: (row: any) => {
                return <span className="fs-13">{row.personal_account.personal_account}</span>
            },
            center: true
        },
        {
            name: <span className='font-weight-bold fs-14'>Школа</span>,
            selector: (row: any) => {
                if (!row.income) {
                    return <span className="fs-13">&mdash;</span>
                }
                return <span className="fs-13">{row.income}</span>
            },
            center: true
        },
        {
            name: <span className='font-weight-bold fs-14'>Корпус</span>,
            selector: (row: any) => {
                if (!row.income) {
                    return <span className="fs-13">&mdash;</span>
                }
                return <span className="fs-13">{row.income}</span>
            },
            center: true
        },
        {
            name: <span className='font-weight-bold fs-14'>Класс</span>,
            selector: (row: any) => {
                if (!row.income) {
                    return <span className="fs-13">&mdash;</span>
                }
                return <span className="fs-13">{row.income}</span>
            },
            center: true
        },
        {
            name: <span className='font-weight-bold fs-14'>Номер транзакции</span>,
            selector: (row: any) => {
                return <span className="fs-13">{row.number_transaction}</span>
            },
            center: true
        },
        {
            name: <span className='font-weight-bold fs-14'>Сумма</span>,
            selector: (row: any) => {
                return <span className="fs-13">{row.sum}</span>
            },
            center: true
        },
    ];


    return (
        <>
            <Row>
                {
                    paymentsWait.map((el: any) => (
                        <ElemPay key={el.id} elem={el} dateElemPay={() => updateElemPay()} updateData={() => updateData()}/>
                    ))
                }
            </Row>
            <Row>
                <Card>
                    <CardHeader>
                        <button className="btn btn-primary" onClick={() => setAddPayment(true)}>Оплатить</button>
                    </CardHeader>
                    <CardBody>
                        <DataTable
                            columns={columns}
                            data={payments}
                            defaultSortAsc={false}
                            defaultSortFieldId={'date'}
                            noDataComponent={'Нет оплат'}
                            highlightOnHover
                            customStyles={{
                                rows: {
                                    highlightOnHoverStyle: {
                                        backgroundColor: "#ffebb5"
                                    }
                                }
                            }}
                        />
                    </CardBody>
                </Card>
            </Row>
            <AddPayments updateElemPay={() => updateElemPay()} updateData={() => updateData()} selectChildren={selectChildren}
                         addPayment={addPayment} setAddPayment={value => setAddPayment(value)}/>
        </>
    );
};

export default ListMyPayments;
