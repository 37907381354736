export const customStyles = {
    control: (ds: any) => ({
        ...ds,
        backgroundColor: "#fff",
    }),
    option: (ds: any, state: any) => ({
        ...ds,
        backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
        color: state.isFocused ? "#fff" : "#000"
    })
}

export const customStylesImport = () => {
    return {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
            borderColor: "red"
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }
}