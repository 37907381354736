import React, {FC, useEffect, useState} from 'react';
import {ISchool} from "../../../models/KSHP/ISchool";
import AxiosClient from "../../../api/AxiosClient";
import {Alert, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Select from "react-select";
import {customStyles} from "../../settings";
interface IModalUploadParentAccountData {
    openModalUploadParentAccountData: boolean,
    setOpenModalUploadParentAccountData: (value: boolean) => void
}
const ModalUploadParentAccountData: FC<IModalUploadParentAccountData> = ({setOpenModalUploadParentAccountData, openModalUploadParentAccountData}) => {
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [pageOptionStudents, setPageOptionStudents] = useState(1);
    const [totalOptionsStudents, setTotalOptionsStudents] = useState(0);
    const [schools, setSchools] = useState<ISchool[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'})
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'})
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'})
    const [builds, setBuilds] = useState<any[]>([])
    const [classes, setClasses] = useState<any[]>([])
    const [students, setStudents] = useState<any[]>([]);
    const [selectStudent, setSelectStudent] = useState({value: '', label: 'Не выбрано'});

    useEffect(() => {
        fetchMoreStudents(1, selectSchool.value, selectBuild.value, selectClass.value)
            .then(r => {
                setPageOptionStudents(state => state + 1)
                setStudents(r)
            })
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
    }, [])
    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const fetchMoreStudents = async (page: number, schoolId: string, buildingId: string, classId: string) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/students`, {params: {page: page, per_page: 10, schoolId, buildingId, classId}})
        setLoadingOptions(false)
        setTotalOptionsStudents(response?.data.total)
        return response?.data.students
    };

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };

    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchools(prevOptions => [...prevOptions, ...newData]);
    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const loadMoreDataStudents = async () => {
        fetchMoreStudents(pageOptionStudents, selectSchool.value, selectBuild.value, selectClass.value)
            .then(r => {
                setStudents(prevOptions => [...prevOptions, ...r]);
                setPageOptionStudents(state => state + 1)
            })
    };

    const handleMenuScrollStudents = async (event: any) => {
        const target = event.target;
        if (totalOptionsStudents !== students.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreDataStudents();
        }
    };

    const onChangeSchool = (data: { value: string, label: string }) => {
        fetchMoreStudents(1, data.value, '', '')
            .then(r => {
                setStudents(r);
                setPageOptionStudents(2)
            })
        setSelectBuild({value: '', label: 'Не выбрано'})
        setSelectClass({value: '', label: 'Не выбрано'})
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        setSelectSchool(data)
    }

    const onChangeBuilding = (data: { value: string, label: string }) => {
        fetchMoreStudents(1, selectSchool.value, data.value, '')
            .then(r => {
                setStudents(r);
                setPageOptionStudents(2)
            })
        setSelectClass({value: '', label: 'Не выбрано'})
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        setSelectBuild(data)
        setClasses(sortClass)
    }

    const onChangeClass = (data: { value: string, label: string }) => {
        setSelectClass(data)
        fetchMoreStudents(1, selectSchool.value, selectBuild.value, data.value)
            .then(r => {
                setStudents(r);
                setPageOptionStudents(2)
            })
    }

    const handleExport = () => {
        AxiosClient.get(`/users/upload_parent_account_data`, { responseType: 'blob', params: {schoolId: selectSchool.value, buildingId: selectBuild.value, classId: selectClass.value, studentId: selectStudent.value} })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Пользователи.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    return (
        <Modal id="createProjectModal" isOpen={openModalUploadParentAccountData}
               toggle={() => setOpenModalUploadParentAccountData(!openModalUploadParentAccountData)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setOpenModalUploadParentAccountData(!openModalUploadParentAccountData)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Получить родителей КШП
            </ModalHeader>
            <ModalBody>
                <Alert color="danger">ПАРОЛИ БУДУТ ОБНОВЛЕНЫ!</Alert>
                <Row>
                    <Col sm={12}>
                        <label htmlFor="">Школа</label>
                        <Select
                            options={schools?.length > 0 ? [{
                                value: '',
                                label: 'Не выбрано'
                            }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                            value={selectSchool}
                            onChange={(change: any) => {
                                onChangeSchool({value: change.value, label: change.label})
                            }}
                            styles={customStyles}
                            placeholder="Поиск"
                            name="name"
                            isLoading={loadingOptions}
                            onMenuScrollToBottom={handleMenuScroll}
                        />
                    </Col>
                    <Col sm={12}>
                        <label htmlFor="">Корпус</label>
                        <Select
                            options={builds?.length > 0 ? [{
                                value: '',
                                label: 'Не выбрано'
                            }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                            value={selectBuild}
                            onChange={(change: any) => {
                                onChangeBuilding({value: change.value, label: change.label})
                            }}
                            isLoading={loadingOptionsBuildings}
                            styles={customStyles}
                            placeholder="Поиск"
                            name="name"
                        />
                    </Col>
                    <Col sm={12}>
                        <label htmlFor="">Класс</label>
                        <Select
                            options={classes?.length > 0 ? [{
                                value: '',
                                label: 'Не выбрано'
                            }, ...classes?.map(v => ({value: v.id, label: v.name}))] : [{
                                value: '',
                                label: 'Не выбрано'
                            }]}
                            value={selectClass}
                            onChange={(change: any) => {
                                onChangeClass({value: change.value, label: change.label})
                            }}
                            isLoading={loadingOptionsBuildings}
                            styles={customStyles}
                            placeholder="Поиск"
                            name="name"
                        />
                    </Col>
                    <Col>
                        <label htmlFor="">Ученик</label>
                        <Select
                            options={students?.length > 0 ? [{
                                value: '',
                                label: 'Не выбрано'
                            }, ...students?.map(v => ({value: v.id, label: v.name}))] : [{
                                value: '',
                                label: 'Не выбрано'
                            }]}
                            value={selectStudent}
                            onChange={(change: any) => {
                                setSelectStudent({value: change.value, label: change.label})
                            }}
                            onMenuScrollToBottom={handleMenuScrollStudents}
                            isLoading={loadingOptionsBuildings || loadingOptions}
                            styles={customStyles}
                            placeholder="Поиск"
                            name="name"
                        />
                    </Col>
                </Row>
                <button className="btn btn-primary w-100 mt-2" onClick={() => handleExport()}>Получить родителей КШП</button>
            </ModalBody>
        </Modal>
    );
};

export default ModalUploadParentAccountData;
