import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup'
import WidgetService from "../../../api/services/widgetService/WidgetService";
import Select from "react-select";

interface IModalAddDefect {
    addDefect: boolean,
    setAddDefect: (addDefect: boolean) => void,
    setAddNewDefect: (defect: object) => void,
    compl: any
}
const ModalAddDefect:FC<IModalAddDefect> = ({addDefect, setAddDefect, setAddNewDefect, compl}) => {

    const [materials, setMaterials] = useState<any[]>([]);
    const [selectCompl, setSelectCompl] = useState<any | null>(null);

    useEffect(() => {
        WidgetService.getListMaterials()
            .then(r => setMaterials(r.data))
        setSelectCompl({value: compl.uid, label: compl.name})
    }, []);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }

    return (
        <Modal id="createProjectModal" isOpen={addDefect}
               toggle={() => setAddDefect(!addDefect)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddDefect(!addDefect)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновить комплектующие
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            uid: materials[0]?.uid,
                            count: compl.count,
                        }
                    }
                    validationSchema={
                        Yup.object({
                            count: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        setAddNewDefect({
                            index: compl.index,
                            name: selectCompl.label,
                            uid: selectCompl.value,
                            count: values.count
                        })
                        setAddDefect(false)
                    }}>
                    {
                        ({handleChange}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Название:</label>
                                        <Select options={materials.map(object => ({value: object.uid, label: object.name}))}
                                                value={selectCompl}
                                                onChange={(change: any) => {
                                                    setSelectCompl({value: change.value, label: change.label})
                                                    handleChange('uid')(change.value)
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Колличество:</label>
                                        <Field name="count" className="form-control"/>
                                        <ErrorMessage name="count"/>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <button type="submit" className="btn btn-primary" id="addNewProject">Обновить</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddDefect;
