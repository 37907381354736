import React, {FC, useEffect, useState} from 'react';
import {ISchool} from "../../../models/KSHP/ISchool";
import moment from "moment";
import AxiosClient from "../../../api/AxiosClient";
import {Col, Input, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles} from "../../settings";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import * as Yup from "yup";
import Notification from "../../widgets/Notification";
import {ICateringOrganizer} from "../../../models/KSHP/ICateringOrganizer";

interface IModalImportFileStudents {
    importFile: boolean,
    setImportFile(value: boolean) : any,
    setTableConfirmationImport(value: any) : any,
    setListImport(value: any[]) : any,
}

const ModalImportFileStudents: FC<IModalImportFileStudents> = ({importFile, setImportFile, setTableConfirmationImport, setListImport}) => {
    const dispatch = useAppDispatch();
    const [schools, setSchools] = useState<ISchool[]>([]);
    const [builds, setBuilds] = useState<any[]>([]);
    const [selectSchool, setSelectSchool] = useState<{ value: string, label: string }>({ value: '', label: 'Не выбрано' })
    const [selectBuild, setSelectBuild] = useState<any>({ value: '', label: 'Не выбрано' });
    const [selectCateringOrganizers, setSelectCateringOrganizers] = useState<any>({ value: '', label: 'Не выбрано' });
    const [file, setFile] = useState<any>();
    const [dateRecord, setDateRecord] = useState(moment().startOf('day').toDate());
    const [schoolsOption, setSchoolsOption] = useState<ISchool[]>([])
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [cateringOrganizers, setCateringOrganizers] = useState<ICateringOrganizer[]>([]);

    useEffect(() => {
        AxiosClient.get<ICateringOrganizer[]>('/users/catering_organizers')
            .then((r) => setCateringOrganizers(r.data))
            .catch(() => console.log('error'))
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchoolsOption(r)
            })
        setSelectBuild({ value: '', label: 'Не выбрано' })
    }, [])

    useEffect(() => {
        if (selectSchool?.value){
            AxiosClient.get(`/schools/${selectSchool.value}/buildings`)
                .then((r) => {
                    setBuilds(r.data)
                })
        }
    }, [selectSchool.value, schools])


    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchoolsOption(prevOptions => [...prevOptions, ...newData]);
    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schoolsOption.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    return (
        <>
        <Modal id="createProjectModal" isOpen={importFile}
               toggle={() => setImportFile(!importFile)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setImportFile(!importFile)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Импорт файла студентов
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            dateRecord: '',
                            school: selectSchool.value,
                            build: selectBuild.value,
                            catering_organizer_id: selectCateringOrganizers.value,
                            file: ''
                        }
                    }
                    validationSchema={
                        Yup.object({
                            school: Yup.string().required('Обязательное поле'),
                            build: Yup.string().required('Обязательное поле'),
                            catering_organizer_id: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true));
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('date', dateRecord.toLocaleDateString('en-CA'))
                        formData.append('school', selectSchool.value);
                        formData.append('build', selectBuild.value);
                        formData.append('catering_organizer_id', values.catering_organizer_id);

                        AxiosClient.post('/students/file_import_students', formData)
                            .then((r) => {
                                setImportFile(false);
                                setTableConfirmationImport("student");
                                setListImport(r.data);
                                dispatch(setIsSend(false))
                            })
                            .catch((error) => {
                                dispatch(setIsSend(false))
                                setNotificationType('error');
                                setNotificationMessage('Ошибка, проверьте корректность данных!');
                                setShowNotification(true);
                                console.log(error);
                            })
                    }}>
                    {
                        ({errors, touched, setFieldValue}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100" htmlFor="dateRecord" >Дата:
                                        <Flatpickr
                                            name="dateRecord"
                                            id="dateRecord"
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateRecord}
                                            options={{
                                                locale: Russian,
                                                dateFormat: "d.m.Y"
                                            }}
                                            onChange={ (date) => setDateRecord(date[0])}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label  className="w-100">Организатор питания:
                                        <Field name="catering_organizer_id">
                                               {() => (
                                                   <Select
                                                       options={schoolsOption?.length > 0 ? [{
                                                           value: '',
                                                           label: 'Не выбрано'
                                                       }, ...cateringOrganizers.map(v => ({ value: v.id.toString(), label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                                                       value={selectCateringOrganizers}
                                                       onChange={(change: any) => {
                                                           setSelectCateringOrganizers({ value: change.value, label: change.label })
                                                       }}
                                                       styles={customStyles}
                                                       className={`${errors.catering_organizer_id && touched.catering_organizer_id && "border rounded border-danger"}`}
                                                       isLoading={loadingOptions}
                                                       placeholder="Поиск"
                                                       name="catering_organizer_id"
                                                       onMenuScrollToBottom={handleMenuScroll}
                                                   />
                                               )}
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Школа:
                                        <Field name="school">
                                            {() => (
                                                <Select
                                                    options={schoolsOption?.length > 0 ? [{
                                                        value: '',
                                                        label: 'Не выбрано'
                                                    }, ...schoolsOption.map(v => ({ value: v.id.toString(), label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                                                    value={selectSchool}
                                                    onChange={(change: any) => {
                                                        setSelectSchool({ value: change.value, label: change.label })
                                                    }}
                                                    styles={customStyles}
                                                    className={`${errors.school && touched.school && "border rounded border-danger"}`}
                                                    isLoading={loadingOptions}
                                                    placeholder="Поиск"
                                                    name="school"
                                                    onMenuScrollToBottom={handleMenuScroll}
                                                />
                                            )}
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Корпус:
                                        <Field name="build">
                                            {() => (
                                                <Select
                                                    options={builds?.length > 0 ? [{
                                                        value: '',
                                                        label: 'Не выбрано'
                                                    }, ...builds?.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                                                    value={selectBuild}
                                                    onChange={(change: any) => {
                                                        setSelectBuild({ value: change.value, label: change.label })
                                                    }}
                                                    styles={customStyles}
                                                    className={`${errors.build && touched.build && "border rounded border-danger"}`}
                                                    placeholder="Поиск"
                                                    name="build"
                                                />
                                            )}
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100" htmlFor="file">Файл (.xlsx):
                                        <Input
                                            name="file"
                                            id="file"
                                            type="file"
                                            className="mb-2"
                                            accept=".xlsx"
                                            onChange={(e: any)=>setFile(e.target.files[0])}
                                        ></Input>
                                        </label>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <SubmitWithLoading text={'Готово'}/>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
    </>
);
};

export default ModalImportFileStudents;
