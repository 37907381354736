import React, {useEffect, useState} from 'react';
import {Alert, Container, Row} from "reactstrap";
import SelectToUpdateOrganization from "../../components/admin/organization/SelectToUpdateOrganization";
import OrganizationAdd from "../../components/admin/organization/OrganizationAdd";
import OrganizationUpdate from "../../components/admin/organization/OrganizationUpdate";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getOrganizations, getRegions} from "../../store/reducers/admin/ActionCreator";

const SettingsOrganization = () => {

    const dispatch = useAppDispatch();
    const {organizations, isError} = useAppSelector(state => state.AdminReducer)
    const [addOrganization, setAddOrganization] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getOrganizations())
        dispatch(getRegions())
    }, [dispatch]);


    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdateOrganization
                        setAddOrganization={(val) => setAddOrganization(val)}
                        organizations={organizations}/>
                    <div className="w-100 overflow-hidden mt-2 mt-lg-0 mt-xl-0 mt-xxl-0">
                        <div className="chat-content d-lg-flex">
                            <div className="w-100 overflow-hidden position-relative">
                                <div className="position-relative">
                                    <div className="position-relative mt-5" id="users-chat">
                                        <Row>
                                            {
                                                addOrganization ? <div className="mt-n5"><OrganizationAdd /></div>  : <OrganizationUpdate />
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsOrganization;
