import { FC, useEffect, useState } from "react";
import {Modal, ModalBody, ModalHeader, ModalFooter} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";
import {customStyles} from "../../settings";
import Select from "react-select";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";

interface IModalAddParent {
    addParent: boolean,
    setAddParent: (value: boolean) => void,
    students: any[],
    setStudents: (value: any[]) => void,
    studentId: string | number
}

const ModalAddParent: FC<IModalAddParent> = ({setAddParent, addParent, setStudents, studentId, students}) => {

    const [users, setUsers] = useState<any[]>([]);
    const [selectUser, setSelectUser] = useState({value: '', label: ''});
    const dispatch = useAppDispatch()
    useEffect(() => {
        AxiosClient.get('/users/users_for_add_children')
            .then((r) => {
                setUsers(r.data)
                r.data[0]?.id &&
                setSelectUser({value: r.data[0].id, label: `${r.data[0].middle_name} ${r.data[0].name} ${r.data[0].last_name}`})
            })
    }, []);

    const saveParent = () => {
        dispatch(setIsSend(true))
        AxiosClient.post('/students/add_parent', {id: studentId, idUser: selectUser.value})
            .then((r) => {
                dispatch(setIsSend(false))
                setStudents(students.map(s => {
                    if(s.id.toString() === studentId.toString()){
                        return {
                            ...s,
                            parents: r.data
                        }
                    } else return s
                }))
                setAddParent(false)
            })
            .catch(() => dispatch(setIsSend(false)))
    }

    return (
        <Modal id="createProjectModal" isOpen={addParent}
            toggle={() => setAddParent(!addParent)}
            modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddParent(!addParent)}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel">
                Добавить родителя
            </ModalHeader>
            <ModalBody>
            <Select
                    options={users?.map(v => ({value: v.id, label: `${v.middle_name ? v.middle_name : ''} ${v.name} ${v?.last_name ? v?.last_name : ''}`}))}
                    value={selectUser}
                    onChange={(change: any) => {
                        setSelectUser({value: change.value, label: change.label})
                    }}
                    styles={customStyles}
                    placeholder="Поиск"
                    name="name"
                    className="mt-3"
                />
            </ModalBody>
            <ModalFooter className="justify-content-start">
                <div className="hstack gap-2">
                    <SubmitWithLoading text={"Добавить"} onClick={() => saveParent()} />
                </div>
            </ModalFooter>
        </Modal>

    )
}

export default ModalAddParent;