import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {IOrganization} from "../../../models/IOrganization";

class OrganizationService {
    static async  getOrganizations(): Promise<AxiosResponse<IOrganization[]>>{
        return $api.post("/organizations");
    }

    static async addOrganization(value: object): Promise<AxiosResponse<void>>{
        return $api.post("/organizations/add", value);
    }

    static async updateOrganization(value: object, organizationId: number): Promise<AxiosResponse<void>>{
        return $api.post(`/organizations/update/${organizationId}`, value);
    }
    static async updatePasswordOrganization(value: object, organizationId: number): Promise<AxiosResponse<void>>{
        return $api.post(`/organizations/update/password/${organizationId}`, value);
    }
}

export default OrganizationService;