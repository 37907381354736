import React, { FC, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import AxiosClient from "../../../api/AxiosClient"
import moment from 'moment'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup"
import Cookie from "../../../Cookie";
import CheckPayInfo from "./CheckPayInfo";
import SuccessPayInfo from "./SuccessPayInfo";
import CancelPayInfo from "./CancelPayInfo";
import NoTimePayInfo from "./NoTimePayInfo";
interface IAddPayments {
    addPayment: boolean,
    setAddPayment: (value: boolean) => void
    selectChildren: string | number
    updateData: () => void
    updateElemPay: () => void
}

const AddPayments: FC<IAddPayments> = ({ setAddPayment, addPayment, updateData, selectChildren, updateElemPay }) => {
    const [selectPersonalAccount, setSelectPersonalAccount] = useState('')
    const [personalAccounts, setPersonalAccounts] = useState<any[]>([])
    const [balance, setBalance] = useState(0)
    const [maxDeposit, setMaxDeposit] = useState(0)
    const [orderId, setOrderId] = useState<null | string>(null)
    const [countRequest, setCountRequest] = useState(0);
    const [typePay, setTypePay] = useState<'idle' | 'pending' | 'success' | 'cancel' | 'noTime'>('idle')

    const statusPayment = {
        pending: <CheckPayInfo/>,
        success: <SuccessPayInfo/>,
        cancel: <CancelPayInfo/>,
        noTime: <NoTimePayInfo/>,
        idle: <></>
    }

    useEffect(() => {
        selectChildren &&
            AxiosClient.get('/students/pesonal_accounts', { params: { id: selectChildren } })
                .then(r => {
                    setSelectPersonalAccount(r.data.find((pa: any) => moment(pa.date_begin).toDate() <= moment().toDate() && (moment(pa.date_end).toDate() <= moment().toDate() || !pa.date_end)).id)
                    setPersonalAccounts(r.data)
                })
                .catch(() => console.log('error'))
    }, [selectChildren])

    useEffect(() => {
        selectPersonalAccount &&
            AxiosClient.get('/personal_accounts/balance_for_pesonal_account', { params: { id: selectPersonalAccount } })
                .then(r => {
                    setBalance(r.data.balance)
                    setMaxDeposit(r.data.max_deposit)
                })
                .catch(() => console.log('error'))
    }, [selectPersonalAccount])

    useEffect(() => {
        const id = Cookie.getCookie('orderId')
        if (id) {
            setOrderId(id)
            setTypePay('pending')
        }
    }, [])

    useEffect(() => {
        if (orderId && typePay === "pending") {
            let count = 0
            const checkPaymentStatus = () => {
                AxiosClient.post('/sber/check_pay', { orderId: orderId, personalAccount: Cookie.getCookie('pa') })
                    .then((r) => {
                        if (r.data.orderStatus === 2) {
                            setTypePay('success');
                            updateData()
                            updateElemPay()
                        }
                        if (r.data.orderStatus === 6) {
                            setTypePay('noTime');
                            updateData()
                            updateElemPay()
                        }
                        else {
                            if (count < 4){
                                count += 1;
                            } else {
                                setTypePay('cancel');
                                updateElemPay()
                            }
                        }
                    })
                    .catch(() => console.log('error checking payment status'));
            };

            const timer = setInterval(checkPaymentStatus, 10000);

            // Функция очистки для остановки интервала, когда компонент размонтируется или изменятся зависимости
            return () => clearInterval(timer);
        }
    }, [orderId, typePay])

    const toggle = () => {
        if (typePay === 'success' || typePay === 'cancel' || typePay === 'noTime'){
            setTypePay('idle')
            Cookie.deleteCookie('orderId')
            Cookie.deleteCookie('pa')
            setOrderId(null);
        }
    }

    return (
        <>
            <Modal id="createProjectModal" isOpen={addPayment}
                toggle={() => setAddPayment(!addPayment)}
                modalClassName="zoomIn" tabIndex={-1} centered>
                <ModalHeader toggle={() => setAddPayment(!addPayment)}
                    className="p-3 bg-soft-primary"
                    id="createProjectModalLabel">
                    Оплата
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            personalAccount: selectPersonalAccount,
                            deposit: maxDeposit
                        }}
                        validationSchema={
                            Yup.object({
                                deposit: Yup.number().typeError('Только числа').max(maxDeposit, `Максимальный сумма пополнения ${maxDeposit}`).required('Обязательное поле')
                            })
                        }
                        onSubmit={(value) => {
                            AxiosClient.post('/sber/pay', { ...value })
                                .then((r) => {
                                    if (r.data.errorCode === "0") {
                                        Cookie.setCookie('orderId', r.data.orderId)
                                        Cookie.setCookie('pa', value.personalAccount)
                                        //document.cookie = "orderId=" + r.data.orderId
                                        window.location.href = r.data.formUrl
                                    }
                                })
                        }}
                    >
                        {
                            ({ errors, touched }) => (
                                <Form>
                                    <div>
                                        <label className="w-100">Лицевой счет:
                                        <Field name="personalAccounts" as="select" className='form-select'>
                                            {
                                                personalAccounts.map(pa => <option key={pa.id} value={pa.id}>{pa.personal_account}</option>)
                                            }
                                        </Field>
                                        </label>
                                    </div>
                                    <div className="mt-1 fs-5">
                                        <p>Баланс: {balance}</p>
                                    </div>
                                    <div>
                                        <label className="w-100">Сумма пополнения (Макс. сумма пополнения: {maxDeposit}):
                                            <Field type="number" className={`form-control ${errors.deposit && touched.deposit && "border border-2 border-danger"}`} name="deposit" />
                                        </label>
                                        <ErrorMessage name="deposit" component="div" className="text-danger"></ErrorMessage>
                                    </div>
                                    <div>
                                        <button type='submit' className='btn btn-primary mt-2'>Продолжить</button>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            <Modal isOpen={(typePay === "pending" || typePay === "success" || typePay === "cancel" || typePay === "noTime") && !!Cookie.getCookie('orderId')}
                toggle={() => toggle()}
                modalClassName="zoomIn" tabIndex={-1} centered>
                <ModalHeader toggle={() => toggle()}
                    className="p-3 bg-soft-primary">
                    Статус оплаты
                </ModalHeader>
                <ModalBody>
                    {
                        statusPayment[typePay]
                    }
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddPayments
