import {AxiosResponse} from "axios";
import {IUser} from "../../../models/IUser";
import $api from "../../AxiosClient";

class UserService{
    static async  getUser(): Promise<AxiosResponse<any>>{
        return $api.get("/user");
    }

    static async getUsersOrganization(): Promise<AxiosResponse<any>>{
        return $api.get("/users/organization");
    }

    static async  updateUserProfile(userId: number, value: any): Promise<AxiosResponse<any>>{
        return $api.post(`/update/user/profile/${userId}`, value);
    }

    static async getUsers(page?: number, name?: string, schoolId?: string | number, buildId?: string | number, termId?: string | number): Promise<AxiosResponse<IUser[]>>{
        return $api.get("/users", {params: {page, searchName: name, selectSchoolId: schoolId, buildId, termId}});
    }

    static async addUser(data: FormData): Promise<AxiosResponse<any>>{
        return $api.post("/create", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    static async updateUserImage(data: FormData, idUser: number): Promise<AxiosResponse<any>>{
        return $api.post(`/user/update-image/${idUser}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    static async updateUser(value: object, idUser: number): Promise<AxiosResponse<any>>{
        return $api.post(`/update/user/${idUser}`, value)
    }

    static async updatePasswordUser(value: object, idUser: number): Promise<AxiosResponse<any>>{
        return $api.post(`/user/update-password/${idUser}`, value)
    }
}

export default UserService;