import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMoneySpent} from "../../../models/parent/IMoneySpent";
import {IChildren} from "../../../models/parent/IChildren";
import {IAllDeviationChild} from "../../../models/parent/IAllDeviationChild";
import {IAllAttendance} from "../../../models/parent/IAllAttendance";
import {IDeviationChild} from "../../../models/parent/IDeviationChild";
import {IEvolutionChild} from "../../../models/parent/IEvolutionChild";
import {IAllInfoEvolution} from "../../../models/parent/IAllInfoEvolution";
import {IDateEvolution} from "../../../models/parent/IDateEvolution";
import {IFoodRation} from "../../../models/parent/IFoodRation";
import {IPFCFoodRation} from "../../../models/parent/IPFCFoodRation";
import {IAllIPFCFoodRation} from "../../../models/parent/IAllIPFCFoodRation";
import {ICashAccountingTotal} from "../../../models/parent/ICashAccountingTotal";
import {ICashAccounting} from "../../../models/parent/ICashAccounting";

interface IInitialState {
    isLoading: boolean,
    isError: string,
    dateMonth: Date
    moneySpent: IMoneySpent[],
    child: string,
    children: IChildren[],
    allDeviationChild: IAllDeviationChild[],
    allAttendance: IAllAttendance[],
    deviationChild: IDeviationChild[],
    allDeviationChildPaid: IAllDeviationChild[],
    allAttendancePaid: IAllAttendance[],
    deviationChildPaid: IDeviationChild[],
    evolutionChild: IEvolutionChild[],
    allInfoEvolutionChild: IAllInfoEvolution[],
    evolutionWays: string[],
    datesEvolution: IDateEvolution[],
    selectDatesEvolution: IDateEvolution[],
    foodRation: IFoodRation[],
    pfcFoodRation: IPFCFoodRation[],
    allPfcFoodRation: IAllIPFCFoodRation[],
    cashAccountingTotal: ICashAccountingTotal[],
    cashAccounting: ICashAccounting[],
    cashAccountingGraph: ICashAccounting[]

}

const initialState: IInitialState = {
    isLoading: false,
    isError: '',
    dateMonth: new Date(),
    moneySpent: [],
    child: '',
    children: [],
    allDeviationChild: [],
    allAttendance: [],
    deviationChild: [],
    allDeviationChildPaid: [],
    allAttendancePaid: [],
    deviationChildPaid: [],
    evolutionChild: [],
    allInfoEvolutionChild: [],
    evolutionWays: [],
    datesEvolution: [],
    selectDatesEvolution: [],
    foodRation: [],
    pfcFoodRation: [],
    allPfcFoodRation: [],
    cashAccountingTotal: [],
    cashAccounting: [],
    cashAccountingGraph: []
}

export const ParentSlice = createSlice({
    name: "parent",
    initialState,
    reducers: {
        dataLoading (state){
            state.isLoading = true;
        },
        dataLoadingError(state, action: PayloadAction<string> ){
            state.isLoading = false;
            state.isError = action.payload
        },
        setDate(state, action: PayloadAction<Date>){
          state.dateMonth = action.payload;
        },
        dataSuccessMoneySpent(state, action: PayloadAction<IMoneySpent[]>){
            state.isLoading = false;
            state.isError = '';
            state.moneySpent = action.payload
        },
        setChild(state, action: PayloadAction<string>){
            state.child = action.payload;
        },
        dataSuccessChildren(state, action: PayloadAction<IChildren[]>){
            state.isLoading = false;
            state.isError = '';
            state.children = action.payload
        },
        dataSuccessAllDeviationChild(state, action: PayloadAction<IAllDeviationChild[]>){
            state.isLoading = false;
            state.isError = '';
            state.allDeviationChild = action.payload
        },
        dataSuccessAllAttendance(state, action: PayloadAction<IAllAttendance[]>){
            state.isLoading = false;
            state.isError = '';
            state.allAttendance = action.payload
        },
        dataSuccessDeviationChild(state, action: PayloadAction<IDeviationChild[]>){
            state.isLoading = false;
            state.isError = '';
            state.deviationChild = action.payload
        },
        dataSuccessAllDeviationChildPaid(state, action: PayloadAction<IAllDeviationChild[]>){
            state.isLoading = false;
            state.isError = '';
            state.allDeviationChildPaid = action.payload
        },
        dataSuccessAllAttendancePaid(state, action: PayloadAction<IAllAttendance[]>){
            state.isLoading = false;
            state.isError = '';
            state.allAttendancePaid = action.payload
        },
        dataSuccessDeviationChildPaid(state, action: PayloadAction<IDeviationChild[]>){
            state.isLoading = false;
            state.isError = '';
            state.deviationChildPaid = action.payload
        },
        dataSuccessEvolutionChild(state, action: PayloadAction<IEvolutionChild[]>){
            state.isLoading = false;
            state.isError = '';
            state.evolutionChild = action.payload
        },
        dataSuccessAllInfoEvolutionChild(state, action: PayloadAction<IAllInfoEvolution[]>){
            state.isLoading = false;
            state.isError = '';
            state.allInfoEvolutionChild = action.payload
        },
        setEvolutionWay(state, action: PayloadAction<string[]>){
            state.isLoading = false;
            state.isError = '';
            state.evolutionWays = action.payload
        },
        dataSuccessDatesEvolution(state, action: PayloadAction<IDateEvolution[]>){
            state.isLoading = false;
            state.isError = '';
            state.datesEvolution = action.payload
        },
        setSelectDatesEvolution(state, action: PayloadAction<IDateEvolution[]>){
            state.isLoading = false;
            state.isError = '';
            state.selectDatesEvolution = action.payload
        },
        dataSuccessFoodRation(state, action: PayloadAction<IFoodRation[]>){
            state.isLoading = false;
            state.isError = '';
            state.foodRation = action.payload
        },
        dataSuccessPFCFoodRation(state, action: PayloadAction<IPFCFoodRation[]>){
            state.isLoading = false;
            state.isError = '';
            state.pfcFoodRation = action.payload
        },
        dataSuccessAllPFCFoodRation(state, action: PayloadAction<IAllIPFCFoodRation[]>){
            state.isLoading = false;
            state.isError = '';
            state.allPfcFoodRation = action.payload
        },
        dataSuccessCashAccountingTotal(state, action: PayloadAction<ICashAccountingTotal[]>){
            state.isLoading = false;
            state.isError = '';
            state.cashAccountingTotal = action.payload
        },
        dataSuccessCashAccounting(state, action: PayloadAction<ICashAccounting[]>){
            state.isLoading = false;
            state.isError = '';
            state.cashAccounting = action.payload
        },
        dataSuccessCashAccountingGraph(state, action: PayloadAction<ICashAccounting[]>){
            state.isLoading = false;
            state.isError = '';
            state.cashAccountingGraph = action.payload
        }
    }
})

export default ParentSlice.reducer