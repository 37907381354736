import React, {useEffect, useState} from 'react';
import {Alert, Col, Container, Row} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import SelectToUpdateCity from "../../components/admin/city/SelectToUpdateCity";
import CityAdd from "../../components/admin/city/CityAdd";
import CityUpdate from "../../components/admin/city/CityUpdate";
import {getCities} from "../../store/reducers/admin/ActionCreator";

const SettingsCity = () => {
    const dispatch = useAppDispatch();
    const {isError, cities} = useAppSelector(state => state.AdminReducer);
    const [addCity, setAddCity] = useState(false);

    useEffect(() => {
        dispatch(getCities())
    }, [dispatch])

    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdateCity
                        setAddCity={(val) => setAddCity(val)}
                        cities={cities}/>
                    <div className="w-100 overflow-hidden">
                        <div className="chat-content d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {
                                                    addCity ? <CityAdd /> : <CityUpdate/>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsCity;
