import React, {FC, useEffect, useState} from 'react';
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import TableContactWithBank from "./TableContactWithBank";
import ModalAddContactWithBank from "./ModalAddContactWithBank";
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classNames from "classnames";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import AxiosClient from "../../../api/AxiosClient";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import {ICateringOrganizer} from "../../../models/KSHP/ICateringOrganizer";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";

interface IFormUpdateCateringOrganizers {
    data: ICateringOrganizer | null,
    updateCateringOrganizers: boolean,
    setUpdateCateringOrganizers: (value: boolean) => void,
    setCateringOrganizers: (value: any[]) => void
}

const FormUpdateCateringOrganizers: FC<IFormUpdateCateringOrganizers> = ({
                                                                             data,
                                                                             updateCateringOrganizers,
                                                                             setUpdateCateringOrganizers,
                                                                             setCateringOrganizers
                                                                         }) => {

    const idButtonSave: string = 'buttonSaveFormUpdateCateringOrganizers';
    const [addContactWithBank, setAddContactWithBank] = useState(false);
    const [banksNew, setBanksNew] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState("1");
    const [date, setDate] = useState(new Date());
    const [withoutChangesBanksNew, setWithoutChangesBanksNew] = useState<any[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    const [newObject, setNewObject] = useState<any>();
    const dispatch = useAppDispatch();
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    useEffect(() => {
        data &&
        AxiosClient.get(`catering_organizaers/${data.id}/banks`)
            .then(r => {
                setBanksNew(r.data)
                setWithoutChangesBanksNew(r.data)
            })
        if (data?.timeBlockMenu) {
            const newDate = moment()
            newDate.set({
                hour: +data?.timeBlockMenu.split(':')[0],
                minute: +data?.timeBlockMenu.split(':')[1],
                second: +data?.timeBlockMenu.split(':')[2]
            })
            setDate(newDate.toDate())
        }

        setNewObject(data)
    }, [data]);

    const confirmOrExit = () => {
        if(!_.isEqual(data, newObject) || !_.isEqual(banksNew, withoutChangesBanksNew)){
            setConfirmExit(true);
        }else{
            setUpdateCateringOrganizers(false);
        }
    }

    return (
        <Modal id="createProjectModal" isOpen={updateCateringOrganizers}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered size="lg">
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление организатора питания
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            name: data?.name,
                            code: data?.identifier,
                            inn: data?.inn.toString(),
                            kpp: data?.kpp.toString(),
                            legal_address: data?.legal_address,
                            actual_address: data?.actual_address,
                            kbk: data?.kbk ? data?.kbk : '',
                            oktmo: data?.oktmo ? data?.oktmo : '',
                            okato: data?.okato ? data?.okato : '',
                            email: data?.email ? data?.email : '',
                            countDayNoUpdateMenu: data?.countDayNoUpdateMenu.toString(),
                            timeBlockMenu: data?.timeBlockMenu,
                            countDayUploadMenu: data?.countDayUploadMenu.toString(),
                            banks: [{}]
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                            kpp: Yup.number().typeError("Только числа").integer("Только целые числа").required('Обязательное поле'),
                            legal_address: Yup.string().required('Обязательное поле'),
                            inn: Yup.number().typeError("Только числа").integer("Только целые числа").required('Обязательное поле'),
                            actual_address: Yup.string().required('Обязательное поле'),
                            kbk: Yup.number().typeError("Только числа").integer("Только целые числа"),
                            oktmo: Yup.number().typeError("Только числа").integer("Только целые числа"),
                            okato: Yup.number().typeError("Только числа").integer("Только целые числа"),
                            countDayNoUpdateMenu: Yup.number().typeError("Только числа").integer("Только целые числа"),
                            countDayUploadMenu: Yup.number().typeError("Только числа").integer("Только целые числа").required('Обязательное поле')
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        values.timeBlockMenu = moment(date).format('H:mm:00').toString();
                        values.banks = banksNew

                        AxiosClient.post('/catering_organizaers/update/' + data?.id, values)
                            .then(() => {
                                dispatch(setIsSend(false))
                                setUpdateCateringOrganizers(false)
                                AxiosClient.get('/catering_organizaers')
                                    .then(r => setCateringOrganizers(r.data))
                            })
                            .catch(() => {
                                console.log('error')
                                dispatch(setIsSend(false))
                            })
                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Общие реквизиты
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Доп. настройки
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "3", "p-2": true})}
                                            onClick={() => {
                                                tabChange("3");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Связь с банком
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Код:
                                                        <Field name="code" className="form-control" disabled/>
                                                    </label>
                                                    <ErrorMessage name="code"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="flex-1">
                                                    <label title="Обязательное поле" className="w-100">Наименование *:
                                                    <Field name="name" className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`} onChange={(e: any) => {
                                                        setNewObject({...newObject, 'name': e.target.value});
                                                        setFieldValue('name', e.target.value);
                                                    }}/>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="flex-1">
                                                    <label title="Обязательное поле" className="w-100">Юридичееский адрес *:
                                                    <Field name="legal_address" className={`form-control ${errors.legal_address && touched.legal_address && "border border-2 border-danger"}`} onChange={(e: any) => {
                                                        setNewObject({...newObject, 'legal_address': e.target.value});
                                                        setFieldValue('legal_address', e.target.value);
                                                    }}/>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="flex-1">
                                                    <label title="Обязательное поле" className="w-100">Фактический адрес *:
                                                    <Field name="actual_address" className={`form-control ${errors.actual_address && touched.actual_address && "border border-2 border-danger"}`} onChange={(e: any) => {
                                                        setNewObject({...newObject, 'actual_address': e.target.value});
                                                        setFieldValue('actual_address', e.target.value);
                                                    }}/>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label title="Обязательное поле" className="w-100">КПП *:
                                                    <div className="position-relative">
                                                        <Field name="kpp" className={`form-control ${errors.kpp && touched.kpp && "border border-2 border-danger"}`} onChange={(e: any) => {
                                                            setNewObject({...newObject, 'kpp': e.target.value});
                                                            setFieldValue('kpp', e.target.value);
                                                        }}/>
                                                    </div></label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="flex-1">
                                                    <label title="Обязательное поле" className="w-100">ИНН *:
                                                    <Field name="inn" className={`form-control ${errors.inn && touched.inn && "border border-2 border-danger"}`} onChange={(e: any) => {
                                                        setNewObject({...newObject, 'inn': e.target.value});
                                                        setFieldValue('inn', e.target.value);
                                                    }}/>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="flex-1">
                                                    <label className="w-100">ОКТМО:
                                                    <Field name="oktmo" className={`form-control ${errors.oktmo && touched.oktmo && "border border-2 border-danger"}`} onChange={(e: any) => {
                                                        setNewObject({...newObject, 'oktmo': e.target.value});
                                                        setFieldValue('oktmo', e.target.value);
                                                    }}/>
                                                    </label>
                                                    <ErrorMessage name="oktmo">
                                                        {err => <p className="text-danger">{err}</p>}
                                                    </ErrorMessage>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">КБК:
                                                    <Field name="kbk" className={`form-control ${errors.kbk && touched.kbk && "border border-2 border-danger"}`} onChange={(e: any) => {
                                                        setNewObject({...newObject, 'kbk': e.target.value});
                                                        setFieldValue('kbk', e.target.value);
                                                    }}/>
                                                    </label>
                                                    <ErrorMessage name="kbk">
                                                        {err => <p className="text-danger">{err}</p>}
                                                    </ErrorMessage>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">ОКАТО:
                                                    <Field name="okato" className={`form-control ${errors.kbk && touched.kbk && "border border-2 border-danger"}`} onChange={(e: any) => {
                                                        setNewObject({...newObject, 'okato': e.target.value});
                                                        setFieldValue('okato', e.target.value);
                                                    }}/>
                                                    </label>
                                                    <ErrorMessage name="okato">
                                                        {err => <p className="text-danger">{err}</p>}
                                                    </ErrorMessage>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col>
                                                <div className="flex-1">
                                                    <label className="w-100">Email:
                                                    <Field name="email" className="form-control" onChange={(e: any) => {
                                                        setNewObject({...newObject, 'email': e.target.value});
                                                        setFieldValue('email', e.target.value);
                                                    }}/>
                                                    </label>
                                                    <ErrorMessage name="email"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="mt-1">
                                            <SubmitWithLoading id={idButtonSave} text={"Сохранить"}/>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <Row className="m-0">
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <div className="flex-1">
                                                            <label className="w-100">Количество рабочих дней, для запрета редактирования меню:
                                                            <Field name="countDayNoUpdateMenu" className="form-control" onChange={(e: any) => {
                                                                setNewObject({...newObject, 'countDayNoUpdateMenu': e.target.value});
                                                                setFieldValue('countDayNoUpdateMenu', e.target.value);
                                                            }}/>
                                                            </label>
                                                        </div>
                                                        <div className="flex-1">
                                                            <label className="w-100">Время блокировки меню:
                                                            <Flatpickr
                                                                className="form-control border-0 dash-filter-picker shadow"
                                                                value={date}
                                                                options={{
                                                                    locale: Russian,
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                }}
                                                                onChange={(date) => {
                                                                    setNewObject({...newObject, 'timeBlockMenu': date[0]});
                                                                    setDate(date[0]);
                                                                }}
                                                            />
                                                            </label>
                                                        </div>
                                                        <div className="flex-1">
                                                            <label className="w-100">Количество открытых дней, для оповещения о загруженных комплексах *:
                                                            <Field name="countDayUploadMenu" className={`form-control ${errors.countDayUploadMenu && touched.countDayUploadMenu && "border border-2 border-danger"}`} onChange={(e: any) => {
                                                                setNewObject({...newObject, 'countDayUploadMenu': e.target.value});
                                                                setFieldValue('countDayUploadMenu', e.target.value);
                                                            }}/>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="3" className="align-items-center">
                                        <TableContactWithBank setBanksNew={value => setBanksNew(value)} banksNew={banksNew}/>
                                        <Col sm={12} lg={4} xxl={4}>
                                            <button type="button" className="btn btn-primary w-100 mt-1" id="addNewProject"
                                                    onClick={() => setAddContactWithBank(true)}>Добавить банк
                                            </button>
                                        </Col>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
                {
                    addContactWithBank &&
                    <ModalAddContactWithBank setBanksNew={(value) => setBanksNew([...banksNew, value])}
                                             addContactWithBank={addContactWithBank}
                                             setAddContactWithBank={(value) => setAddContactWithBank(value)}/>
                }
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setUpdateCateringOrganizers(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal>

    );
};

export default FormUpdateCateringOrganizers;
