import React, {FC, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface INotification {
    type: string,
    message: string,
    setShowNotification(value: boolean) : any
}

const Notification: FC<INotification> = ({type, message, setShowNotification}) => {
    useEffect(() => {
        const notify = () => {
            if (type === 'success') {
                toast.success(message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            }
            else if (type === 'info') {
                toast.info(message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            } else {
                toast.error(message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            }
            setTimeout(() => {
                setShowNotification(false);
            }, 5000);
        };
        notify();
    }, [type, message])

    return (
        <ToastContainer/>
    );
};

export default Notification;
