import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import ModalUpdateZamer from "../ModalUpdateZamer";
interface ITableDefect {
    compl: any,
    setCompl: (data: any[]) => void
}
const TableDefect: FC<ITableDefect> = ({compl, setCompl}) => {

    const [update, setUpdate] = useState(false);
    const [zamer, setZamer] = useState<any>({});

    function deleteZamers(i: number){
        const newZamers = compl.filter((data: any, indexElem: number) => indexElem !== i)
        setCompl(newZamers)
    }

    const columns: any = [
        {
            name: <span className='font-weight-bold fs-13'>Название</span>,
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Замер</span>,
            selector: (row: any) => row.count,
        },
        {
            selector: (row: any, i: number) => (
                <div className="col-auto">
                    <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                        <div className="text-center">
                            <Link to="#" className="text-black"><i className="bx bx-trash fs-24" onClick={() => deleteZamers(i)}></i></Link>
                        </div>
                    </div>
                </div>
            ),
        }
    ];
    function updateData(data: any) {
        const newData = compl.map((zam: any, i: number) => {
            if (i === data.index){
                return {
                    name: data.name,
                    uid: data.uid,
                    count: data.count
                }
            }
            return zam
        })
        setCompl(newData)
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={compl}
                noDataComponent={'Нет замеров'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                onRowClicked={(row: any) => {
                    setZamer({...row, index: compl.findIndex((zam: any) => zam.uid === row.uid)})
                    setUpdate(true)
                }}
            />
            {
                update &&
                <ModalUpdateZamer addDefect={update} setAddDefect={(data) => setUpdate(data)} setAddNewDefect={updateData} zamer={zamer}/>
            }
        </>
    );
};

export default TableDefect;
