import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import TableFoodRation from "../../components/KShP/Buffet/TableFoodRation";
import AxiosClient from "../../api/AxiosClient";

const Buffet = () => {
    const [date, setDate] = useState<Date[]>([new Date(), new Date()]);
    const [children, setChildren] = useState<any[]>([]);
    const [selectChildren, setSelectChildren] = useState('');

    useEffect(() => {
        AxiosClient.get('/users/students')
            .then(r => {
                setChildren(r.data)
                r.data.length > 0 && setSelectChildren(r.data[0].id)
            })
            .catch(() => console.log('error'))
    }, []);


    return (
        <div className="page-content">
            <Container fluid>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Буфет</title>
                </Helmet>
                <Row>
                    <Col xxl={3}>
                        <div className="col-auto mb-3">
                            <div className="input-group">
                                <Flatpickr
                                    className="form-control border-0 dash-filter-picker shadow"
                                    value={date}
                                    options={{
                                        locale: Russian, dateFormat: "d m Y", mode: 'range'
                                    }}
                                    onClose={(date) => {
                                        setDate(date)
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <Card>
                                <CardBody className="fs-18 ">
                                    Ребенок:
                                    <select className="form-select mt-1" value={selectChildren}
                                            onChange={event => setSelectChildren(event.target.value)}>
                                        {
                                            children.map(child =>
                                                (<option key={'ch'+child.id} value={child.id}>{child.name}</option>)
                                            )
                                        }
                                    </select>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h5>Меню на день</h5>
                            </CardHeader>
                            <CardBody>
                                <TableFoodRation selectChildren={selectChildren} date={date}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Buffet;
