import React, {FC, memo, useEffect} from 'react';
import {FormRoutes, PrivateRoutes, PublicRoutes} from "../routes/routes";
import {Routes, Route, Navigate} from "react-router-dom";
import {ConstRoutes} from "../routes/constRoutes";
import {useStateContext} from "../hooks/useStateContext";
import AuthService from "../api/services/authService/AuthService";
import SideBarMain from "./layouts/sideBar/SideBarMain";
import Lockscreen from "../pages/Lockscreen";

const AppRoutes: FC = () => {

    const {token, setUser, lockscreen, user} = useStateContext()

    useEffect(() => {
        if (token)
            AuthService.getUser()
                .then(({data}) => {
                    setUser(data)
                })
    }, [setUser, token])
    return (
        <>
            {
                !token ?
                    <Routes>
                        {
                            FormRoutes.map(route => {
                                return <Route key={route.path} path={route.path} element={route.component}/>
                            })
                        }
                        {
                            PublicRoutes.map(route => {
                                return <Route key={route.path} path={route.path} element={route.component}/>
                            })
                        }
                        <Route path="*" element={<Navigate to={ConstRoutes.LOGIN}/>}/>
                    </Routes>
                    :
                    lockscreen === "true" ?
                    <Lockscreen/> :
                    <Routes>
                        {
                            PrivateRoutes.map(route => {
                                return user.term?.pages?.map((routeUser) => {
                                    if (routeUser.path === route.path){
                                        return <Route key={route.path} path={route.path} element={
                                            <SideBarMain>
                                                {route.component}
                                            </SideBarMain>
                                        }/>
                                    }
                                    return null
                                })
                            })
                        }
                         <Route path="*" element={<Navigate to={user?.term?.base_url}/>}/>
                    </Routes>
            }
        </>


    );
};

export default memo(AppRoutes);
