import React from 'react';
import useWidth from "../../../hooks/useWidth";
import Desktop from "./Desktop";
import Phone from "./Phone";

const DevicesChat = () => {
    const {width} = useWidth();
    return (
        width > 991.8
        ?
            <Desktop/>
            :
            <Phone/>
    );
};

export default DevicesChat;
