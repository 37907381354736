import React, {FC, useState} from 'react';
import {CardBody} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalTeacherClasses from "./ModalTeacherClasses";
import AxiosClient from "../../../api/AxiosClient";

interface IListTeacher {
    teacher: any[],
    schoolId: string | number,
    setSchool: (value: any[]) => void,
}

const ListTeacher: FC<IListTeacher> = ({teacher, schoolId, setSchool}) => {

    const [openTeacherClasses, setOpenTeacherClasses] = useState(false);
    const [teacherId, setTeacherId] = useState('');

    const columns: any = [
        {
            name: <span className='font-weight-bold fs-14'></span>,
            selector: (row: any) => '',
            sortable: true,
            width: '48px'
        },
        {
            name: <span className='font-weight-bold fs-14'>Учитель</span>,
            selector: (row: any) => `${row.middle_name} ${row.name} ${row.last_name==null? '': row.last_name}`,
            sortable: true,
            style: {
                minWidth: '110px'
            }
        },
        {
            cell: (row: any) => <i className="bx bx-trash fs-3 cursor-pointer" title="Удалить" onClick={() => deleteUser(row.id.toString())}></i>,
            center: true,
            width: '60px'
        }
    ];

    const deleteUser = (id: string) => {
        AxiosClient.post('/schools/remove_user_school', {id: schoolId, idUser: id})
            .then((r) => {
                AxiosClient.get('/schools/get_users')
                    .then((r) => setSchool(r.data))
            })
    }


    return (
        <>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={teacher}
                    noDataComponent={'Нет данных'}
                    noTableHead
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                    onRowClicked={
                        (row) => {
                            if(window.innerWidth <= 768){
                                setOpenTeacherClasses(true)
                                setTeacherId(row.id)
                            }
                        }
                    }
                    onRowDoubleClicked={
                        (row) => {
                            if(window.innerWidth > 768){
                                setOpenTeacherClasses(true)
                                setTeacherId(row.id)
                            }
                        }
                    }
                />
            </CardBody>
            <ModalTeacherClasses schoolId={schoolId} teacherId={teacherId} openTeacherClasses={openTeacherClasses} setOpenTeacherClasses={value => setOpenTeacherClasses(value)}/>
        </>

    );
};

export default ListTeacher;
