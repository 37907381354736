import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Select from "react-select";
import WidgetService from "../../../api/services/widgetService/WidgetService";

interface IModalAddDefect {
    addDefect: boolean,
    setAddDefect: (addDefect: boolean) => void
    setAddNewDefect: (defect: object) => void
}
const ModalAddDefect:FC<IModalAddDefect> = ({addDefect, setAddDefect, setAddNewDefect}) => {

    const [typeDefect, setTypeDefect] = useState<any[]>([]);
    const [criticalDefect, setCriticalDefect] = useState<any[]>([]);
    const [defectCauses, setDefectCauses] = useState<any[]>([]);

    const [selectDefect, setSelectDefect] = useState<any>('');
    const [selectCriticalDefect, setSelectCriticalDefect] = useState<any>('');
    const [selectCause, setSelectCause] = useState<any>('');

    useEffect(() => {
        WidgetService.getListTypeDefect().then(r => setTypeDefect(r.data))
        WidgetService.getListCriticalDefect().then(r => setCriticalDefect(r.data))
        WidgetService.getListDefectCauses().then(r => setDefectCauses(r.data))
    }, []);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }

    return (
        <Modal id="createProjectModal" isOpen={addDefect}
               toggle={() => setAddDefect(!addDefect)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddDefect(!addDefect)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавить дефект
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            defect: typeDefect[0]?.uid,
                            status: criticalDefect[0]?.uid,
                            cause: defectCauses[0]?.uid,
                            description: ''
                        }
                    }
                    onSubmit={(values) => {
                        setAddNewDefect({
                            name_type_defect: typeDefect.find(def => def.uid === selectDefect).name,
                            type_defect_uid: selectDefect,
                            name_critical_defect: criticalDefect.find(def => def.uid === selectCriticalDefect).name,
                            critical_defect_uid: selectCriticalDefect,
                            name_cause_defect: defectCauses.find(def => def.uid === selectCause).name,
                            cause_defect_uid: selectCause,
                            description: values.description
                        })
                        setAddDefect(false)
                    }}>
                    {
                        ({}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Вид дефекта:</label>
                                        <Select options={typeDefect.map(defect => ({value: defect.uid, label: defect.name}))}
                                                name="defect"
                                                onChange={(change: any) => setSelectDefect(change.value)}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Критичность дефекта:</label>
                                        <Select options={criticalDefect.map(defect => ({value: defect.uid, label: defect.name}))}
                                                name="status"
                                                onChange={(change: any) => setSelectCriticalDefect(change.value)}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Причина дефекта:</label>
                                        <Select options={defectCauses.map(defect => ({value: defect.uid, label: defect.name}))}
                                                name="cause"
                                                onChange={(change: any) => setSelectCause(change.value)}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Причина дефекта:</label>
                                        <Field as="textarea" name="description" className="form-control" placeholder="Описание" />
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <button type="submit" className="btn btn-primary" id="addNewProject">Добавить</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddDefect;
