import React, {useState} from 'react';
import {Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classNames from "classnames";
import UpdateDataOrganizationForm from "./UpdateDataOrganizationForm";
import UpdatePasswordOrganizationForm from "./UpdatePasswordOrganizationForm";

const OrganizationUpdate = () => {
    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    return (
        <>
            <Col>
                <Row>
                    <Col xxl={12}>
                        <Card className="mt-n5 mx-n3">
                            <CardHeader>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1"})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Обновление организации
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2"})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Обновление пароля организации
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody className="p-4">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1" className="align-items-center">
                                        <UpdateDataOrganizationForm />
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <UpdatePasswordOrganizationForm/>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default OrganizationUpdate;
