import React, {useState} from 'react';
import {Alert, Button, Label} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup'
import AuthService from "../../api/services/authService/AuthService";
import {useStateContext} from "../../hooks/useStateContext";

const LockscreenForm = () => {
    const {setLockscreen} = useStateContext()
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [errorMes, setErrorMes] = useState<any>([false, ''])

    return (
        <>
            {
                errorMes[0] && <Alert color="danger">{errorMes[1]}</Alert>
            }
            <Formik
                initialValues={{
                    password: ''
                }}
                validationSchema={
                    Yup.object({
                        password: Yup.string().required('Введите пароль')
                    })
                }
                onSubmit={
                    ({password}) => {
                        AuthService.lockscreen(password)
                            .then(
                                () => setLockscreen("false")
                            )
                            .catch(err => {
                                const response = err.response;
                                if (response && response.status === 422) {
                                    setErrorMes([true, 'Не верный пароль, попробуйте снова.'])
                                }
                            })
                    }
                }
            >
                <Form>
                    <div className="mb-3">
                        <Label className="form-label" htmlFor="password">Пароль</Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                            <Field
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                className="form-control pe-5"
                                placeholder="Введите пароль"
                            />
                            <span
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                id="password-addon"
                                onClick={() => setPasswordShow(!passwordShow)}><i
                                className="ri-eye-fill align-middle"></i></span>
                            <ErrorMessage name="password"/>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Button color="success" className="btn btn-success w-100"
                                type="submit">
                            Войти
                        </Button>
                    </div>
                </Form>
            </Formik>
        </>

    );
};

export default LockscreenForm;
