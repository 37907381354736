import {AxiosResponse} from "axios";
import {IWidget} from "../../../models/IWidget";
import $api from "../../AxiosClient";
import {ITableAllInfoGroup} from "../../../models/widgets/ITableAllInfoGroup";
import {IDeviation} from "../../../models/widgets/IDeviation";
import {IStudent} from "../../../models/widgets/IStudent";
import {IAllDeviationGroup} from "../../../models/widgets/IAllDeviationGroup";
import {IDeviationGroupMonth} from "../../../models/widgets/IDeviationGroupMonth";
import {IDeviationGroupGraph} from "../../../models/widgets/IDeviationGroupGraph";
import {IDeviationDataBase} from "../../../models/widgets/IDeviationDataBase";
import {IChildrenAttendances} from "../../../models/widgets/IChildrenAttendances";
import {IAllDailyReportsNutrition} from "../../../models/widgets/IAllDailyReportsNutrition";
import {IAllDailyReportsSleep} from "../../../models/widgets/IAllDailyReportsSleep";
import {IAllDailyReportsMood} from "../../../models/widgets/IAllDailyReportsMood";
import moment from "moment";

interface IWidgetResponse {
    requireWidgets: string[],
    widgets: IWidget[],
    requireWidgetsFull: RequireWidgetsFull[]
}

interface RequireWidgetsFull {
    child: string,
    widgetParent: number
}


class WidgetService {
    static async getWidgets(path: string): Promise<AxiosResponse<IWidgetResponse>> {
        return $api.post<IWidgetResponse>('/widgets', {path})
    }

    static async getAllInfoGroup(date: Date[], group_do: string = "null"): Promise<AxiosResponse<ITableAllInfoGroup[]>> {
        if (group_do === "null")
            return $api.get<ITableAllInfoGroup[]>('/get-group', {params: {date: [moment(date[0]).format('DD.MM.Y'), moment(date[1]).format('DD.MM.Y')]}})
        else
            return $api.get<ITableAllInfoGroup[]>('/get-group', {params: {date: [moment(date[0]).format('DD.MM.Y'), moment(date[1]).format('DD.MM.Y')], group_do}})
    }

    static async getUserGroup(date: Date[], group_do: string): Promise<AxiosResponse<ITableAllInfoGroup[]>> {
        if (group_do === "null")
            return $api.get<ITableAllInfoGroup[]>('/user/get-group', {params: {date}})
        else
            return $api.get<ITableAllInfoGroup[]>('/user/get-group', {params: {date, group_do}})
    }

    static async changeWidth(path: string, width: string, id: number) {
        return $api.post<void>('/widget/width', {path: path, width: width, id: id})
    }

    static async getDeviations(): Promise<AxiosResponse<IDeviation[]>> {
        return $api.get<IDeviation[]>('/get-deviations')
    }

    static async getStudents(date: Date, group: string, group_do: string): Promise<AxiosResponse<IStudent[]>> {
        if (group_do === "null")
            return $api.get<IStudent[]>('/user/list-students', {params: {date: moment(date).format('DD.MM.YYYY'), group}})
        else
            return $api.get<IStudent[]>('/user/list-students', {params: {date:  moment(date).format('DD.MM.YYYY'), group, group_do}})
    }

    static async setAttendance(data: object): Promise<AxiosResponse<void>> {
        return $api.post<void>('/set-attendance', {data: data})
    }

    static async getAllDeviationGroup(date: Date[], group_do: string = 'null'): Promise<AxiosResponse<IAllDeviationGroup[]>> {
        if (group_do === "null")
            return $api.get<IAllDeviationGroup[]>('/get-all-deviations-group', {params: {date: [moment(date[0]).format('DD.MM.Y'), moment(date[1]).format('DD.MM.Y')]}})
        else
            return $api.get<IAllDeviationGroup[]>('/get-all-deviations-group', {params: {date: [moment(date[0]).format('DD.MM.Y'), moment(date[1]).format('DD.MM.Y')], group_do}})
    }

    static async getDeviationGroupMonth(group_do: string | null = null, date: Date[]): Promise<AxiosResponse<IDeviationGroupMonth[]>> {
        if (!group_do)
            return $api.get<IDeviationGroupMonth[]>('/get-deviations-group-month', {params: {date: [moment(date[0]).format('DD.MM.Y'), moment(date[1]).format('DD.MM.Y')]}})
        else
            return $api.get<IDeviationGroupMonth[]>('/get-deviations-group-month', {params: {group_do, date: [moment(date[0]).format('DD.MM.Y'), moment(date[1]).format('DD.MM.Y')]}})
    }

    static async getDeviationGroupGraph(group_do: string | null = null, date: Date[]): Promise<AxiosResponse<IDeviationGroupGraph[]>> {
        if (!group_do)
            return $api.get<IDeviationGroupGraph[]>('/get-all-deviations-group-graph', {params: {date: [moment(date[0]).format('DD.MM.Y'), moment(date[1]).format('DD.MM.Y')]}})
        else
            return $api.get<IDeviationGroupGraph[]>('/get-all-deviations-group-graph', {params: {group_do, date: [moment(date[0]).format('DD.MM.Y'), moment(date[1]).format('DD.MM.Y')]}})
    }

    static async getDeviationsDataBase(): Promise<AxiosResponse<IDeviationDataBase[]>> {
        return $api.post<IDeviationDataBase[]>('/deviations')
    }

    static async getChildrenAttendances(date: Date, group: string): Promise<AxiosResponse<IChildrenAttendances[]>> {
        return $api.post<IChildrenAttendances[]>('/attendance/date', {date, group})
    }

    static async getAllDailyReportsNutrition(date: Date[], group: string): Promise<AxiosResponse<IAllDailyReportsNutrition[]>> {
        const params = {
            date,
            group
        }
        return $api.get<IAllDailyReportsNutrition[]>('/get_all_deily_reports', {params})
    }

    static async deleteDailyReportsNutrition(date: Date, group: string): Promise<AxiosResponse<any>> {
        return $api.post<any>('/delete_dayli_report_nutrition', {date, group})
    }

    static async getAllDailyReportsSleep(date: Date[], group: string): Promise<AxiosResponse<IAllDailyReportsSleep[]>> {
        const params = {
            date,
            group
        }
        return $api.get<IAllDailyReportsSleep[]>('/get_all_deily_reports_sleep', {params})
    }

    static async deleteDailyReportsSleep(date: Date, group: string): Promise<AxiosResponse<any>> {
        return $api.post<any>('/delete_dayli_report_sleep', {date, group})
    }

    static async getAllDailyReportsMood(date: Date[], group: string): Promise<AxiosResponse<IAllDailyReportsMood[]>> {
        const params = {
            date,
            group
        }
        return $api.get<IAllDailyReportsMood[]>('/get_all_deily_reports_mood', {params})
    }

    static async deleteDailyReportsMood(date: Date, group: string): Promise<AxiosResponse<any>> {
        return $api.post<any>('/delete_dayli_report_mood', {date, group})
    }

    static async getTypeEquipmentRepair(date: Date[]): Promise<AxiosResponse<any[]>> {
        const params = {
            date
        }
        return $api.get<any[]>('/get_type_equipment_repair', {params})
    }

    static async getDispatchServiceRequest(date: Date[]): Promise<AxiosResponse<any[]>> {
        const params = {
            date
        }
        return $api.get<any[]>('/get_dispatch_service_request', {params})
    }

    static async getInspectionAct(date: Date[]): Promise<AxiosResponse<any[]>> {
        const params = {
            date
        }
        return $api.get<any[]>('/get_inspection_act', {params})
    }

    static async getListPerson(date: Date[]): Promise<AxiosResponse<any[]>> {
        const params = {
            date
        }
        return $api.get<any[]>('/get_list_person', {params})
    }

    static async getListRepairTeam(date: Date[]): Promise<AxiosResponse<any[]>> {
        const params = {
            date
        }
        return $api.get<any[]>('/get_list_repair_team', {params})
    }

    static async getListObjectWarehouse(date: Date[]): Promise<AxiosResponse<any[]>> {
        const params = {
            date
        }
        return $api.get<any[]>('/get_list_object_warehouse', {params})
    }

    static async getListObjectRepair(date: Date, sklad_id: string): Promise<AxiosResponse<any[]>> {
        const params = {
            date,
            sklad_id
        }
        return $api.get<any[]>('/get_list_object_repair', {params})
    }

    static async getListTypeDefect(): Promise<AxiosResponse<any[]>> {
        return $api.get<any[]>('/get_list_type_defect')
    }

    static async getListCriticalDefect(): Promise<AxiosResponse<any[]>> {
        return $api.get<any[]>('/get_list_critical_defect')
    }

    static async getListDefectCauses(): Promise<AxiosResponse<any[]>> {
        return $api.get<any[]>('/get_list_defect_causes')
    }

    static async getListTypeView(): Promise<AxiosResponse<any[]>> {
        return $api.get<any[]>('/get_list_type_view')
    }

    static async getInfoObject(date: Date, sklad_id: string, equipment_id: string): Promise<AxiosResponse<any[]>> {
        const params = {
            date,
            sklad_id,
            equipment_id
        }
        return $api.get<any[]>('/get_info_object', {params})
    }

    static async getInfoDispatchServiceRequest(doc_id: string): Promise<AxiosResponse<any[]>> {
        const params = {
            doc_id
        }
        return $api.get<any[]>('/toir_get_info_dispatch_service_request', {params})
    }

    static async getInfoActInspection(doc_id: string): Promise<AxiosResponse<any[]>> {
        const params = {
            doc_id
        }
        return $api.get<any[]>('/toir_get_info_act_inspection', {params})
    }

    static async getInfoOrganization(): Promise<AxiosResponse<any[]>> {
        return $api.get<any[]>('/toir_get_info_organization')
    }

    static async getItogInfoObjectsDefects(date: Date[]): Promise<AxiosResponse<any[]>> {
        const params = {
            date
        }
        return $api.get<any[]>('/get_itog_info_objects_defects', {params})
    }

    static async getListMaterials(): Promise<AxiosResponse<any[]>> {
        return $api.get<any[]>('/get_list_materials')
    }


    static async getListControlZamer(): Promise<AxiosResponse<any[]>> {
        return $api.get<any[]>('/get_list_control_zamer')
    }
}

export default WidgetService;