import ReactApexChart from "react-apexcharts";


const getChartColorsArray = (colors) => {
    colors = JSON.parse(colors);
    return colors.map(function (value) {
        const newValue = value.replace(" ", "");
        if (newValue.indexOf(",") === -1) {
            let color = getComputedStyle(document.documentElement).getPropertyValue(newValue);

            if (color.indexOf("#") !== -1)
                color = color.replace(" ", "");
            if (color) return color;
            else return newValue;
        } else {
            let val = value.split(',');
            if (val.length === 2) {
                let rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
                return rgbaColor;
            } else {
                return newValue;
            }
        }
    });
};

const ProjectStatusCharts = ({series}) => {
    //var donutchartProjectsStatusColors = getChartColorsArray(dataColors);

    let options = {
        labels: (series || []).map((dev) => dev.deviation_name),
        chart: {
            type: "donut",
            height: 230,
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
            width: 0
        },
        //colors: donutchartProjectsStatusColors,
    }

    return (
        <>
            <ReactApexChart
                options={options}
                series={(series || []).map((dev) => dev.count)}
                type="donut"
                height="150"
                className="apex-charts"
            />
        </>
    );
}

const ProjectStatusChartsEmployee = ({series}) => {

    let options = {
        labels: (series || []).map((dev) => dev.name),
        chart: {
            type: "donut",
            height: 230,
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
            width: 0
        }
    }

    return (
        <>
            <ReactApexChart
                options={options}
                series={(series || []).map((dev) => dev.count)}
                type="donut"
                height="150"
                className="apex-charts"
            />
        </>
    );
}

const ProjectOverviewChartRegisterDefectEquipment = ({series}) => {

    const categories = ['Всего'];
    let options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 3],
            width: [0, 0, 2],
        },
        fill: {
            opacity: [1, 1, 0.3]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 3,
            }
        },
        xaxis: {
            categories: categories,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '100%',
                barHeight: '40%'
            }
        },
        tooltip: {
            shared: true,
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="350"
                className="apex-charts"
            />
        </>
    );
}

const ProjectOverviewChartsActEquipmentInspectionCertificate = ({data}) => {

    const categories = ['Всего'];
    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 3],
            width: [0, 0, 2],
        },
        fill: {
            opacity: [1, 1, 0.3]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: categories,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        tooltip: {
            shared: true,
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={data}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
}

const ProjectsOverviewCharts = ({dataColors, series}) => {
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    let categories = series.map((i) => {
        return i.mounth.slice(3)
    })

    let present = {
        name: 'Присутствует',
        type: 'bar',
        data: series.map((i) => {
            return i.present
        })
    }

    let noPresent = {
        name: 'Отсутствует',
        type: 'bar',
        data: series.map((i) => {
            return i.no_present
        })
    }
    let zabolevanie = {
        name: 'Болеет',
        type: 'area',
        data: series.map((i) => {
            return i.zabolevanie
        })
    }

    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 3],
            width: [0, 0, 2],
        },
        fill: {
            opacity: [1, 1, 0.3]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: categories,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={[present, noPresent, zabolevanie]}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const ProjectsOverviewChartsOperationalControl = ({dataColors, series}) => {
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    let categories = series.map((i) => {
        return i.month
    })

    let hard = {
        name: 'Грубые',
        type: 'bar',
        data: series.map((i) => {
            return i.hard
        })
    }

    let light = {
        name: 'Незначительные',
        type: 'bar',
        data: series.map((i) => {
            return i.light
        })
    }

    let none = {
        name: 'Отсутствуют',
        type: 'bar',
        data: series.map((i) => {
            return i.none
        })
    }

    let all = {
        name: 'Всего',
        type: 'bar',
        data: series.map((i) => {
            return i.all
        })
    }

    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 0],
            width: [0, 0, 0],
        },
        fill: {
            opacity: [1, 1, 0.3]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: categories,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors,
    };

    return (
        <>
            <ReactApexChart
                options={options}
                series={[hard, light, none, all]}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const ProjectStatusChartsDonutOperationalControl = ({series}) => {
    //var donutchartProjectsStatusColors = getChartColorsArray(dataColors);

    let options = {
        labels: (series || []).map((dev) => dev.name),
        chart: {
            type: "donut",
            height: 230,
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
            width: 0
        },
        //colors: donutchartProjectsStatusColors,
    }

    return (
        <>
            <ReactApexChart
                options={options}
                series={(series || []).map((dev) => dev.count)}
                type="donut"
                height="150"
                className="apex-charts"
            />
        </>
    );
}

export {ProjectStatusCharts, ProjectStatusChartsDonutOperationalControl, ProjectsOverviewChartsOperationalControl, ProjectsOverviewCharts, ProjectOverviewChartRegisterDefectEquipment, ProjectOverviewChartsActEquipmentInspectionCertificate, ProjectStatusChartsEmployee}