import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import ListClass from "./ListClass";

interface IListBuilds {
    builds: any[]
}
const ListBuilds: FC<IListBuilds> = ({builds}) => {

    const [openListClass, setOpenListClass] = useState(false);
    const [listClass, setListClass] = useState<any[]>([]);

    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Корпус</span>,
            selector: (row: any) => row.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Классов</span>,
            cell: (row: any) => row.class.length,
        }
    ]

    return (
        <>
            <DataTable
                columns={columns}
                data={builds}
                noDataComponent={'Нет данных'}
                pagination
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Видно корпусов:',
                        rangeSeparatorText: 'из'
                    }
                }
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                onRowClicked={(row) => {
                    setListClass(row.class)
                    setOpenListClass(true)
                }}
            />
            <ListClass listClass={listClass} openListClass={openListClass} setOpenListClass={value => setOpenListClass(value)}/>
        </>
    );
};

export default ListBuilds;
