import React, {FC, useState} from 'react';
import {Card, Col} from "reactstrap";
import ModalCheckPay from "./ModalCheckPay";
import AxiosClient from "../../../api/AxiosClient";

interface IPropsElemPay {
    elem: any
    dateElemPay: () => void
    updateData: () => void
}
const ElemPay: FC<IPropsElemPay> = ({elem, dateElemPay, updateData}) => {

    const [checkPay, setCheckPay] = useState(false);

    const statusPay: any = {
        "0": {
            icon: <i className="bx bx-time-five fs-48 text-danger"></i>,
            text: "Ожидает оплаты"
        }
    }

    const decline = () => {
        AxiosClient.post('/sber/decline', { orderId: elem.orderId})
            .then((r) => {
                dateElemPay()
            })
            .catch(() => console.log('error decline payment status'));
    }

    const goPay = () => {
        window.location.href = elem.formUrl
    }

    return (
        <Col sm={6} lg={4} xl={4} xxl={3}>
            <Card className="p-2">
                <div className="d-flex gap-2 align-items-center justify-content-between">
                    <div>
                        {statusPay[elem.status as string].icon}
                    </div>
                    <div className="flex-1">
                        <div className="d-flex justify-content-between align-items-end">
                            <h3>{elem.amount} р</h3>
                            {
                                +elem.status === 0 &&
                                <div>
                                    <p className="text-danger fw-bold cursor-pointer m-0" onClick={() => decline()}>Отменить оплату</p>
                                    <p className="text-success fw-bold cursor-pointer m-0" onClick={() => goPay()}>К оплате</p>
                                </div>
                            }
                        </div>
                        <p className="m-0">{statusPay[elem.status as string].text}</p>
                    </div>
                </div>
                <button className="btn btn-primary" onClick={() => setCheckPay(true)}>Проверить оплату</button>
            </Card>
            <ModalCheckPay show={checkPay} setShow={value => setCheckPay(value)} orderId={elem.orderId} updateData={() => updateData} pa={elem.personal_account_id} updateElemPay={() => dateElemPay()}/>
        </Col>
    );
};

export default ElemPay;
