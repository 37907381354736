import { Provider } from "react-redux"
import './assets/scss/themess.scss'
import AppRoutes from "./components/AppRoutes"
import { ContextProvider } from "./context/ContextProvider"
import { setupStore } from "./store/store"
function App() {
    const store = setupStore()

    return (
        <ContextProvider>
            <Provider store={store}>
                <AppRoutes />
            </Provider>
        </ContextProvider>
    )
}

export default App
