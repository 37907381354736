import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {IPosition} from "../../../models/IPosition";

class PositionService {
    static async getPositions(): Promise<AxiosResponse<IPosition[]>>{
        return $api.post("/positions")
    }

    static async addPosition(value: object): Promise<AxiosResponse<any>>{
        return $api.post("/positions/add", value)
    }

    static async updatePosition(value: object, positionId: number): Promise<AxiosResponse<any>>{
        return $api.post(`/positions/update/${positionId}`, value)
    }
}

export default PositionService;