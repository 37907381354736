import React from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";

const AboutMe = () => {
    return (
        <Card>
            <CardBody>
                <h5 className="card-title mb-3">Обо мне</h5>
                <p>Hi I'm Anna Adame, It will be as simple as Occidental; in
                    fact, it will be Occidental. To an English person, it will
                    seem like simplified English, as a skeptical Cambridge
                    friend of mine told me what Occidental is European languages
                    are members of the same family.</p>
                <p>You always want to make sure that your fonts work well
                    together and try to limit the number of fonts you use to
                    three or less. Experiment and play around with the fonts
                    that you already have in the software you’re working with
                    reputable font websites. This may be the most commonly
                    encountered tip I received from the designers I spoke with.
                    They highly encourage that you use different fonts in one
                    design, but do not over-exaggerate and go overboard.</p>
                <Row>
                    <Col xs={6} md={4}>
                        <div className="d-flex mt-4">
                            <div
                                className="flex-shrink-0 avatar-xs align-self-center me-3">
                                <div
                                    className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-user-2-fill"></i>
                                </div>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="mb-1">Должность :</p>
                                <h6 className="text-truncate mb-0">Воспитатель</h6>
                            </div>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default AboutMe;
