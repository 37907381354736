import React, {FC} from 'react';
import LoginLayout from "../components/login/LoginLayout";
import LoginForm from "../components/login/LoginForm";
import {Helmet} from "react-helmet";
import {useStateContext} from "../hooks/useStateContext";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
const Login: FC = () => {
    const {setToken} = useStateContext()
    const location = useLocation();
    const token = queryString.parse(location.search).token;
    if(token){
        setToken(token as string)
    }

    return (
        <LoginLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Вход</title>
            </Helmet>
            <LoginForm/>
        </LoginLayout>
    );
};

export default Login;
