import {ConstRoutes} from "./constRoutes";
import Login from "../pages/Login";
import {JSX} from "react";
import SettingsUser from "../pages/admin/SettingsUser";
import SettingsCity from "../pages/admin/SettingsCity";
import SettingsCountry from "../pages/admin/SettingsCountry";
import SettingsOrganization from "../pages/admin/SettingsOrganization";
import SettingsPosition from "../pages/admin/SettingsPosition";
import SettingsTerm from "../pages/admin/SettingsTerm";
import HelpPage from "../pages/HelpPage";
import Settings from "../pages/Settings";
import RegisterDefectEquipment from "../pages/RegisterDefectEquipment";
import ProfileGuest from "../pages/ProfileGuest";
import MyProfile from "../pages/MyProfile";
import Chat from "../pages/Chat";
import CateringOrganizers from "../pages/KShP/CateringOrganizers";
import AddCateringOrganizers from "../pages/KShP/AddCateringOrganizers";
import TypesNutrition from "../pages/KShP/TypesNutrition";
import Schools from "../pages/KShP/Schools";
import SeminarAdmin from "../pages/admin/SeminarAdmin";
import Students from "../pages/KShP/Students";
import HomeEducation from "../pages/KShP/HomeEducation";
import Assortment from "../pages/KShP/Assortment";
import ComplexMenu from "../pages/KShP/ComplexMenu";
import Balance from "../pages/KShP/Balance";
import CashMovement from "../pages/KShP/CashMovement";
import RequestForNutrition from "../pages/KShP/RequestForNutrition";
import Educator from "../pages/KShP/Educator";
import CashAccounting from "../pages/KShP/CashAccounting";
import FoodRation from "../pages/KShP/FoodRation";
import Buffet from "../pages/KShP/Buffet";
import ArmApBuffet from "../pages/KShP/ArmApBuffet";
import MyPayments from "../pages/KShP/MyPayments";
import ChildrenAndParent from "../pages/KShP/ChildrenAndParent";
import ClassesAndTeacher from "../pages/KShP/ClassesAndTeacher";
import TypePlannedMenu from "../pages/KShP/TypePlannedMenu";
import Benefit from "../pages/KShP/Benefit";
import Status from "../pages/KShP/Status";
import Import from "../pages/KShP/Import";
import CopyBenefit from "../pages/KShP/CopyBenefit";
import SettingsUserKSHP from "../pages/KShP/SettingsUserKSHP";
import Report from "../pages/KShP/Report";
import AgeCategory from "../pages/KShP/AgeCategory";
import SettingsPage from "../pages/admin/SettingsPage";
import StatisticLogin from "../pages/StatisticLogin";
import StatisticLoginKshp from "../pages/KShP/StatisticLoginKshp";
import StatisticLoginKshpArmAp from "../pages/KShP/StatisticLoginKshpArmAp";
import StatisticLoginKshpArmOp from "../pages/KShP/StatisticLoginKshpArmOp";

interface IRoutes {
    path: string,
    component: JSX.Element
}
export const PublicRoutes: IRoutes[] = [
    {path: ConstRoutes.LOGIN, component: <Login/>},
]

export const FormRoutes: IRoutes[] = []

export const PrivateRoutes: IRoutes[] = [
    {path: ConstRoutes.ADMIN_SETTINGS_USER, component: <SettingsUser/>},
    {path: ConstRoutes.ADMIN_SETTINGS_CITY, component: <SettingsCity/>},
    {path: ConstRoutes.ADMIN_SETTINGS_COUNTRY, component: <SettingsCountry/>},
    {path: ConstRoutes.ADMIN_SETTINGS_ORGANIZATION, component: <SettingsOrganization/>},
    {path: ConstRoutes.ADMIN_SETTINGS_POSITION, component: <SettingsPosition/>},
    {path: ConstRoutes.ADMIN_SETTINGS_TERM, component: <SettingsTerm/>},
    {path: ConstRoutes.HELP_PAGE, component: <HelpPage/>},
    {path: ConstRoutes.SETTINGS, component: <Settings/>},
    {path: ConstRoutes.REGISTER_DEFECT_EQUIPMENT, component: <RegisterDefectEquipment/>},
    {path: ConstRoutes.PROFILE_GUEST, component: <ProfileGuest/>},
    {path: ConstRoutes.PROFILE, component: <MyProfile/>},
    {path: ConstRoutes.CHAT, component: <Chat/>},
    {path: ConstRoutes.CATERING_ORGANIZERS, component: <CateringOrganizers/>},
    {path: ConstRoutes.CATERING_ORGANIZERS_ADD, component: <AddCateringOrganizers/>},
    {path: ConstRoutes.TYPES_NUTRITION, component: <TypesNutrition/>},
    {path: ConstRoutes.SCHOOLS, component: <Schools/>},
    {path: ConstRoutes.SEMINAR_ADMIN, component: <SeminarAdmin/>},
    {path: ConstRoutes.STUDENTS, component: <Students/>},
    {path: ConstRoutes.HOME_EDUCATION, component: <HomeEducation/>},
    {path: ConstRoutes.ASSORTMENT, component: <Assortment/>},
    {path: ConstRoutes.COMPLEX_MENU, component: <ComplexMenu/>},
    {path: ConstRoutes.BALANCE, component: <Balance/>},
    {path: ConstRoutes.REQUEST_FOR_NUTRITION, component: <RequestForNutrition/>},
    {path: ConstRoutes.CASH_MOVEMENT, component: <CashMovement/>},
    {path: ConstRoutes.ARM_EDUCATOR, component: <Educator/>},
    {path: ConstRoutes.ARM_PARENT_CASH_ACCOUNTING, component: <CashAccounting/>},
    {path: ConstRoutes.ARM_PARENT_FOOD_RATION, component: <FoodRation/>},
    {path: ConstRoutes.ARM_PARENT_BUFFET, component: <Buffet/>},
    {path: ConstRoutes.BUFFET, component: <ArmApBuffet/>},
    {path: ConstRoutes.ARM_PARENT_MY_PAYMENTS, component: <MyPayments/>},
    {path: ConstRoutes.CHILDREN_AND_PARENT, component: <ChildrenAndParent/>},
    {path: ConstRoutes.CLASSES_AND_TEACHER, component: <ClassesAndTeacher/>},
    {path: ConstRoutes.TYPE_PLANNED_MENU, component: <TypePlannedMenu/>},
    {path: ConstRoutes.BENEFIT, component: <Benefit/>},
    {path: ConstRoutes.STATUSES, component: <Status/>},
    {path: ConstRoutes.IMPORTS, component: <Import/>},
    {path: ConstRoutes.COPY_BENEFITS, component: <CopyBenefit/>},
    {path: ConstRoutes.SETTINGS_USER, component: <SettingsUserKSHP/>},
    {path: ConstRoutes.REPORT, component: <Report/>},
    {path: ConstRoutes.AGE_CATEGORIES, component: <AgeCategory/>},
    {path: ConstRoutes.ADMIN_SETTINGS_PAGE, component: <SettingsPage/>},
    {path: ConstRoutes.STATISTICS_LOGIN, component: <StatisticLogin/>},
    {path: ConstRoutes.STATISTICS_LOGIN_KSHP, component: <StatisticLoginKshp/>},
    {path: ConstRoutes.STATISTICS_LOGIN_KSHP_ARM_AP, component: <StatisticLoginKshpArmAp/>},
    {path: ConstRoutes.STATISTICS_LOGIN_KSHP_ARM_OP, component: <StatisticLoginKshpArmOp/>},
]