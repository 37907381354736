import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row} from "reactstrap";
import * as Yup from 'yup';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import TableSelectPage from "./TableSelectPage";
import {translit} from "../../../functions/translit";
import {addTerm} from "../../../store/reducers/admin/ActionCreator";


const TermAdd = () => {
    const dispatch = useAppDispatch();
    const {pages} = useAppSelector(state => state.AdminReducer)
    const [role, setRole] = useState<string>('')
    const [trans, setTrans] = useState<string>("");
    const [checkAll, setCheckAll] = useState<boolean>(false);
    useEffect(() => {
        setTrans(translit(role))
    }, [role]);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={
                    {
                        role: "",
                        page: checkAll ? pages.map((page) => {
                            return `${page.id}`
                        }) : [],
                        mainPage: pages[0].path,
                    }
                }
                validationSchema={
                    Yup.object(
                        {
                            role: Yup.string().required('Введите название роли'),
                        }
                    )
                }
                onSubmit={
                    (value, {resetForm}) => {
                        (value as any).slug = trans
                        dispatch(addTerm(value)).then()
                        resetForm()
                    }
                }
            >
                <Form encType="multipart/form-data">
                    <Card className="mx-n3">
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                 role="tablist">
                                <NavItem>
                                    <NavLink
                                        className="active">
                                        <i className="fas fa-home"></i>
                                        Данные о роли
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className="p-4">
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="roleInput" className="form-label">Роль</Label>
                                        <Field type="text" className="form-control" id="roleInput"
                                               name="role" onKeyUp={(e: any) => setRole(e.target.value)}
                                               placeholder="Введите роль"/>
                                        <ErrorMessage name="role">{(msg) => <div
                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="slugInput" className="form-label">Имя роли (на
                                            английском)</Label>
                                        <Field type="text" className="form-control" id="slugInput"
                                               name="slug" value={trans}
                                               placeholder="Введите роль"/>
                                        <ErrorMessage name="slug">{(msg) => <div
                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="mainPageInput" className="form-label">Главная
                                            страница</Label>
                                        <Field
                                            as="select"
                                            className="form-select"
                                            id="mainPageInput"
                                            name="mainPage"
                                        >
                                            {
                                                pages.map((page) => {
                                                    return <option key={page.id}
                                                                   value={page.path}>{page.name}</option>
                                                })
                                            }
                                        </Field>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <TableSelectPage setCheckAll={(check) => setCheckAll(check)}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="mt-3">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="submit"
                                                className="btn btn-primary">Добавить
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Form>
            </Formik>
        </>
    );
};

export default TermAdd;
