import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {customStyles} from "../../settings";

interface IModalUpdateStatus {
    selectStatus: any,
    updateStatus: boolean,
    setUpdateStatus: (value: boolean) => void,
    addMyStatuses: any[],
    setAddMyStatuses: (value: any[]) => void
}

const ModalUpdateStatus: FC<IModalUpdateStatus> = ({setUpdateStatus, selectStatus, updateStatus, setAddMyStatuses, addMyStatuses }) => {
    const [statuses, setStatuses] = useState<any[]>([]);
    const [selectStatusesValue, setSelectStatusesValue] = useState<any>({ value: '', label: ''})

    useEffect(() => {
        setSelectStatusesValue({ value: selectStatus?.id.toString(), label: selectStatus?.name})
        AxiosClient.get('/statuses')
            .then((r => setStatuses(r.data)))
    }, []);
    useEffect(() => {
        setSelectStatusesValue({ value: selectStatus?.id.toString(), label: selectStatus?.name})
    }, [selectStatus]);

    return (
        <Modal id="createProjectModal" isOpen={updateStatus}
               toggle={() => setUpdateStatus(!updateStatus)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setUpdateStatus(!updateStatus)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление статуса
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            status_id: selectStatusesValue?.value,
                        }
                    }
                    validationSchema={
                        Yup.object({
                            status_id: Yup.string().required('Require'),
                        })
                    }
                    onSubmit={(values, {setErrors}) => {
                        const isDuplicate = addMyStatuses.some(m => m.id.toString() === values.status_id.toString())
                        if (isDuplicate) {
                            setErrors({status_id: 'Такой статус уже существует'});
                        } else {
                            setAddMyStatuses(addMyStatuses.map(el => {
                                if (el.id === selectStatus.id){
                                    return statuses.find(el => el.id.toString() === values.status_id)
                                }
                                return el
                            }))
                            setUpdateStatus(false)
                        }
                    }}
                >
                    {
                        ({errors, touched, setFieldValue}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Статус:
                                            <Field name="status_id">
                                                {() => (
                                                    <Select
                                                        options={statuses?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...statuses.map(v => ({ value: v.id.toString(), label: v.name}))] : [{ value: '', label: 'Не выбрано' }]}
                                                        value={selectStatusesValue}
                                                        onChange={(change: any) => {
                                                            setSelectStatusesValue({ value: change.value, label: change.label })
                                                            setFieldValue('status_id', change.value)
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                        name="status_id"
                                                    />
                                                )}
                                            </Field>
                                        </label>
                                        {errors.status_id && touched.status_id ? (
                                            <div className="text-danger">{errors.status_id.toString()}</div>
                                        ) : null}
                                    </Col>
                                </Row>
                                <button type="submit" className="btn btn-primary mt-1" id="addNewProject">Сохранить</button>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateStatus;
