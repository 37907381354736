import React, {useState} from 'react';
import {Card, CardBody, Col, Collapse, Container, Row} from "reactstrap";
import progileBg from '../assets/images/childrenRom.jpeg';
import smallImage1 from '../assets/images/small/img-1.jpg';
import classNames from "classnames";
import {Helmet} from "react-helmet";

const HelpPage = () => {

    const teamList = [
        {
            id: 1,
            backgroundImg: smallImage1,
            userImage: null,
            userShortName: "А",
            name: "Александр",
            designation: "Специалист по технической поддержке",
            projectCount: 225,
            taskCount: 197
        },
        {
            id: 2,
            backgroundImg: smallImage1,
            userImage: null,
            userShortName: "П",
            name: "Павел",
            designation: "Специалист по инновационным технологиям",
            projectCount: 352,
            taskCount: 376,
            bgColor: "danger",
            textColor: "danger",
        },
        {
            id: 3,
            backgroundImg: smallImage1,
            userImage: null,
            userShortName: "О",
            name: "Олеся",
            designation: "Начальник технологического отдела",
            projectCount: 162,
            taskCount: 192
        },
    ]

    const [col1, setcol1] = useState(false);
    const [col2, setcol2] = useState(true);
    const [col3, setcol3] = useState(false);

    const t_col1 = () => {
        setcol1(!col1)
        setcol2(false)
        setcol3(false)

    }

    const t_col2 = () => {
        setcol2(!col2)
        setcol1(false)
        setcol3(false)

    }

    const t_col3 = () => {
        setcol3(!col3)
        setcol1(false)
        setcol2(false)

    }
    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Помощь</title>
            </Helmet>
            <Container fluid>
                <div className="position-relative mx-n4 mt-n4 flex-start">
                    <div className="profile-wid-bg profile-setting-img ">
                        <img src={progileBg} className="profile-wid-img" alt=""/>
                        <div className="overlay-content " style={{position: 'absolute', float: 'left'}}>
                            <div className="px-4">
                                <Row>
                                    <Col className="">
                                        <div className="py-5">
                                            <h4 className="display-5 coming-soon-text">ООО Бизнес-Премиум</h4>
                                            <p className="text-white fs-20 mt-3">Если у вас возникли вопросы, напишите
                                                нам на почту <br/> или позвоните на телефон горячей линии.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <Col lg={12}>
                    <Row className="justify-content-evenly">
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-question-line fs-24 align-middle text-success me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Главные вопросы</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion accordion-border-box" id="genques-accordion">
                                <div className="accordion-item shadow">
                                    <h2 className="accordion-header" id="genques-headingTwo">
                                        <button
                                            className={classNames(
                                                "accordion-button",
                                                "fw-medium",
                                                {collapsed: !col2}
                                            )}
                                            type="button"
                                            onClick={t_col2}
                                            style={{cursor: "pointer"}}
                                        >
                                            Телефоны горячей линии
                                        </button>
                                    </h2>
                                    <Collapse isOpen={col2} className="accordion-collapse">
                                        <div className="accordion-body fs-16">
                                            +7 (3452) 68-30-86 доб. 2 <br/>
                                            +7 (3452) 38-89-27 <br/>
                                            +7 (3452) 38-89-51
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item shadow">
                                    <h2 className="accordion-header" id="genques-headingOne">
                                        <button
                                            className={classNames(
                                                "accordion-button",
                                                "fw-medium",
                                                {collapsed: !col1}
                                            )}
                                            type="button"
                                            onClick={t_col1}
                                            style={{cursor: "pointer"}}
                                        >
                                            Электронная почта
                                        </button>
                                    </h2>
                                    <Collapse isOpen={col1} className="accordion-collapse">
                                        <div className="accordion-body fs-16">
                                            info@youbp.ru
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="accordion-item shadow">
                                    <h2 className="accordion-header" id="genques-headingThree">
                                        <button
                                            className={classNames(
                                                "accordion-button",
                                                "fw-medium",
                                                {collapsed: !col3}
                                            )}
                                            type="button"
                                            onClick={t_col3}
                                            style={{cursor: "pointer"}}
                                        >
                                            Время работы
                                        </button>
                                    </h2>
                                    <Collapse isOpen={col3} className="accordion-collapse">
                                        <div className="accordion-body fs-16">
                                            ПН-ПТ: С 9:00 ДО 18:00 <br/>
                                            +2 часа к Московскому времени <br/>

                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Row className="mt-2">
                    <Col lg="12">
                        <Row className="team-list grid-view-filter">
                            {(teamList || []).map((item, key) => (
                                <Col key={key} lg='4'>
                                    <Card className="team-box">
                                        <div className="team-cover">
                                            <img src={item.backgroundImg} alt="" className="img-fluid"/>
                                        </div>
                                        <CardBody className="p-4">
                                            <Row className="align-items-center team-row">
                                                <Col lg={4} className="col">
                                                    <div className="team-profile-img">

                                                        <div
                                                            className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                                            {item.userImage != null ?
                                                                <img src={item.userImage} alt=""
                                                                     className="img-fluid d-block rounded-circle"/>

                                                                :
                                                                <div
                                                                    className="avatar-title text-uppercase border rounded-circle bg-light text-primary">
                                                                    {item.name.charAt(0)}
                                                                </div>}
                                                        </div>
                                                        <div className="team-content">
                                                            <h5 className="fs-16 mb-1">{item.name}</h5>
                                                            <p className="text-muted mb-0">{item.designation}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default HelpPage;
