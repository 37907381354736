import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import ListTableCateringOrganizers from "../../components/KShP/CateringOrganizers/ListTableCateringOrganizers";

const CateringOrganizers = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Организаторы питания</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={12}>
                            <ListTableCateringOrganizers/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default CateringOrganizers;
