import React, {FC} from 'react';

interface IMessage {
    isUser: boolean,
    message: string,
    time: string,
    type?: 'warning' | 'info'
}
const Message: FC<IMessage> = ({isUser, message, time, type}) => {

    const warn = type === "warning" ? " bg-danger text-white" : "";
    const info = type === "info" ? " bg-info text-white" : "";
    return (
        <li
            className={
                !isUser
                    ? " chat-list left"
                    : "chat-list right"
            }
        >
            <div className="conversation-list">
                <div className="user-chat-content">
                    <div className="ctext-wrap ">
                        <div className={"ctext-wrap-content " + warn + info} style={{whiteSpace: "pre-line"}}>
                            {message}
                        </div>
                    </div>
                    <div className="conversation-name">
                        <small className="text-muted time">
                            {time}
                        </small>{" "}
                        <span className="text-success check-message-icon">
                            <i className="ri-check-double-line align-bottom"></i>
                        </span>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default Message;
