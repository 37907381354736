import React from 'react';
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import LockscreenForm from "../components/lockscreen/LockscreenForm";
import {Helmet} from "react-helmet";

const Lockscreen = () => {
    return (
        <div className="auth-page-wrapper pt-5">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Экран заблокирован</title>
            </Helmet>
            <div className="auth-one-bg-position auth-one-bg" id="auth-particles" >
                <div className="bg-overlay" ></div>
                <div className="shape" >
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 1440 120">
                        <path  d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                    </svg>
                </div>
            </div>
            <div className="auth-page-content">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img alt="" height="20"/>
                                    </Link>
                                </div>
                                <p className="mt-3 fs-24 fw-medium">Вход <br/>
                                    в Систему контроля деятельности <br/> Образовательного учреждения</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5} className="mt-3">
                            <Card style={{boxShadow: '0px 0px 10px 10px rgba(0,0,0,0.5)'}}>
                                <CardBody>
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Экран заблокирова</h5>
                                        <p className="text-muted">Введите пароль, чтобы продолжить</p>
                                    </div>
                                    <div className="p-2 m-2">
                                        <LockscreenForm/>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Lockscreen;
