import React, {FC, memo} from 'react';
import {Col, Container, Row} from 'reactstrap';

const Footer: FC = () => {
    return (
        <>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            2023 © ООО Бизнес-Премиум.
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                + 7 (3452) 68-30-86 доб. 2
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default memo(Footer);