import React from 'react';

const TableLoaders = () => {
    return (
        <div className="graphLoader">
            <div className="graphLoader-line"></div>
            <div className="graphLoader-post">
                <div>
                    <div className="graphLoader-avatar" style={{height: '50px', width: '50px'}}></div>
                    <div className="graphLoader-avatar" style={{height: '50px', width: '50px'}}></div>
                    <div className="graphLoader-avatar" style={{height: '50px', width: '50px'}}></div>
                </div>
                <div>
                    <div className="graphLoader-avatar" style={{height: '50px', width: '50px'}}></div>
                    <div className="graphLoader-avatar" style={{height: '50px', width: '50px'}}></div>
                    <div className="graphLoader-avatar" style={{height: '50px', width: '50px'}}></div>
                </div>
                <div>
                    <div className="graphLoader-avatar" style={{height: '50px', width: '50px'}}></div>
                    <div className="graphLoader-avatar" style={{height: '50px', width: '50px'}}></div>
                    <div className="graphLoader-avatar" style={{height: '50px', width: '50px'}}></div>
                </div>
            </div>
        </div>
    );
};

export default TableLoaders;
