import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardFooter, CardHeader, Row, Col } from "reactstrap"
import DataTable from "react-data-table-component"
import { Link } from "react-router-dom"
import FormUpdateCateringOrganizers from "./FormUpdateCateringOrganizers"
import AxiosClient from "../../../api/AxiosClient"
import {ICateringOrganizer} from "../../../models/KSHP/ICateringOrganizer";

const ListTableCateringOrganizers = () => {

    const [updateCateringOrganizers, setUpdateCateringOrganizers] = useState(false)
    const [cateringOrganizers, setCateringOrganizers] = useState<ICateringOrganizer[]>([])
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState<ICateringOrganizer | null>(null)
    const [search, setSearch] = useState('')

    useEffect(() => {
        AxiosClient.get<ICateringOrganizer[]>('/catering_organizaers')
            .then(r => setCateringOrganizers(r.data))
    }, [])

    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Наименование</span>,
            selector: (row: ICateringOrganizer) => row.name,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Идентификатор</span>,
            selector: (row: ICateringOrganizer) => row.identifier,
            hide: 959
        },
        {
            name: <span className='font-weight-bold fs-13'>ИНН</span>,
            selector: (row: ICateringOrganizer) => row.inn,
            hide: 959
        },
        {
            cell: (row: ICateringOrganizer) => <i className="bx bx-archive-in fs-3 cursor-pointer" title="Добавить в архив" onClick={() => {
                AxiosClient.post(`/catering_organizaers/archive/${row.id}`)
                    .then(() => {
                        AxiosClient.get('/catering_organizaers')
                            .then(r => setCateringOrganizers(r.data))
                    })
            }}></i>,
            center: true,
            width: '70px'
        },
    ]

    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label className="w-100">Наименования или ИНН:
                        <input name="nameOrINN" type="text" className='form-control' value={search} onChange={(e) => setSearch(e.target.value)} />
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardHeader>
                            Организаторы питания
                        </CardHeader>
                        <CardBody className=' align-items-center'>
                            <DataTable
                                columns={columns}
                                data={cateringOrganizers.filter(el => el.name.toLowerCase().includes(search.toLowerCase()) ||  el.inn.toLowerCase().includes(search.toLowerCase()))}
                                noDataComponent={'Нет организаторов питания'}
                                defaultSortFieldId={"date"}
                                defaultSortAsc={true}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно организаторов:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        }
                                    }
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setSelectCateringOrganizer(row)
                                            setUpdateCateringOrganizers(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setSelectCateringOrganizer(row)
                                            setUpdateCateringOrganizers(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter>
                            <Link to="/catering_organizers/add" className="btn btn-primary" id="de">
                                Добавить ОП
                            </Link>
                        </CardFooter>
                        <FormUpdateCateringOrganizers setCateringOrganizers={value => setCateringOrganizers(value)} data={selectCateringOrganizer} updateCateringOrganizers={updateCateringOrganizers} setUpdateCateringOrganizers={value => setUpdateCateringOrganizers(value)} />
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ListTableCateringOrganizers
