import React, {FC, useEffect, useState} from 'react';
import {Alert, Col, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import Select from "react-select";
import {customStyles} from "../../settings";
import AxiosClient from "../../../api/AxiosClient";
import {ISchool} from "../../../models/KSHP/ISchool";
import DataTable from "react-data-table-component";
interface IModalUploadEmployeeAccountData {
    openModalUploadEmployeeAccountData: boolean,
    setOpenModalUploadEmployeeAccountData: (value: boolean) => void
}
const ModalUploadEmployeeAccountData: FC<IModalUploadEmployeeAccountData> = ({setOpenModalUploadEmployeeAccountData, openModalUploadEmployeeAccountData}) => {
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [schools, setSchools] = useState<ISchool[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'})
    const [search, setSearch] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [users, setUsers] = useState<any[]>([]);
    const [checkedUsers, setCheckedUsers] = useState<any[]>([]);
    const [checkAll, setCheckAll] = useState(false);
    useEffect(() => {
        fetchUsers(1)
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
    }, [])
    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchools(prevOptions => [...prevOptions, ...newData]);
    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const onChangeSchool = (data: { value: string, label: string }) => {
        fetchUsers(1, perPage, data.value)
        setSelectSchool(data)
    }

    const handleExport = () => {
        const params = {
            schoolId: selectSchool.value,
            checkAll,
            checkedUsers: checkAll ? [] : checkedUsers
        }
        AxiosClient.get(`/users/upload_employee_account_data`, { responseType: 'blob', params })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Пользователи.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    const fetchUsers = async (page: number, size = perPage, selectSchoolId = '',) => {
        setSearch(true)
        await AxiosClient.get(`/users`, {params: {page: page, per_page: size, selectSchoolId}})
            .then((response) => {
                setSearch(false)
                setUsers(response.data.users);
                if (checkAll){
                    setCheckedUsers(response.data.users)
                }
                setTotalRows(response.data.total);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });
    };

    const handlePageChange = (page: number) => {
        fetchUsers(page, perPage, selectSchool.value);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        await fetchUsers(page, newPerPage, selectSchool.value);
        setPerPage(newPerPage);
    };

    const onChangeCheckedUser = (value: boolean, user: any) => {
        if (!value){
            setCheckedUsers(checkedUsers.filter(el => el.id !== user.id))
        } else {
            setCheckedUsers([...checkedUsers, user])
        }
    }
    const checked = (id: number) => {
        return !!checkedUsers.find(el => el.id === id)
    }

    const onChangeCheckAll = (value: boolean) => {
        setCheckAll(value)
        if (value)
            setCheckedUsers(users)
        else
            setCheckedUsers([])
    }

    const columns: any = [
        {
            name: <input type="checkbox" className="form-check-input fs-18" checked={checkAll} onChange={event => onChangeCheckAll(event.target.checked)} />,
            selector: (row: any) => <input type="checkbox" className="form-check-input fs-18" checked={checked(row.id)} onChange={event => onChangeCheckedUser(event.target.checked, row)}/>,
            width: '60px'
        },
        {
            name: 'ФИО',
            cell: (row: any) => `${row.middle_name} ${row.name} (${row.term.name})`,
            wrap: true
        },
        {
            name: 'Классы',
            selector: (row: any) => row?.classes?.map((cl: any) => cl.name).join(", ")
        }
    ]

    return (
        <Modal id="createProjectModal" isOpen={openModalUploadEmployeeAccountData}
               toggle={() => setOpenModalUploadEmployeeAccountData(!openModalUploadEmployeeAccountData)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setOpenModalUploadEmployeeAccountData(!openModalUploadEmployeeAccountData)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Получить сотрудников КШП
            </ModalHeader>
            <ModalBody>
                <Alert color="danger">ПАРОЛИ БУДУТ ОБНОВЛЕНЫ!</Alert>
                <Row>
                    <Col sm={12}>
                        <label htmlFor="">Школа</label>
                        <Select
                            options={schools?.length > 0 ? [{
                                value: '',
                                label: 'Не выбрано'
                            }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                            value={selectSchool}
                            onChange={(change: any) => {
                                onChangeSchool({value: change.value, label: change.label})
                            }}
                            styles={customStyles}
                            placeholder="Поиск"
                            name="name"
                            isLoading={loadingOptions}
                            onMenuScrollToBottom={handleMenuScroll}
                        />
                    </Col>
                    <Col>
                        <label htmlFor="">Сотрудники</label>
                        <DataTable
                            columns={columns}
                            data={users}
                            noDataComponent={'Нет данных'}
                            progressComponent={<Spinner></Spinner>}
                            progressPending={search}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationRowsPerPageOptions={[5, 10, 20, 30]}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: 'Видно всего:',
                                    rangeSeparatorText: 'из'
                                }
                            }
                            highlightOnHover
                            customStyles={{
                                rows: {
                                    highlightOnHoverStyle: {
                                        backgroundColor: "#ffebb5"
                                    },
                                },
                                expanderRow: {
                                    style: {
                                        backgroundColor: "#ffebb5",
                                        paddingTop: '5px',
                                        paddingBottom: '5px'
                                    },
                                },
                            }}
                        />
                    </Col>
                </Row>
                <button className="btn btn-primary w-100 mt-2" onClick={() => handleExport()}>Создать пароли</button>
            </ModalBody>
        </Modal>
    );
};

export default ModalUploadEmployeeAccountData;
