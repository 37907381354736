import React, {FC, useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment/moment";

interface ITableFoodRation {
    selectChildren: string | number,
    date: Date
}
const TableFoodRation: FC<ITableFoodRation> = ({selectChildren, date}) => {

    const [foodRation, setFoodRation] = useState<any[]>([]);

    useEffect(() => {
        selectChildren &&
        AxiosClient.get('/students/food_ration', {params: {id: selectChildren, date: moment(date).format('YYYY-MM-DD').toString()}})
            .then(r => setFoodRation(r.data))
    }, [selectChildren, date]);

    const columnsDishes: any = [
        {
            selector: (row: any) => (<span className='font-weight-bold fs-13'>{row.dish} ({row.weight}гр.)</span>),
            wrap: true,
        },
    ]

    const columnsEating: any = [
        {
            name: <span className='font-weight-bold fs-15'></span>,
            selector: (row: any) => <span className="fs-15">{row.mealtime}</span>,
            wrap: true,
            width: "15%"
        },
        {
            name: <div className='font-weight-bold fs-15 '>
                <div className="d-flex ">
                    <span className='font-weight-bold fs-15 mx-n3'>Блюдо</span>
                </div>
            </div>,
            selector: (row: any) => (
                <DataTable
                    columns={columnsDishes}
                    data={row.dishes}
                    noDataComponent={'Нет данных'}
                    noTableHead
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            ),
            center: true,
            grow: 5,
            wrap: true,
            style: {
                display: 'block'
            }
        }
    ];


    return (
        <DataTable
            columns={columnsEating}
            data={foodRation}
            noDataComponent={'Нет данных'}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default TableFoodRation;
