import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import ModalConfirmExit from "./ModalConfirmExit";
import _ from "lodash";
import {customStyles} from "../../settings";
import Select from "react-select";

interface IModalAddCateringOrganizer {
    addCateringOrganizer: boolean,
    setAddCateringOrganizer: (value: boolean) => void,
    schoolId: string | number,
    setSchools: (value: any) => void
}
const ModalAddCateringOrganizer: FC<IModalAddCateringOrganizer> = ({setSchools,setAddCateringOrganizer, addCateringOrganizer, schoolId}) => {
    const idButtonSave: string = 'buttonSaveModalAddCateringOrganizer';
    const [dateContract, setDateContract] = useState(new Date());
    const [dateBegin, setDateBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [selectCateringOrganizers, setSelectCateringOrganizers] = useState<any>({value: '', label: 'Не выбрано'});
    const [confirmExit, setConfirmExit] = useState(false);
    const [currentObject, setCurrentObject] = useState<any>({catering_organizer_id: '', contract: '', date_contract: '',
        date_begin: '', date_end: '', discount_price: ''});
    const [newObject, setNewObject] = useState<any>({catering_organizer_id: '', contract: '', date_contract: '',
        date_begin: '', date_end: '', discount_price: ''});
    const [isChanges, setIsChanges] = useState(false);
    const [cateringOrganizers, setCateringOrganizers] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/catering_organizaers')
            .then(r => setCateringOrganizers(r.data))
        setNewObject(currentObject)
    }, []);

    useEffect(() => {
        if (addCateringOrganizer) {
            setCurrentObject({catering_organizer_id: '', contract: '', date_contract: '',
                    date_begin: '', date_end: '', discount_price: ''});
            setNewObject({catering_organizer_id: '', contract: '', date_contract: '',
                date_begin: '', date_end: '', discount_price: ''});
        }
    }, [addCateringOrganizer]);

    const confirmOrExit = () => {
        if(!_.isEqual(currentObject, newObject)){
            setConfirmExit(true);
        }else{
            setAddCateringOrganizer(false);
        }
    }

    return (
        <Modal id="createProjectModal" isOpen={addCateringOrganizer}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление ОП
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            catering_organizer_id: '',
                            contract: '',
                            date_contract: new Date(),
                            date_begin: new Date(),
                            date_end: new Date(),
                            school_id: '',
                            discount_price: 0.0
                        }
                    }
                    validationSchema={
                        Yup.object({
                            contract: Yup.string().matches(/^[a-zA-Zа-яА-Я0-9]+$/, 'Можно использовать только буквы и цифры').required('Обязательное поле'),
                            discount_price: Yup.number()
                        })
                    }
                    onSubmit={(values) => {
                        values.date_contract = dateContract
                        values.date_begin = dateBegin
                        values.date_end = dateEnd
                        values.school_id = schoolId.toString()

                        AxiosClient.post('/schools/add_catering_organizer', values)
                            .then(() => {
                                setAddCateringOrganizer(false)
                                AxiosClient.get('/schools',{params: {page: 1, per_page: 10}})
                                    .then((r) => setSchools(r.data))
                                    .catch(() => console.log('error'))
                            })
                            .catch(() => console.log('error'))

                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Организатор питания:
                                        <Select
                                            options={cateringOrganizers?.length > 0 ? [{
                                                value: '',
                                                label: 'Не выбрано'
                                            }, ...cateringOrganizers.map(v => ({value: v.id.toString(), label: v.name}))] : [{
                                                value: '',
                                                label: 'Не выбрано'
                                            }]}
                                            value={selectCateringOrganizers}
                                            onChange={(change: any) => {
                                                setSelectCateringOrganizers({value: change.value, label: change.label});
                                                setNewObject({...newObject, 'catering_organizer_id': change.value});
                                                setFieldValue('catering_organizer_id', change.value);
                                            }}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                            name="catering_organizer_id"
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Номер договора с ОП:
                                        <Field name="contract" className={`form-control ${errors.contract && touched.contract && "border border-2 border-danger"}`}
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'contract': e.target.value});
                                                   setFieldValue('contract', e.target.value);
                                               }}
                                        />
                                        </label>
                                    </Col>
                                    <Col>
                                        <label className="w-100">Дата договора с ОП:
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateContract}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onClose={(date) => {
                                                setDateContract(date[0])
                                                setNewObject({...newObject, 'date_contract': date[0]});
                                            }}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Дата с:
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateBegin}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onClose={(date) => {
                                                setDateBegin(date[0])
                                                setNewObject({...newObject, 'date_begin': date[0]});
                                            }}
                                        />
                                        </label>
                                    </Col>
                                    <Col>
                                        <label className="w-100">Дата по:
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateEnd}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onClose={(date) => {
                                                setDateEnd(date[0])
                                                setNewObject({...newObject, 'date_end': date[0]});
                                            }}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100">Скидка:
                                        <Field name="discount_price" className={`form-control ${errors.discount_price && touched.discount_price && "border border-2 border-danger"}`}
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'discount_price': e.target.value});
                                                   setFieldValue('discount_price', e.target.value);
                                               }}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2 mt-2">
                                    <button type="submit" className="btn btn-primary" id={idButtonSave}>Добавить</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setAddCateringOrganizer(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal>
    );
};

export default ModalAddCateringOrganizer;
