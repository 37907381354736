import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import Select from "react-select";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import AllAttendanceChildren from "../../components/KShP/Educator/AllAttendanceChildren";
import AxiosClient from "../../api/AxiosClient";
import {customStyles} from "../../components/settings";

const Educator = () => {

    const [classes, setClasses] = useState<any[]>([]);
    const [selectClass, setSelectClass] = useState({value: '', label: ''});

    useEffect(() => {
        AxiosClient.get('/users/classes')
            .then(r => {
                setClasses(r.data)
                r.data.length > 0 &&
                setSelectClass(r.data[0])
            })
            .catch(() => console.log('error'))
    }, []);

    useEffect(() => {
        if (classes && classes.length > 0) {
            setSelectClass({ value: classes[0].id, label: classes[0].name });
        }
    }, [classes]);


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Табель основной</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <WidgetRequiredFlatpikerPeriod/>
                            <div className="col">
                                <Card>
                                    <CardHeader className="fs-18 shadow">
                                        <label className="w-100">Класс:
                                            <Select
                                                className="mt-3 shadow w-100"
                                                value={selectClass}
                                                onChange={(selectedOption: any) => setSelectClass(selectedOption)}
                                                options={classes?.map(v => ({value: v.id, label: v.name }))}
                                                noOptionsMessage={() => "Нет классов"}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                            />
                                        </label>
                                    </CardHeader>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AllAttendanceChildren classes={classes} selectClass={selectClass.value}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Educator;
