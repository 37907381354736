import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {Alert, Col, Container, Row} from "reactstrap";
import SelectToUpdatePosition from "../../components/admin/position/SelectToUpdatePosition";
import PositionAdd from "../../components/admin/position/PositionAdd";
import PositionUpdate from "../../components/admin/position/PositionUpdate";
import {getPositions} from "../../store/reducers/admin/ActionCreator";

const SettingsPosition = () => {

    const dispatch = useAppDispatch();
    const {isError, positions} = useAppSelector(state => state.AdminReducer);
    const [addPosition, setAddPosition] = useState(false);

    useEffect(() => {
        dispatch(getPositions())
    }, [dispatch]);


    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdatePosition
                        setAddPosition={(val) => setAddPosition(val)}
                        positions={positions}/>
                    <div className="w-100 overflow-hidden">
                        <div className="d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {
                                                    addPosition ? <PositionAdd /> : <PositionUpdate/>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsPosition;
