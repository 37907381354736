import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import {Card, CardBody, CardFooter} from "reactstrap";
import ModalAddMealtime from "./ModalAddMealtime";
import ModalUpdateMealtime from "./ModalUpdateMealtime";

interface IListMealtime {
    listMealtime: any[],
    setListMealtime: (values: any[]) => void
}
const ListMealtime:FC<IListMealtime> = ({listMealtime, setListMealtime}) => {
    const [addMealtime, setAddMealtime] = useState(false);
    const [updateMealtime, setUpdateMealtime] = useState(false);
    const [selectMealtime, setSelectMealtime] = useState<any | null>(null);
    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Прием пищи</span>,
            selector: (row: any) => row.name,
            center: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Цена (руб.)</span>,
            selector: (row: any) => row.price,
            center: true
        }
    ]

    return (
        <Card>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={listMealtime}
                    noDataComponent={'Нет данных'}
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                    onRowClicked={(row) => {
                        setSelectMealtime(row)
                        setUpdateMealtime(true)
                    }}
                />
            </CardBody>
            <CardFooter>
                <div className="btn btn-primary" onClick={() => {
                    setAddMealtime(true)
                }}>Добавить</div>
            </CardFooter>
            {
                updateMealtime &&
                <ModalUpdateMealtime setListMealtime={value => setListMealtime(value)} listMealtime={listMealtime} updateMealtime={updateMealtime} setUpdateMealtime={value => setUpdateMealtime(value)} selectMealtime={selectMealtime}/>
            }
            {
                addMealtime &&
                <ModalAddMealtime listMealtime={listMealtime} setListMealtime={value => setListMealtime(value)} addMealtime={addMealtime} setAddMealtime={value => setAddMealtime(value)}/>
            }
        </Card>
    );
};

export default ListMealtime;
