import axios from "axios";

const $api = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
    timeout: 180000
})

$api.interceptors.request.use((config) => {
    const token: string | null = localStorage.getItem('ACCESS_TOKEN') ? localStorage.getItem('ACCESS_TOKEN') : sessionStorage.getItem('ACCESS_TOKEN');
    config.headers.setAuthorization(`Bearer ${token && window.atob(token)}`);
    return config;
})

$api.interceptors.response.use((response) => {
    return response
}, (error) => {
    const {response} = error;
    if (response.status === 401) {
        localStorage.removeItem('ACCESS_TOKEN')
        sessionStorage.removeItem('ACCESS_TOKEN')
        window.location.href = '/login'
    }

    throw error
})

export default $api;