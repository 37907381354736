import React, {FC, useEffect, useState} from 'react';
import {IPFCFoodRation} from "../../../models/parent/IPFCFoodRation";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";

interface ITablePfcFoodRation {
    selectChildren: string | number,
    date: Date
}

const TablePfcFoodRation: FC<ITablePfcFoodRation> = ({selectChildren, date}) => {

    const [pfcFoodRation, setPfcFoodRation] = useState<any[]>([]);


    useEffect(() => {
        selectChildren &&
        AxiosClient.get('/students/pfc_food_ration', {params: {id: selectChildren, date: moment(date).format('YYYY-MM-DD').toString()}})
            .then(r => setPfcFoodRation(r.data))
    }, [selectChildren, date]);

    const columnsPFC: any = [
        {
            name: <span className='font-weight-bold fs-18'>БЖУ</span>,
            selector: (row: IPFCFoodRation) => <span className="fs-13">{row.name}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold fs-18'>Кол-во</span>,
            selector: (row: IPFCFoodRation) => (
                row.count
            ),
            center: true
        }
    ];


    return (
        <DataTable
            columns={columnsPFC}
            data={pfcFoodRation}
            noDataComponent={'Нет данных'}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default TablePfcFoodRation;
