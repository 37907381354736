import React from 'react';
import {Card, CardBody} from "reactstrap";
import {Link} from "react-router-dom";
import avatar1 from "../../assets/images/users/avatar-1.jpg";

const EventCard = () => {
    return (
        <Card
            className={`profile-project-card shadow-none profile-project-warning`}>
            <CardBody className="p-4">
                <div className="d-flex">
                    <div className="flex-grow-1 text-muted overflow-hidden">
                        <h5 className="fs-14 text-truncate"><Link to="#"
                                                                  className="text-dark">Спектакль</Link>
                        </h5>
                        <p className="text-muted text-truncate mb-0">Дата: <span
                            className="fw-semibold text-dark">01.12.2023</span>
                        </p>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                        <div
                            className={`badge badge-soft-success fs-10`}>
                            Интересно
                        </div>
                    </div>
                </div>

                <div className="d-flex mt-4">
                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center gap-2">
                            <div>
                                <h5 className="fs-12 text-muted mb-0">
                                    Участники :</h5>
                            </div>
                            <div className="avatar-group">
                                <div className="avatar-group-item">
                                    <div className="avatar-xs">
                                        <img src={avatar1} alt=""
                                             className="rounded-circle img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default EventCard;
