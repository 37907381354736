import React, {FC} from 'react';
import {ButtonProps, Spinner} from "reactstrap";
import {useAppSelector} from "../../../hooks/redux";

interface ISubmitWithLoading{
    text: string,
    noActive?: boolean
}
const SubmitWithLoading: FC<ISubmitWithLoading & ButtonProps> = ({text,noActive , ...props}) => {

    const {isSend} = useAppSelector(state => state.WidgetReducer)

    return (
        <button type="submit" className={`btn btn-primary`} {...props} id={props.id} disabled={isSend || noActive}>{
            isSend ?
                <Spinner size={'sm'}>
                    Loading...
                </Spinner>
                :
                text
        }</button>
    );
};

export default SubmitWithLoading;
