import React from 'react';
import {Card, CardBody, CardHeader, ListGroup, ListGroupItem} from "reactstrap";
import {useStateContext} from "../hooks/useStateContext";
import {Helmet} from "react-helmet";

const Settings = () => {
    const {user} = useStateContext();

    const handleActive = (e: Event) => {
       (e.target as Element).classList.add('active', 'bg-opacity-25')
    }

    const handleUnActive = (e: Event) => {
        (e.target as Element).classList.remove('active', 'bg-opacity-25')
    }

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Настройки</title>
            </Helmet>
            <Card>
                <CardHeader><h4>Все справочники</h4></CardHeader>
                <CardBody>
                    <ListGroup flush>
                        {
                                user.term?.pages.map(page => {
                                    if (page.prefix_table.name === 'Справочник')
                                        return <ListGroupItem className="bg-light border-light fs-16 text-black"
                                                              onMouseEnter={(e: any) => handleActive(e)}
                                                              onMouseLeave={(e: any) => handleUnActive(e)}
                                                              key={page.id}
                                                              tag="a"
                                                              href={page.path}>
                                                                {page.name}</ListGroupItem>
                                    else
                                        return null
                                })
                        }
                    </ListGroup>
                </CardBody>
            </Card>
        </div>
    );
};

export default Settings;
