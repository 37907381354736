import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import Notification from "../../widgets/Notification";
import ModalNutritionChildren from "./ModalNutritionChildren";
import ModalBalance from "./ModalBalance";
import ModalExportStudents from "./ModalExportStudents";
import TableConfirmationExportStudents from "./TableConfirmationExportStudents";
import TableConfirmationExportStudentsTypePlannedMenu from "./TableConfirmationExportStudentsTypePlannedMenu";
import TableConfirmationExportStudentsBenefit from "./TableConfirmationExportStudentsBenefit";
import TableConfirmationExportClassNutritionClildren from "./TableConfirmationExportClassNutritionClildren";
import ModalExportStudentsParent from "./ModalExportStudentsParent";
import ModalExportTeachersClasses from "./ModalExportTeachersClasses";
import ModalNutritionChildrenCountInClass from "./ModalNutritionChildrenCountInClass";
import TableConfirmationExportClassNutritionClildrenCountInClass
    from "./TableConfirmationExportClassNutritionClildrenCountInClass";

const Reports = () => {
    const [openModalFileNutritionChildren, setOpenModalFileNutritionChildren] = useState(false);
    const [openModalFileNutritionChildrenCountInClass, setOpenModalFileNutritionChildrenCountInClass] = useState(false);
    const [openModalFileBalance, setOpenModalFileBalance] = useState(false);
    const [tableConfirmationImport, setTableConfirmationImport] = useState<string>('');
    const [listImport, setListImport] = useState<any[]>([]);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [openModalExportStudents, setOpenModalExportStudents] = useState(false);
    const [students, setStudents] = useState<any[]>([]);
    const [schoolId, setSchoolId] = useState('');
    const [buildingId, setBuildingId] = useState('');
    const [classId, setClassId] = useState('');
    const [totalRowsStudents, setTotalRowsStudents] = useState(0);
    const [groupBy, setGroupBy] = useState<any>({value: 'default', label: 'Не группировать'});
    const [openModalExportStudentsParent, setOpenModalExportStudentsParent] = useState(false);
    const [openModalExportTeachersClasses, setOpenModalExportTeachersClasses] = useState(false);


    function selectTable(name: string) {
        const tables = {
            "": <></>,
            "nutritionChildren": <TableConfirmationExportClassNutritionClildren listImport={listImport} />,
            "nutritionChildrenCountInClass": <TableConfirmationExportClassNutritionClildrenCountInClass listImport={listImport}/>,
            "default": <TableConfirmationExportStudents groupBy={groupBy.value} setStudents={value => setStudents(value)} students={students} classId={classId} totalRowsStudents={totalRowsStudents} buildingId={buildingId} schoolId={schoolId}/>,
            "typePlannedMenu": <TableConfirmationExportStudentsTypePlannedMenu groupBy={groupBy.value} setStudents={value => setStudents(value)} students={students} classId={classId} totalRowsStudents={totalRowsStudents} buildingId={buildingId} schoolId={schoolId}/>,
            "benefit": <TableConfirmationExportStudentsBenefit groupBy={groupBy.value} setStudents={value => setStudents(value)} students={students} classId={classId} totalRowsStudents={totalRowsStudents} buildingId={buildingId} schoolId={schoolId}/>
        }
        return tables[name as keyof typeof tables];
    }

    return (
        <>
            <Row>
                <Col xxl={2} xl={3} lg={3}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setTableConfirmationImport("null");
                                setOpenModalFileNutritionChildren(true);
                            }}
                    >Питание детей</button>
                </Col>
                <Col xxl={2} xl={3} lg={3}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setTableConfirmationImport("null");
                                setOpenModalFileNutritionChildrenCountInClass(true);
                            }}
                    >Кол-во питающихся</button>
                </Col>
                <Col xxl={2} xl={3} lg={3}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setOpenModalFileBalance(true);
                            }}
                    >Баланс</button>
                </Col>
                <Col xxl={2} xl={3} lg={3}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setOpenModalExportStudents(true);
                            }}
                    >Ученики</button>
                </Col>
                <Col xxl={2} xl={3} lg={3}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setOpenModalExportStudentsParent(true);
                            }}
                    >Дети и родители</button>
                </Col>
                <Col xxl={2} xl={3} lg={3}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setOpenModalExportTeachersClasses(true);
                            }}
                    >Учителя и классы</button>
                </Col>
            </Row>
            {selectTable(tableConfirmationImport)}
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
            {
                openModalFileNutritionChildren &&
                <ModalNutritionChildren openModalFileNutritionChildren={openModalFileNutritionChildren}
                                        setOpenModalFileNutritionChildren={(value: boolean) => setOpenModalFileNutritionChildren(value)}
                                        setTableConfirmationImport={(value: any) => setTableConfirmationImport(value)}
                                        setListImport={(value: any) => setListImport(value)} />
            }
            {
                openModalFileNutritionChildrenCountInClass &&
                <ModalNutritionChildrenCountInClass openModalFileNutritionChildrenCountInClass={openModalFileNutritionChildrenCountInClass}
                                        setOpenModalFileNutritionChildrenCountInClass={(value: boolean) => setOpenModalFileNutritionChildrenCountInClass(value)}
                                        setTableConfirmationImport={(value: any) => setTableConfirmationImport(value)}
                                        setListImport={(value: any) => setListImport(value)} />
            }
            {
                openModalFileBalance &&
                <ModalBalance setOpenModalFileBalance={value => setOpenModalFileBalance(value)} openModalFileBalance={openModalFileBalance}/>
            }
            {
                openModalExportStudents &&
                <ModalExportStudents setTableConfirmationImport={value => setTableConfirmationImport(value)} groupBy={groupBy} setGroupBy={value => setGroupBy(value)} setTotalRowsStudents={value => setTotalRowsStudents(value)} setClassId={value => setClassId(value)} setBuildingId={value => setBuildingId(value)} setSchoolId={value => setSchoolId(value)} setStudents={value => setStudents(value)} openModalExportStudents={openModalExportStudents} setOpenModalExportStudents={value => setOpenModalExportStudents(value)}/>
            }
            {
                openModalExportStudentsParent &&
                <ModalExportStudentsParent setOpenModalExportStudentsParent={value => setOpenModalExportStudentsParent(value)} openModalExportStudentsParent={openModalExportStudentsParent}/>
            }
            {
                openModalExportTeachersClasses &&
                <ModalExportTeachersClasses openModalExportTeachersClasses={openModalExportTeachersClasses} setOpenModalExportTeachersClasses={value => setOpenModalExportTeachersClasses(value)}/>
            }
            </>
    );
};

export default Reports;
