import React, {useState} from 'react';
import {Form, Formik, Field, ErrorMessage} from "formik";
import * as Yup from 'yup'
import {
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Row,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import classNames from 'classnames';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {addUser} from "../../../store/reducers/admin/ActionCreator";

const UserAdd = () => {
    const dispatch = useAppDispatch();
    const {cities,
            organizations,
            terms,
            positions,
            countries} = useAppSelector(state => state.AdminReducer)
    const [file, setFile] = useState<File | string>('')
    const [activeTab, setActiveTab] = useState<string>("1");
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <>
            <Formik
                initialValues={
                    {
                        firstname: "",
                        middlename: "",
                        lastname: "",
                        login: "",
                        phone: "",
                        email: "",
                        position: 1,
                        company: 1,
                        city: 1,
                        country: 3,
                        role: 2,
                        newpassword: '',
                        newpassword_confirmation: ''
                    }
                }
                validationSchema={
                    Yup.object(
                        {
                            firstname: Yup.string().required('Обязательное поле*'),
                            middlename: Yup.string().required('Обязательное поле*'),
                            lastname: Yup.string().required('Обязательное поле*'),
                            login: Yup.string().required('Обязательное поле*'),
                            phone: Yup.string().required('Обязательное поле*'),
                            position: Yup.number(),
                            company: Yup.number(),
                            city: Yup.number(),
                            country: Yup.number(),
                            role: Yup.number(),
                            newpassword: Yup.string().required('Введите новый пароль').min(8, 'Минимальная длина пароля 8 символов'),
                            newpassword_confirmation: Yup.string().required('Повторите новый пароль').oneOf([Yup.ref('newpassword'), "null"], 'Пароли не совпадают')
                        }
                    )
                }
                onSubmit={
                    (value) => {
                        const formdata = new FormData();
                        formdata.append('firstname', value.firstname)
                        formdata.append('middlename', value.middlename)
                        formdata.append('lastname', value.lastname)
                        formdata.append('phone', value.phone)
                        formdata.append('email', value.email)
                        formdata.append('position', value.position.toString())
                        formdata.append('company', value.company.toString())
                        formdata.append('city', value.city.toString())
                        formdata.append('country', value.country.toString())
                        formdata.append('role', value.role.toString())
                        formdata.append('login', value.login.toString())
                        formdata.append('newpassword', value.newpassword)
                        formdata.append('newpassword_confirmation', value.newpassword_confirmation)
                        formdata.append('img', file.toString())

                        dispatch(addUser(formdata))
                    }
                }
            >
                <Form encType="multipart/form-data">
                    <Row>
                        <Col xxl={3}>
                            <Card className="mt-n5 mx-1">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img src={require('../../../assets/images/users/user-dummy-img.jpg')}
                                                 className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                 alt="user-profile"/>
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input id="profile-img-file-input" type="file"
                                                       className="profile-img-file-input" name="photo"
                                                       onChange={(e) => setFile((e.target.files as FileList)[0] as File)}/>
                                                <Label htmlFor="profile-img-file-input"
                                                       className="profile-photo-edit avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <h5 className="fs-16 mb-1">Новый пользователь</h5>
                                        <p className="text-muted mb-0">Нет Компании</p>
                                        <p className="text-muted mb-0">Нет Должности</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xxl={9}>
                            <Card className="mt-xxl-n5 mx-n3">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                         role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classNames({active: activeTab === "1"})}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}
                                                type="button">
                                                <i className="fas fa-home"></i>
                                                Персональные данные
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="middlenameInput"
                                                               className="form-label">Фамилия</Label>
                                                        <Field type="text" className="form-control" id="middlenameInput"
                                                               name="middlename"
                                                               placeholder="Введите фамилию"/>
                                                        <ErrorMessage name="middlename">{(msg) => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="countryInput"
                                                               className="form-label">Страна</Label>
                                                        <Field
                                                            as="select"
                                                            className="form-select"
                                                            id="countryInput"
                                                            name="country"
                                                        >
                                                            {
                                                                countries.map((country) => {
                                                                    return <option key={country.id}
                                                                                   value={country.id}>{country.name}</option>
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="firstnameInput"
                                                               className="form-label">Имя</Label>
                                                        <Field type="text" className="form-control" id="firstnameInput"
                                                               name="firstname"
                                                               placeholder="Введите имя"/>
                                                        <ErrorMessage name="firstname">{(msg) => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="cityInput" className="form-label">Город</Label>
                                                        <Field
                                                            as="select"
                                                            className="form-select"
                                                            id="cityInput"
                                                            name="city"
                                                        >
                                                            {
                                                                cities.map((city) => {
                                                                    return <option key={city.id}
                                                                                   value={city.id}>{city.name}</option>
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="lastnameInput"
                                                               className="form-label">Отчество</Label>
                                                        <Field type="text" className="form-control" id="lastnameInput"
                                                               name="lastname"
                                                               placeholder="Введите отчество"/>
                                                        <ErrorMessage name="lastname">{(msg) => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="designationInput"
                                                               className="form-label">Организация</Label>
                                                        <Field
                                                            as="select"
                                                            className="form-select"
                                                            name="company">
                                                            {
                                                                organizations.map(element => {
                                                                    return <option key={element.id}
                                                                                   value={element.id}>{element.name}</option>
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="designationInput"
                                                               className="form-label">Должность</Label>
                                                        <Field
                                                            as="select"
                                                            className="form-select"
                                                            name="position"
                                                        >
                                                            {
                                                                positions.map((position) => {
                                                                    return <option key={position.id}
                                                                                   value={position.id}>{position.name}</option>
                                                                })
                                                            }
                                                        </Field>
                                                        <ErrorMessage name="position">{(msg) => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="emailInput" className="form-label">Почта</Label>
                                                        <Field type="text" className="form-control" id="emailInput"
                                                               name="email"
                                                               placeholder="Введите почту"/>
                                                        <ErrorMessage name="email">{(msg) => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="roleInput" className="form-label">Роль</Label>
                                                        <Field
                                                            as="select"
                                                            id="roleInput"
                                                            className="form-select"
                                                            name="role">
                                                            {
                                                                terms.map((term) => {
                                                                    return <option key={term.id}
                                                                                   value={term.id}>{term.name}</option>
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="phonenumberInput"
                                                               className="form-label">Телефон</Label>
                                                        <Field type="text" className="form-control" name="phone"
                                                               id="phonenumberInput"
                                                               placeholder="Введите свой телефон"/>
                                                        <ErrorMessage name="phone">{(msg) => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="loginInput"
                                                               className="form-label">Логин</Label>
                                                        <Field type="text" className="form-control"
                                                               id="loginInput" name="login"
                                                               placeholder="Введите логин"/>
                                                        <ErrorMessage name="login">{(msg) => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div>
                                                        <Label htmlFor="newpasswordInput" className="form-label">Новый
                                                            пароль*</Label>
                                                        <Field type="password" className="form-control"
                                                               name="newpassword"
                                                               id="newpasswordInput"
                                                               placeholder="Введите новый пароль"/>
                                                        <ErrorMessage name="newpassword">{(msg) => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div>
                                                        <Label htmlFor="confirmpasswordInput" className="form-label">Подтвердите
                                                            пароль*</Label>
                                                        <Field type="password" className="form-control"
                                                               name="newpassword_confirmation"
                                                               id="confirmpasswordInput"
                                                               placeholder="Подтвердите пароль"/>
                                                        <ErrorMessage name="newpassword_confirmation">{(msg) => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </div>
                                                </Col>

                                                <Col lg={12} className="mt-3">
                                                    <div className="hstack gap-2 justify-content-end">
                                                        <button type="submit"
                                                                className="btn btn-primary">Добавить
                                                        </button>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </>
    );
};

export default UserAdd;
