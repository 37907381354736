import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import ModalAddCateringOrganizer from "./ModalAddCateringOrganizer";
import ModalUpdateCateringOrganizer from "./ModalUpdateCateringOrganizer";
import {ICateringOrganizer} from "../../../models/KSHP/ICateringOrganizer";
import AxiosClient from "../../../api/AxiosClient";
interface IListCateringOrganizer {
    schoolId: number | string,
    setSchools: (value: any) => void,
    cateringOrganizerFilter: string
}

const ListCateringOrganizer: FC<IListCateringOrganizer> = ({schoolId, setSchools, cateringOrganizerFilter}) => {

    const [addCateringOrganizer, setAddCateringOrganizer] = useState(false);
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState<any | null>(null);
    const [updateCateringOrganizer, setUpdateCateringOrganizer] = useState(false);
    const [listCateringOrganizers, setListCateringOrganizers] = useState<ICateringOrganizer[]>([]);

    useEffect(() => {
        AxiosClient.get(`/schools/${schoolId}/catering_organizers`, {params: {cateringOrganizerFilter}})
            .then(r => setListCateringOrganizers(r.data))
    }, [schoolId]);

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'></span>,
            selector: () => '',
            width: '20px',
            wrap: true,
        },
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Организатор питания</span>,
            selector: (row: any) => row.catering_organizer.name,
            wrap: true,
            width: '14rem'
        },
        {
            name: <span className='font-weight-bold fs-13'>Номер договора</span>,
            selector: (row: any) => row.contract,
            wrap: true,
            hide: 959,
            width: '11rem'
        },
        {
            name: <span className='font-weight-bold fs-13'>Дата с</span>,
            selector: (row: any) => moment(new Date(row.date_begin)).format("DD.MM.YYYY"),
            wrap: true,
            hide: 959,
            width: '11rem'
        },
        {
            name: <span className='font-weight-bold fs-13'>Дата по</span>,
            selector: (row: any) => moment(new Date(row.date_end)).format("DD.MM.YYYY"),
            wrap: true,
            hide: 959,
            width: '11rem',
        },
        {
            name: <span className='font-weight-bold fs-13'>Скидка</span>,
            selector: (row: any) => row.discount_price,
            wrap: true,
            hide: 959
        },
        {
            name: <span className='font-weight-bold fs-13'>Сб</span>,
            selector: (row: any) => '',
            wrap: true,
            width: '50px',
            hide: 959
        }
    ]

    return (
        <Card>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={listCateringOrganizers}
                    noDataComponent={'Нет данных'}
                    noTableHead
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно ОП:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                    onRowClicked={
                    (row) => {
                        if(window.innerWidth <= 768){
                            setSelectCateringOrganizer(row)
                            setUpdateCateringOrganizer(true)
                        }
                    }
                }
                    onRowDoubleClicked={
                        (row) => {
                            if(window.innerWidth > 768){
                                setSelectCateringOrganizer(row)
                                setUpdateCateringOrganizer(true)
                            }
                        }
                    }
                />
                <Row className="m-0 mt-2">
                    <Col sm={12} lg={4} xxl={3}>
                        <button className="btn btn-primary w-100 mt-1" id="addNewProject" onClick={() => setAddCateringOrganizer(true)}>Добавить ОП</button>
                    </Col>
                </Row>

                <ModalAddCateringOrganizer setSchools={value => setSchools(value)} schoolId={schoolId} addCateringOrganizer={addCateringOrganizer} setAddCateringOrganizer={value => setAddCateringOrganizer(value)}/>
                <ModalUpdateCateringOrganizer setSchools={value => setSchools(value)} selectCateringOrganizer={selectCateringOrganizer} updateCateringOrganizer={updateCateringOrganizer} setUpdateCateringOrganizer={value => setUpdateCateringOrganizer(value)}/>
            </CardBody>
        </Card>
    );
};

export default ListCateringOrganizer;
