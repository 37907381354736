import React, {FC, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import UpdateImageForm from "./UpdateImageForm";
import classNames from "classnames";
import UpdateDataUserForm from "./UpdateDataUserForm";
import UpdatePasswordForm from "./UpdatePasswordForm";
import LoginHistory from "./LoginHistory";
import {useAppSelector} from "../../../hooks/redux";

const UserUpdate: FC = () => {
    const [activeTab, setActiveTab] = useState("1");
    const {selectUser} = useAppSelector(state => state.AdminReducer)

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    return (
        <>
            <Col>
                <Row className="mt-sm-3 mt-3 mt-xl-0 mt-lg-0 mt-xll-0">
                    {
                        selectUser &&
                        <Col xxl={12} xl={12}>
                            <Row>
                                <UpdateImageForm />
                            </Row>
                            <Row>
                                <Col>
                                    <Card className="my-0">
                                        <CardHeader>
                                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                                 role="tablist">
                                                <NavItem>
                                                    <NavLink
                                                        className={classNames({active: activeTab === "1"})}
                                                        onClick={() => {
                                                            tabChange("1");
                                                        }}
                                                        type="button">
                                                        <i className="fas fa-home"></i>
                                                        Персональные данные
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classNames({active: activeTab === "2"})}
                                                        onClick={() => {
                                                            tabChange("2");
                                                        }}
                                                        type="button">
                                                        <i className="fas fa-home"></i>
                                                        Пароль
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classNames({active: activeTab === "3"})}
                                                        onClick={() => {
                                                            tabChange("3");
                                                        }}
                                                        type="button">
                                                        <i className="fas fa-home"></i>
                                                        История посещений
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardHeader>
                                        <CardBody className="p-4">
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1" className="align-items-center">
                                                    <UpdateDataUserForm />
                                                </TabPane>
                                                <TabPane tabId="2" className="align-items-center">
                                                    <UpdatePasswordForm />
                                                </TabPane>
                                                <TabPane tabId="3" className="align-items-center">
                                                    <LoginHistory/>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
            </Col>
        </>
    );
};

export default UserUpdate;
