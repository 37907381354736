import React, {useState} from 'react';
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {setSelectChat} from "../../store/reducers/widgets/WidgetsSlice";

const RequireChat = () => {

    const dispatch = useAppDispatch();
    const {selectChat} = useAppSelector(state => state.WidgetReducer)
    const [currentChat, setCurrentChat] = useState<number | null>(null);


    return (
        <SimpleBar
            className="chat-room-list pt-3"
            style={{margin: "-16px 0px 0px"}}
        >
            <div className="d-flex align-items-center px-4 mb-2">
                <div className="flex-grow-1">
                    <h4 className="mb-0 fs-14 bold text-truncate text-uppercase">
                        Чаты СКДОУ
                    </h4>
                </div>
            </div>

            <div className="chat-message-list">
                <ul
                    className="list-unstyled chat-list chat-user-list users-list"
                    id="userList"
                >
                    <li
                        className={
                            currentChat === selectChat?.id ? "active" : ""
                        }
                    >
                        <Link
                            to="#"
                            onClick={(e) => {
                                dispatch(setSelectChat(
                                    {
                                        id: 0,
                                        name: 'Виртуальный помощник',
                                        isRequire: true
                                    }
                                ))
                                setCurrentChat(0)
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <div
                                    className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                    <div className="avatar-xxs">
                                        <div
                                            className={
                                                "avatar-title rounded-circle bg-primary userprofile"
                                            }
                                        >
                                            В
                                        </div>
                                    </div>
                                    <span className="user-status"></span>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-0">Виртуальный помощник</p>
                                </div>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </SimpleBar>
    );
};

export default RequireChat;
