import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Col, Label, Row} from "reactstrap";
import * as Yup from "yup"
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {updateCountry} from "../../../store/reducers/admin/ActionCreator";

const UpdateDataCountryForm = () => {
    const dispatch = useAppDispatch();
    const {selectCountry} = useAppSelector(state => state.AdminReducer)

    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    country: `${selectCountry?.name}`
                }
            }
            validationSchema={
                Yup.object(
                    {
                        country: Yup.string().required('Обязательное поле')
                    }
                )
            }
            onSubmit={
                (value) => {
                    dispatch(updateCountry(value, selectCountry!.id))
                }
            }
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="countryInput"
                                   className="form-label">Страна</Label>
                            <Field type="text" className="form-control" id="countryInput"
                                   name="country"
                                   placeholder="Введите страну"/>
                            <ErrorMessage name="country">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataCountryForm;
