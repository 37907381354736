import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import AxiosClient from "../../api/AxiosClient";
import ListMyPayments from "../../components/KShP/MyPayments/ListMyPayments";
import {Link} from "react-router-dom";
import payVTB from '../../assets/images/payments/schoolAndKindergartenVTBpay.jpg'
import ElemPay from "../../components/KShP/MyPayments/ElemPay";
const MyPayments = () => {
    const [children, setChildren] = useState<any[]>([]);
    const [selectChildren, setSelectChildren] = useState('');

    useEffect(() => {
        AxiosClient.get('/users/students')
            .then(r => {
                setChildren(r.data)
                r.data.length > 0 && setSelectChildren(r.data[0].id)
            })
            .catch(() => console.log('error'))
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Мои оплаты</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <Col xxl={3}>
                                    <Card>
                                        <CardBody className="fs-18 ">
                                            Ребенок:
                                            <select className="form-select mt-1" value={selectChildren}
                                                    onChange={event => setSelectChildren(event.target.value)}>
                                                {
                                                    children.map(child =>
                                                        (<option key={child.id} value={child.id}>{child.name}</option>)
                                                    )
                                                }
                                            </select>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <Link to="http://online.vtb.ru/i/pay">
                                                <img src={payVTB} width='100%' height={'100%'} className="mb-3" alt="payVTB"/>
                                            </Link>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col>
                                    <ListMyPayments selectChildren={selectChildren}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
};

export default MyPayments;
