import React from 'react';

const GraphLoaders = () => {
    return (
        <div className="graphLoader">
            <div className="graphLoader-post">
                <div className="graphLoader-avatar" style={{height: '100px'}}></div>
                <div className="graphLoader-avatar" style={{height: '150px'}}></div>
                <div className="graphLoader-avatar" style={{height: '60px'}}></div>
            </div>
            <div className="graphLoader-line"></div>
        </div>
    );
};

export default GraphLoaders;
