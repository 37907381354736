import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import * as Yup from "yup";
import AxiosClient from "../../../api/AxiosClient";
import {Field, Form, Formik} from "formik";
import Select from "react-select";
import {customStyles} from "../../settings";

interface IModalAddStatus {
    addStatus: boolean,
    setAddStatus: (value: boolean) => void,
    addMyStatuses: any[],
    setAddMyStatuses: (value: any[]) => void
}

const ModalAddStatus: FC<IModalAddStatus> = ({setAddStatus, setAddMyStatuses, addMyStatuses, addStatus}) => {

    const [statuses, setStatuses] = useState<any[]>([]);
    const [selectStatuses, setSelectStatuses] = useState<{ value: string, label: string }>({ value: '', label: 'Не выбрано' })

    useEffect(() => {
        AxiosClient.get('/statuses')
            .then((r => setStatuses(r.data)))
    }, []);

    return (
        <Modal id="createProjectModal" isOpen={addStatus}
               toggle={() => setAddStatus(!addStatus)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddStatus(!addStatus)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление статуса
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            status_id: selectStatuses.value,
                        }
                    }
                    validationSchema={
                        Yup.object({
                            status_id: Yup.string().required('Require'),
                        })
                    }
                    onSubmit={(values, {setErrors}) => {
                        const isDuplicate = addMyStatuses.some(m => m.id.toString() === values.status_id.toString())
                        if (isDuplicate) {
                            setErrors({status_id: 'Такой статус уже существует'});
                        } else {
                            setAddMyStatuses([
                                ...addMyStatuses,
                                statuses.find(el => el.id.toString() === values.status_id)
                            ])
                            setAddStatus(false)
                        }
                    }}
                >
                    {
                        ({errors, touched, setFieldValue}) => (
                                <Form>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">Статус:
                                                <Field name="status_id">
                                                    {() => (
                                                        <Select
                                                            options={statuses?.length > 0 ? [{
                                                                value: '',
                                                                label: 'Не выбрано'
                                                            }, ...statuses.map(v => ({ value: v.id.toString(), label: v.name}))] : [{ value: '', label: 'Не выбрано' }]}
                                                            value={selectStatuses}
                                                            onChange={(change: any) => {
                                                                setSelectStatuses({ value: change.value, label: change.label })
                                                                setFieldValue('status_id', change.value)
                                                            }}
                                                            styles={customStyles}
                                                            placeholder="Поиск"
                                                            name="status_id"
                                                        />
                                                    )}
                                                </Field>
                                            </label>
                                            {errors.status_id && touched.status_id ? (
                                                <div className="text-danger">{errors.status_id.toString()}</div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                    <button type="submit" className="btn btn-primary mt-1" id="addNewProject">Добавить</button>
                                </Form>
                            )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddStatus;
