import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";
import DataTable from "react-data-table-component";
import ModalAddTypePlannedMenu from "./ModalAddTypePlannedMenu";
import ModalUpdateTypePlannedMenu from "./ModalUpdateTypePlannedMenu";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import Notification from "../../widgets/Notification";

const ListTypePlannedMenu = () => {
    const [search, setSearch] = useState('')
    const [selectTypePlannedMenu, setSelectTypePlannedMenu] = useState<any | null>(null);
    const [addTypePlannedMenu, setAddTypePlannedMenu] = useState(false);
    const [updateTypePlannedMenu, setUpdateTypePlannedMenu] = useState(false);
    const [dataFiltering, setDataFiltering] = useState<any[]>([]);
    const [typePlannedMenus, setTypePlannedMenus] = useState<any[]>([]);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');

    useEffect(() => {
        AxiosClient.get('/type_planned_menu')
            .then(r => {
                setDataFiltering(r.data)
                setTypePlannedMenus(r.data)
            })
    }, []);

    useEffect(() => {
        setDataFiltering(filtering())
    }, [search, typePlannedMenus])

    const filtering = () => {
        let data = typePlannedMenus;

        if(search) {
            data = data.filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
        }

        return data;
    }

    const removeTypePlannedMenu = (id: any) => {
        AxiosClient.post(`/type_planned_menu/archive/${id}`)
            .then((r) => {
                AxiosClient.get('/type_planned_menu')
                    .then(r => {
                        setDataFiltering(r.data)
                        setTypePlannedMenus(r.data)
                    })
                setNotificationType('success');
                setNotificationMessage('Готово, тип планового меню в архиве');
                setShowNotification(true);
            })
            .catch((error) => {
                console.log(error);
                setNotificationType('error');
                setNotificationMessage('Ошибка, не удалось добавить в архив');
                setShowNotification(true);
            })
    }

    const columns : any = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Наименование</span>,
            selector: (row: any) => row.name,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold fs-13'></span>,
            selector: (row: any) => <i className="bx bx-archive-in fs-24 cursor-pointer" onClick={() => {removeTypePlannedMenu(row.id)}}></i>,
            width: '60px'
        }
    ]
    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label className="w-100">Наименование:
                        <input type="text" className='form-control' value={search} onChange={(e) => setSearch(e.target.value)} />
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={dataFiltering}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно плановых меню:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setSelectTypePlannedMenu(row)
                                            setUpdateTypePlannedMenu(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setSelectTypePlannedMenu(row)
                                            setUpdateTypePlannedMenu(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter>
                            <button className="btn btn-primary" onClick={() => setAddTypePlannedMenu(true)}>Добавить</button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <ModalAddTypePlannedMenu setTypePlannedMenus={value => setTypePlannedMenus(value)} addTypePlannedMenu={addTypePlannedMenu} setAddTypePlannedMenu={value => setAddTypePlannedMenu(value)}/>
            <ModalUpdateTypePlannedMenu setTypePlannedMenus={value => setTypePlannedMenus(value)} updateTypePlannedMenu={updateTypePlannedMenu} setUpdateTypePlannedMenu={value => setUpdateTypePlannedMenu(value)} selectTypePlannedMenu={selectTypePlannedMenu}/>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default ListTypePlannedMenu;
