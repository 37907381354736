import React from 'react';
import {Col, Container, Row} from "reactstrap";

import AboutMe from "../components/profile/AboutMe";
import GuestInfo from "../components/profile/GuestInfo";
import GuestEvents from "../components/profile/GuestEvents";
import GuestHeaderInfo from "../components/profile/GuestHeaderInfo";

const ProfileGuest = () => {
    return (
        <div className="page-content">
            <Container fluid>
               <GuestHeaderInfo />
                <Row>
                    <Col lg={12}>
                        <div>
                            <Row>
                                <Col xxl={3}>
                                    <GuestInfo/>
                                </Col>
                                <Col xxl={9}>
                                    <AboutMe/>
                                    <GuestEvents/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ProfileGuest;
