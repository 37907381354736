import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setModalAddRegisterDefectEquipment,
} from "../../../store/reducers/widgets/WidgetsSlice";
import ModalAddRegisterDefectEquipment from "../modal/ModalAddRegisterDefectEquipment";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import ModalUpdateRegisterDefectEquipment from "../modal/ModalUpdateRegisterDefectEquipment";
import TableLoaders from "../../layouts/SceletonLoaders/TableLoaders";


const WidgetTableRegisterDefectEquipment = () => {

    const dispatch = useAppDispatch();
    const {date, modalAddRegisterDefectEquipment} = useAppSelector(state => state.WidgetReducer)

    const [update, setUpdate] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [idDoc, setIdDoc] = useState<string>('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        WidgetService.getDispatchServiceRequest(date).then(r => {
            setData(r.data)
            setLoading(false)
        })
    }, [date]);


    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Дата</span>,
            selector: (row: any) => row.date,
            sortable: true,
            compact: true,
            width: '20%'
        },
        {
            name: <span className='font-weight-bold fs-13'>Объект ремонта</span>,
            selector: (row: any) => row.equipment,
            wrap: true,
            compact: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Склад</span>,
            selector: (row: any) => row.sklad,
            wrap: true,
            compact: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Статус</span>,
            selector: (row: any) => {
                switch (row.status) {
                    case "В ремонте":
                        return <span className="badge bg-warning fs-12">{row.status}</span>
                    case "Закрыт":
                        return <span className="badge bg-danger fs-12">{row.status}</span>
                    case "Зарегистрирован":
                        return <span className="badge bg-info fs-12">{row.status}</span>
                }
            },
            compact: true,
            width: '25%'
        }
    ];

    return (
        <>
            {
                loading ?
                    <TableLoaders/>
                    :
                <Card>
                    <CardHeader>
                        Заявки диспетчерской службы
                    </CardHeader>
                    <CardBody className='align-items-center'>
                        <DataTable
                            columns={columns}
                            data={data}
                            noDataComponent={'В данной группе нет отклонений'}
                            defaultSortFieldId={"date"}
                            defaultSortAsc={true}
                            highlightOnHover
                            pagination
                            paginationRowsPerPageOptions={[5, 10, 20, 30]}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: 'Видно заявок:',
                                    rangeSeparatorText: 'из'
                                }
                            }
                            customStyles={{
                                rows: {
                                    highlightOnHoverStyle: {
                                        backgroundColor: "#ffebb5"
                                    }
                                }
                            }}
                            onRowClicked={
                                (row) => {
                                    if(window.innerWidth <= 768){
                                        setIdDoc(row.doc_id)
                                        setUpdate(true)
                                    }
                                }
                            }
                            onRowDoubleClicked={
                                (row) => {
                                    if(window.innerWidth > 768){
                                        setIdDoc(row.doc_id)
                                        setUpdate(true)
                                    }
                                }
                            }
                        />
                    </CardBody>
                    <CardFooter className='d-flex justify-content-between'>
                        <div className="button_modal mx-1">
                            <button className="btn btn-primary w-100"
                                    onClick={() => dispatch(setModalAddRegisterDefectEquipment(true))}>Добавить
                            </button>
                        </div>
                    </CardFooter>
                    {
                        modalAddRegisterDefectEquipment &&
                        <ModalAddRegisterDefectEquipment/>
                    }
                    {
                        update &&
                        <ModalUpdateRegisterDefectEquipment id={idDoc} setModalUpdate={data => setUpdate(data)} modalUpdate={update}/>
                    }
                </Card>
            }
        </>
    );
};

export default WidgetTableRegisterDefectEquipment;
