import React, { useEffect, useState, useCallback } from 'react'
import {Card, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap"
import DataTable from "react-data-table-component"
import { Russian } from "flatpickr/dist/l10n/ru"
import Flatpickr from "react-flatpickr"
import ModalUpdateHomeEducation from "./ModalUpdateHomeEducation"
import ModalAddHomeEducation from "./ModalAddHomeEducation"
import AxiosClient from "../../../api/AxiosClient"
import moment from "moment"
import Select from 'react-select'
import { customStyles } from "../../settings"

const ListHomeEducation = () => {
    const [dateBegin, setDateBegin] = useState([moment().startOf('month').toDate(),moment().endOf('month').toDate()])
    const [updateHomeEducation, setUpdateHomeEducation] = useState(false)
    const [addHomeEducation, setAddHomeEducation] = useState(false)
    const [homeEducations, setHomeEducations] = useState<any[]>([])
    const [selectHomeEducation, setSelectHomeEducation] = useState<any | null>(null)
    const [schools, setSchools] = useState<any[]>([])
    const [builds, setBuilds] = useState<any[]>([])
    const [classes, setClasses] = useState<any[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({ value: '', label: 'Не выбрано' })
    const [selectBuild, setSelectBuild] = useState<any>({ value: '', label: 'Не выбрано' })
    const [selectClass, setSelectClass] = useState<any>({ value: '', label: 'Не выбрано' })
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(false);
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState({value: '', label: 'Не выбрано'});

    useEffect(() => {
        fetchCateringOrganizer()
        fetchHomeEducations(1, perPage, selectSchool.value, selectBuild.value, selectClass.value, dateBegin, selectCateringOrganizer.value);
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
    }, [])

    const fetchHomeEducations = async (page: number, size = perPage, schoolId = '', buildingId = '', classId = '', date: Date[], cateringOrganizerId = '') => {
        setSearch(true)
        await AxiosClient.get(`/home_educations`, {params: {page: page, per_page: size, schoolId, buildingId, cateringOrganizerId, classId, date: [moment(date[0]).format('YYYY-MM-DD').toString(), moment(date[1]).format('YYYY-MM-DD').toString()]}})
            .then((response) => {
                setSearch(false)
                setHomeEducations(response.data.homeEducations);
                setTotalRows(response.data.total);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });

    };

    const fetchCateringOrganizer = () => {
        AxiosClient.get(`/users/catering_organizers`)
            .then((r) => setListCateringOrganizer(r.data))
    }

    const handlePageChange = (page: number) => {
        fetchHomeEducations(page, perPage, selectSchool.value, selectBuild.value, selectClass.value, dateBegin, selectCateringOrganizer.value);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        await fetchHomeEducations(page, newPerPage, selectSchool.value, selectBuild.value, selectClass.value, dateBegin, selectCateringOrganizer.value);
        setPerPage(newPerPage);
    };

    const onChangeBuild = (data: { value: string, label: string }) => {
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        fetchHomeEducations(currentPage, perPage, selectSchool.value, data.value, selectClass.value, dateBegin, selectCateringOrganizer.value);
        setSelectBuild(data)
        setClasses(sortClass)
        setSelectClass({ value: '', label: 'Не выбрано' })
    }

    const onChangeClass = (data: { value: string, label: string }) => {
        fetchHomeEducations(currentPage, perPage, selectSchool.value, selectBuild.value, data.value, dateBegin, selectCateringOrganizer.value);
        setSelectClass(data)
    }

    const onChangeSchool = (data: { value: string, label: string }) => {
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        fetchHomeEducations(currentPage, perPage, data.value, selectBuild.value, selectClass.value, dateBegin, selectCateringOrganizer.value);
        setSelectSchool(data)
    }

    const onChangeDate = (data: Date[]) => {
        fetchHomeEducations(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, data, selectCateringOrganizer.value);
        setDateBegin(data)
    }
    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };
    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchools(prevOptions => [...prevOptions, ...newData]);
    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const onChangeCateringOrganizer = (data: { value: string, label: string }) => {
        fetchHomeEducations(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, dateBegin, data.value);
        setSelectCateringOrganizer(data)
    }

    const onUpdateData = () => {
        fetchHomeEducations(1, perPage, selectSchool.value, selectBuild.value, selectClass.value, dateBegin, selectCateringOrganizer.value);
    }

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Лиц. счет</span>,
            selector: (row: any) => row.personal_account.personal_account,
        },
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>ФИО</span>,
            selector: (row: any) => `${row.personal_account.student.last_name} ${row.personal_account.student.name} ${row.personal_account.student.middle_name}`,
            wrap: true,
            width: '150px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Школа</span>,
            selector: (row: any) => row.personal_account.school.name,
            wrap: true,
            hide: 599
        },
        {
            name: <span className='font-weight-bold fs-13'>Корпус</span>,
            selector: (row: any) => row.personal_account.building.name,
            hide: 599
        },
        {
            name: <span className='font-weight-bold fs-13'>Класс</span>,
            selector: (row: any) => row.personal_account.class.name,
            width: '70px',
            hide: 599
        },
        {
            name: <span className='font-weight-bold fs-13'>Дней</span>,
            selector: (row: any) => row.count_day,
            width: '70px',
            hide: 1280
        },
        {
            name: <span className='font-weight-bold fs-13'>Сумма заказа (руб.)</span>,
            selector: (row: any) => `${row.result_price ? row.result_price : 0} р.`,
            hide: 1280,
            width: '130px',
            //right: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Получил</span>,
            selector: (row: any) => row.date_receive ? moment(new Date(row.date_receive)).format('DD.MM.Y') : "",
            hide: 1280,
            width: '120px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Дата с</span>,
            selector: (row: any) => row.date_begin ? moment(new Date(row.date_begin)).format('DD.MM.Y') : "",
            hide: 1280,
            width: '120px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Дата по</span>,
            selector: (row: any) => row.date_end ? moment(new Date(row.date_end)).format('DD.MM.Y') : "",
            hide: 1280,
            width: '120px'
        },
    ]

    return (
        <>
            <Row>
                <Col>
                    <label>Организатор питания:</label>
                    <Select
                        options={listCateringOrganizer?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...listCateringOrganizer.map(v => ({value: v.id, label: v.name}))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectCateringOrganizer}
                        onChange={(change: any) => {
                            onChangeCateringOrganizer({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={6} xl={6} xxl={3} >
                <label htmlFor="">Школа:</label>
                    <Select
                        options={schools?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...schools.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectSchool}
                        onChange={(change: any) => {
                            onChangeSchool({ value: change.value, label: change.label })
                        }}
                        isLoading={loadingOptions}
                        styles={customStyles}
                        onMenuScrollToBottom={handleMenuScroll}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={6} xl={6} xxl={3} >
                    <label htmlFor="">Корпус:</label>
                    <Select
                        options={builds?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...builds?.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectBuild}
                        onChange={(change: any) => {
                            onChangeBuild({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                        isLoading={loadingOptionsBuildings}
                    />
                </Col>
                <Col sm={12} lg={6} xl={6} xxl={3} >
                    <label htmlFor="">Класс:</label>
                    <Select
                        options={classes?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...classes?.map(v => ({ value: v.id, label: v.name }))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectClass}
                        onChange={(change: any) => {
                            onChangeClass({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                        isLoading={loadingOptionsBuildings}
                    />
                </Col>
                <Col sm={12} lg={6} xl={6} xxl={3} >
                    <label htmlFor="">Дата:</label>
                    <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        value={dateBegin}
                        options={{
                            locale: Russian, dateFormat: "d M Y", mode: 'range'
                        }}
                        onChange={(date) => {
                            onChangeDate(date);}}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={homeEducations}
                                noDataComponent={'Нет данных'}
                                progressComponent={<Spinner></Spinner>}
                                progressPending={search}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно учеников:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setSelectHomeEducation(row)
                                            setUpdateHomeEducation(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setSelectHomeEducation(row)
                                            setUpdateHomeEducation(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter>
                            <button className="btn btn-primary" onClick={() => setAddHomeEducation(true)}>Добавить</button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            {
                updateHomeEducation &&
                <ModalUpdateHomeEducation onUpdateData={() => onUpdateData()} updateHomeEducation={updateHomeEducation} setUpdateHomeEducation={value => setUpdateHomeEducation(value)} selectHomeEducation={selectHomeEducation} />

            }
            {
                addHomeEducation &&
                <ModalAddHomeEducation onUpdateData={() => onUpdateData()} addHOmeEducation={addHomeEducation} setAddHomeEducation={value => setAddHomeEducation(value)} />
            }
        </>
    )
}

export default ListHomeEducation
