import React, {useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment/moment";
import {Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles} from "../../settings";
import DataTable from "react-data-table-component";
import AddBuffet from "./AddBuffet";
import UpdateBuffet from "./UpdateBuffet";
import useDebounce from "../../../hooks/useDebounce";

const ListBuffet = () => {
    const [dateBegin, setDateBegin] = useState([new Date(), new Date()]);
    const [schools, setSchools] = useState<any[]>([]);
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'});
    const [personalAccount, setPersonalAccount] = useState('');
    const [addBuffet, setAddBuffet] = useState(false);
    const [updateBuffet, setUpdateBuffet] = useState(false);
    const [buffetOrders, setBuffetOrders] = useState<any[]>([]);
    const [selectBuffetOrder, setSelectBuffetOrder] = useState<any | null>(null);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(false);
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState({value: '', label: 'Не выбрано'});
    const debouncePersonalAccount = useDebounce(personalAccount, 500)

    useEffect(() => {
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
        fetchBuffetOrders(1, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, dateBegin, selectCateringOrganizer.value)
        AxiosClient.get(`/users/catering_organizers`)
            .then((r) => setListCateringOrganizer(r.data))
    }, []);

    useEffect(() => {
        search &&
        fetchBuffetOrders(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, dateBegin, selectCateringOrganizer.value);
    }, [debouncePersonalAccount]);

    const fetchBuffetOrders = async (page: number, size = perPage, schoolId = '', buildingId = '', classId = '', personalAccount: string, date: Date[], cateringOrganizerId = '') => {
        setSearch(true)
        await AxiosClient.get(`/buffet_orders`, {params: {page: page, per_page: size, schoolId, buildingId, cateringOrganizerId, classId, personalAccount, date: [moment(date[0]).format('YYYY-MM-DD').toString(), moment(date[1]).format('YYYY-MM-DD').toString()]}})
            .then((response) => {
                setSearch(false)
                setBuffetOrders(response.data.buffetOrders);
                setTotalRows(response.data.total);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });
    };

    const onChangeCateringOrganizer = (data: { value: string, label: string }) => {
        fetchBuffetOrders(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, dateBegin, data.value);
        setSelectCateringOrganizer(data)
    }
    const onChangePersonalAccount = (name: string) => {
        setPersonalAccount(name)
        setSearch(true)
    }

    const onChangeSchool = (data: { value: string, label: string }) => {
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        fetchBuffetOrders(currentPage, perPage, data.value, selectBuild.value, selectClass.value, personalAccount, dateBegin, selectCateringOrganizer.value);
        setSelectSchool(data)
    }
    const onChangeDate = (data: Date[]) => {
        fetchBuffetOrders(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, data, selectCateringOrganizer.value);
        setDateBegin(data)
    }
    const onChangeBuild = (data: { value: string, label: string }) => {
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        fetchBuffetOrders(currentPage, perPage, selectSchool.value, data.value, selectClass.value, personalAccount, dateBegin, selectCateringOrganizer.value);
        setSelectBuild(data)
        setClasses(sortClass)
        setSelectClass({ value: '', label: 'Не выбрано' })
    }

    const onChangeClass = (data: { value: string, label: string }) => {
        fetchBuffetOrders(currentPage, perPage, selectSchool.value, selectBuild.value, data.value, personalAccount , dateBegin, selectCateringOrganizer.value);
        setSelectClass(data)
    }

    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };
    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };
    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchools(prevOptions => [...prevOptions, ...newData]);
    };
    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const handlePageChange = (page: number) => {
        fetchBuffetOrders(page, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, dateBegin, selectCateringOrganizer.value)
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        fetchBuffetOrders(page, newPerPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, dateBegin, selectCateringOrganizer.value)
        setPerPage(newPerPage);
    };

    const syncBuffetOrders = () => {
        fetchBuffetOrders(1, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, dateBegin, selectCateringOrganizer.value)
    }

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Дата</span>,
            selector: (row: any) => moment(row.date).format('DD.MM.YYYY'),
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Лиц. счет</span>,
            selector: (row: any) => row.personal_account ? row.personal_account.personal_account : '',
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Школа</span>,
            selector: (row: any) => row.school.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Корпус</span>,
            selector: (row: any) => row.building.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Касса</span>,
            selector: (row: any) => row.cash_register.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Технологическая карта</span>,
            selector: (row: any) => row.product?.name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Кол-во</span>,
            selector: (row: any) => row.count,
        },
        {
            name: <span className='font-weight-bold fs-13'>Цена</span>,
            selector: (row: any) => row.price + ' р',
        },
        {
            name: <span className='font-weight-bold fs-13'>Сумма</span>,
            selector: (row: any) => row.sum + ' р',
        },
        {
            name: <span className='font-weight-bold fs-13'>Корр</span>,
            cell: (row: any) => !!row.correction ?  <i className="bx bx-check fs-3"></i> : '',
            width: '65px'
        },
    ]

    return (
        <>
            <Row>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                   <label className="w-100">Дата:
                    <Flatpickr
                        name="date"
                        className="form-control border-0 dash-filter-picker shadow"
                        value={dateBegin}
                        options={{
                            locale: Russian, dateFormat: "d M Y", mode: "range"
                        }}
                        onChange={(date) => onChangeDate(date)}
                    /></label>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label className="w-100">Организатор питания:
                    <Select
                        name="cateringOrganizer"
                        options={listCateringOrganizer?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...listCateringOrganizer.map(v => ({value: v.id, label: v.name}))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectCateringOrganizer}
                        onChange={(change: any) => {
                            onChangeCateringOrganizer({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                    />
                    </label>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label className="w-100">Школа:
                    <Select
                        name="school"
                        options={schools?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                        value={selectSchool}
                        onChange={(change: any) => {
                            onChangeSchool({value: change.value, label: change.label})
                        }}
                        isLoading={loadingOptions}
                        onMenuScrollToBottom={handleMenuScroll}
                        styles={customStyles}
                        placeholder="Поиск"
                    />
                    </label>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label className="w-100">Корпус:
                    <Select
                        name="building"
                        options={builds?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                        value={selectBuild}
                        onChange={(change: any) => {
                            onChangeBuild({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        isLoading={loadingOptionsBuildings}
                    />
                    </label>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label className="w-100">Класс:
                    <Select
                        name="class"
                        options={classes?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...classes?.map(v => ({value: v.id, label: v.name}))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectClass}
                        onChange={(change: any) => {
                            onChangeClass({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        isLoading={loadingOptionsBuildings}
                    />
                    </label>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label className="w-100">ЛС:
                    <input type="text" id="personalAccount" className="form-control" value={personalAccount}
                           onChange={(event) => onChangePersonalAccount(event.target.value)}/>
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardHeader className="d-flex gap-2">
                            <button className="btn btn-primary" onClick={() => setAddBuffet(true)}>Добавить</button>
                        </CardHeader>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={buffetOrders}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно заказов:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setSelectBuffetOrder(row)
                                            setUpdateBuffet(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setSelectBuffetOrder(row)
                                            setUpdateBuffet(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                addBuffet &&
                <AddBuffet syncBuffetOrders={() => syncBuffetOrders()} addBuffet={addBuffet} setAddBuffet={value => setAddBuffet(value)}/>
            }
            {
                updateBuffet &&
                <UpdateBuffet syncBuffetOrders={() => syncBuffetOrders()} updateBuffet={updateBuffet} setUpdateBuffet={value => setUpdateBuffet(value)} selectBuffetOrder={selectBuffetOrder}/>
            }
        </>
    );
};

export default ListBuffet;
