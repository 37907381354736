import React, {useEffect, useState} from 'react';
import {Alert, Col, Container, Row} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import SelectToUpdateCountry from "../../components/admin/country/SelectToUpdateCountry";
import CountryAdd from "../../components/admin/country/CountryAdd";
import CountryUpdate from "../../components/admin/country/CountryUpdate";
import {getCountries} from "../../store/reducers/admin/ActionCreator";

const SettingsCountry = () => {

    const dispatch = useAppDispatch();
    const {isError, countries} = useAppSelector(state => state.AdminReducer)

    const [addCountry, setAddCountry] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getCountries())
    }, [dispatch]);


    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdateCountry
                        setAddCountry={(val) => setAddCountry(val)}
                        countries={countries}/>
                    <div className="w-100 overflow-hidden">
                        <div className="chat-content d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {
                                                    addCountry ? <CountryAdd /> : <CountryUpdate/>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsCountry;
