import React, {FC, useEffect, useState} from 'react';
import {IDishes, INutrition} from "../../../models/parent/IFoodRation";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";
import {Card, CardBody, CardHeader} from "reactstrap";

interface ITableFoodRation {
    selectChildren: string | number,
    date: Date[]
}

const TableFoodRation: FC<ITableFoodRation> = ({selectChildren, date}) => {

    const [foodRation, setFoodRation] = useState<any[]>([]);

    useEffect(() => {
        selectChildren &&
        AxiosClient.get('/students/buffet_ration', {params: {date: [moment(date[0]).format('YYYY-MM-DD').toString(), moment(date[1]).format('YYYY-MM-DD').toString()], id: selectChildren}})
            .then((r) => setFoodRation(r.data))
    }, [selectChildren, date]);


    const columnsDishes: any = [
        {
            name: <span className='font-weight-bold fs-15'>Название</span>,
            selector: (row: any) => (<span className='font-weight-bold fs-13'>{row.assortment.name}</span>),
            wrap: true,
        },
        {
            name: <span className='font-weight-bold fs-15'>Кол-во</span>,
            selector: (row: any) => (<span className='font-weight-bold fs-13'>{row.count}</span>),
            wrap: true,
        },
        {
            name: <span className='font-weight-bold fs-15'>Цена</span>,
            selector: (row: any) => (<span className='font-weight-bold fs-13'>{row.price}</span>),
            wrap: true,
        },
        {
            name: <span className='font-weight-bold fs-15'>Сумма</span>,
            selector: (row: any) => (<span className='font-weight-bold fs-13'>{row.sum}</span>),
            wrap: true,
        },
    ]

    const columnsEating: any = [
        {
            name: <span className='font-weight-bold fs-15'></span>,
            selector: (row: any) => <span className="fs-15">{row.date}</span>,
            wrap: true,
            width: "15%"
        },
        {
            name: <div className='font-weight-bold fs-15 '>
                <div className="d-flex ">
                    <span className='font-weight-bold fs-15 mx-n3'>Блюдо</span>
                </div>
            </div>,
            selector: (row: any) => (
                <DataTable
                    columns={columnsDishes}
                    data={row.dishes}
                    noDataComponent={'Нет данных'}
                    //noTableHead
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            ),
            center: true,
            grow: 5,
            wrap: true,
            style: {
                display: 'block'
            },
        }
    ];


    return (
        <>
            {
                foodRation.map((v) => {
                    if (v.dishes.length > 0) {
                        return <Card>
                            <CardHeader>
                                {
                                    moment(v.date).format('DD.MM.YYYY').toString()
                                }
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={columnsDishes}
                                    data={v.dishes}
                                    noDataComponent={'Нет данных'}
                                    //noTableHead
                                    highlightOnHover
                                    customStyles={{
                                        rows: {
                                            highlightOnHoverStyle: {
                                                backgroundColor: "#ffebb5"
                                            }
                                        }
                                    }}
                                />
                            </CardBody>
                        </Card>
                    }
                    else {
                        return <></>
                    }
                })
            }
        </>
    );
};

export default TableFoodRation;
