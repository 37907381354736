import {createContext, useContext} from "react";
import {IUser} from "../models/IUser";

interface IStateContext {
    user: IUser,
    token: string | null,
    lockscreen: string | null,
    setLockscreen: (lock: string) => void,
    setUser: (user: IUser) => void,
    setToken: (token: string | null) => void,
    setTokenSession: (token: string | null) => void
}

export const stateContext = createContext<IStateContext>({
    user: {} as IUser,
    token: null,
    lockscreen: null,
    setLockscreen: () => { },
    setUser: () => { },
    setToken: () => { },
    setTokenSession: () => { }
})

export const useStateContext = () => useContext(stateContext)


