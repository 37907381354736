import React from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import EventCard from "./EventCard";

const GuestEvents = () => {
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xxl={3} sm={6}>
                        <EventCard/>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default GuestEvents;
