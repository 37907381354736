import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup'
import WidgetService from "../../../api/services/widgetService/WidgetService";
import Select from "react-select";

interface IModalAddDefect {
    addDefect: boolean,
    setAddDefect: (addDefect: boolean) => void
    setAddNewDefect: (defect: any) => void
}
const ModalAddDefect:FC<IModalAddDefect> = ({addDefect, setAddDefect, setAddNewDefect}) => {

    const [zamers, setZamers] = useState<any[]>([]);
    const [selectZamer, setSelectZamer] = useState<any | null>(null);

    useEffect(() => {
        WidgetService.getListControlZamer().then(r => {
            setZamers(r.data)
            setSelectZamer({value: r.data[0].uid, label: r.data[0].name})
        })
    }, []);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }

    return (
        <Modal id="createProjectModal" isOpen={addDefect}
               toggle={() => setAddDefect(!addDefect)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddDefect(!addDefect)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавить замеры
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            count: '',
                        }
                    }
                    validationSchema={
                        Yup.object({
                            count: Yup.string().required('Введите замер'),
                        })
                    }
                    onSubmit={(values) => {
                        setAddNewDefect({
                            name: selectZamer.label,
                            uid: selectZamer.value,
                            count: values.count
                        })
                        setAddDefect(false)
                    }}>
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Название:</label>
                                        <Select options={zamers.map(object => ({value: object.uid, label: object.name}))}
                                                value={selectZamer}
                                                onChange={(change: any) => {
                                                    setSelectZamer({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Данные замера:</label>
                                        <Field name="count" className="form-control"/>
                                        <ErrorMessage name="count"/>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <button type="submit" className="btn btn-primary" id="addNewProject">Добавить</button>
                                </div>
                            </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddDefect;
