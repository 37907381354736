import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import * as Yup from "yup";
import {Col, Label, Row} from "reactstrap";
import {updateCity} from "../../../store/reducers/admin/ActionCreator";

const UpdateDataCityForm = () => {
    const dispatch = useAppDispatch();
    const {selectCity} = useAppSelector(state => state.AdminReducer)

    return (
        <Formik
            enableReinitialize
            initialValues={{
                city: `${selectCity?.name}`
            }}
            validationSchema={Yup.object({
                city: Yup.string().required('Обязательное поле')
            })}
            onSubmit={(value) => {
                dispatch(updateCity(value, selectCity!.id))
            }}
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="roleInput"
                                   className="form-label">Город</Label>
                            <Field type="text" className="form-control" id="roleInput"
                                   name="city"
                                   placeholder="Введите город"/>
                            <ErrorMessage name="city">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataCityForm;
