import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {customStyles} from "../../settings";

interface IModalUpdateParallelClass {
    selectParallelClass: any
    updateParallelClass: boolean
    setUpdateParallelClass: (value: boolean) => void
    addMyParallelClasses: any[]
    setAddMyParallelClasses: (value: any[]) => void
}

const ModalUpdateParallelClass: FC<IModalUpdateParallelClass> = ({setAddMyParallelClasses, selectParallelClass, setUpdateParallelClass, updateParallelClass, addMyParallelClasses}) => {
    const [parallelClasses, setParallelClasses] = useState<any[]>([]);
    const [selectParallelClassesValue, setSelectParallelClassesValue] = useState<{ value: string, label: string }>({ value: '', label: 'Не выбрано' })


    useEffect(() => {
        AxiosClient.get('/parallel_classes')
            .then((r => setParallelClasses(r.data)))
    }, []);
    useEffect(() => {
        setSelectParallelClassesValue({ value: selectParallelClass?.id.toString(), label: selectParallelClass?.name})
    }, [selectParallelClass]);


    return (
        <Modal id="createProjectModal" isOpen={updateParallelClass}
               toggle={() => setUpdateParallelClass(!updateParallelClass)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setUpdateParallelClass(!updateParallelClass)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление параллели
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            parallel_class_id: selectParallelClassesValue.value,
                        }
                    }
                    validationSchema={
                        Yup.object({
                            parallel_class_id: Yup.string().required('Require'),
                        })
                    }
                    onSubmit={(values, {setErrors}) => {
                        const isDuplicate = addMyParallelClasses.some(m => m.id.toString() === values.parallel_class_id.toString())
                        if (isDuplicate) {
                            setErrors({parallel_class_id: 'Такая параллель уже существует'});
                        } else {
                            setAddMyParallelClasses(addMyParallelClasses.map(el => {
                                if (el.id === selectParallelClass.id){
                                    return parallelClasses.find(el => el.id.toString() === values.parallel_class_id)
                                }
                                return el
                            }))
                            setUpdateParallelClass(false)
                        }
                    }}
                >
                    {
                        ({errors, touched, setFieldValue}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Параллель:
                                            <Field name="parallel_class_id">
                                                {() => (
                                                    <Select
                                                        options={parallelClasses?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...parallelClasses.map(v => ({ value: v.id.toString(), label: v.name}))] : [{ value: '', label: 'Не выбрано' }]}
                                                        value={selectParallelClassesValue}
                                                        onChange={(change: any) => {
                                                            setSelectParallelClassesValue({ value: change.value, label: change.label })
                                                            setFieldValue('parallel_class_id', change.value)
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                        name="parallel_class_id"
                                                    />
                                                )}
                                            </Field>
                                        </label>
                                        {errors.parallel_class_id && touched.parallel_class_id ? (
                                            <div className="text-danger">{errors.parallel_class_id.toString()}</div>
                                        ) : null}
                                    </Col>
                                </Row>
                                <button type="submit" className="btn btn-primary mt-1" id="addNewProject">Сохранить</button>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateParallelClass;
