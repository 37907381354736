import React, {FC, useState} from 'react';
import {Card, CardBody, Label} from "reactstrap";
import {Field, Form, Formik} from "formik";
import userDummayImage from "../../../assets/images/users/user-dummy-img.jpg"
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {updateImageUser} from "../../../store/reducers/admin/ActionCreator";

const UpdateImageForm: FC = () => {
    const dispatch = useAppDispatch();
    const [file, setFile] = useState<File>()
    const {selectUser} = useAppSelector(state => state.AdminReducer)

    return (
        <Card className="mt-n5">
            <CardBody className="p-1">
                <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                        <img src={selectUser?.photo === null ? userDummayImage : `http://127.0.0.1:8000${selectUser?.photo}`}
                             className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                             alt="user-profile" />
                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                            <Formik
                                initialValues={{
                                    file1: ''
                                }}
                                onSubmit={
                                    (value, { resetForm }) => {
                                        const formData = new FormData();
                                        formData.append('img', file!);
                                        dispatch(updateImageUser(formData, selectUser!.id))
                                        resetForm()
                                    }
                                }
                            >{() => (
                                <Form encType="multipart/form-data" >
                                    <Field id="profile-img-file-input" type="file"
                                           className="profile-img-file-input" name="file1" onChange={(e: any) => { setFile(e.target?.files[0]); (document.getElementById('btnfile') as HTMLElement).click() }} />
                                    <Label htmlFor="profile-img-file-input"
                                           className="profile-photo-edit avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                    </Label>
                                    <button type="submit" id="btnfile"
                                            className="btn-primary" style={{ display: 'none' }} ></button>
                                </Form>
                            )}

                            </Formik>

                        </div>
                    </div>
                    <h5 className="fs-16 mb-1" >{`${selectUser?.name} ${selectUser?.last_name ? selectUser.last_name : ""}`}</h5>
                    <p className="text-muted mb-0">{selectUser?.organization?.name}</p>
                    <p className="text-muted mb-3">{selectUser?.position.name}</p>
                </div>
            </CardBody>
        </Card>
    );
};

export default UpdateImageForm;
