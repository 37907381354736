import React from 'react';
import ChatGPT from "./ChatGPT";
import {useAppSelector} from "../../hooks/redux";

const ChatMessageRequire = () => {
    const {selectChat} = useAppSelector(state => state.WidgetReducer)
    return (
        <>
            {
                selectChat?.id === 0 &&
                <ChatGPT/>
            }
        </>
    );
};

export default ChatMessageRequire;
