import { FC } from "react"
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";

interface IListParent {
    parents: any[],
    studentId: string | number,
    students: any[],
    setStudents: (value: any[]) => void,
}

const ListParent: FC<IListParent> = ({parents, setStudents, studentId, students}) => {

    const deleteParent = (idParent: string | number) => {
        AxiosClient.post('/students/delete_parent', {id: studentId, idUser: idParent})
            .then((r) => {
                setStudents(students.map(s => {
                    if(s.id.toString() === studentId.toString()){
                        return {
                            ...s,
                            parents: r.data
                        }
                    } else return s
                }))
            })
    }

    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'></span>,
            selector: (row: any) => '',
            width: '47px'
        },
        {
            name: <span className='font-weight-bold fs-13'></span>,
            selector: (row: any) => `${row.last_name} ${row.name} ${row.middle_name}`,
            wrap: true
        },
        {
            cell: (row: any) => <i className="bx bx-trash fs-3 cursor-pointer" title="Удалить" onClick={() => deleteParent(row.id)}></i>,
            center: true,
            width: '60px'
        }
    ]
    return (
        <>
            <DataTable
                columns={columns}
                data={parents}
                noTableHead
                noDataComponent={'Нет родителей'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        },
                    }
                }}
            />
        </>
    )
}

export default ListParent;