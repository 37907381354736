import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles} from "../../settings";
import moment from "moment";

interface ModalRecalculateRequestFromNutritionProps {
    recalculateRequestFromNutrition: boolean
    setRecalculateRequestFromNutrition: (value: boolean) => void
    updateData: () => void
}
const ModalRecalculateRequestFromNutrition: FC<ModalRecalculateRequestFromNutritionProps> = ({recalculateRequestFromNutrition, setRecalculateRequestFromNutrition, updateData}) => {
    const [dateBegin, setDateBegin] = useState([new Date(), new Date()]);
    const [schools, setSchools] = useState<any[]>([]);
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'});
    useEffect(() => {
        AxiosClient.get('/schools')
            .then((r) => {
                setSchools(r.data.schools)
            })
    }, []);

    useEffect(() => {
        fetchBuildings(selectSchool.value)
            .then(r => setBuilds(r))
        setSelectBuild({value: '', label: 'Не выбрано'})
        setSelectClass({value: '', label: 'Не выбрано'})
    }, [selectSchool.value, schools])

    useEffect(() => {
        const sortClass = builds?.find(s => s.id.toString() === selectBuild.value.toString())?.class
        setClasses(sortClass)
        setSelectClass({value: '', label: 'Не выбрано'})
    }, [builds, selectBuild.value])

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            return response?.data
        }
        return []
    };

    return (
        <Modal id="createProjectModal" isOpen={recalculateRequestFromNutrition}
               toggle={() => setRecalculateRequestFromNutrition(!recalculateRequestFromNutrition)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setRecalculateRequestFromNutrition(!recalculateRequestFromNutrition)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Пересчет заказов на питания
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{personal_account: ''}}
                    onSubmit={(values) => {
                        AxiosClient.post('/request_for_nutrition/recalculate', {
                            school_id: selectSchool.value,
                            building_id: selectBuild.value,
                            class_id: selectClass.value,
                            personalAccount: values.personal_account,
                            dateTo: moment(dateBegin[0]).format('YYYY-MM-DD'),
                            dateFrom: moment(dateBegin[1]).format('YYYY-MM-DD')
                        })
                            .then(() => updateData())
                            .catch(() => console.log('error'))
                    }}
                >
                    <Form>
                        <Row className="mt-1">
                            <Col>
                                <label className="w-100">Дата:
                                <Flatpickr
                                    className="form-control border-0 dash-filter-picker shadow"
                                    value={dateBegin}
                                    options={{
                                        locale: Russian, dateFormat: "d M Y", mode: "range"
                                    }}
                                    onChange={(date) => setDateBegin(date)}
                                />
                                </label>
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col>
                                <label className="w-100">Школа:
                                <Select
                                    options={schools?.length > 0 ? [{
                                        value: '',
                                        label: 'Не выбрано'
                                    }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                    value={selectSchool}
                                    onChange={(change: any) => {
                                        setSelectSchool({value: change.value, label: change.label})
                                    }}
                                    styles={customStyles}
                                    placeholder="Поиск"
                                    name="name"
                                />
                                </label>
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col>
                                <label className="w-100">Корпус:
                                <Select
                                    options={builds?.length > 0 ? [{
                                        value: '',
                                        label: 'Не выбрано'
                                    }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                    value={selectBuild}
                                    onChange={(change: any) => {
                                        setSelectBuild({value: change.value, label: change.label})
                                    }}
                                    styles={customStyles}
                                    placeholder="Поиск"
                                    name="name"
                                />
                                </label>
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col>
                                <label className="w-100">Класс:
                                <Select
                                    options={classes?.length > 0 ? [{
                                        value: '',
                                        label: 'Не выбрано'
                                    }, ...classes?.map(v => ({value: v.id, label: v.name}))] : [{
                                        value: '',
                                        label: 'Не выбрано'
                                    }]}
                                    value={selectClass}
                                    onChange={(change: any) => {
                                        setSelectClass({value: change.value, label: change.label})
                                    }}
                                    styles={customStyles}
                                    placeholder="Поиск"
                                    name="name"
                                />
                                </label>
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col>
                                <label className="w-100">ЛС:
                                    <Field type="text" name="personal_account" className="form-control"/>
                                </label>
                            </Col>
                        </Row>
                        <button type="submit" className="btn btn-primary mt-2" id="addNewProject">Сформировать</button>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalRecalculateRequestFromNutrition;
